import * as React from 'react';
import Send from '../assets/icons/send.svg';
import sendComment from '../assets/icons/sendComment.svg';

export const CommentInput = (props) => {
    const {
        comment, setComment, 
        onSend
    } = props;

    return(
        <div className='mt-[10px] border-white laptop:border-blue-border border-[1px] rounded-[11px] laptop:rounded-[19px] bg-white laptop:bg-white-input flex flex-row laptop:flex-col laptop:pt-[27px] laptop:pb-[15px] laptop:pl-[40px] laptop:pr-[15px]'>
            <div className='pl-[30px] w-full flex flex-col justify-center bg-white-input border-blue laptop:border-white-input border-[1px] rounded-[12px] laptop:rounded-none pr-[10px] laptop:pr-0'>
                <p className='flex laptop:hidden text-[10px] font-regular text-blue'>Написать комментарий</p>
                <input 
                    className='focus:outline-none text-[14px] laptop:text-[16px] font-regular text-black bg-white-input'
                    value={comment}
                    onChange={(event) => {
                        setComment(event.target.value);
                    }}
                    placeholder="Написать комментарий"
                />
            </div>
            <button 
                className=' h-[60px] w-[63px] rounded-[12px] bg-blue flex laptop:hidden flex-col items-center justify-center ml-[8px]'
                disabled={comment.length > 0? false: true}
                onClick={() => {
                    onSend();
                }}
            >
                <img src={Send} alt="image1" />
            </button>
            <div className='hidden laptop:flex flex-row items-center justify-end mt-[12px]'>
                <button 
                    className={`w-[35px] h-[35px] rounded-[35px] ${comment.length > 0? 'bg-blue': "bg-grey"} flex flex-col items-center justify-center`}
                    disabled={comment.length > 0? false: true}
                    onClick={() => {
                        onSend();
                    }}    
                >
                    <img src={sendComment} alt="image2" />
                </button>
            </div>
        </div>
    );
};