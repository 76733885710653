import * as React from 'react';
import ArrowDown from '../assets/icons/ArrowDown.svg'
import { getWorkingDay, isWorkingDay } from '../configs/functions';
import { Loading } from './Loading';

export const Calendar = (props) => {
    const {
        date, workingDates, onChangeDate,
        loading, isSimple
    } = props;
    const [date1, setDate1] = React.useState(date !== null? date: new Date());
    const [choosed, setChoosed] = React.useState(false);
    const [workDay, setWorkDay] = React.useState(null);
    
    const weekStyle = `text-14 desktop:text-14 text-center text-blue font-medium`;

    // console.log('date', date1);
    // console.log('working-dates', workingDates);

    return(
        <div className={styles.main}>
            <div className={styles.divHeader}>
                <div>
                    <button 
                        className={styles.btnPrevMobile}
                        onClick={() => {setDate1(new Date(date1.getTime() - 1000 * 60 * 60 * 24 * 30) ) }}
                    ><img src={ArrowDown} alt="image1" /></button>
                </div>
                <p className={styles.monthP}>{date1.toLocaleDateString('ru-RU', {month: 'long', year: 'numeric'})}</p>
                <div className=''>
                    <div className={styles.divLaptopArrows}>
                        <button 
                            className={styles.btnPrevLaptop}
                            onClick={() => {
                                setDate1(new Date(date1.getTime() - 1000 * 60 * 60 * 24 * 30) )
                            }}    
                        ><img src={ArrowDown} alt="image2" /></button>
                        <button 
                            className={styles.btnNextLaptop}
                            onClick={() => {
                                setDate1(new Date(date1.getTime() + 1000 * 60 * 60 * 24 * 30));
                            }}
                        ><img src={ArrowDown} alt="image3" /></button>
                    </div>
                    <button
                        className={styles.btnNextMobile}
                        onClick={() => {
                            setDate1(new Date(date1.getTime() + 1000 * 60 * 60 * 24 * 30));
                        }}
                    ><img src={ArrowDown} alt="image4" /></button>    
                </div>
            </div>
            <div className={styles.divWeek}>
                <p className={weekStyle}>Пн</p>
                <p className={weekStyle}>Вт</p>
                <p className={weekStyle}>Ср</p>
                <p className={weekStyle}>Чт</p>
                <p className={weekStyle}>Пт</p>
                <p className={weekStyle}>Сб</p>
                <p className={weekStyle}>Вс</p>
            </div>
            <div className={styles.divDate}>
            {
                getEmptyDate(new Date(date1.getFullYear(), date1.getMonth(), 1)).concat(getDaysOfMonth(getLastDayOfMonth(date1), date1))
                .map((item, index) =>
                    <button 
                        key={index.toString()}
                        className={`flex flex-col ${
                            item !== null? 
                                item.getDate() === date1.getDate() && item.getMonth() === date1.getMonth() && choosed? 
                                    'bg-blue text-white': 
                                    isSimple? 'bg-white-input text-blue' :
                                    isWorkingDay(workingDates, item)? 
                                        'bg-white-input text-blue': 
                                        'bg-grey-divide text-grey':
                                'bg-transparent'
                            } h-[36px] laptop:h-[73px] text-[12px] laptop:text-[18px] items-center rounded-[15px] justify-center`}
                        disabled={isSimple? false : !isWorkingDay(workingDates, item)}
                        onClick={() => {
                            if(item !== null) {
                                setDate1(item);
                                setChoosed(true);
                                setWorkDay(getWorkingDay(workingDates, item));
                                //getWorkingDay(workingDates, item)
                            };
                        }}    
                    >{item !== null? item.getDate(): ''}</button>
                )
            }
            </div> 
            <button
                className={styles.btnNextScreen(choosed)}
                onClick={() => { if(choosed) {onChangeDate(date1, workDay);}  }}
            >{loading?
                <Loading 
                    width="w-[20px]"
                    height="h-[20px]"
                    fill={"fill-grey"}
                    text="text-white"
                />: 
                'Далее'
            }</button>   
        </div>
    );
};

const styles = {
    main: 'w-full laptop:w-[527px]',
    divHeader: "flex flex-row justify-center desktop:justify-between laptop:justify-between items-center",
    btnPrevMobile: 'rotate-90 w-[41px] h-[41px] mr-[10px] flex flex-col items-center justify-center desktop:hidden laptop:hidden',
    monthP: 'text-14 laptop:text-[20px] text-black laptop:text-blue desktop:text-blue font-medium',
    divLaptopArrows: 'hidden desktop:flex laptop:flex flex-row items-end',
    btnPrevLaptop: 'rotate-90 w-[33.53px] h-[33.53px] flex flex-col items-center justify-center',
    btnNextLaptop: 'rotate-[270deg] w-[33.53px] h-[33.53px] flex flex-col items-center justify-center',
    btnNextMobile: 'flex flex-col items-center justify-center desktop:hidden laptop:hidden w-[41px] h-[41px] ml-[10px] rotate-[270deg]',
    divWeek: 'grid grid-cols-7 mt-[35px] gap-[4.88px]',
    divDate: 'grid grid-cols-7 mt-[20px] gap-[4px] laptop:gap-[23.78px]',
    
    btnNextScreen: (choosed) => `mt-[36px] h-[54px] laptop:h-[60px] rounded-[19px] ${choosed? 'bg-blue': 'bg-grey '} desktop:w-[289px] laptop:w-[289px] w-full flex flex-1 items-center justify-center text-white font-medium text-[16px] laptop:mx-auto`,
}

const getLastDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

const getDaysOfMonth = (days, date) => {
    let arr = [...Array(days)].map((item, index) => {
        return new Date(date.getFullYear(), date.getMonth(), index + 1);
    });
    
    return arr;
};

const getFixedDayOfWeek = (date) => {
    switch(date.getDay()) {
        case 1: return 1;
        case 2: return 2;
        case 3: return 3;
        case 4: return 4;
        case 5: return 5;
        case 6: return 6;
        default: return 7;
    }
};

const getEmptyDate = (date) => {
    let weekdayFirst = getFixedDayOfWeek(date);
    let arr = [];
    for(let i = 1; i < weekdayFirst; i++) {
        arr.push(null);
    };

    return arr;
}