import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector} from 'react-redux';

import { MainClient } from "./pages/client/MainClient";
import { Login } from './pages/login/Login';
import { Register } from './pages/login/Register';
import { Recovery } from './pages/login/Recovery';
import { PsychologiesClient } from './pages/client/PsycologiesClient';
import { AbouUs } from "./pages/static/aboutUs";
import { UsefulTips } from './pages/client/UsefulTips';
import { UsefulTipsView } from './pages/client/UsefulTipsView';
import { Management } from "./pages/static/management";
import { Contacts } from './pages/static/Contacts';
import { NewsClient } from './pages/client/NewsClient';
import { Faqs } from "./pages/static/faqs";
import { GoPsychotherapy } from "./pages/static/goPsychotherapy";
import { NewsViewClient } from './pages/client/NewsViewClient';
import { ArticleViewClient } from './pages/client/ArticleViewClient';
import { FormClient } from './pages/client/FormClient';
import { ProfileClient } from './pages/client/ProfileClient';
import { ViewPsycologie } from './pages/client/ViewPsycologie';
import { Payment } from "./pages/static/payment";
import { PrivacyPolicy } from "./pages/static/PrivacyPolicy";
import { TermOfUse } from "./pages/static/TermsOfUse";
import { Requisites } from "./pages/static/Requisites";
import { Collaboration } from "./pages/static/Collaboration";
import { CreateRecord } from './pages/client/CreateRecord';
import { ChangePassword } from "./pages/client/ChangePassword";
import { MySessions } from "./pages/client/MySessions";
import { VideoConference } from "./pages/client/videoСonference";
import { SelectedSessions } from "./pages/client/SelectedSession";
import { Support } from "./pages/static/Support";
import { Reset } from './pages/login/Reset';
import { Favorite } from './pages/client/Favorite';
import { setWorriesRedux } from "./redux/worries/worriesSlice";
import { setCitiesRedux } from "./redux/cities/citiesSlice";
import { Tests } from './pages/static/Tests';
import { ViewTest } from './pages/static/ViewTest';
import { ViewRecord } from './pages/client/ViewRecord';
import { ArticlePage } from './pages/client/ArticlePage';
import { request } from "./requests/requests";

export const MainRoutes = () =>{
    const signedIn = useSelector(state => state.auth.session.signedIn);
    const dispatch = useDispatch();
    const effectRun = React.useRef(false);
    
    const isLogged = useSelector(state => state.auth.session.signedIn);

    React.useEffect(() => { 
        if(effectRun.current === false) {
            (async function() {
                try {
                    let responseWorries = await request({
                        method: 'GET',
                        url: '/api/worries',
                        token: null,
                        body: undefined
                    })
                    if(responseWorries.status === 200) {
                        dispatch(setWorriesRedux(responseWorries.data.data));
                    }
                } catch(e) {
                    console.log('e', e);
                }
                try {
                    let responseCities = await request({
                        method: 'GET',
                        url: '/api/cities',
                        token: null,
                        body: undefined
                    });
                    if(responseCities.status === 200) {
                        dispatch(setCitiesRedux(responseCities.data.data));
                    }
                } catch(e) {
                    console.log('e', e);
                }
            })()
        }
        return () => { effectRun.current = true; }
        // eslint-disable-next-line
    }, []);

    return(
        <Routes>
            <Route path="/" element={<MainClient />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/recovery" element={<Recovery />} />
            <Route path="/reset/:id/:id" element={<Reset />} />
            <Route path="/psycologies" element={<PsychologiesClient />} />
            <Route path="/about-us" element={<AbouUs />} />
            <Route path="/usefultips" element={<UsefulTips />} />
            <Route path="/usefultips/:id" element={<UsefulTipsView />} />
            <Route path="/management" element={<Management />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/news" element={<NewsClient />} />
            <Route path="/articles" element={<ArticlePage />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/gopsychotherapy" element={<GoPsychotherapy />} />
            <Route path="/news/:id" element={<NewsViewClient />} />
            <Route path="/article/:id" element={<ArticleViewClient />} />
            <Route path="/client/form" element={<FormClient />} />
            <Route path="/client/profile" element={isLogged? <ProfileClient/>: <Navigate to={'/'} replace />} />
            <Route path="/psycologies/:id" element={<ViewPsycologie />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/term-of-use" element={<TermOfUse />} />
            <Route path="/requisites" element={<Requisites />} />
            <Route path="/collaboration" element={<Collaboration />} />
            <Route path="/record/create/:id" element={<CreateRecord />} />
            <Route path="/change-password" element={isLogged? <ChangePassword/>: <Navigate to={'/'} replace />} />
            <Route path="/my-sessions" element={<MySessions />} />
            <Route path="/conference" element={<VideoConference />} />
            <Route path="/my-sessions/:session" element={<SelectedSessions />}/>
            <Route path="/support" element={<Support />}/>
            <Route path="/favorite" element={isLogged? <Favorite />: <Navigate to={'/'} replace />} />
            <Route path="/record/:id" element={isLogged? <ViewRecord />: <Navigate to={'/'} replace />} />
            
            <Route path="/tests" element={<Tests />} />
            <Route path="/tests/:id" element={<ViewTest />} />
            <Route path="*" element={<Navigate to={signedIn? '/': '/login'} replace />} />
        </Routes>
    )
}