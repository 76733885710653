import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../../components/Modal';
import { ModalRecordTransfer } from '../../components/modals/ModalRecordTransfer';
import {request} from '../../requests/requests';
import {useTranslation} from 'react-i18next';


export const MySessions = () => {
    const navigate = useNavigate();
    
    const RepeatFunc = React.useRef(false);
    let token = useSelector(state => state.auth.session.token);
    const {t} = useTranslation();

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const update = async () => {
        try {
            setLoading(true);
            let response = await request({
                method: 'GET',
                url: '/api/records',
                token: token,
                body: undefined
            });
            if(response.status === 200) {
                setData(response.data.data);
            }
        } catch(e) {} finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if(RepeatFunc.current === false) {
            setLoading(true)
            if(token) { update(); }
        }
        return () => { RepeatFunc.current = true; }
        // eslint-disable-next-line
    }, [])

    return(
        <div className=''>
            <title>{t('myRecords.title')}</title>
            <div className='container mx-auto items-center content-center desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]'>
                <div className="flex flex-row">
                    <div className="text-blue mobile:text-16 desktop:text-[50px] font-medium">{t('myRecords.title').split(' ')[0]}</div>
                    <div className="mobile:text-16 desktop:text-[50px] font-medium desktop:ml-[13px] mobile:ml-[5px]">{t('myRecords.title').substring(t('myRecords.title').indexOf(' '))}</div>
                </div>
            </div>
            {
                loading? 
                <div className="flex flex-1 h-[50vh] justify-center items-center">
                    <div className="text-blue spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div> : 
                <>
                    {
                        Array.isArray(data)?
                            data.length > 0?
                                <div className='container mx-auto laptop:mt-[120px] mobile:mt-[40px] items-center grid desktop:grid-cols-4 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-[33px]'>
                                    {
                                        data.map((item, index) =>
                                            <Cards
                                                key={item.id+''}
                                                token={token}
                                                item={item}
                                                navigate={navigate}
                                                setData={setData}
                                                setLoading={setLoading}
                                                update={update}
                                            />
                                        )
                                    }
                                </div> 
                                :
                            <div className='flex flex-1 h-[300px] items-center'>
                                <button
                                    onClick={() => navigate('/psycologies')}
                                    className="bg-blue font-medium text-14 hover:bg-white hover:text-blue hover:border-blue hover:border rounded-[19px] flex justify-center items-center h-[52px] text-white disabled:bg-grey min-w-[257px] mobile:w-[90%] tablet:w-auto laptop:w-auto desktop:w-auto mx-auto mobile:mt-[0px] tablet:mt-[0px] laptop:mt-[5px] desktop:mt-[5px]">
                                    Записаться на консультацию
                                </button>
                            </div>:
                            <></>
                    }
                    
                </>
            }   
        </div>
    )
}

const Cards = (props) => {
    const { item, token, navigate, setData, setLoading, update} = props;
    
    const dataPsych = item.psych;
    const [modalTransfer, setModalTransfer] = React.useState(false);
    const {t} = useTranslation();

    return (
        <div  
            
            className=' px-[35px] py-[30px] rounded-[19px] border border-blue/10 flex flex-col gap-[10px] shadow-[0_0_80px_-0px_rgba(158,158,158,0.08)]'>
            <div
                className='cursor-pointer group '
                onClick={() => navigate(''+item.id)}>
                <div 
                    className='flex flex-row justify-between items-center'  
                >
                    <div className='flex flex-row items-center'>
                        
                        <div className='mr-[8px]'>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_856_463)">
                                    <path d="M7.5 3.28125C7.5 3.15693 7.45061 3.0377 7.36271 2.94979C7.2748 2.86189 7.15557 2.8125 7.03125 2.8125C6.90693 2.8125 6.7877 2.86189 6.69979 2.94979C6.61189 3.0377 6.5625 3.15693 6.5625 3.28125V8.4375C6.56253 8.52012 6.58439 8.60127 6.62588 8.67273C6.66737 8.74418 6.72701 8.80339 6.79875 8.84438L10.08 10.7194C10.1877 10.7776 10.3139 10.7913 10.4315 10.7577C10.5492 10.7241 10.6491 10.6458 10.7099 10.5396C10.7706 10.4333 10.7873 10.3075 10.7565 10.189C10.7257 10.0706 10.6498 9.96885 10.545 9.90562L7.5 8.16563V3.28125Z" fill="#3A7DFF"/>
                                    <path d="M7.5 15C9.48912 15 11.3968 14.2098 12.8033 12.8033C14.2098 11.3968 15 9.48912 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0C5.51088 0 3.60322 0.790176 2.1967 2.1967C0.790176 3.60322 0 5.51088 0 7.5C0 9.48912 0.790176 11.3968 2.1967 12.8033C3.60322 14.2098 5.51088 15 7.5 15ZM14.0625 7.5C14.0625 9.24048 13.3711 10.9097 12.1404 12.1404C10.9097 13.3711 9.24048 14.0625 7.5 14.0625C5.75952 14.0625 4.09032 13.3711 2.85961 12.1404C1.6289 10.9097 0.9375 9.24048 0.9375 7.5C0.9375 5.75952 1.6289 4.09032 2.85961 2.85961C4.09032 1.6289 5.75952 0.9375 7.5 0.9375C9.24048 0.9375 10.9097 1.6289 12.1404 2.85961C13.3711 4.09032 14.0625 5.75952 14.0625 7.5Z" fill="#3A7DFF"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_856_463">
                                        <rect width="15" height="15" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className='text-14 text-blue'>{new Date(item.window.working_date.date).toLocaleDateString('ru-RU', {day: '2-digit', month: 'short', year: 'numeric'})} {item.window.from} - {item.window.to}</div>
                    </div>
                    <div className='flex items-center justify-center rounded-full w-[34px] h-[34px] bg-white group-hover:bg-blue'>
                        <svg className='group-hover:stroke-white stroke-blue' fill='none' width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">    
                            <path d="M8.625 17.25L14.375 11.5L8.625 5.75"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <rect width="23" stroke='none' height="23" transform="translate(23) rotate(90)" fill="none"/>
                        </svg>
                    </div>
                </div>
                <div className='flex flex-row items-center'>
                    <div>
                        <img
                            src={dataPsych.avatar_url? dataPsych.avatar_url : require('../../assets/icons/nulled-photo.png')}
                            className="rounded-full w-[44px] h-[44px] object-cover"
                            alt='image1'
                        />
                    </div>
                    <div className='ml-[14px] font-medium text-16 whitespace-nowrap'>{dataPsych.first_name} {dataPsych.last_name}</div>
                </div>
            </div>
            
            <div className='flex flex-row'>
                <div className='text-14 whitespace-nowrap'>Статус сеанса:</div>
                <div className='text-14 text-green pl-[5px] whitespace-nowrap'>
                    {
                        item.status === 'active'? <p className='text-green text-14'>Активный</p> 
                        : item.status === 'cancelled_by_client'? <p className='text-red text-14'>Отмененный клиентом</p>
                        : item.status === 'cancelled_by_psych'? <p className='text-red text-14'>Отмененный психологом</p>
                        : item.status === 'done'? <p className='text-blue text-14'>Завершен</p>
                        : 'Ошибка'
                    }
                </div>
            </div>
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    window.open(item.meet_url, '_blank').focus();
                }}
                disabled={item.status !== 'active'} 
                className="bg-blue font-medium text-14 disabled:hover:text-white disabled:hover:border-grey hover:bg-white hover:text-blue hover:border-blue hover:border rounded-[19px] flex justify-center items-center h-[52px] text-white disabled:bg-grey min-w-[257px] mobile:w-full tablet:w-auto laptop:w-auto desktop:w-auto mx-auto mobile:mt-[0px] tablet:mt-[0px] laptop:mt-[5px] desktop:mt-[5px]">
                {t('myRecords.connect')}
            </button>
            <div className='text-[10px] text-grey leading-[12.75px] text-center'>Действие активно за 10 минут до начала сеанса</div>
            <button 
                onClick={(e) => {
                    e.stopPropagation();
                    setModalTransfer(true)
                }}
                disabled={item.status !== 'active'} 
                className="bg-white font-medium text-14  disabled:border-grey hover:text-white hover:bg-blue border border-blue rounded-[19px] flex justify-center items-center h-[52px] text-blue disabled:text-white disabled:bg-grey min-w-[257px] mobile:w-full tablet:w-auto laptop:w-auto desktop:w-auto mx-auto mobile:mt-[0px] tablet:mt-[0px] laptop:mt-[10px] desktop:mt-[10px]">
                    {t('myRecords.moveSeans')}</button>
            <Modal
                visible={modalTransfer}
                children={
                    <ModalRecordTransfer
                        token={token}
                        id={dataPsych.id}
                        close={() => { setModalTransfer(false); }}
                        onSelect={() => {
                            setModalTransfer(false);
                        }}
                        successFunction={() => {
                            setModalTransfer(false);
                            update();
                        }}
                        idRecord={item.id}
                        status={item.status}
                    />
                }
                onClose={() => {setModalTransfer(false); }}
            />
        </div>
    );
}