import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    test1, test2, test3
} from '../../configs/tests';
import {useTranslation} from 'react-i18next';
import { t } from 'i18next';

export const ViewTest = (props) => {
    const {} = props;
    const id = parseInt(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1));
    const navigate = useNavigate();
    const {t} = useTranslation();

    return( 
        <div className={styles.main}>
            {
                id === 1?
                    <Test1 data={test1} navigate={navigate} t={t} />:
                    id === 2?
                        <Test2 data={test2} t={t} />:
                        <Test3 data={test3} t={t} />
            }
        </div>
    );
};

const Test1 = (props) => {
    const {
        data, t
    } = props;
    const [choose, setChoose] = React.useState(new Array(data.questions.length).fill(0));
    const [result, setResult] = React.useState({
        visible: false,
        sum: 0
    });

    if(result.visible) {
        return(
            <div>
                <p className={styles.title}>{data.title}</p>
                <p className='mt-[20px] laptop:mt-[60px] laptop:text-[20px] text-[16px] font-regular text-blue'>Результат</p>
                <p className='mt-[20px] text-[16px] laptop:text-[20px] font-regular'>Индекс жизненной удовлетворенности составляет {result.sum} - {result.sum > 30? <label className='text-blue font-medium'>Максимальная удовлетворенность</label>: result.sum >= 25 && result.sum <= 30? <label className='text-grey font-medium'>Средняя удовлетворенность</label>: <label className='text-red font-medium'>Низкая удовлетворенность</label>}</p>
                <p className='mt-[20px] laptop:mt-[20px] whitespace-pre-line'>{data.interpretation}</p>
                <p className='mt-[20px] laptop:mt-[20px] whitespace-pre-line'>{data.other}</p>
                <button 
                    className='h-[54px] laptop:h-[60px] rounded-[19px] border-blue border-[1px] mt-[70px] laptop:w-[289px] w-full text-blue text-[16px] font-medium'
                    onClick={() => {
                        setChoose(new Array(data.questions.length).fill(0));
                        setResult({
                            visible: false,
                            sum: 0
                        })
                    }}
                >{t('test.runTest')}</button>
            </div>
        );
    } else {
        return(
            <div>
                <p className={styles.title}>{data.title}</p>
                <p className={styles.descr}>{data.descr}</p>
                <p className={styles.instruction}>Инструкция</p>
                <p className={styles.instructionP}>{data.instruction}</p>
                {
                    data.questions.map((item, index) => 
                        <div key={index.toString()} className='mt-[20px]'>
                            <p className={styles.quesTitle}>{index + 1}. {item.name}</p>
                            <RadioComponent 
                                key={index.toString()}
                                choose={choose}
                                setChoose={setChoose}
                                index={index}
                            />
                        </div>
                    )
                }
                <button 
                    className={`mt-[70px] w-full laptop:w-[289px] h-[54px] laptop:h-[60px] ${choose.includes(0)? 'bg-grey': 'bg-blue'} rounded-[19px] text-[16px] font-medium text-white`}
                    onClick={() => {
                        let sum = 0;
                        data.keys.map((item, index) => {
                            if(choose[index] === 1) {
                                sum += item.yes;
                            } else {
                                if(choose[index] === 2) {
                                    sum += item.no;
                                } else {
                                    sum += item.yesno
                                }
                            }
                        });
                        setResult({
                            visible: true,
                            sum: sum
                        });
                    }}
                    disabled={choose.includes(0)}
                >{t('test.getResult')}</button>
                <p className='mt-[20px] text-[16px] font-regular text-grey'>Необходимо ответить на все вопросы</p>
            </div>
        );
    };
};

const Test2 = (props) => {
    const {
        data
    } = props;
    const [choose, setChoose] = React.useState(new Array(data.questions.length).fill(0));
    const [result, setResult] = React.useState({
        visible: false,
        count: 0
    });

    if(result.visible) {
        return(
            <div>
                <p className={styles.title}>{data.title}</p>
                <p className='text-[14px] laptop:text-[16px] font-regular whitespace-pre-line'>{data.result}</p>
                <button 
                    className='h-[54px] laptop:h-[60px] rounded-[19px] border-blue border-[1px] mt-[70px] laptop:w-[289px] w-full text-blue text-[16px] font-medium'
                    onClick={() => {
                        setChoose(new Array(data.questions.length).fill(0));
                        setResult({
                            visible: false,
                            sum: 0
                        })
                    }}
                >{t('test.runTest')}</button>
            </div>
        ) 
    } else {
        return(
            <div>
                <p className={styles.title}>{data.title}</p>
                <p className={styles.descr}>{data.descr}</p>
                <p className='text-center mt-[20px] font-medium text-[16px] laptop:text-[20px]'>Краткая история</p>
                <p className='text-[14px] laptop:text-[16px] font-regular mt-[20px] whitespace-pre-line'>{data.history}</p>
                <p className='text-center mt-[20px] font-medium text-[16px] laptop:text-[20px]'>4 дескриптора</p>
                <p className='mt-[20px] text-[14px] laptop:text-[16px] font-regular whitespace-pre-line'>{data.desriptors}</p>
                {
                    data.questions.map((item, index) => 
                        <div 
                            key={index.toString()}
                            className={'mt-[20px]'}    
                        >
                            <p className='text-black font-medium text-[14px] laptop:text-[16px]'>{index + 1}. {item.name}</p>
                            <RadioComponent2 
                                key={index.toString()}
                                a={item.first.name}
                                b={item.second.name}
                                choose={choose}
                                setChoose={setChoose}
                                index={index}
                            />
                        </div>
                    )
                }
                <button 
                    className={`mt-[70px] w-full laptop:w-[289px] h-[54px] laptop:h-[60px] ${choose.includes(0)? 'bg-grey': 'bg-blue'} rounded-[19px] text-[16px] font-medium text-white`}
                    onClick={() => {
                        let sum = 0;
                        // data.keys.map((item, index) => {
                        //     if(choose[index] === 1) {
                        //         sum += item.yes;
                        //     } else {
                        //         if(choose[index] === 2) {
                        //             sum += item.no;
                        //         } else {
                        //             sum += item.yesno
                        //         }
                        //     }
                        // });
                        setResult({
                            visible: true,
                            sum: sum
                        });
                    }}
                    disabled={choose.includes(0)}
                >{t('test.getResult')}</button>
                <p className='mt-[20px] text-[16px] font-regular text-grey'>Необходимо ответить на все вопросы</p>
            </div>
        );
    }
    
};

const Test3 = (props) => {
    const {
        data
    } = props;
    const [choose, setChoose] = React.useState(new Array(data.questions.length).fill(0));
    const [result, setResult] = React.useState({
        visible: false,
        sum: 0
    });


    if(result.visible) {
        return(
            <div>
                <p className={styles.title}>{data.title}</p>
                <p className='text-[16px] laptop:text-[20px] font-medium mt-[20px]'>Вы - <label className='text-blue'>{result.sum < 36? 'Интроверт': result.sum >= 36 && result.sum <= 65? 'Амбиверт': 'Экстраверт' }</label></p>
                {
                    result.sum < 36?
                        <p className='mt-[20px] text-[14px] laptop:text-[16px] font-regular'><label className='text-blue font-medium'>интроверт</label> — человек, который заинтересован в том, чтобы найти интерес к себе, к своему внутреннему миру.</p>:
                        result.sum >= 36 && result.sum <= 65?
                            <p className='mt-[20px] text-[14px] laptop:text-[16px] font-regular'><label className='text-blue font-medium'>амбиверт</label> — это человек, которому свойственны черты как интроверта, так и экстраверта. Они имеют особенности обоих этих типов характера, и могут даже приспосабливаться в зависимости от ситуации.</p>:
                            <p className='mt-[20px] text-[14px] laptop:text-[16px] font-regular'><label className='text-blue font-medium'>экстраверт</label> — человек, который заинтересован в коммуникации с внешним миром.</p> 
                }
            </div>
        );
    } else {
        return(
            <div>
                <p className={styles.title}>{data.title}</p>
                <p className={styles.descr}>{data.descr}</p>
                <p className={styles.instruction}>Инструкция</p>
                <p className={styles.instructionP}>{data.instruction}</p>
                {
                    data.questions.map((item, index) => 
                        <div 
                            key={index.toString()}
                            className="mt-[20px]"    
                        >
                            <p className='text-[14px] laptop:text-[16px] font-medium'>{index + 1}. {item.name}</p>
                            <RadioComponent2 
                                key={index.toString()}
                                choose={choose}
                                setChoose={setChoose}
                                index={index}
                                a={item.a}
                                b={item.b}
                            />
                        </div>
                    )
                }
                <button 
                    className={`mt-[70px] w-full laptop:w-[289px] h-[54px] laptop:h-[60px] ${choose.includes(0)? 'bg-grey': 'bg-blue'} rounded-[19px] text-[16px] font-medium text-white`}
                    onClick={() => {
                        let sum = 0;
                        data.keys.map((item, index) => {
                            if(choose[index] === 1 && item.key === 'a') {
                                sum += 1;
                            } else {
                                if(choose[index] === 2 && item.key === 'b') {
                                    sum += 1;
                                }
                            }
                        });
                        setResult({
                            visible: true,
                            sum: sum * 5
                        });
                    }}
                    disabled={choose.includes(0)}
                >Получить результат</button>
                <p className='mt-[20px] text-[16px] font-regular text-grey'>Необходимо ответить на все вопросы</p>
            </div>
        );
    }
    
};

const styles = {
    main: 'container mx-auto',
    title: 'text-[20px] laptop:text-[39px] font-medium',
    descr: 'mt-[20px] laptop:mt-[60px] text-[14px] laptop:text-[16px] font-regular whitespace-pre-line',
    instruction: 'mt-[20px] text-[16px] laptop:text-[20px] text-blue font-regular',
    instructionP: 'mt-[20px] ',
    quesTitle: 'text-[16px] laptop:text-[20px] font-medium',
    divRadio: 'mt-[10px] laptop:mt-[30px] text-[14px] laptop:text-[14px] font-regular flex flex-row'
};

const RadioComponent = (props) => {
    const {choose, setChoose, index} = props;
    return(
        <div>
            <button 
                className={styles.divRadio}
                onClick={() => {
                    let temp = choose.map((item2, index2) => {if(index2 === index) {
                        return 1;
                    } else {
                        return item2;
                    }})
                    setChoose(temp);
                }}    
            >
                <div className={radio.div} >
                    <div className={radio.circle + ` ${choose[index] === 1? 'bg-blue': 'bg-white'}`} />    
                </div> 
                <p>Согласен</p>
            </button>
            <button 
                className={styles.divRadio}
                onClick={() => {
                    let temp = choose.map((item2, index2) => {if(index2 === index) {
                        return 2;
                    } else {
                        return item2;
                    }})
                    setChoose(temp);
                }}    
            >
                <div className={radio.div}>
                    <div className={radio.circle + ` ${choose[index] === 2? 'bg-blue': 'bg-white'}`} />    
                </div> 
                <p>Не согласен</p>    
            </button>
            <button 
                className={styles.divRadio}
                onClick={() => {
                    let temp = choose.map((item2, index2) => {if(index2 === index) {
                        return 3;
                    } else {
                        return item2;
                    }})
                    setChoose(temp);
                }}    
            >
                <div className={radio.div} >
                    <div className={radio.circle + ` ${choose[index] === 3? 'bg-blue': 'bg-white'}`} />    
                </div> 
                <p>Не знаю</p>
            </button>
        </div>
    );
};

const RadioComponent2 = (props) => {
    const {a, b, choose, setChoose, index} = props;

    return(
        <div className='flex flex-col'>
            <button 
                className='flex flex-row mt-[20px]'
                onClick={() => {
                    setChoose(choose.map((item2, index2) => {
                        if(index2 === index) {  
                            return 1;
                        } else {
                            return item2
                        }
                    }))
                }}    
            >
                <div className={radio.div} >
                    <div className={radio.circle + `${choose[index] === 1? 'bg-blue': 'bg-white'}`} />    
                </div> 
                <p className={`text-[14px] laptop:text-[16px] font-regular`}>{a}</p>
            </button>
            <button 
                className='flex flex-row mt-[20px]'
                onClick={() => {
                    setChoose(choose.map((item2, index2) => {
                        if(index2 === index) {  
                            return 2;
                        } else {
                            return item2
                        }
                    }))
                }}    
            >
                <div className={radio.div} >
                    <div className={radio.circle + `${choose[index] === 2? 'bg-blue': 'bg-white'}`} />    
                </div> 
                <p className='text-[14px] laptop:text-[16px] font-regular'>{b}</p>
            </button>
        </div>
    );
}

const radio = {
    div: 'w-[29px] h-[29px] border-blue border-[1px] rounded-[29px] flex flex-col items-center justify-center mr-[20px]',
    circle: 'w-[19px] h-[19px] rounded-[19px] '
}