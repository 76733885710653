import * as React from 'react';
import {useTranslation} from 'react-i18next';

export const Search = (props) => {
    const {
        search, setSearch
    } = props;
    const {t} = useTranslation();

    return(
        <div className={styles.main}>
            <div className={styles.divSvg} >
                <svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.4948 24.4987L19.2611 19.2557L24.4948 24.4987ZM22.1615 12.2487C22.1615 14.8788 21.1167 17.4011 19.2569 19.2608C17.3972 21.1206 14.8749 22.1654 12.2448 22.1654C9.61473 22.1654 7.09239 21.1206 5.23265 19.2608C3.37291 17.4011 2.32813 14.8788 2.32812 12.2487C2.32813 9.61863 3.37291 7.09629 5.23265 5.23656C7.09239 3.37682 9.61473 2.33203 12.2448 2.33203C14.8749 2.33203 17.3972 3.37682 19.2569 5.23656C21.1167 7.09629 22.1615 9.61863 22.1615 12.2487V12.2487Z" stroke="#3A7DFF" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </div>
            <input 
                className={styles.input}
                value={search}
                onChange={(event) => {setSearch(event.target.value); }}
                placeholder={t('listPsy.search')}
            />
            <button 
                className={styles.btn}
                onClick={() => { }}
            >
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.4948 24.4987L19.2611 19.2557L24.4948 24.4987ZM22.1615 12.2487C22.1615 14.8788 21.1167 17.4011 19.2569 19.2608C17.3972 21.1206 14.8749 22.1654 12.2448 22.1654C9.61473 22.1654 7.09239 21.1206 5.23265 19.2608C3.37291 17.4011 2.32813 14.8788 2.32812 12.2487C2.32813 9.61863 3.37291 7.09629 5.23265 5.23656C7.09239 3.37682 9.61473 2.33203 12.2448 2.33203C14.8749 2.33203 17.3972 3.37682 19.2569 5.23656C21.1167 7.09629 22.1615 9.61863 22.1615 12.2487V12.2487Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </button>
        </div>
    );
};

const styles = {
    main: 'flex flex-row items-center justify-between bg-white-input laptop:bg-white rounded-[11px] laptop:mt-[60px] mt-[11px]',
    divSvg: 'flex laptop:hidden bg-white laptop:w-[78px] laptop:h-[78px] flex-col items-center justify-center rounded-[20px] laptop:ml-[33px] ml-[17px]',
    input: 'h-[43px] laptop:h-[78px] bg-white-input border-white-input laptop:border-blue border-[1px] rounded-[11px] laptop:rounded-[20px] w-full focus:outline-none px-[20px] laptop:px-[40px] text-[16px] font-regular text-black',
    btn: 'hidden laptop:flex bg-blue laptop:w-[78px] laptop:h-[78px] flex-col items-center justify-center rounded-[20px] laptop:ml-[33px]'
};