import * as React from 'react';
import Location from '../../assets/icons/location.svg';
import {useNavigate} from 'react-router-dom';
import {Modal} from '../../components/Modal';
import { ModalRecordTransfer } from '../../components/modals/ModalRecordTransfer';
import { ModalRecordCancel } from '../../components/modals/ModalRecordCancel';
import { useSelector } from 'react-redux';
import Play from '../../assets/icons/play.svg';
import { sessionTypeRevert } from '../../configs/functions';
import {useTranslation} from 'react-i18next';
import { request } from '../../requests/requests';

export const ViewPsycologie = () => {
    const effectRun = React.useRef(false);
    const navigate = useNavigate();
    const [modalVisibleTransfer, setModalVisibleTransfer] = React.useState(false);
    const [modalVisibleCancel, setModalVisibleCancel] = React.useState(false);
    const citiesRedux = useSelector(state => state.cities.cities);
    const [cities, setCities] = React.useState(citiesRedux);
    const token = useSelector(state => state.auth.session.token);
    const {t} = useTranslation();

    const [data, setData] = React.useState({
        first_name: '', 
        last_name: '',
        psych_type: '',
        typeWork: '',
        city: '',
        completed_records_count: 0,
        price: 0,
        typePay: '',
        email: '',
        phone: '',
        descr: '',
        direction: [],
        session_types: [],
        vk_url: null,
        telegram_url: null,
        video_presentation_url: null,
        worries: [],
        educations: []
    });

    const update = async () => {
        try {
            let response = await request({
                method: 'GET',
                url: `/api/users/${parseInt(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1))}`,
                token: token,
                body: undefined
            });
            if(response.status === 200) {
                setData(response.data.data);
            }
        } catch(e) {

        }
        try {
            let response = await request({
                method: 'GET',
                token: token,
                url: '/api/cities',
                body: undefined
            });
            if(response.status === 200) {
                setCities(response.data.data);
            }
        } catch(e) {

        }
    }

    React.useEffect(() => {
        if(effectRun.current === false) {
            update();
        }
        return () => { effectRun.current = true; }
        // eslint-disable-next-line
    }, []);

    return(
        <div className={styles.main}>
            <title>{t('profile.title')}</title>
            <p className={styles.title}>{t('profile.title')}</p>
            <div className={styles.divGrid}>
                <div className={styles.div1}> 
                    <div className={styles.div1Top}>
                        <div className={styles.div1Avatar}>
                            <img className={styles.avatar} alt="..." src={data.avatar_url? data.avatar_url : require('../../assets/icons/nulled-photo.png')}/>
                        </div>

                        <div className='ml-[20px] desktop:hidden laptop:hidden'>
                            <p className='text-16 font-medium'>{data.first_name} {data.last_name}</p>
                            <p className='mt-[3px] text-12 font-regular text-grey'>{data.psych_type === 'staff'? 'Штатный психолог': 'Частный психолог'}</p>
                            <div className='mt-[10px] flex flex-row text-12 font-regular items-center'><img className='mr-[10px]' alt='image1' src={Location} />{data.city}</div>
                        </div>
                    </div>
                    {
                        data.video_presentation_url !== null?
                            <button
                                className='flex flex-row items-center justify-center mx-[19px] bg-white-input rounded-[19px] py-[16px] mt-[27px]'
                                onClick={() => {
                                    window.open(data.video_presentation_url)
                                }}
                            >
                                <img src={Play} alt="image2" />
                                <p className='text-14 font-regular ml-[10px]'>{t('profile.videoPresentation')}</p>
                            </button>:
                            <></>
                    }
                    <button className={styles.button}><p className={styles.buttonTop}>{data.completed_records_count? data.completed_records_count: 0}</p><p className={styles.buttonBottom}>{t('profile.countConc')}</p> </button>
                    <div className={styles.button}>
                        <p className={styles.buttonTop}>от {data.price} (1 час)</p>
                        <p className={styles.buttonBottom}>{t('profile.price')}</p> 
                    </div>
                    <div className={styles.button}>
                        <p className={styles.buttonTop}>{data.session_types.map((item, index) => sessionTypeRevert(item.session_type)).toString()}</p>
                        <p className={styles.buttonBottom}>{t('profile.format')}</p>
                    </div>
                    <button 
                        className='mt-[30px] w-full h-[54px] rounded-[19px] bg-blue text-white text-16 font-medium mb-[13px]'
                        onClick={() => { 
                            navigate('/record/create/' + data.id); 
                        }}
                    >{t('profile.write')}</button>
                </div>
                <div className='col-span-2 container mx-auto'>
                {
                    <>
                        <p className='hidden desktop:flex laptop:flex text-[40px]'>{data.first_name !== null? data.first_name: ''} {data.last_name !== null? data.last_name: ''}</p>
                        <p className='hidden desktop:flex laptop:flex text-grey text-18 mt-[6px]'>{data.psych_type === 'staff'? 'Штатный психолог': 'Частный психолог'}</p>
                        <div className='hidden desktop:flex laptop:flex flex-row mt-[20px]'>
                            <img className='mr-[10px] text-20 font-regular' alt='image3' src={Location} />{cities.find(element => element.id === data.city_id) !== undefined? cities.find(element => element.id === data.city_id).name: ''}
                        </div>
                        {
                            // Создать проверку имеющихся записей
                        }
                        <div className='mt-[77px] flex flex-col items-center'>
                            {
                                data?.descr?
                                    <>
                                        <p className='text-14 desktop:text-20 laptop:text-20 font-medium self-start'>{t('profile.specialist')}</p>
                                        <p className='mt-[20px] text-12 desktop:text-16 laptop:text-16'>{data.descr}</p>
                                    </>:<></>
                            }
                            <DropDownNo 
                                label={t('profile.education')} 
                                style1='mt-[10px]'
                                data={ data.educations?.filter((item, index) => item.type !== '' && item.type !== 'retraining') ?? []}    
                                child={(item, index) => (
                                    <div 
                                        key={index.toString()} 
                                        className={`py-[15px] ${index < [1,2]?.length - 1? 'border-b-grey border-b-[1px] ': ' '} `}
                                    >
                                        <p className='text-[14px] laptop:text-[16px] font-regular'>{item.university}</p>
                                        {
                                            item.profile?
                                                <p className='text-grey mt-[10px] text-[14px] laptop:text-[16px] font-regular'>Профиль:<label className='text-black ml-[5px]'>{item.profile}</label></p>:
                                                <></>
                                        }
                                    </div>
                                )}
                            />
                            {
                                data.educations?.length > 0?
                                    <DropDownNo 
                                        label={t('profile.courses')} 
                                        style1='mt-[26px]'
                                        data={data.educations?.filter((item, index) => item.type === 'refresher') ?? []}   
                                        child={(item, index) => (
                                            <div className={`flex flex-row py-[15px] ${index < data.educations?.filter((item, index) => item.type === 'refresher')?.length - 1? 'border-b-grey border-b-[1px] ': ' '}`}>
                                                <label className='mr-[10px] text-[14px] laptop:text-[16px] font-regular'>{item.university}</label>
                                                {
                                                    item.profile? 
                                                        <label className='mr-[10px] text-[14px] laptop:text-[16px] font-regular'>{item.profile}</label>:<></>
                                                }
                                                <label className='text-[14px] laptop:text-[16px] font-regular'>{item.year}</label>
                                            </div>
                                        )} 
                                    />: <></>
                            }
                            <DropDownNo 
                                label={t('profile.workLine')} 
                                style1='mt-[26px]'
                                data={data?.worries ?? []}   
                                child={(item, index) => (
                                    <p
                                        key={index.toString()}
                                        className={`py-[15px] text-[14px] laptop:text-[16px] font-regular ${index < data?.worries?.length - 1? 'border-b-grey border-b-[1px] ': " "} `}
                                    >{item.title}</p>
                                )} 
                            />
                            {
                                data.vk_url !== null && data.telegram_url !== null?
                                <>
                                    <p className='self-start mt-[26px] desktop:mt-[40px] laptop:mt-[40px] text-14 desktop:text-20 laptop:text-20 font-medium'>{t('profile.social')}</p>
                                    <div className='self-start mt-[22px] laptop:[30px] flex flex-row'>
                                        {
                                            data.telegram_url !== null?
                                                <button 
                                                    className='w-[41px] h-[41px] laptop:w-[70px] laptop:h-[70px] bg-white-input rounded-[100px] mr-[25px] flex items-center justify-center'
                                                    onClick={() => { window.open(data.telegram_url, 'noopener,noreferrer'); }}    
                                                >
                                                    <svg width="30" height="25.16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17.2097 3.42589L2.4462 9.11894C1.43865 9.52363 1.44448 10.0857 2.26134 10.3363L6.05171 11.5187L14.8215 5.98556C15.2362 5.73325 15.6151 5.86898 15.3037 6.14543L8.19837 12.5579H8.1967L8.19837 12.5588L7.93691 16.4657C8.31994 16.4657 8.48898 16.29 8.70381 16.0827L10.5449 14.2924L14.3744 17.121C15.0805 17.5099 15.5876 17.31 15.7633 16.4674L18.2772 4.61996C18.5345 3.58826 17.8833 3.12113 17.2097 3.42589Z" fill="#234082"/>
                                                    </svg>
                                                </button>:
                                                <></>
                                        }
                                        {
                                            data.vk_url !== null?
                                                <button 
                                                    className='w-[41px] h-[41px] laptop:w-[70px] laptop:h-[70px] bg-white-input rounded-[100px] flex items-center justify-center'
                                                    onClick={() => { window.open(data.vk_url, 'noopener,noreferrer'); }}    
                                                >
                                                    <svg className='hidden laptop:flex' width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M19.5413 5.74486C19.6796 5.31586 19.5413 5 18.8788 5H16.6913C16.1347 5 15.878 5.27264 15.7388 5.57357C15.7388 5.57357 14.6264 8.08471 13.0506 9.71586C12.5406 10.1889 12.3089 10.3389 12.0306 10.3389C11.8914 10.3389 11.6823 10.1889 11.6823 9.75907V5.74486C11.6823 5.22943 11.5289 5 11.0656 5H7.62567C7.27818 5 7.06902 5.23886 7.06902 5.46593C7.06902 5.95386 7.85734 6.067 7.93817 7.44043V10.4246C7.93817 11.0791 7.81067 11.1977 7.53234 11.1977C6.79069 11.1977 4.98656 8.67479 3.91575 5.78807C3.70826 5.22629 3.49826 5 2.93911 5H0.749984C0.124997 5 0 5.27264 0 5.57357C0 6.10943 0.741651 8.77143 3.45409 12.2922C5.26239 14.6965 7.80817 16 10.1273 16C11.5181 16 11.6898 15.7109 11.6898 15.2119V13.3946C11.6898 12.8155 11.8214 12.7 12.2622 12.7C12.5872 12.7 13.1431 12.8509 14.4414 14.0098C15.9247 15.384 16.1688 16 17.0038 16H19.1913C19.8163 16 20.1296 15.7109 19.9496 15.1389C19.7513 14.57 19.0429 13.7442 18.1038 12.7644C17.5938 12.2066 16.8288 11.6055 16.5963 11.3046C16.2722 10.9188 16.3647 10.7467 16.5963 10.4034C16.5963 10.4034 19.2629 6.92579 19.5404 5.74486H19.5413Z" fill="#234082"/>
                                                    </svg>
                                                    <svg className='flex laptop:hidden' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M19.5413 5.74486C19.6796 5.31586 19.5413 5 18.8788 5H16.6913C16.1347 5 15.878 5.27264 15.7388 5.57357C15.7388 5.57357 14.6264 8.08471 13.0506 9.71586C12.5406 10.1889 12.3089 10.3389 12.0306 10.3389C11.8914 10.3389 11.6823 10.1889 11.6823 9.75907V5.74486C11.6823 5.22943 11.5289 5 11.0656 5H7.62567C7.27818 5 7.06902 5.23886 7.06902 5.46593C7.06902 5.95386 7.85734 6.067 7.93817 7.44043V10.4246C7.93817 11.0791 7.81067 11.1977 7.53234 11.1977C6.79069 11.1977 4.98656 8.67479 3.91575 5.78807C3.70826 5.22629 3.49826 5 2.93911 5H0.749984C0.124997 5 0 5.27264 0 5.57357C0 6.10943 0.741651 8.77143 3.45409 12.2922C5.26239 14.6965 7.80817 16 10.1273 16C11.5181 16 11.6898 15.7109 11.6898 15.2119V13.3946C11.6898 12.8155 11.8214 12.7 12.2622 12.7C12.5872 12.7 13.1431 12.8509 14.4414 14.0098C15.9247 15.384 16.1688 16 17.0038 16H19.1913C19.8163 16 20.1296 15.7109 19.9496 15.1389C19.7513 14.57 19.0429 13.7442 18.1038 12.7644C17.5938 12.2066 16.8288 11.6055 16.5963 11.3046C16.2722 10.9188 16.3647 10.7467 16.5963 10.4034C16.5963 10.4034 19.2629 6.92579 19.5404 5.74486H19.5413Z" fill="#1D1D1D"/>
                                                    </svg>
                                                </button>:
                                                <></>
                                        }
                                    </div>
                                </>:<></>
                            }
                        </div>
                    </>
                }
                </div>
            </div>
            <Modal 
                visible={modalVisibleTransfer}
                children={
                    <ModalRecordTransfer 
                        close={() => { setModalVisibleTransfer(false); }}
                        onSelect={() => {
                            setModalVisibleTransfer(false);
                        }}
                        successFunction={() => {
                            setModalVisibleTransfer(false);
                            update();
                        }}
                    />
                }
                onClose={() => { setModalVisibleTransfer(false); }}
            />
            <Modal 
                visible={modalVisibleCancel}
                children={
                    <ModalRecordCancel 
                        close={() => { setModalVisibleCancel(false); }}
                        onSelect={() => {
                            setModalVisibleCancel(false);
                        }}
                        data={data}
                    />
                }
                onClose={()=> {setModalVisibleCancel(false); }}
            />
        </div>
    )
};

const styles = {
    main: 'container mx-auto',
    title: 'text-16 laptop:text-39 font-medium text-center laptop:text-start mt-[23px] laptop:mt-[90px]',
    divGrid: 'grid grid-cols-1 desktop:grid-cols-3 laptop:grid-cols-3 gap-0 laptop:gap-[20px]',
    div1: 'mt-[45px] h-fit desktop:mt-0 laptop:mt-0 col-span-1 bg-white rounded-[11px] desktop:border-white laptop:border-white border-white-input border-[1px] flex flex-col content-center',
    div1Top: 'flex flex-row px-[19px] mt-[17px] items-center justify-center',
    div1Avatar: 'bg-white w-[81px] desktop:w-[190px] h-[81px] desktop:h-[190px] rounded-[95px] items-center justify-center flex shadow-[0_0px_80px_0px_rgba(158,158,158,0.2)] self-center',
    avatar: 'w-[74px] desktop:w-[168px] h-[74px] desktop:h-[168px] rounded-full',
    button: 'flex flex-col bg-white-input flex-1 mx-[23px] rounded-[19px] pt-[20px] pb-[18px] items-center justify-center mt-[15px]',
    buttonTop: 'text-20 font-regular text-blue',
    buttonBottom: 'mt-[2px] text-14 font-regular text-grey'
}

const DropDownNo = (props) => {
    const {label, style1, data, child} = props;
    const [show, setShow] = React.useState(false);

    return(
        <div className={`flex flex-col self-start w-full ${style1}`}>
            <div 
                className='flex flex-row items-center justify-between'
                onClick={() => { setShow(!show); }}    
            >
                <p className='text-14 desktop:text-20 laptop:text-20 flex flex-1 font-medium mr-[50px]'>{label}</p>
                <div className={`${show? 'rotate-[180deg]': 'rotate-0'}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 9L12 15L18 9" stroke="#3A7DFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>

            <div
                className={`${show? 'flex ': 'hidden '}bg-white flex-col ${show? 'translate-y-0 opacity-1': '-translate-y-10 opacity-0 '}`}
            >
            {
                data.map((item, index) => 
                    child(item, index)
                )
            }
            </div>
            
        </div>
    );
}

//psyminmol_miisemaks
// cb8B_4pHsSaA