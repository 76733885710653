import React from 'react';
import { useNavigate } from "react-router-dom";

export const HoverMain = (props) => {
    const navigate = useNavigate();
    const [hoverActive, setHoverActive] = React.useState(false);
    const {titleText, text, uriLink} = props;
    return (
        <div
            onMouseEnter={() => { setHoverActive(true) }}
            onMouseLeave={() => { setHoverActive(false) }}
            onClick={() => {
                navigate('/'+ uriLink)
            }}
            className='relative cursor-pointer desktop:h-[400px] laptop:h-[300px] mobile:h-auto mobile:px-[10px] tablet:px-[20px] laptop:px-[40px] desktop:px-[52px] mobile:pt-[35px] mobile:pb-[35px] desktop:pt-[66px] pb-[28px] bg-white hover:bg-blue hover:text-white rounded-[19px] border-solid border-blue border-[1px] shadow-[0_4px_20px_rgba(34,134,250,0.05)]'>
            <h3 className='desktop:leading-[36px] laptop:leading-[24px] tablet:leading-[16px] mobile:leading-[16px] mobile:text-[13px] tablet:text-[16px] laptop:text-[24px] desktop:text-[30px] desktop:pb-[50px] mobile:pb-[15px] font-medium'>{titleText}</h3>
            <span className='desktop:text-16 mobile:text-[12px] font-regular desktop:leading-[20px] laptop:leading-[20px] tablet:leading-[15px] mobile:leading-[12px]'>{text}</span>
            <svg
                className={`desktop:w-[68px] desktop:h-[68px] laptop:w-[56px] laptop:h-[56px] desktop:flex laptop:flex tablet:hidden mobile:hidden absolute bottom-[28px] fill-none  rounded-[50%] ${hoverActive ? 'bg-white ' : ''}  stroke-blue`} width="68" height="68" viewBox="0 0 68 68" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="66" height="66" rx="33" stroke={hoverActive ? 'transparent' : 'stroke-blue'} />
                <path d="M26.7109 26.7129H41.2862V41.2882" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M26.7109 41.2871L41.2862 26.7118" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </div>
    )
}
