import { API } from '../configs/api';

export const PostData = async (route, token, body, responseFunction, errorFunction) => {
    await fetch(API + route, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token !== null? `Bearer ${token}`: null
        },
        body: body
    })
    .then((response) => {
        let statusCode = response.status;
        let data = response.json();

        return Promise.all([data, statusCode]);
    })
    .then(([json, statusCode]) => {
        responseFunction(json, statusCode);
    })
    .catch((e) => {
        errorFunction(e);
    })
};

export const uploadPhoto = async (url, token, formData, responseFunction, errorFunction) => {
    await fetch(API + url, {
        method: 'POST',
        headers: {
            Accept: 'application/json'
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': token !== null? `Bearer ${token}`: null
        },
        body: formData
    }).then((response) => {
        let status = response.status;
        let data = response.json();
        return Promise.all([data, status]);
    }).then(([json, statusCode]) => {
        responseFunction(json, statusCode);
    })
    .catch((e) => {
        errorFunction(e);
    })
}