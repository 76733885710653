import { createSlice } from '@reduxjs/toolkit';

export const psychSlice = createSlice({
    name: 'psych',
    initialState: {
        psych: []
    },
    reducers: {
        setPsych: (state, action) => {
            state.psych = action.payload
        }
    }
});

export const {
    setPsych
} = psychSlice.actions;

export default psychSlice.reducer;