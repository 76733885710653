import * as React from 'react';
import { Calendar } from '../../components/Calendar';
import {Pills} from '../../components/Pills';
import { TimePicker } from '../../components/TimePicker';
import { Input } from '../../components/Input';
import { DropDown } from '../../components/DropDown';
import { Avatar } from '../../components/Avatar';
import Time2 from '../../assets/icons/time2.svg';
import DefaultAvatar from '../../assets/icons/fi_user.svg';
import { useSelector } from 'react-redux';
import { GetData } from '../../requests/get';
import { getDuration, getPsychType, groupFunction, groupFunctionRevert, sessionTypeFunction, sessionTypeRevert } from '../../configs/functions';
import { PostData } from '../../requests/post';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import {useTranslation} from 'react-i18next';
import { t } from 'i18next';
import { v4 as uuid } from 'uuid';
import {request} from '../../requests/requests';

export const CreateRecord = () => {
    const token = useSelector(state => state.auth.session.token);
    const emailRedux = useSelector(state => state.auth.user.email);
    const phoneRedux = useSelector(state => state.auth.user.phone);
    const {t} = useTranslation();

    const idPsy = parseInt(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1));

    const [screenType, setScreenType] = React.useState('Выберите дату посещения');
    const [screenIndex, setScreenIndex] = React.useState(0);
    const [date, setDate] = React.useState(new Date());
    const [idDate, setIdDate] = React.useState(0);

    const [group, setGroup] = React.useState('Один');
    const [session, setSession] = React.useState('Онлайн');
    const [email, setEmail] = React.useState(emailRedux);
    const [phone, setPhone] = React.useState(phoneRedux);
    const [anonim, setAnonim] = React.useState(false);

    const [workingId, setWorkingId] = React.useState(null);
    const [windowItem, setWindowItem] = React.useState(null);

    const [psy, setPsy] = React.useState({
        id: idPsy,
        first_name: '',
        last_name: '',
        middle_name: '',
        psych_type: '',
        price: null,
        groups: [],
        session_types: [],
        free_completed_records_count: 0,
    });
    // eslint-disable-next-line
    const [loading, setLoading] = React.useState(false);
    const repeatRef = React.useRef(false);

    const update = async () => {
        try {
            setLoading(true);
            const response = await request({
                method: 'GET',
                url: `/api/users/${idPsy}`,
                token: token,
                body: undefined
            });
            if(response.status === 200) {
                console.log('response psy', response);
                setPsy(response.data.data);
            }
        } catch(e) {
            console.log('e', e);
        } finally {
            setLoading(false);
        };
    }

    const getMe = async () => {
        let response = await request({
            method: 'GET',
            url: `/api/users/me`,
            token: token,
            body: undefined
        });

        setPhone(response.data.data.phone);
    }

    React.useEffect(() => {
        if(repeatRef.current === false) {
            update();
            getMe();
        } 

        return () => repeatRef.current = true;
        // eslint-disable-next-line
    }, []);

    return(
        <div className='container mx-auto'>
            <title>{t('writePsy.title')}</title>
            <p className='mt-[23px] laptop:mt-[90px] text-16 laptop:text-39'>{t('writePsy.title')}</p>
            <Pills 
                names={[t('writePsy.date'), t('writePsy.time'), t('writePsy.form'), t('writePsy.detail')]}
                value={screenType}
                onChange={(value) => { setScreenType(value); }}
                screenIndex={screenIndex}
            />
            {
                screenType === 'Выберите дату посещения'?
                    <Screen1 
                        date={date}
                        setDate={setDate}
                        id={idPsy}
                        token={token}
                        setScreenType={setScreenType}
                        idDate={idDate}
                        setIdDate={setIdDate}
                        setWorkingId={setWorkingId}
                        setScreenIndex={setScreenIndex}
                    />:
                    screenType === 'Выберите время посещения'?
                        <Screen2 
                            token={token}
                            setScreenType={setScreenType}
                            idDate={idDate}
                            workingId={workingId}
                            setWindowItem={setWindowItem}
                            setScreenIndex={setScreenIndex}
                        />:
                        screenType === 'Заполните анкету'?
                            <Screen3 
                                date={date}
                                setDate={setDate}
                                setScreenType={setScreenType}

                                session={session} setSession={setSession}
                                group={group} setGroup={setGroup}
                                email={email} setEmail={setEmail}
                                phone={phone} setPhone={setPhone}
                                anonim={anonim} setAnonim={setAnonim}
                                psy={psy} setScreenIndex={setScreenIndex}
                            />:
                            <Screen4 
                                group={group} session={session}
                                email={email} phone={phone} 
                                anonim={anonim} psy={psy} date={date}
                                windowItem={windowItem} token={token}
                                setScreenIndex={setScreenIndex}
                            />
            }
            
        </div>
    );
};

const Screen1 = (props) => {
    const {
        date, setDate, id, token,
        setScreenType,
        idDate, setIdDate, setScreenIndex,
        setWorkingId
    } = props;
    const oneQuery = React.useRef(false);
    const [workingDates, setWorkingDates] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const getWorkingDates = async () => {
        try {
            setLoading(true);
            const response = await request({
                method: 'GET',
                url: `/api/working-dates?user_id=${id}`,
                token: token,
                body: undefined
            });
            console.log('workingDates', response.data);
            if(response.status === 200) {
                setWorkingDates(response.data.data);
            }
        } catch(e) {
            console.log('e', e);
        } finally {
            setLoading(false);
        };
    };

    React.useEffect(() => {
        if(oneQuery.current === false) {
            getWorkingDates();
        }   
        
        return () => oneQuery.current = true;
        // eslint-disable-next-line
    }, []);

    return(
        <div className='mt-[25px] laptop:mt-[90px] flex flex-col items-center justify-center'>
            <Calendar 
                date={date}
                workingDates={workingDates.filter(item => new Date(item.date).getMonth() === date.getMonth())}
                //workingDates={[{id: 1, created_at: new Date().toString(), update_at: new Date().toString(), date: new Date().toString()}]}
                onChangeDate={(date, work_date) => {
                    setDate(date);
                    setWorkingId(work_date.id);
                    setScreenType('Выберите время посещения');
                    setScreenIndex(1);
                }}
                idDate={idDate}
                setIdDate={setIdDate}
                loading={loading}
                isLoading={true}
            />
        </div>
    );
};

const Screen2 = (props) => {
    const {
        workingId, setScreenType,
        token, setWindowItem,
        setScreenIndex
    } = props;
    const [select, setSelect] = React.useState('');
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const repeatRef = React.useRef(false);

    React.useEffect(() => {
        if(repeatRef.current === false) {
            setLoading(true);
            GetData(`/api/working-dates/${workingId}/windows`, token, (json, statusCode) => {
                if(statusCode === 200) {
                    setData(json.data);
                }
                setLoading(false);
            }, (e) => {
                console.log('e api/working-dates', e);
                setLoading(false);
            })
        }
        return () => repeatRef.current = true
        // eslint-disable-next-line
    }, []); 

    return(
        <div className='container mx-auto flex flex-col items-center'>
            <TimePicker 
                times={data.map(item => item.from)}
                style1={'mt-[25px] laptop:mt-[120px]'}
                value={select}
                onChange={(value) => { setSelect(value); }}
                windows={data}
                setWindowItem={setWindowItem}
            />
            <div className='mt-[30px] laptop:mt-[80px] flex flex-row justify-center items-center w-full'>
                <button
                    className={`h-[54px] laptop:h-[60px] rounded-[19px] font-medium text-[16px] ${select === ''? 'bg-grey': "bg-blue"} w-full laptop:w-[289px] self-center text-white flex flex-col items-center justify-center`}
                    onClick={() => {
                        if(select !== '') {
                            setScreenType('Заполните анкету');
                            setScreenIndex(2);
                        }
                    }}
                    disabled={loading}
                >{loading? 
                    <Loading 
                        width="w-[20px]"
                        height="h-[20px]"
                        fill={select === ''? 'fill-grey': "fill-blue"}
                        text="text-white"
                    />:
                    'Далее'
                }</button>
            </div>
            
        </div>
    );
};

const Screen3 = (props) => {
    const {
        setScreenType,
        setScreenIndex,
        session, setSession,
        group, setGroup,
        email, setEmail,
        phone, setPhone,
        anonim, setAnonim, psy
    } = props;

    const styleLabel = 'text-[12px] laptop:text-[14px] font-medium text-black';

    return(
        <div className='flex flex-1 flex-col'>
            <div className='grid grid-cols-1 laptop:grid-cols-2'>
                <div>
                    <p className={'mt-[25px] laptop:mt-[78px]' + styleLabel} >{t('listPsy.want')}</p>
                    <DropDown 
                        value={group}
                        data={psy.groups.map((item, index) => ({name: groupFunctionRevert(item.group) }))}
                        placeholder=""
                        height="mt-[10px] h-[67px] w-full laptop:w-[70%]" 
                        onChange={(value) => { setGroup(value.name); }}
                        styleButton={['text-black text-[12px] laptop:text-[16px] text-start', 'font-regular', 'whitespace-nowrap', 'w-full']} 
                        styleButtonMain={['w-full', 'items-center', 'justify-center', 'flex flex-row', 'h-[60px] laptop:h-[67px]', 'rounded-[19px]']}
                        styleMenuItem={['font-regular ', 'text-12 laptop:text-16', 'font-medium', 'whitespace-nowrap']} 
                        styleMenu={['mr-[25px]', 'mt-[10px]', 'w-full laptop:w-[336px] ']}
                    />
                    <p className={'mt-[30px] ' + styleLabel}>{t('listPsy.vidSeans')}</p>
                    <DropDown 
                        value={session}
                        data={psy.session_types.map((item, index) => ({name: sessionTypeRevert(item.session_type)}))}
                        placeholder=""
                        height="mt-[10px] h-[67px] w-full laptop:w-[70%]" 
                        onChange={(value) => { setSession(value.name); }}
                        styleButton={['text-black text-[12px] laptop:text-[16px] text-start', 'font-regular', 'whitespace-nowrap', 'w-full']} 
                        styleButtonMain={['w-full', 'items-center', 'justify-center', 'flex flex-row', 'h-[60px] laptop:h-[67px]']}
                        styleMenuItem={['font-regular ', 'text-12 laptop:text-16', 'font-medium', 'whitespace-nowrap']} 
                        styleMenu={['mr-[25px]', 'mt-[10px]', 'w-full laptop:w-[336px] ']}
                    />
                    <p className={'mt-[30px] ' + styleLabel}>{t('personalScreen.email')}</p>
                    <Input 
                        value={email}
                        onChange={(event) => { setEmail(event.target.value); }}
                        label={t('register.enterEmail')}
                        style1={'mt-[10px] w-full laptop:w-[336px] h-[67px]'}
                        styleInput={'text-[14px] laptop:text-[16px] font-regular text-black'}
                        styleLabel={'mt-0 text-[10px] laptop:text-[12px]'}
                    />
                    <p className={'mt-[30px] ' + styleLabel}>{t('personalScreen.phone')}</p>
                    <Input 
                        value={phone}
                        onChange={(event) => { setPhone(event.target.value); }}
                        label={t('register.enterPhone')}
                        style1={'mt-[10px] w-full laptop:w-[336px] h-[67px]'}
                        styleInput={'text-[14px] laptop:text-[16px] font-regular text-black'}
                        styleLabel={'mt-0 text-[10px] laptop:text-[12px]'}
                    />
                    <div className='mt-[26px] flex flex-row'>
                        <input 
                            value={anonim}
                            type={'checkbox'}
                            onChange={(event) => { setAnonim(event.target.value); }}
                            className='mr-[10px] w-[20px] h-[20px]'
                        />
                        <p className='text-[12px] laptop:text-[14px] font-regular'>{t('anonim')}</p>
                    </div>
                </div>
                <div></div>
            </div>

            <div className='flex flex-col mt-[29px] laptop:mt-[119px] items-center'>
                <button 
                    className={`${ session.length > 0 && group.length > 0? 'bg-blue ': 'bg-grey'}  text-16 laptop:w-[289px] w-full font-medium text-white h-[54px] laptop:h-[60px] rounded-[19px]`}
                    disabled={!(session.length > 0 && group.length > 0)}
                    onClick={() => {
                        setScreenType('Детали записи');
                        setScreenIndex(3);
                    }}    
                >{t('writeCons.continue')}</button>
            </div>
        </div>
    );
};

const Screen4 = (props) => {
    const {
        group, session, 
        email, phone, 
        anonim, psy, date,
        windowItem, token
    } = props;
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();
    const [modal, setModal] = React.useState(false);

    return(
        <div>
            <PublicPsy 
                psy={psy}
                session={session}
                group={group}
                anonim={anonim}
                date={date}
                windowItem={windowItem}
                token={token}
                email={email}
                phone={phone}
                navigate={navigate}
                loading={loading}
                setLoading={setLoading}
            />
            <div id="payment-form"></div>
            
            <div className='hidden laptop:flex flex-row mt-[130px] items-center justify-center'>
                {
                    psy.free_completed_records_count <= 2?
                    <button className='w-[289px] h-[54px] mt-[3px] bg-blue text-white rounded-[19px] mr-[15px] text-16 font-medium' onClick={async () => {
                        try {
                            setLoading(true);
                            PostData(`/api/records`, token, JSON.stringify({
                                window_id: windowItem.id,
                                email: email,
                                phone: phone,
                                anonymously: anonim,
                                session_type: sessionTypeFunction(session),
                                group: groupFunction(group),
                                user_note: '',
                                free: true
                            }), (json, statusCode) => {
                                if(statusCode === 200) {
                                    setLoading(false);
                                    setModal(true);
                                } else {
                                    setLoading(false);
                                }
                            }, (e) => {
                                console.log('e', e);
                                setLoading(false);
                            })
                        } catch(e) {
                            alert(e.message);
                        };
                    }}>{t('writePsy.useFree')}</button>:
                    <></>
                }
                <button 
                    className='mr-[15px] w-[289px] h-[54px] rounded-[19px] bg-green text-white text-16 font-medium flex flex-col items-center justify-center'
                    onClick={async () => {
                        const idempotent = uuid()
                        const conf_token = await window.fetch('https://api.yookassa.ru/v3/payments', {
                            method: 'POST',
                            headers: { 
                                'Accept': "application/json",
                                'Content-Type': 'application/json',
                                'Idempotence-Key': idempotent,
                                'Authorization': "Basic "+btoa('964782:test_iFFmgG25A2-N_iRgjcaLvptfnr5HoLIP6kIY2-2xkCs'),
                            },
                            body: JSON.stringify({
                                "amount": {
                                  "value": psy.price,
                                  "currency": "RUB"
                                },
                                "confirmation": {
                                  "type": "embedded"
                                },
                                "capture": true,
                                "description": ""
                            }),
                        }).then(res => res.json())
                        .then(json => json.confirmation.confirmation_token)
                        .catch(e => {
                            console.log(e);
                        });
                        
                        const checkout = new window.YooMoneyCheckoutWidget({
                            confirmation_token: conf_token, //Токен, который перед проведением оплаты нужно получить от ЮKassa
                            return_url: 'https://psysakha.ru',
                            customization: {
                                //Настройка цветовой схемы, минимум один параметр, значения цветов в HEX
                                colors: {
                                    //Цвет акцентных элементов: кнопка Заплатить, выбранные переключатели, опции и текстовые поля
                                    control_primary: '#234082', //Значение цвета в HEX
                                    background: '#F3F7FF'
                                }
                            },
                            error_callback: function(error) {
                                console.log(error)
                                checkout.destroy();
                            }
                        });
                      
                        //Отображение платежной формы в контейнере
                        checkout.on('complete', () => {
                            setLoading(true);
                            PostData(`/api/records`, token, JSON.stringify({
                                window_id: windowItem.id,
                                email: email,
                                phone: phone,
                                anonymously: anonim,
                                session_type: sessionTypeFunction(session),
                                group: groupFunction(group),
                                user_note: ''
                            }), (json, statusCode) => {
                                if(statusCode === 200) {
                                    setLoading(false);
                                    setModal(true);
                                    // navigate('/psycologies/' + psy.id);
                                } else {
                                    setLoading(false);
                                }
                            }, (e) => {
                                console.log('e', e);
                                setLoading(false);
                            })
                        })
                        checkout.render('payment-form');
                    }}    
                >{
                    loading? 
                        <Loading 
                            width="w-[20px]"
                            height="h-[20px]"
                            fill="fill-green"
                            text="text-white"
                        />:
                        t('writePsy.pay')
                }</button>
                <button 
                    className='border w-[289px] h-[54px] rounded-[19px] border-blue text-blue text-16 font-medium'
                    onClick={() => {
                        navigate('/psycologies/' + psy.id);
                    }}    
                >{t('writePsy.cancel')}</button>
            </div>
            {
                modal && 
                <div className='fixed w-screen h-full py-[30px] px-[20px] top-0 left-0 flex flex-col items-center justify-center bg-[#00000020]'>
                    <div className='w-[500px] h-[130px] shadow-[0_4px_15px_-0px_rgba(105,105,105,0.25)] rounded-[12px] flex flex-col items-center justify-center bg-white'>
                        <p className='text-center'>Ваша заявка принята, с вами свяжется администратор для подтверждения записи</p>
                        <div className='flex flex-row items-center justify-center'>
                            <button className='w-[100px] h-[40px] rounded-[12px] mt-[10px] text-white bg-blue self-center' onClick={() => {setModal(false); navigate('/psycologies/'+psy.id);}}>Ок</button>
                        </div>
                    </div>
                </div>
            }
        </div>
        
    );
};

const styles = {
    main: `
        border border-white-input 
        bg-white laptop:bg-white-input 
        mt-[25px] laptop:mt-[78px] 
        py-[44px] px-[40px] 
        rounded-[19px] 
        grid grid-cols-1 laptop:grid-cols-7 gap-[20px]
    `,
    label: `
        text-12 laptop:text-14 text-grey font-regular
    `
};

const PublicPsy = (props) => {
    const {
        psy, session, group, anonim, token,
        date, windowItem, phone, email,
        navigate, loading, setLoading
    } = props;
    const {t} = useTranslation();
    const [modal, setModal] = React.useState(false);

    return(
        <div className='grid grid-cols-1 laptop:grid-cols-9 gap-[16px] mt-[25px] laptop:mt-[78px] bg-white laptop:bg-white-input rounded-[19px] px-[44px] pt-[15px] laptop:pt-[44px] pb-[19px] laptop:pb-[61px] border border-white-input laptop:border-white'>
            <div className='col-span-3 grid grid-cols-4'>
                <Avatar
                    source={psy.avatar_url? psy.avatar_url: DefaultAvatar} 
                    styleImage="mobile:w-[24px] mobile:h-[24px] w-[75px] laptop:w-[68px] h-[75px] laptop:h-[68px] "
                    style1="mr-[10px] mobile:w-[48px] mobile:h-[48px] w-[81px] laptop:w-[78px] h-[81px] laptop:h-[78px] "
                /> 
                <div className='col-span-3 laptop:ml-[10px] desktop:ml-0'>
                    <p className={styles.label + 'hidden laptop:flex'}>{getPsychType(psy.psych_type)}:</p>
                    <p className='text-16 font-regular laptop:font-medium laptop:mt-[25px]'>{psy.first_name? psy.first_name: ''}{psy.middle_name? ' '+psy.middle_name: ''}{psy.last_name? ' '+psy.last_name: ''}</p>
                    <p className='text-grey text-12 laptop:text-14 font-regular mt-[3px] flex laptop:hidden'>{getPsychType(psy.psych_type)}</p>
                    <div className='flex flex-row mt-[10px] laptop:mt-[26px]'>
                        <p className='text-12 laptop:text-14 font-regular text-grey mr-[5px]'>{t('listPsy.vidSeans')}:</p>
                        <p className='text-12 laptop:text-14 font-regular text-blue'>{session}</p>
                    </div>
                    <div className='flex flex-row mt-[10px] laptop:mt-[26px]'>
                        <p className='text-12 laptop:text-14 font-regular text-grey mr-[5px]'>Тип записи:</p>
                        <p className='text-12 laptop:text-14 font-regular text-blue'>{group}</p>
                    </div>
                </div>
            </div>
            <div className='col-span-2'>
                <p className={styles.label}>Дата и время сеанса:</p>
                <div className='mt-[10px] laptop:mt-[30px] flex flex-row items-center'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.375 0C4.54076 0 4.69973 0.065848 4.81694 0.183058C4.93415 0.300269 5 0.45924 5 0.625V1.25H15V0.625C15 0.45924 15.0658 0.300269 15.1831 0.183058C15.3003 0.065848 15.4592 0 15.625 0C15.7908 0 15.9497 0.065848 16.0669 0.183058C16.1842 0.300269 16.25 0.45924 16.25 0.625V1.25H17.5C18.163 1.25 18.7989 1.51339 19.2678 1.98223C19.7366 2.45107 20 3.08696 20 3.75V17.5C20 18.163 19.7366 18.7989 19.2678 19.2678C18.7989 19.7366 18.163 20 17.5 20H2.5C1.83696 20 1.20107 19.7366 0.732233 19.2678C0.263392 18.7989 0 18.163 0 17.5V3.75C0 3.08696 0.263392 2.45107 0.732233 1.98223C1.20107 1.51339 1.83696 1.25 2.5 1.25H3.75V0.625C3.75 0.45924 3.81585 0.300269 3.93306 0.183058C4.05027 0.065848 4.20924 0 4.375 0V0ZM16.8175 3.75H3.18125C2.80625 3.75 2.5 4.03 2.5 4.375V5.625C2.5 5.97 2.805 6.25 3.18125 6.25H16.8188C17.1938 6.25 17.5 5.97 17.5 5.625V4.375C17.5 4.03 17.195 3.75 16.8175 3.75ZM13.5675 11.0675C13.6849 10.9501 13.7508 10.791 13.7508 10.625C13.7508 10.459 13.6849 10.2999 13.5675 10.1825C13.4501 10.0651 13.291 9.99921 13.125 9.99921C12.959 9.99921 12.7999 10.0651 12.6825 10.1825L9.375 13.4913L7.9425 12.0575C7.88439 11.9994 7.8154 11.9533 7.73948 11.9218C7.66356 11.8904 7.58218 11.8742 7.5 11.8742C7.41782 11.8742 7.33644 11.8904 7.26052 11.9218C7.1846 11.9533 7.11561 11.9994 7.0575 12.0575C6.99939 12.1156 6.95329 12.1846 6.92185 12.2605C6.8904 12.3364 6.87421 12.4178 6.87421 12.5C6.87421 12.5822 6.8904 12.6636 6.92185 12.7395C6.95329 12.8154 6.99939 12.8844 7.0575 12.9425L8.9325 14.8175C8.99056 14.8757 9.05953 14.9219 9.13546 14.9534C9.21139 14.9849 9.29279 15.0011 9.375 15.0011C9.45721 15.0011 9.53861 14.9849 9.61454 14.9534C9.69047 14.9219 9.75944 14.8757 9.8175 14.8175L13.5675 11.0675Z" fill="#3A7DFF"/>
                    </svg>
                    <p className='text-14 laptop:text-16 font-medium text-blue ml-[10px]'>{date.toLocaleDateString('ru-RU', {weekday: 'short', day: '2-digit', month: 'long'})}</p>
                </div>
                <div className='mt-[15px] laptop:mt-[8px] flex flex-row'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10ZM10 4.375C10 4.20924 9.93415 4.05027 9.81694 3.93306C9.69973 3.81585 9.54076 3.75 9.375 3.75C9.20924 3.75 9.05027 3.81585 8.93306 3.93306C8.81585 4.05027 8.75 4.20924 8.75 4.375V11.25C8.75004 11.3602 8.77919 11.4684 8.83451 11.5636C8.88982 11.6589 8.96934 11.7379 9.065 11.7925L13.44 14.2925C13.5836 14.3701 13.7518 14.3885 13.9087 14.3437C14.0657 14.2989 14.1988 14.1944 14.2798 14.0527C14.3608 13.9111 14.3831 13.7433 14.342 13.5854C14.301 13.4274 14.1997 13.2918 14.06 13.2075L10 10.8875V4.375Z" fill="#3A7DFF"/>
                    </svg>
                    <p className='text-14 laptop:text-16 font-medium text-blue ml-[10px]'>{windowItem.from}-{windowItem.to}</p>
                </div>
                <div className='mt-[15px] laptop:mt-[8px] flex flex-row items-center'>
                    <img src={Time2} alt="image1" />
                    <p className='text-14 laptop:text-16 font-medium text-blue ml-[10px]'>{getDuration(windowItem.from, windowItem.to)}</p>
                </div>
            </div>  
            <div className='col-span-2'>
                <p className={styles.label}>Итоговая стоимость:</p>
                <div className='mt-[7px] laptop:mt-[25px] flex flex-row items-center'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 5C0 4.33696 0.263392 3.70107 0.732233 3.23223C1.20107 2.76339 1.83696 2.5 2.5 2.5H17.5C18.163 2.5 18.7989 2.76339 19.2678 3.23223C19.7366 3.70107 20 4.33696 20 5V6.25H0V5ZM0 8.75V15C0 15.663 0.263392 16.2989 0.732233 16.7678C1.20107 17.2366 1.83696 17.5 2.5 17.5H17.5C18.163 17.5 18.7989 17.2366 19.2678 16.7678C19.7366 16.2989 20 15.663 20 15V8.75H0ZM3.75 11.25H5C5.33152 11.25 5.64946 11.3817 5.88388 11.6161C6.1183 11.8505 6.25 12.1685 6.25 12.5V13.75C6.25 14.0815 6.1183 14.3995 5.88388 14.6339C5.64946 14.8683 5.33152 15 5 15H3.75C3.41848 15 3.10054 14.8683 2.86612 14.6339C2.6317 14.3995 2.5 14.0815 2.5 13.75V12.5C2.5 12.1685 2.6317 11.8505 2.86612 11.6161C3.10054 11.3817 3.41848 11.25 3.75 11.25Z" fill="#3A7DFF"/>
                    </svg>
                    <p className='text-14 laptop:text-16 text-blue font-medium ml-[10px]'>{psy.price? psy.price: ''} ₽</p>
                </div>
                {
                    psy.psych_type === 'staff'?
                        <button className='hidden py-[9px] px-[12.5px] bg-blue text-white text-11 laptop:text-14 rounded-[10px] font-regular mt-[8px] laptop:mt-[10px]'>{t('writePsy.useFree')}</button>
                        :null

                }
                <div className='flex flex-row mt-[20px] laptop:mt-[10px]'>
                    <p className='mr-[10px] text-12 laptop:text-14 text-grey font-regular'>{t('profile.paymentM')}:</p>
                    <p className='text-12 laptop:text-14 font-regular'>Не оплачен</p>
                </div>
            </div>
            <div className='col-span-2'>
                <p className={styles.label}>{t('profile.paymentM')}:</p>
                <p className='w-full bg-white-input laptop:bg-white py-[15px] rounded-[19px] mt-[10px] laptop:mt-[29px] text-blue text-regular text-16 text-center'>{t('profile.onlinePaym')}</p>
                {
                    psy.free_completed_records_count <= 2?
                    <button className='laptop:hidden desktop:hidden tablet:hidden mobile:flex items-center justify-center w-full h-[52px] mt-[35px] bg-blue text-white rounded-[19px] mr-[15px] text-16 font-medium' onClick={async () => {
                        try {
                            setLoading(true);
                            PostData(`/api/records`, token, JSON.stringify({
                                window_id: windowItem.id,
                                email: email,
                                phone: phone,
                                anonymously: anonim,
                                session_type: sessionTypeFunction(session),
                                group: groupFunction(group),
                                user_note: '',
                                free: true
                            }), (json, statusCode) => {
                                if(statusCode === 200) {
                                    setLoading(false);
                                    setModal(true);
                                } else {
                                    setLoading(false);
                                }
                            }, (e) => {
                                console.log('e', e);
                                setLoading(false);
                            })
                        } catch(e) {
                            alert(e.message);
                        };
                    }}>{t('writePsy.useFree')}</button>:
                    <></>
                }
                <button 
                    className='col-span-1 mt-[35px] w-full h-[52px] rounded-[19px] laptop:hidden bg-blue text-16 font-medium text-white flex flex-col items-center justify-center'
                    onClick={() => {
                        setLoading(true);
                        PostData(`/api/records`, token, JSON.stringify({
                            window_id: windowItem.id,
                            email: email,
                            phone: phone,
                            anonymously: anonim,
                            session_type: sessionTypeFunction(session),
                            group: groupFunction(group),
                            user_note: ''
                        }), (json, statusCode) => {
                            if(statusCode === 200) {
                                setLoading(false);
                                setModal(true);
                            } else {
                                setLoading(false);
                            }
                        }, (e) => {
                            console.log('e', e);
                            setLoading(false);
                        })
                    }}
                >{
                    loading?
                        <Loading 
                            width="w-[20px]"
                            height="h-[20px]"
                            fill="fill-blue"
                            text="text-white"
                        />:
                        t('writePsy.record')
                }</button>
            </div>
            {
                modal && 
                <div className='fixed w-screen h-full py-[30px] px-[20px] top-0 left-0 flex flex-col items-center justify-center bg-[#00000020]'>
                    <div className='tablet:w-[500px] laptop:w-[500px] desktop:w-[500px] mobile:w-[90%] h-[130px] shadow-[0_4px_15px_-0px_rgba(105,105,105,0.25)] rounded-[12px] flex flex-col items-center justify-center bg-white'>
                        <p className='text-center'>Ваша заявка принята, с вами свяжется администратор для подтверждения записи</p>
                        <div className='flex flex-row items-center justify-center'>
                            <button className='w-[100px] h-[40px] rounded-[12px] mt-[10px] text-white bg-blue self-center' onClick={() => {setModal(false); navigate('/psycologies/'+psy.id);}}>Ок</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}