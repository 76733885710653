import * as React from 'react';
import { useSelector } from 'react-redux';
import {PostData} from '../../requests/post';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export const ChangePassword = () => {
    const [data, setData] = React.useState({
        currentPass: '',
        newPass: '',
        repeatPass: ''
    });
    const navigate = useNavigate();
    const {t} = useTranslation();

    const token = useSelector(state => state.auth.session.token);

    return(
        <div>
            <title>{t('password.title')}</title>
            <div className='container mx-auto laptop:w-[600px] mobile:w-full flex flex-col justify-center desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]'>
                <div className="mb-[60px] text-center">
                    <h2 className='mb-[20px]'>{t('password.title')}</h2>
                </div>
                <Form
                    name=''
                    label={t('password.current')}
                    value={data.currentPass}
                    onChange={txt => setData({ ...data, currentPass: txt })}
                />
                <Form
                    name=''
                    label={t('password.new')}
                    value={data.newPass}
                    onChange={txt => setData({ ...data, newPass: txt })}
                />
                <Form
                    name=''
                    label={t('password.repeat')}
                    value={data.repeatPass}
                    onChange={txt => setData({ ...data, repeatPass: txt })}
                />
                <div className='flex laptop:flex-row mobile:flex-col laptop:gap-[20px]'>
                    <button 
                        className="bg-blue rounded-[19px] flex justify-center items-center h-[52px] text-white disabled:bg-grey min-w-[289px] mobile:w-full tablet:w-auto laptop:w-auto desktop:w-auto mx-auto mobile:mt-[30px] tablet:mt-[30px] laptop:mt-[40px] desktop:mt-[40px]"
                        onClick={() => {
                            if(data.newPass.length >= 6 && data.newPass === data.repeatPass) {
                                PostData('/api/auth/change-password', token, JSON.stringify({
                                    current_password: data.currentPass,
                                    password: data.newPass,
                                    password_confirmation: data.repeatPass
                                }), (json, statusCode) => {
                                    if(statusCode === 200) {
                                        navigate('/client/profile');
                                    }
                                }, (e) => {
                                    console.log('e change-password', e);
                                })
                            } else {
                                alert('Пароли не совпадают');
                            }
                            
                        }}
                    >{t('password.change')}</button>
                    <button
                        onClick={() => {navigate('/client/profile')}} 
                        className="bg-white border border-blue rounded-[19px] flex justify-center items-center h-[52px] text-blue disabled:bg-grey min-w-[289px] mobile:w-full tablet:w-auto laptop:w-auto desktop:w-auto mx-auto mobile:mt-[30px] tablet:mt-[30px] laptop:mt-[40px] desktop:mt-[40px]">{t('password.cancel')}</button>
                </div>
            </div>
        </div>
    )
}

const Form = ({ name, label, type, defaultValue, onChange, value }) => {
    const ref = React.useRef();
    return (
        <div className='mb-[20px]'>
            <p className='text-14 font-medium text-black'>{name}</p>
            <div className='grid grid-rows-2 bg-white-cards border-blue border-[1px] rounded-[19px] pt-[10px] pb-[12px] pl-[53px] pr-[10px] mt-[10px]' onClick={() => { ref.current?.focus() }}>
                <label className='text-12 text-pick text-blue font-regular'>{label}</label>
                <input
                    className='text-16 outline-none font-regular bg-white-cards'
                    type={type}
                    value={value}
                    defaultValue={defaultValue}
                    ref={ref}
                    onChange={(e) => { if (typeof onChange === 'function') { onChange(e.currentTarget.value) } }}
                />
            </div>
        </div>
    );
}