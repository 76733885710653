import React from "react";
// import { Link, useNavigate } from "react-router-dom";

export const PrivacyPolicy  = () => {
    return(
        <div className="desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]">
            
            <title>Портал психологов - Политика конфиденциальности</title>
            
            <div className='container mx-auto items-center content-center '>
                <div className="flex flex-row">
                    <div className="text-blue mobile:text-16 tablet:text-16 laptop:text-[50px] desktop:text-[50px] font-medium">Политика </div>
                    <div className="mobile:text-16 desktop:text-[50px] laptop:text-[50px] tablet:text-16 font-medium desktop:ml-[13px] mobile:ml-[5px]">конфиденциальности</div>
                </div>
                <div className="desktop:mt-[100px] laptop:mt-[60px] tablet:mt-[40px] mobile:mt-[20px]">
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую Сайт «Республиканский психологический центр», расположенный на доменном имени «http://psysakha.ru», может получить о Пользователе во время использования сайта.</p>
                </div>
                
                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        1.1. В настоящей Политике конфиденциальности используются следующие термины:
                        <br/>1.1.1. «Администрация сайта» – уполномоченные сотрудники на управления сайтом, действующие от имени Сайта http://psysakha.ru, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
                        <br/>1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
                        <br/>1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                        <br/>1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.
                        <br/>1.1.5. «Пользователь сайта» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.
                        <br/>1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
                        <br/>1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
                    </p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">2. ОБЩИЕ ПОЛОЖЕНИЯ</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        2.1. Использование Пользователем сайта означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
                        <br/>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта.
                        <br/>2.3. Настоящая Политика конфиденциальности применяется только к сайту http://psysakha.ru. Сайт не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте.
                        <br/>2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта.
                    </p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте.
                        <br/>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на Сайте http://psysakha.ru в разделе обратной связи включают в себя следующую информацию:
                        <br/>3.2.1. Фамилию, имя, отчество Пользователя;
                        <br/>3.2.2. Контактный телефон Пользователя;
                        <br/>3.2.3. Адрес электронной почты (e-mail);
                        <br/>3.3. Сайт защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы ("пиксель"): 
                        <br/>•	IP адрес;
                        <br/>•	информация из cookies;
                        <br/>•	информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);
                        <br/>•	время доступа;
                        <br/>•	адрес страницы, на которой расположен рекламный блок;
                        <br/>•	реферер (адрес предыдущей страницы).

                        <br/>3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта, требующим авторизации.
                        <br/>3.3.2. Сайт осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.
                        <br/>3.4. Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
                    </p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        4.1. Персональные данные Пользователя Администрация сайта может использовать в целях:
                        <br/>4.1.1. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта.
                        <br/>4.1.1. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.
                        <br/>4.1.2. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработка запросов и заявок от Пользователя.
                        <br/>4.1.3. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
                        <br/>4.1.4. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
                        <br/>4.1.5. Создания учетной записи для совершения покупок, если Пользователь дал согласие на создание учетной записи.
                        <br/>4.1.6. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта.
                        <br/>4.1.7. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени сайта.
                        <br/>4.1.8. Осуществления рекламной деятельности с согласия Пользователя.
                    </p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
                        <br/>5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного на Сайте http://psysakha.ru.
                        <br/>5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
                        <br/>5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.
                        <br/>5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
                        <br/>5.6. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
                    </p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">6. ОБЯЗАТЕЛЬСТВА СТОРОН</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        6.1. Пользователь обязан:
                        <br/>6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.
                        <br/>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.
                        <br/>6.2. Администрация сайта обязана:
                        <br/>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.
                        <br/>6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.
                        <br/>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.
                        6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.
                    </p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">7. ОТВЕТСТВЕННОСТЬ СТОРОН</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.
                        <br/>7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:
                        <br/>7.2.1. Стала публичным достоянием до её утраты или разглашения.
                        <br/>7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.
                        <br/>7.2.3. Была разглашена с согласия Пользователя.
                    </p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">8. РАЗРЕШЕНИЕ СПОРОВ</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).
                        <br/>8.2 .Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
                        <br/>8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.
                        <br/>8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.
                    </p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
                        <br/>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.
                        <br/>9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать.
                        {/* <br/>9.4. Действующая Политика конфиденциальности размещена на странице по адресу http://psy.sakha.gov.ru/politika-konfidicialnosti. */}
                    </p>
                </div>
            </div>

            

        </div>
    )
}