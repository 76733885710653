import { createSlice } from '@reduxjs/toolkit';

export const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        psycologies: {
            city: '',
            cityId: 0,
            worry: '',
            worryId: 0,
            type: '',
            group: '',
            sex: '',
            date: null,
            dateType: '',
            minPrice: '',
            maxPrice: ''
        }
    },
    reducers: {
        setPsycologiesRedux: (state, action) => {
            state.psycologies = action.payload;
        },
        setPsycologiesDefault: (state) => {
            state.psycologies = {
                city: '',
                cityId: 0,
                worry: '',
                worryId: 0,
                type: '',
                group: '',
                sex: '',
                date: new Date(),
                dateType: '',
                minPrice: '',
                maxPrice: ''
            }
        }
    }
});

export const { 
    setPsycologiesRedux, setPsycologiesDefault
} = filterSlice.actions;
export default filterSlice.reducer;