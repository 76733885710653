import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getPsychFullName } from '../../configs/functions';
import emptyImg from '../../assets/icons/emptyImg.svg';
import { GetData } from '../../requests/get';

import {CommentInput} from '../../components/CommentInput';
import {CommentComponent} from '../../components/CommentComponent';
import { PostData } from '../../requests/post';
import { t } from 'i18next';

export const ArticleViewClient = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const psych = useSelector(state => state.psych.psych);

    const [data, setData] = React.useState([]);
    // eslint-disable-next-line
    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);
    const token = useSelector(state => state.auth.session.token);

    const [other, setOther] = React.useState([]);

    const [comment, setComment] = React.useState('');

    React.useEffect(() => {
        setLoading(true);
        setData([])
        GetData(`/api/posts/${location.state.id}/comments`, token, (json, statusCode) => {
            console.log('json comments', statusCode, json);
            if(statusCode === 200) {
                setData(json.data);
                setLoading(false);
            } else {    
                setLoading(false);
            }
        }, (e) => {
            console.log('e', e);
            setLoading(false);
        })
        if(refRepeat.current === false) {
            GetData(`/api/posts`, token, (json, statusCode) => {
                console.log('json posts', statusCode, json);
                if(statusCode === 200) {
                    setOther(json.data); 
                } else {
                }
            }, (e) => {
                console.log('e', e);
                setLoading(false);
            });
        }

        return () => refRepeat.current = true;
        // eslint-disable-next-line
    }, []); 

    return(
        <div >
            <title className='laptop:text-39'>{location.state.title}</title>
            <div className='container mx-auto flex flex-row'>
                <p className='text-blue text-16 desktop:text-50 laptop:text-39'>{location.state.title.replace(' ', '^/').split('^/')[0]}</p>
                <p className='text-black text-16 desktop:text-50 laptop:text-39 ml-[10px]'>{location.state.title.replace(' ', '^/').split('^/')[1]}</p>
            </div>
            
            <div className='container mx-auto flex flex-row mt-[15px] desktop:mt-[30px]'>
                <p className='text-grey flex text-12 desktop:text-16'> {t('news.author')}: {getPsychFullName(psych, location.state.user_id)}, </p>
                <p className='text-grey text-12 desktop:text-16'> {new Date(location.state.created_at).toLocaleDateString('ru-RU', {day: '2-digit', month: 'long'})}</p>
            </div>
            <div className='mt-[20px] dekstop:mt-[90px]'>
            {
                location.state.text.split('\n').length > 3?
                    <div>
                        <div className='container mx-auto desktop:grid'>
                            <p className='text-12 desktop:text-16 font-regular'>{location.state.text.split('\n')[0]}</p>
                            <p className='text-12 desktop:text-16 font-regular mt-[10px] desktop:mt-0'>{location.state.text.split('\n')[1]}</p>
                        </div>
                        <img 
                            className='container mx-auto rounded-[11px] desktop:rounded-[0px] mt-[15px] desktop:[30px] laptop:rounded-[0px]' 
                            src={location.state.cover_url? location.state.cover_url:
                                emptyImg
                            } 
                            alt="image1"
                        />
                        <div className='container mx-auto mt-[15px] laptop:mt-[30px]'>
                        {
                            location.state.text.split('\n').filter((item, index) => index > 1).map((item, index) => 
                                <p 
                                    key={index.toString()}
                                    className='text-12 desktop:text-16'
                                >{item}</p>    
                            )
                        }
                            <p className='text-blue text-14 desktop:text-16 laptop:text-16 mt-[15px] desktop:mt-[30px] laptop:mt-[30px] font-medium'>Этапы развития семьи</p>
                            <p className='mt-[15px] desktop:mt-[30px] laptop:mt-[30px] text-12 desktop:text-16 laptop:text-14'>Почему проблема одного члена семьи — это проблема всей семьи? Может ли ребенок заболеть из-за того, что родители плохо ладят? Почему семейная система может «сопротивляться» изменениям в вашей жизни?</p>
                        </div>
                    </div>:
                    <div className='container mx-auto'>
                    {
                        location.state.text.split('\n').map((item, index) => 
                            <p key={index.toString()} className={`text-12 desktop:text-16 font-regular ${index === 0?'': 'mt-[10px]'}`}>{item}</p>
                        )
                    }
                    </div>
            }
            </div>
            <div className='container mx-auto mt-[35px] desktop:[55px]'>
                <p className='text-16 desktop:text-[25px] font-medium'>{t('news.cpmments')}</p>
                <CommentInput 
                    comment={comment}
                    setComment={setComment}
                    onSend={() => {
                        PostData(`/api/posts/${location.state.id}/comments`, token, JSON.stringify({
                            text: comment
                        }), (json, statusCode) => {
                            if(statusCode === 200) { 
                                setLoading(true);
                                setComment('');
                                GetData(`/api/posts/${location.state.id}/comments`, token, (json, statusCode) => {
                                    if(statusCode === 200) {
                                        setData(json.data);
                                        setLoading(false);
                                    } else {    
                                        setLoading(false);
                                    }
                                }, (e) => {
                                    console.log('e', e);
                                    setLoading(false);
                                })
                            }
                        }, (e) => {
                            console.log('e', e);
                        })
                    }}
                />
            </div>
            <div className='laptop:container laptop:mx-auto mt-[20px]'>
                {
                    data.map((item, index) => 
                        <CommentComponent 
                            item={item}
                            index={index}
                            key={index.toString()}
                            token={token}
                            onSend={() => {
                                setLoading(true);
                                GetData(`/api/posts/${location.state.id}/comments`, token, (json, statusCode) => {
                                    if(statusCode === 200) {
                                        setData(json.data);
                                        setLoading(false);
                                    } else {    
                                        setLoading(false);
                                    }
                                }, (e) => {
                                    console.log('e', e);
                                    setLoading(false);
                                })
                            }}
                        />
                    )
                }
            </div>
            <div className='desktop:bg-white-input laptop:bg-white-input mt-[25px] desktop:mt-[50px] laptop:pb-[25px]'>
                <div className='container mx-auto desktop:pt-[35px] laptop:pt-[35px]'>
                    <p className='text-[25px] font-medium'>{t('news.author')}</p>    
                </div> 
                <div className='mt-[25px] desktop:mt-[40px]]'>
                {
                    other.filter((a) => a.id !== location.state.id).map((item,index) =>
                        <div 
                            key={index.toString()} 
                            className={`pb-[10px] desktop:pb-[25px] mb-[20px] desktop:mb-[25px] laptop:mb-[25px] desktop:container desktop:mx-auto laptop:container laptop:mx-auto ${index < [data, data].length -1 ?'border-b-blue-border border-b-[1px]': ''} `}
                            onClick={() => navigate('/article/'+item.id, {state: item})}
                        >
                            <p className='container mx-auto text-14 desktop:text-20 font-regular text-blue'>{item.title}</p>
                            <p className='container mx-auto font-thin text-12 desktop:text-[13px] text-grey'>{new Date(item.created_at).toLocaleDateString('ru-RU', {day: '2-digit', month: 'long', year: 'numeric'})}</p>
                            <p className='container mx-auto line-clamp-5 mt-[15px] desktop:mt-[20px]'>
                                <div dangerouslySetInnerHTML={{__html: item.text}}/>
                            </p>
                        </div>
                    )
                }
                </div>
            </div>
        </div>
    );
};