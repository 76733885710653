import { t } from 'i18next';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Send from '../../assets/icons/send.svg';

export const NewsViewClient = () => {
    //const [loading, setLoading] = React.useState(false);    
    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state.data;
    const [postPage, setPostPage] = React.useState(1);
    // console.log(data);

    return(
        <div >
            <title className='laptop:text-39'>{data.title}</title>
            <div className='container mx-auto flex flex-row'>
                <p className='text-blue text-16 desktop:text-50 laptop:text-39'>{data.title.replace(' ', '^/').split('^/')[0]}</p>
                <p className='text-black text-16 desktop:text-50 laptop:text-39 ml-[10px]'>{data.title.replace(' ', '^/').split('^/')[1]}</p>
            </div>
            
            <div className='container mx-auto flex flex-row mt-[15px] desktop:mt-[30px]'>
                <p className='text-grey flex text-12 desktop:text-16'>{t('news.author')}: {data.user.first_name} {data.last_name}, </p>
                <p className='text-grey text-12 desktop:text-16'>{new Date(data.created_at).toLocaleDateString('ru-RU', {day: "2-digit", month: 'short', year: 'numeric'})}</p>
            </div>
            
            <div className='container mx-auto flex flex-row mt-[15px] desktop:mt-[30px]'>
                <img
                    src={data?.cover_url? data?.cover_url : ''}
                    alt='...'
                    className="laptop:w-[60%]  laptop:h-[500px] mobile:w-full mobile:h-[200px] mobile:min-w-[74px] py-[25px] object-cover"
                />   
            </div>
            
            <div className='container mx-auto'>
                <div className='mt-[20px] dekstop:mt-[90px]'>
                    <div dangerouslySetInnerHTML={{__html: data.content}}/>
                </div>
            </div>
            
           
            
            <div className='desktop:bg-white-input laptop:bg-white-input mt-[25px] desktop:mt-[50px] laptop:pb-[25px]'>
                <div className='container mx-auto desktop:pt-[35px] laptop:pt-[35px]'>
                    <p className='text-[25px] font-medium'>Ранее добавленные</p>    
                </div> 
                <div className='mt-[25px] desktop:mt-[40px]]'>
                    {
                        location.state.other.filter((item) => item.id !== location.state.id).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).filter((item, index) => (index < postPage*3 && index >= (postPage*3-3))).map((item, index) => 
                            <div 
                                onClick={() => {
                                    navigate(`/news/${item.id}`,{state: {data: item, other: [...location.state.other, data].filter((n,i) => i !== index)}});
                                }} 
                                key={index.toString()}
                                className={`pb-[10px] desktop:pb-[25px] mb-[20px] desktop:mb-[25px] laptop:mb-[25px] desktop:container desktop:mx-auto laptop:container laptop:mx-auto ${index < location.state.other.length -1 ?' border-b-blue-border border-b-[1px]': ''} `}
                            >
                                <p className='container mx-auto text-14 desktop:text-20 font-regular text-blue'>{item.title}</p>
                                <p className='container mx-auto font-thin text-12 desktop:text-[13px] text-grey'>{new Date(item.created_at).toLocaleDateString('ru-RU', {day: '2-digit', month: 'long', year: 'numeric'})}</p>
                                <p className='container mx-auto line-clamp-5 mt-[15px] desktop:mt-[20px] overflow-hidden h-[70px]'>
                                    <div dangerouslySetInnerHTML={{__html: item.content}}/>    
                                </p>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};