import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal } from './Modal';
import { ReviewPost } from './ReviewPost';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const [ModalVisible, setModalVisible] = React.useState(false);
    const isSignedIn = useSelector(state => state.auth.session.signedIn);
    const {t} = useTranslation();
    return (
        <footer>
            <Modal
                visible={ModalVisible}
                children={<ReviewPost 
                        close={() => { setModalVisible(false); }}

                    />
                }
                onClose={() => {setModalVisible(false); }}
            />
            {
                window.location.pathname.includes('login') || window.location.pathname.includes('register') || window.location.pathname.includes('recovery')?
                <div className='laptop:mt-[100px] mobile:mt-[30px]'></div>:
                <div className="flex desktop:pt-[120px] laptop:pt-[120px] tablet:pt-[90px] mobile:pt-[70px] pb-[20px]">
                    <div className='flex container mx-auto laptop:flex-row mobile:flex-col laptop:columns-2 mobile:columns-1  '>
                        <div className='desktop:mr-[40px] laptop:mr-[40px] tablet:mr-[0px] mobile:mr-[0px]'>
                            <div className='flex flex-row items-center gap-[10px]'>
                                <img src={require('../assets/icons/minmol-logo.png')} alt="image1" />
                                <div className='desktop:text-16 mobile:text-[12px] font-regular leading-[20px]'>Министерство по делам молодежи и социальным <br className='desktop:flex laptop:flex tablet:hidden mobile:hidden'/>коммуникациям Республики Саха (Якутия)</div>
                            </div>
                            <div className='font-regular mobile:text-[12px] desktop:text-[26px] text-blue mobile:my-[16px] desktop:my-[32px] mobile:leading-[15px] desktop:leading-[30px]'>Берегите психологическое здоровье - свое и <br className='desktop:flex laptop:flex tablet:hidden mobile:hidden' /> своих окружающих!</div>
                            <div className='mobile:hidden desktop:flex font-regular text-16 text-grey flex'>Якутский Республиканский Психологический Центр © 2022, Все права защищены</div>
                        </div>
                        <div className={'flex flex-1 flex-wrap justify-start gap-x-[40px]'}>
                            <a href='https://lk.psysakha.ru/' className='mobile:mt-[5px] desktop:mt-[0px] font-regular text-16 text-grey flex flex-0 max-h-[35px] hover:text-blue'>Психологам</a>
                            <Link to='/contacts' className='mobile:mt-[5px] desktop:mt-[0px] font-regular text-16 text-grey max-h-[35px] flex hover:text-blue'>{t('aboutUs.contacts')}</Link>
                            <Link to='/payment' className='whitespace-nowrap mobile:mt-[5px] desktop:mt-[0px] font-regular text-16 text-grey max-h-[35px] flex hover:text-blue'>{t('paymentOrder')}</Link>
                            {/* {
                                isSignedIn?
                                <Link to='/' className='whitespace-nowrap mobile:mt-[5px] desktop:mt-[0px] font-regular text-16 text-grey max-h-[35px] flex  hover:text-blue'>{t('support.title')}</Link>:<></>
                            } */}
                            <Link to='/privacy-policy' className='whitespace-nowrap mobile:mt-[5px] desktop:mt-[0px] font-regular text-16 text-grey max-h-[35px] flex hover:text-blue'>{t('footer.policy')}</Link>
                            <Link to='/term-of-use' className='whitespace-nowrap mobile:mt-[5px] desktop:mt-[0px] font-regular text-16 text-grey max-h-[35px] flex hover:text-blue'>{t('footer.terms')}</Link>
                            <Link to='/requisites' className='whitespace-nowrap mobile:mt-[5px] desktop:mt-[0px] font-regular text-16 text-grey max-h-[35px] flex hover:text-blue'>{t('requisites.title')}</Link>
                            {
                                isSignedIn?
                                <button
                                    onClick={() => {setModalVisible(true)}} 
                                    to='/' 
                                    className='mobile:mt-[5px] desktop:mt-[0px] font-regular text-16 text-grey max-h-[35px] flex hover:text-blue'>Оставить отзыв</button>:<></>
                            }

                            <div className='desktop:hidden laptop:hidden tablet:visible mobile:visible mt-[25px] font-regular text-16 text-grey flex'>{t('main.psyCenter')} © 2022, Все права защищены</div>
                        </div>
                    </div>
                </div>
            }
        </footer>

    )
}