import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigation, useParams } from 'react-router-dom';
import { Modal } from '../../components/Modal';
import { ModalRecordCancel } from '../../components/modals/ModalRecordCancel';
import { ModalRecordTransfer } from '../../components/modals/ModalRecordTransfer';
import { groupFunctionRevert, sessionTypeRevert } from '../../configs/functions';
import { GetData } from '../../requests/get';

import {useTranslation} from 'react-i18next';

export const SelectedSessions = () => {
    const navigation = useParams();
    const {t} = useTranslation();

    const id = navigation.session
    //const [submit, setSubmit] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const [modalCancel, setModalCancel] = React.useState(false);
    const [modalTransfer, setModalTransfer] = React.useState(false);

    const RepeatFunc = React.useRef(false);
    let token = useSelector(state => state.auth.session.token);

    React.useEffect(() => {
        if(RepeatFunc.current === false)
        
        {
            setLoading(true)
            if(token){
                GetData('/api/records', token, (json, statusCode) => {
                    setLoading(false)
                    if (statusCode === 200) { 
                        const finded = json.data.find(item => item.id.toString() === id);
                        console.log(finded)
                        setData(finded)
                    } 
                }, (e) => {
                    setLoading(false)
                    console.log(e);
                })
            }
        }
        return () => {
            RepeatFunc.current = true;
        }
    }, [])
    return(
        <div>
            <title>{t('pageRecord.title')}</title>
            <div className='container mx-auto items-center content-center desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]'>
                <div className="flex flex-row">
                    <div className="text-blue mobile:text-16 desktop:text-[50px] font-medium">{t('pageRecord.title').split(' ')[0]}</div>
                    <div className="mobile:text-16 desktop:text-[50px] font-medium desktop:ml-[13px] mobile:ml-[5px]">{t('pageRecord.title').substring(t('pageRecord.title').indexOf(' '))}</div>
                </div>
            </div>
            <div className='container mx-auto items-center content-center desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]'>
                <div className='grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-[30px] justify-between pb-[50px]'>
                    {/* col-1 */}
                    <div className='flex flex-col gap-[30px] bg-white-cards rounded-[19px] p-[30px]'>
                        <div className='text-16'>{data?.psych?.psych_type === 'staff'? t('profile.staff'): t('profile.independent')}</div>
                        <div>
                            <div className='flex flex-row '>
                                <div className=''>
                                    <img
                                        src={data?.psych?.avatar_url? data?.psych?.avatar_url : require('../../assets/icons/nulled-photo.png')}
                                        alt='...'
                                        className="rounded-full laptop:w-[106px]  laptop:h-[106px] mobile:w-[74px] mobile:h-[74px] mobile:min-w-[74px] object-cover mr-[17px]"
                                    />    
                                </div>
                                <div className='flex flex-col gap-[10px]'>
                                    <div className='font-medium text-20'>{data?.psych?.last_name} {data?.psych?.first_name} {data?.psych?.middle_name}</div>
                                    {/* Email */}
                                    <div className='flex flex-row whitespace-nowrap items-center'>
                                        <div className='flex'>
                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.0984 7.00318V13.7738C17.0984 14.4037 16.8482 15.0078 16.4028 15.4532C15.9574 15.8986 15.3533 16.1488 14.7234 16.1488H4.27344C3.64355 16.1488 3.03946 15.8986 2.59406 15.4532C2.14866 15.0078 1.89844 14.4037 1.89844 13.7738V7.00318L9.25714 11.3333C9.33024 11.3764 9.41357 11.3991 9.49844 11.3991C9.58331 11.3991 9.66663 11.3764 9.73974 11.3333L17.0984 7.00318ZM14.7234 3.79883C15.3078 3.79874 15.8717 4.0141 16.3073 4.40373C16.7428 4.79336 17.0194 5.32989 17.0842 5.91068L9.49844 10.3728L1.91269 5.91068C1.97744 5.32989 2.25405 4.79336 2.68959 4.40373C3.12514 4.0141 3.68905 3.79874 4.27344 3.79883H14.7234Z" fill="#3A7DFF"/>
                                            </svg>
                                        </div>
                                        <div className='text-16 text-blue ml-[10px]'>{data?.psych?.email}</div>
                                    </div>
                                    {/* Phone */}
                                    {
                                        data.psych.phone?
                                        <div className='flex flex-row whitespace-nowrap items-center'>
                                            <div>
                                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.6654 10.6676C9.16158 12.1705 7.41928 13.6088 6.73053 12.9201C5.74538 11.9349 5.13738 11.0761 2.96378 12.8232C0.791135 14.5693 2.46028 15.734 3.41503 16.6878C4.51703 17.7898 8.62483 16.7467 12.6851 12.6873C16.7445 8.62702 17.7847 4.51922 16.6818 3.41722C15.727 2.46152 14.568 0.793324 12.8219 2.96597C11.0749 5.13862 11.9327 5.74662 12.9198 6.73272C13.6057 7.42147 12.1683 9.16377 10.6654 10.6676Z" fill="#3A7DFF"/>
                                                </svg>
                                            </div>
                                            <div className='text-16 text-blue ml-[10px]'>{data?.psych?.phone}</div>
                                        </div>
                                        : <></>
                                    }
                                    
                                    <div className='flex flex-row whitespace-nowrap items-center'>
                                        <div className='flex flex-row flex-nowrap desktop:gap-[15px] laptop:gap-x-[15px] tablet:gap-[10px] mobile:gap-x-[17px] desktop:pl-0 laptop:pl-0 tablet:pl-[55px] mobile:pl-[40px]'>
                                            {/* VK social */}
                                            {
                                                data.psych.vk_url?
                                                <div
                                                    className='bg-white hover:bg-blue rounded-full desktop:w-[35px] desktop:h-[35px] laptop:w-[35px] laptop:h-[35px] tablet:h-[45px] tablet:w-[45px] mobile:w-[40px] mobile:h-[40px] justify-center flex items-center'
                                                    onClick={() => {
                                                        window.open(data?.psych?.vk_url, '_blank').focus();
                                                    }}
                                                >
                                                    <svg className='hover:fill-white fill-blue rounded-full cursor-pointer' width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
                                                        {/* <rect width="80" height="80" rx="40" /> */}
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M58.1284 30.4897C58.3912 29.6317 58.1284 29 56.8697 29H52.7135C51.6559 29 51.1682 29.5453 50.9038 30.1471C50.9038 30.1471 48.7901 35.1694 45.7961 38.4317C44.8271 39.3777 44.3869 39.6779 43.8581 39.6779C43.5937 39.6779 43.1963 39.3777 43.1963 38.5181V30.4897C43.1963 29.4589 42.905 29 42.0246 29H35.4888C34.8285 29 34.4311 29.4777 34.4311 29.9319C34.4311 30.9077 35.9289 31.134 36.0825 33.8809V39.8491C36.0825 41.1581 35.8403 41.3954 35.3114 41.3954C33.9023 41.3954 30.4745 36.3496 28.4399 30.5761C28.0457 29.4526 27.6467 29 26.5843 29H22.425C21.2375 29 21 29.5453 21 30.1471C21 31.2189 22.4091 36.5429 27.5628 43.5844C30.9985 48.393 35.8355 51 40.2418 51C42.8844 51 43.2105 50.4217 43.2105 49.4239V45.7891C43.2105 44.631 43.4607 44.4 44.2983 44.4C44.9157 44.4 45.9718 44.7017 48.4386 47.0196C51.2569 49.768 51.7208 51 53.3072 51H57.4634C58.6509 51 59.2462 50.4217 58.9042 49.2777C58.5274 48.14 57.1816 46.4884 55.3972 44.5289C54.4282 43.4131 52.9747 42.211 52.533 41.6091C51.9171 40.8376 52.0928 40.4934 52.533 39.8067C52.533 39.8067 57.5996 32.8516 58.1268 30.4897H58.1284Z" />
                                                    </svg>
                                                </div> : <></>
                                            }
                                            {
                                                data.psych.telegram_url?
                                                <div
                                                    className='bg-white hover:bg-blue rounded-full desktop:w-[35px] desktop:h-[35px] laptop:w-[35px] laptop:h-[35px] tablet:h-[45px] tablet:w-[45px] mobile:w-[40px] mobile:h-[40px] justify-center flex items-center'
                                                    onClick={() => {
                                                        window.open(data?.psych?.telegram_url, '_blank').focus();
                                                    }}
                                                >
                                                    <svg className='hover:fill-white fill-blue rounded-full cursor-pointer' width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
                                                        <path className='' d="M54.7051 26.1841L24.5876 37.5883C22.5322 38.399 22.5441 39.5249 24.2105 40.0269L31.9428 42.3955L49.8333 31.3116C50.6792 30.8062 51.4521 31.0781 50.8168 31.6318L36.322 44.4772H36.3186L36.322 44.4789L35.7886 52.3052C36.57 52.3052 36.9149 51.9532 37.3531 51.5379L41.1089 47.9517L48.9211 53.6179C50.3615 54.3969 51.396 53.9966 51.7545 52.3085L56.8828 28.576C57.4076 26.5094 56.0793 25.5736 54.7051 26.1841Z" />
                                                        {/* <rect width="80" height="80" rx="40" fill='none' /> */}

                                                    </svg>
                                                </div> : <></>
                                            }
                                            {/* Telegram social */}
                                            
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-16'>{t('pageRecord.actions')}</div>
                        <div className='flex flex-col gap-[10px]'>
                            <button
                                onClick={() => {
                                    window.open(data?.meet_url, '_blank').focus();
                                }}
                                className="font-medium text-14 bg-blue disabled:hover:text-white disabled:hover:border-grey hover:bg-white hover:text-blue hover:border-blue hover:border rounded-[19px] flex justify-center items-center h-[52px] text-white disabled:bg-grey min-w-[257px] mobile:w-full tablet:w-auto laptop:w-auto desktop:w-auto mx-auto mobile:mt-[0px] tablet:mt-[0px] laptop:mt-[5px] desktop:mt-[5px]">
                                {t('pageRecord.connect')}
                            </button>
                            <div className='text-[10px] text-grey leading-[12.75px] text-center'>Действие активно за 10 минут <br/>до начала сеанса</div>
                            <button 
                                onClick={
                                    () => {setModalTransfer(true)}
                                }
                                className="font-medium text-14 bg-white disabled:border-grey hover:text-white hover:bg-blue border border-blue rounded-[19px] flex justify-center items-center h-[52px] text-blue disabled:text-white disabled:bg-grey min-w-[257px] mobile:w-full tablet:w-auto laptop:w-auto desktop:w-auto mx-auto mobile:mt-[0px] tablet:mt-[0px] laptop:mt-[10px] desktop:mt-[10px]">
                                    {t('pageRecord.moveSeans')}
                            </button>
                            <div className='text-[10px] text-grey leading-[12.75px] text-center'>Не позднее 48 часов до сеанса</div>
                            <button 
                                onClick={
                                    () => {setModalCancel(true)}
                                }
                                className="font-medium text-14 disabled:border-grey hover:text-white hover:bg-blue border border-blue rounded-[19px] flex justify-center items-center h-[52px] text-blue disabled:text-white disabled:bg-grey min-w-[257px] mobile:w-full tablet:w-auto laptop:w-auto desktop:w-auto mx-auto mobile:mt-[0px] tablet:mt-[0px] laptop:mt-[10px] desktop:mt-[10px]">
                                    {t('pageRecord.cancel')}
                            </button>
                        </div>
                        
                    </div>
                    {/* col-2 */}
                    <div className='flex h-fit flex-col gap-[30px] bg-white-cards rounded-[19px] p-[30px]'>
                        <div className='text-16'>{t('pageRecord.info')}</div>
                        <div className='grid grid-cols-2'>
                            <div className='grid grid-cols-1 h-fit gap-[23px]'>
                                <div className='text-grey'>{t('profile.paymentM')}:</div>
                                <div className='text-grey'>{t('writeCons.cost')}:</div>
                                <div className='text-grey'>Тип записи:</div>
                                <div className='text-grey'>{t('listPsy.vidSeans')}:</div>
                                <div className='text-grey'>Дата и время сеанса:</div>
                            </div>
                            <div className='grid grid-cols-1 gap-[23px]'>
                                <div>{data?.payment_type === 'cash'? 'Мобильный банк' : 'Онлайн оплата'}</div>
                                <div className='flex flex-row items-center'>
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 4.75C0 4.12011 0.250223 3.51602 0.695621 3.07062C1.14102 2.62522 1.74511 2.375 2.375 2.375H16.625C17.2549 2.375 17.859 2.62522 18.3044 3.07062C18.7498 3.51602 19 4.12011 19 4.75V5.9375H0V4.75ZM0 8.3125V14.25C0 14.8799 0.250223 15.484 0.695621 15.9294C1.14102 16.3748 1.74511 16.625 2.375 16.625H16.625C17.2549 16.625 17.859 16.3748 18.3044 15.9294C18.7498 15.484 19 14.8799 19 14.25V8.3125H0ZM3.5625 10.6875H4.75C5.06494 10.6875 5.36699 10.8126 5.58969 11.0353C5.81239 11.258 5.9375 11.5601 5.9375 11.875V13.0625C5.9375 13.3774 5.81239 13.6795 5.58969 13.9022C5.36699 14.1249 5.06494 14.25 4.75 14.25H3.5625C3.24756 14.25 2.94551 14.1249 2.72281 13.9022C2.50011 13.6795 2.375 13.3774 2.375 13.0625V11.875C2.375 11.5601 2.50011 11.258 2.72281 11.0353C2.94551 10.8126 3.24756 10.6875 3.5625 10.6875Z" fill="#3A7DFF"/>
                                    </svg>
                                    <div className='text-16 text-blue ml-[10px]'>{data?.price} ₽</div>
                                </div>
                                <div>{groupFunctionRevert(data?.group)}</div>
                                <div>{sessionTypeRevert(data?.session_type)}</div>
                                <div className='flex flex-row items-center'>
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_2237_1975)">
                                        <path d="M4.15625 0C4.31372 0 4.46475 0.0625556 4.57609 0.173905C4.68744 0.285255 4.75 0.436278 4.75 0.59375V1.1875H14.25V0.59375C14.25 0.436278 14.3126 0.285255 14.4239 0.173905C14.5353 0.0625556 14.6863 0 14.8438 0C15.0012 0 15.1522 0.0625556 15.2636 0.173905C15.3749 0.285255 15.4375 0.436278 15.4375 0.59375V1.1875H16.625C17.2549 1.1875 17.859 1.43772 18.3044 1.88312C18.7498 2.32852 19 2.93261 19 3.5625V16.625C19 17.2549 18.7498 17.859 18.3044 18.3044C17.859 18.7498 17.2549 19 16.625 19H2.375C1.74511 19 1.14102 18.7498 0.695621 18.3044C0.250223 17.859 0 17.2549 0 16.625V3.5625C0 2.93261 0.250223 2.32852 0.695621 1.88312C1.14102 1.43772 1.74511 1.1875 2.375 1.1875H3.5625V0.59375C3.5625 0.436278 3.62506 0.285255 3.73641 0.173905C3.84776 0.0625556 3.99878 0 4.15625 0V0ZM15.9766 3.5625H3.02219C2.66594 3.5625 2.375 3.8285 2.375 4.15625V5.34375C2.375 5.6715 2.66475 5.9375 3.02219 5.9375H15.9778C16.3341 5.9375 16.625 5.6715 16.625 5.34375V4.15625C16.625 3.8285 16.3353 3.5625 15.9766 3.5625ZM12.8891 10.5141C13.0006 10.4026 13.0632 10.2514 13.0632 10.0938C13.0632 9.93608 13.0006 9.78487 12.8891 9.67338C12.7776 9.56188 12.6264 9.49925 12.4688 9.49925C12.3111 9.49925 12.1599 9.56188 12.0484 9.67338L8.90625 12.8167L7.54537 11.4546C7.49017 11.3994 7.42463 11.3556 7.35251 11.3258C7.28038 11.2959 7.20307 11.2805 7.125 11.2805C7.04693 11.2805 6.96962 11.2959 6.89749 11.3258C6.82537 11.3556 6.75983 11.3994 6.70463 11.4546C6.64942 11.5098 6.60563 11.5754 6.57575 11.6475C6.54588 11.7196 6.5305 11.7969 6.5305 11.875C6.5305 11.9531 6.54588 12.0304 6.57575 12.1025C6.60563 12.1746 6.64942 12.2402 6.70463 12.2954L8.48588 14.0766C8.54103 14.1319 8.60655 14.1758 8.67869 14.2057C8.75082 14.2357 8.82815 14.2511 8.90625 14.2511C8.98435 14.2511 9.06168 14.2357 9.13381 14.2057C9.20595 14.1758 9.27147 14.1319 9.32662 14.0766L12.8891 10.5141Z" fill="#3A7DFF"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2237_1975">
                                        <rect width="19" height="19" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    <div className='text-16 text-blue ml-[10px]'>
                                        {new Date(data?.window?.working_date?.date).toLocaleDateString('ru-RU', {day: '2-digit', month: 'short', year: 'numeric'})}
                                    </div>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_2237_1977)">
                                        <path d="M19 9.5C19 12.0196 17.9991 14.4359 16.2175 16.2175C14.4359 17.9991 12.0196 19 9.5 19C6.98044 19 4.56408 17.9991 2.78249 16.2175C1.00089 14.4359 0 12.0196 0 9.5C0 6.98044 1.00089 4.56408 2.78249 2.78249C4.56408 1.00089 6.98044 0 9.5 0C12.0196 0 14.4359 1.00089 16.2175 2.78249C17.9991 4.56408 19 6.98044 19 9.5ZM9.5 4.15625C9.5 3.99878 9.43744 3.84776 9.32609 3.73641C9.21474 3.62506 9.06372 3.5625 8.90625 3.5625C8.74878 3.5625 8.59776 3.62506 8.48641 3.73641C8.37506 3.84776 8.3125 3.99878 8.3125 4.15625V10.6875C8.31253 10.7922 8.34023 10.8949 8.39278 10.9855C8.44533 11.076 8.52087 11.151 8.61175 11.2029L12.768 13.5779C12.9044 13.6516 13.0642 13.669 13.2133 13.6265C13.3624 13.5839 13.4889 13.4847 13.5658 13.3501C13.6427 13.2155 13.664 13.0561 13.6249 12.9061C13.5859 12.756 13.4898 12.6272 13.357 12.5471L9.5 10.3431V4.15625Z" fill="#3A7DFF"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2237_1977">
                                        <rect width="19" height="19" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    <div className='text-16 text-blue ml-[10px]'>
                                        {data?.window?.from}, {data?.window?.to}
                                    </div>
                                </div>
                                {/* <div className='flex flex-row items-center'>
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_2237_1980)">
                                        <path d="M10.1116 1.21006C9.90805 1.19504 9.70406 1.18751 9.5 1.1875V0C9.73339 0.000113795 9.96669 0.00882804 10.1994 0.026125L10.1116 1.21006ZM12.4913 1.74444C12.1107 1.59755 11.7197 1.47887 11.3216 1.38937L11.5817 0.230375C12.0365 0.3325 12.4842 0.467875 12.9188 0.6365L12.4913 1.74444ZM14.1182 2.58756C13.9484 2.47433 13.7745 2.36739 13.5969 2.26694L14.1823 1.23381C14.5883 1.46387 14.9769 1.72345 15.3449 2.01044L14.6146 2.94737C14.4536 2.82177 14.288 2.70215 14.1182 2.58875V2.58756ZM16.2961 4.71319C16.0612 4.37947 15.802 4.06353 15.5206 3.76794L16.3804 2.94856C16.701 3.287 16.9979 3.64919 17.2674 4.02919L16.2961 4.71319ZM17.1796 6.31869C17.1016 6.13042 17.0169 5.94505 16.9254 5.76294L17.9859 5.22856C18.1956 5.64548 18.3742 6.07732 18.5202 6.52056L17.3921 6.89225C17.3283 6.69853 17.2574 6.50721 17.1796 6.31869ZM17.8089 9.29575C17.7993 8.88765 17.7596 8.48082 17.6902 8.07856L18.8599 7.87669C18.9394 8.33506 18.9857 8.80056 18.9976 9.26606L17.8101 9.29575H17.8089ZM17.6534 11.1221C17.6926 10.9202 17.7246 10.7196 17.7496 10.5165L18.9287 10.6626C18.8717 11.1259 18.7804 11.5843 18.6556 12.0341L17.5109 11.7171C17.5655 11.5211 17.613 11.3228 17.6534 11.1221ZM16.5229 13.9472C16.7414 13.6028 16.9337 13.2418 17.1 12.8689L18.1854 13.3499C17.9954 13.7774 17.7757 14.1882 17.5263 14.5825L16.5229 13.9472ZM15.3781 15.3781C15.523 15.2332 15.6619 15.0836 15.7937 14.9292L16.6939 15.7047C16.5414 15.8814 16.3826 16.0525 16.2177 16.2177L15.3781 15.3781Z" fill="#3A7DFF"/>
                                        <path d="M9.50371 1.1875C8.13675 1.1876 6.79092 1.52481 5.58541 2.16925C4.3799 2.8137 3.35192 3.74549 2.59253 4.88211C1.83313 6.01872 1.36576 7.32507 1.23182 8.68545C1.09787 10.0458 1.30147 11.4182 1.82459 12.6811C2.34772 13.944 3.17422 15.0584 4.23088 15.9256C5.28755 16.7928 6.54177 17.386 7.88246 17.6527C9.22314 17.9194 10.6089 17.8514 11.917 17.4546C13.2251 17.0579 14.4152 16.3447 15.3818 15.3781L16.2214 16.2177C15.1167 17.323 13.7564 18.1387 12.2611 18.5927C10.7658 19.0466 9.18162 19.1247 7.64891 18.8201C6.1162 18.5154 4.68229 17.8374 3.47424 16.8461C2.26618 15.8549 1.32128 14.5809 0.723257 13.1372C0.125231 11.6934 -0.107457 10.1245 0.0458112 8.56932C0.199079 7.01416 0.73357 5.52081 1.60193 4.22158C2.47028 2.92236 3.6457 1.85738 5.02402 1.121C6.40234 0.384625 7.94101 -0.000414119 9.50371 3.34232e-07V1.1875Z" fill="#3A7DFF"/>
                                        <path d="M8.90625 3.5625C9.06372 3.5625 9.21474 3.62506 9.32609 3.73641C9.43744 3.84776 9.5 3.99878 9.5 4.15625V10.3431L13.357 12.5471C13.4898 12.6272 13.5859 12.756 13.6249 12.9061C13.664 13.0561 13.6427 13.2155 13.5658 13.3501C13.4889 13.4847 13.3624 13.5839 13.2133 13.6265C13.0642 13.669 12.9044 13.6516 12.768 13.5779L8.61175 11.2029C8.52087 11.151 8.44533 11.076 8.39278 10.9855C8.34023 10.8949 8.31253 10.7922 8.3125 10.6875V4.15625C8.3125 3.99878 8.37506 3.84776 8.48641 3.73641C8.59776 3.62506 8.74878 3.5625 8.90625 3.5625Z" fill="#3A7DFF"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2237_1980">
                                        <rect width="19" height="19" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    <div className='text-16 text-blue ml-[10px]'>
                                        {data?.window?.from}, {data?.window?.to}
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                    {/* col-3 */}
                </div>
            </div>
            <Modal 
                visible={modalCancel}
                children={
                    <ModalRecordCancel 
                        close={() => { setModalCancel(false); }}
                        onSelect={() => {
                            setModalCancel(false);
                        }}
                        data={data}
                    />
                }
                onClose={() => {setModalCancel(false); }}
            />
            <Modal
                visible={modalTransfer}
                children={
                    <ModalRecordTransfer 
                        close={() => { setModalTransfer(false); }}
                        onSelect={() => {
                            setModalTransfer(false);
                        }}
                        successFunction={() => {
                            setModalTransfer(false);
                            GetData('/api/records', token, (json, statusCode) => {
                                setLoading(false)
                                if (statusCode === 200) { 
                                    const finded = json.data.find(item => item.id.toString() === id);
                                    //console.log(finded)
                                    setData(finded)
                                } 
                            }, (e) => {
                                setLoading(false)
                                console.log(e);
                            })
                        }}
                    />
                }
                onClose={() => {setModalTransfer(false);}}
            />
        </div>
    )
}


