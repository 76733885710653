import * as React from 'react';
import { Link, useNavigate } from "react-router-dom";
import {useTranslation} from 'react-i18next';

export const Support = () => {
    const {t} = useTranslation();
    const [submit, setSubmit] = React.useState(0);
    const navigate = useNavigate();
    const [hoverActive, setHoverActive] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [modalValue, setModalValue] = React.useState({ avatar: null, author: '', text: '' });

    const [data, setData] = React.useState({
        lastName: '',
        firstName: '',
        textSms: ''
    })

    return (
        <div>
            <title>{t('support.title')}</title>
            <div className='container mx-auto items-center content-center desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]'>
                <div className="flex flex-row">
                    <div className="text-blue mobile:text-16 desktop:text-[50px] font-medium">{t('support.title').split(' ')[0]}</div>
                    <div className="mobile:text-16 desktop:text-[50px] font-medium desktop:ml-[13px] mobile:ml-[5px]">{t('support.title').substring(t('support.title').indexOf(' '))}</div>
                </div>
            </div>

            <div className='container mx-auto items-center content-center desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px] mobile:gap-[15px]'>
                <div className="flex flex-row">
                    <h2 className='font-regular mobile:text-16 uppercase'>{t('support.contact')}</h2>
                </div>
                <div className='grid laptop:grid-cols-3 mobile:grid-cols-1 laptop:mt-[50px] mobile:gap-[15px]'>
                    <div className='flex flex-row gap-[20px] mobile:items-center'>
                        <div>
                            <svg className='laptop:w-[45px] laptop:h-[45px] mobile:w-[20px] mobile:h-[20px]' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.0984 7.00318V13.7738C17.0984 14.4037 16.8482 15.0078 16.4028 15.4532C15.9574 15.8986 15.3533 16.1488 14.7234 16.1488H4.27344C3.64355 16.1488 3.03946 15.8986 2.59406 15.4532C2.14866 15.0078 1.89844 14.4037 1.89844 13.7738V7.00318L9.25714 11.3333C9.33024 11.3764 9.41357 11.3991 9.49844 11.3991C9.58331 11.3991 9.66663 11.3764 9.73974 11.3333L17.0984 7.00318ZM14.7234 3.79883C15.3078 3.79874 15.8717 4.0141 16.3073 4.40373C16.7428 4.79336 17.0194 5.32989 17.0842 5.91068L9.49844 10.3728L1.91269 5.91068C1.97744 5.32989 2.25405 4.79336 2.68959 4.40373C3.12514 4.0141 3.68905 3.79874 4.27344 3.79883H14.7234Z" fill="#3A7DFF"/>
                            </svg>
                        </div>
                        <div>
                            <div className=' font-medium laptop:text-20 mobile:text-[10px] '>По вопросам партнерства</div>
                            <div className='font-medium laptop:text-20 mobile:text-[10px] text-blue'>partners@mail.ru</div>
                        </div>
                    </div>
                    <div className='flex flex-row gap-[20px] mobile:items-center'>
                        <div>
                            <svg className='laptop:w-[45px] laptop:h-[45px] mobile:w-[20px] mobile:h-[20px]' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.0984 7.00318V13.7738C17.0984 14.4037 16.8482 15.0078 16.4028 15.4532C15.9574 15.8986 15.3533 16.1488 14.7234 16.1488H4.27344C3.64355 16.1488 3.03946 15.8986 2.59406 15.4532C2.14866 15.0078 1.89844 14.4037 1.89844 13.7738V7.00318L9.25714 11.3333C9.33024 11.3764 9.41357 11.3991 9.49844 11.3991C9.58331 11.3991 9.66663 11.3764 9.73974 11.3333L17.0984 7.00318ZM14.7234 3.79883C15.3078 3.79874 15.8717 4.0141 16.3073 4.40373C16.7428 4.79336 17.0194 5.32989 17.0842 5.91068L9.49844 10.3728L1.91269 5.91068C1.97744 5.32989 2.25405 4.79336 2.68959 4.40373C3.12514 4.0141 3.68905 3.79874 4.27344 3.79883H14.7234Z" fill="#3A7DFF"/>
                            </svg>
                        </div>
                        <div>
                            <div className=' font-medium laptop:text-20 mobile:text-[10px]'>По вопросам партнерства</div>
                            <div className='font-medium laptop:text-20 mobile:text-[10px] text-blue'>partners@mail.ru</div>
                        </div>
                    </div>
                    <div className='flex flex-row gap-[20px] mobile:items-center'>
                        <div>
                            <svg className='laptop:w-[45px] laptop:h-[45px] mobile:w-[20px] mobile:h-[20px]' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.0984 7.00318V13.7738C17.0984 14.4037 16.8482 15.0078 16.4028 15.4532C15.9574 15.8986 15.3533 16.1488 14.7234 16.1488H4.27344C3.64355 16.1488 3.03946 15.8986 2.59406 15.4532C2.14866 15.0078 1.89844 14.4037 1.89844 13.7738V7.00318L9.25714 11.3333C9.33024 11.3764 9.41357 11.3991 9.49844 11.3991C9.58331 11.3991 9.66663 11.3764 9.73974 11.3333L17.0984 7.00318ZM14.7234 3.79883C15.3078 3.79874 15.8717 4.0141 16.3073 4.40373C16.7428 4.79336 17.0194 5.32989 17.0842 5.91068L9.49844 10.3728L1.91269 5.91068C1.97744 5.32989 2.25405 4.79336 2.68959 4.40373C3.12514 4.0141 3.68905 3.79874 4.27344 3.79883H14.7234Z" fill="#3A7DFF"/>
                            </svg>
                        </div>
                        <div>
                            <div className=' font-medium laptop:text-20 mobile:text-[10px]'>По вопросам партнерства</div>
                            <div className='font-medium laptop:text-20 mobile:text-[10px] text-blue'>partners@mail.ru</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto mobile:pt-[30px] laptop:pt-[100px] flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col">
                <div className='desktop:basis-2/4 laptop:basis-2/4 tablet:basis-full mobile:basis-full '>
                    <div className="flex flex-row flex-nowrap ">
                        <h2 className='font-regular mobile:text-16'>СВЯЖИТЕСЬ С НАМИ</h2>
                    </div>
                    <div className='grid laptop:grid-cols-2 mobile:grid-cols-1 laptop:gap-[30px] mobile:gap-0 laptop:mt-[50px] mobile:mt-[15px]'>
                        <div className='flex flex-col gap-y-[25px] mobile:gap-y-[15px]'>
                            <Form
                                name=''
                                label='ФИО'
                                value={data.firstName}
                                onChange={txt => setData({ ...data, firstName: txt })}
                            />
                            <Form
                                name=''
                                label='Электронная почта'
                                value={data.lastName}
                                onChange={txt => setData({ ...data, lastName: txt })}
                            />
                            <Form
                                name=''
                                label='Тема обращения'
                                value={data.lastName}
                                onChange={txt => setData({ ...data, lastName: txt })}
                            />
                        </div>
                        <div className='mobile:mt-[15px]'>                            
                            <FormTextArea
                                name=''
                                label='Текст обращения'
                                value={data.textSms}
                                onChange={txt => setData({ ...data, textSms: txt })}
                            />  
                        </div>
                    </div>
                </div>
                <div className=' desktop:basis-2/4 laptop:basis-2/4 tablet:basis-full mobile:basis-full items-end flex laptop:ml-[40px] mobile:ml-0 laptop:mt-[0px] mobile:mt-[40px]'>
                    
                </div>
            </div>
            <div className="container mx-auto laptop:mt-[40px] flex flex-col columns-1 basis-2/4">
                <div className="flex">
                    <div className="mb-3 w-96">
                        <input className="form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-blue
                        rounded-[19px]
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="file" id="formFile" />
                    </div>
                </div>

                <button
                    className='laptop:self-start bg-blue h-[54px] px-[102px] rounded-[19px] text-white font-medium disabled:bg-grey active:blur-none'
                    disabled={!submit}
                    onClick={() => {
                        if (submit) {
                            setModal(true)
                        } else {
                            window.alert('Заполните поля');
                        }
                    }}
                >{t('recovery.send')}</button>
            </div>
            
        </div>
    )
}

const Form = ({ name, label, type, defaultValue, onChange, value }) => {
    const ref = React.useRef();
    return (
        <div>
            <div className='grid grid-rows-2 border-blue border-[1px] rounded-[19px] pt-[12px] pb-[12px] pl-[53px] pr-[10px]' onClick={() => { ref.current?.focus() }}>
                <label className='text-12 text-pick text-blue font-regular'>{label}</label>
                <input
                    className='text-16 outline-none font-regular'
                    type={type}
                    value={value}
                    defaultValue={defaultValue}
                    ref={ref}
                    onChange={(e) => { if (typeof onChange === 'function') { onChange(e.currentTarget.value) } }}
                />
            </div>
        </div>
    );
}

const FormTextArea = ({ name, label, type, defaultValue, onChange, value }) => {
    const ref = React.useRef();
    return (
        <div>
            <div className='flex flex-col border-blue border-[1px] rounded-[19px] pt-[12px] pb-[12px] pl-[53px] pr-[10px]' onClick={() => { ref.current?.focus() }}>
                <label className='text-12 text-pick text-blue font-regular'>{label}</label>
                <textarea

                    className='text-16 outline-none font-regular whitespace-pre-wrap min-h-[228px] align-text-top'
                    type={type}
                    value={value}
                    defaultValue={defaultValue}
                    ref={ref}
                    onChange={(e) => { if (typeof onChange === 'function') { onChange(e.currentTarget.value) } }}
                />
            </div>
        </div>
    );
}