import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ru from './ru.json'
import sa from './sa.json'

const resources = {
    ru: {
        translation: ru
    },
    sa: {
        translation: sa
    }
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: 'ru',
    lng: 'ru',
    interpolation: {
        escapeValue: false 
    }
})

export default i18n