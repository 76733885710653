import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export const UsefulTipsView = (props) => {
    const {} = props;
    const location = useLocation();
    const {t} = useTranslation();

    return(
        <div className={styles.main}>
            <div className={styles.divHeader}>
                <div className={styles.divTitle}>
                    <p className={styles.title1}>{t('facts').split(' ')[0]}</p>
                    <p className={styles.title2}>{t('facts').substring(t('facts').indexOf(' '))}</p>
                </div>
                <p className={styles.titleText}>{location.state.title}</p>
                {
                    location.state.text.split('\n').map((item, index) => 
                        <p className='text-[14px] laptop:text-[16px] mt-[10px]'>{item}</p>
                    )
                }
                
            </div>
        </div>
    );
}

const styles = {
    main: 'bg-white',
    divHeader: "container mx-auto flex flex-col",
    divTitle: 'flex flex-row items-center justify-start',
    title1: 'text-16 desktop:text-35 font-medium hidden desktop:flex',
    title2: 'text-blue text-16 desktop:text-35 font-medium ml-[10px] hidden desktop:flex',
    titleText: "mt-[20px] laptop:mt-[60px] text-[16px] laptop:text-[20px] font-regular text-blue"
}