import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    token: '',
    id: 0,
    'first_name': '',
    'last_name': '',
    'middle_name': '',
    'sex': '',
    'birthdate': '',
    'city_id': 0,
    'email': '',
    'phone': "",
    'notification_enabled': false,
    'avatar_url': '',
    'vk_url': '',
    'telegram_url': '',
    'video_presentation_url': '',
    'active': false,
    'price': 0,
    'psych_type': '',
    'favorite': []
};

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state = Object.assign(state, action.payload); 
        },
        userLoggedOut: () => initialState,
        addItemToFavorite: (state, action) => {
            if(!state.favorite.includes(action.payload)) {
                state.favorite.push(action.payload);
            }
        },
        removeItemFromFavorite: (state, action) => {
            if(state.favorite.includes(action.payload)) {
                state.favorite = state.favorite.filter((item, index) => item !== action.payload);
            }
        }
    },
    
});

export const { 
    setUser, userLoggedOut,
    addItemToFavorite, removeItemFromFavorite
} = userSlice.actions;

export default userSlice.reducer;