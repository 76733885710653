export const psycologiesData = [
    {id: 1, name: 'Владислав', surname: 'Лукачев', city: 'г. Якутск', count: 50, descr: 'Помощь в разрешении личностных проблем, адаптироваться к окружающему миру и улучшить психологический климат в семье или коллективе', price: 1000, type: 'Очно, онлайн', category: ['Тревожность и фобии', 'Карьера', 'Что-то еще'], gender: 'Мужской', date: new Date(2022, 11, 24), typeWorker: 'Штатный психолог', typePay: 'Онлайн оплата'}, 
    {id: 2, name: 'Анастасия', surname: 'Федорова', city: 'г. Якутск', count: 20, descr: 'Помощь в разрешении личностных проблем, адаптироваться к окружающему миру и улучшить психологический климат в семье или коллективе', price: 200, type: 'Очно, онлайн', category: ['Тревожность и фобии', 'Карьера'], gender: 'Женский', date: new Date(2022, 11, 26), typeWorker: 'Штатный психолог', typePay: 'Онлайн оплата'}, 
    {id: 3, name: 'Евгения', surname: 'Любец', city: 'г. Нерюнгри', count: 10, descr: 'Помощь в разрешении личностных проблем, адаптироваться к окружающему миру и улучшить психологический климат в семье или коллективе', price: 2000, type: 'Очно, онлайн', category: ['Тревожность и фобии', 'Карьера'], gender: 'Женский', date: new Date(2022, 11, 23), typeWorker: 'Штатный психолог', typePay: 'Онлайн оплата'}, 
    {id: 4, name: 'Владислав', surname: 'Лукачев', city: 'г. Якутск', descr: 'Помощь в разрешении личностных проблем, адаптироваться к окружающему миру и улучшить психологический климат в семье или коллективе', count: 50, price: 4000, type: 'Очно, онлайн', category: ['Тревожность и фобии', 'Карьера', 'Спорт'], gender: 'Мужской', date: new Date(2022, 11, 12), typeWorker: 'Штатный психолог', typePay: 'Онлайн оплата'}, 
    {id: 5, name: 'Анастасия', surname: 'Федорова', city: 'г. Якутск', descr: 'Помощь в разрешении личностных проблем, адаптироваться к окружающему миру и улучшить психологический климат в семье или коллективе', count: 50, price: 5000, type: 'Очно, онлайн', category: ['Тревожность и фобии', 'Карьера'], gender: 'Женский', date: new Date(2022, 12, 12), typeWorker: 'Штатный психолог', typePay: 'Онлайн оплата'}, 
    {id: 6, name: 'Евгения', surname: 'Любец', city: 'г. Якутск', count: 70, descr: 'Помощь в разрешении личностных проблем, адаптироваться к окружающему миру и улучшить психологический климат в семье или коллективе', price: 4100, type: 'Очно, онлайн', category: ['Тревожность и фобии', 'Карьера'], gender: 'Женский', date: new Date(2022, 12, 1), typeWorker: 'Штатный психолог', typePay: 'Онлайн оплата'}, 
    {id: 7, name: 'Владислав', surname: 'Лукачев', city: 'г. Нерюнгри', count: 50, descr: 'Помощь в разрешении личностных проблем, адаптироваться к окружающему миру и улучшить психологический климат в семье или коллективе', price: 2500, type: 'Очно, онлайн', category: ['Тревожность и фобии', 'Карьера', 'Бытовые проблемы'], gender: 'Мужской', date: new Date(2022, 12, 13), typeWorker: 'Штатный психолог', typePay: 'Онлайн оплата'}, 
    {id: 8, name: 'Анастасия', surname: 'Федорова', city: 'г. Якутск', count: 40, descr: 'Помощь в разрешении личностных проблем, адаптироваться к окружающему миру и улучшить психологический климат в семье или коллективе', price: 2000, type: 'Очно, онлайн', category: ['Тревожность и фобии', 'Карьера'], gender: 'Женский', date: new Date(2022, 12, 16), typeWorker: 'Штатный психолог', typePay: 'Онлайн оплата'}, 
    {id: 9, name: 'Евгения', surname: 'Любец', city: 'г. Якутск', count: 36, descr: 'Помощь в разрешении личностных проблем, адаптироваться к окружающему миру и улучшить психологический климат в семье или коллективе', price: 1300, type: 'Очно, онлайн', category: ['Тревожность и фобии', 'Карьера'], gender: 'Женский', date: new Date(2023, 1, 10), typeWorker: 'Штатный психолог', typePay: 'Онлайн оплата'}, 
    {id: 10, name: 'Владислав', surname: 'Лукачев', city: 'г. Нерюнгри', count: 30, descr: 'Помощь в разрешении личностных проблем, адаптироваться к окружающему миру и улучшить психологический климат в семье или коллективе', price: 3500, type: 'Очно, онлайн', category: ['Тревожность и фобии', 'Карьера'], gender: 'Мужской', date: new Date(2022, 9, 1), typeWorker: 'Штатный психолог', typePay: 'Онлайн оплата'}, 
    {id: 11, name: 'Анастасия', surname: 'Федорова', city: 'г. Якутск', count: 1, descr: 'Помощь в разрешении личностных проблем, адаптироваться к окружающему миру и улучшить психологический климат в семье или коллективе', price: 3000, type: 'Очно, онлайн', category: ['Тревожность и фобии', 'Карьера'], gender: 'Женский', date: new Date(2022, 11, 1), typeWorker: 'Штатный психолог', typePay: 'Онлайн оплата'}
];

export const citiesData = [
    { name: 'Не важно' },
    { name: 'г. Якутск' },
    { name: 'г. Нерюнгри' }
];

export const worksData = [{name: 'Нет данных'}];

export const typesData = [{name: 'Онлайн'}, {name: 'Оффлайн'}];

export const othersData = [{name: 'Нет данных'}];

export const genderData = [
    { name: 'Мужской' },
    { name: 'Женский' },
    { name: 'Не имеет значения' }
];

export const WhoData = [
    {name: 'Один'}, 
    {name: 'С партнером'}, 
    {name: 'С семьей'}, 
    {name: 'С группой'}
];

export const WhatData = [
    {name: 'Семейные отношения'},
    {name: 'Детско-родительское отношение'},
    {name: 'Кризисные состояния'},
    {name: 'Насилие'},
    {name: 'Зависимость и отклоняющее поведение'},
    {name: 'Социальная адаптация'},
    {name: 'Психическое здоровье'},
    {name: 'Личностное развитие'},
    {name: 'Иной запрос.....'},
];

export const typeRecord = [
    {
        name: 'Онлайн'
    },
    {
        name: 'Оффлайн'
    }
];

export const cancelData = [
    { name: 'Непредвиденные случаи' }//,
    // { name: '' }
]