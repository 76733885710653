import * as React from 'react';
import { Menu } from '@headlessui/react';

export const HamburgerModal = (props) => {

    const {value, data, onChange, width='min-w-[200px]', height='h-[44px]', backgroundColor} = props;

    return(
        <Menu as={'div'} className='z-[100] flex justify-end'>
            <Menu.Button
                className={` flex row items-center justify-between ${width} ${height} ${backgroundColor}`}
            >
                {value}
            </Menu.Button>
            <Menu.Items as={'div'} className='z-[100] absolute flex flex-col bg-white shadow-[0_4px_15px_-0px_rgba(105,105,105,0.25)] desktop:w-[20vw] laptop:w-[20vw] tablet:w-[70vw] mobile:w-[70vw] rounded-[19px] mt-[95px]'>
            {
                data.map((item, index) => 
                    <Menu.Item 
                        as={'div'} 
                        onClick={() => {onChange(item); }} 
                        key={index.toString()} 
                        className={` cursor-pointer hover:bg-white-cards hover:rounded-[19px] hover:text-blue flex font-medium items-center h-[60px] pl-[20px] pr-[20px] ${value === item.name? 'bg-white-input': 'bg-white'} ${index === 0? 'rounded-tl-[19px] rounded-tr-[19px]': index === data.length - 1? 'rounded-bl-[19px] rounded-br-[19px]': ''}`}>
                    
                        <p className='font-medium text-16'>{item.text}</p>
                    
                    </Menu.Item>
                )   
            }
                
            </Menu.Items>
        </Menu>
    );
}