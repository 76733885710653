import * as React from 'react';

export const Pills = (props) => {
    const {
        names, value, onChange, screenIndex
    } = props;
    const [selectIndex, setSelectIndex] = React.useState(0);

    React.useEffect(() => {
        names.forEach((item, index) => {
            if(item === value) {
                setSelectIndex(index);
            }
        })
    }, [value, names]);

    return(
        <div className='flex flex-col mt-[25px] w-full laptop:w-fit self-start laptop:mt-[130px] overflow-x-scroll'>
            <div className={`flex flex-row w-full`}>
            {
                names.map((item, index) => 
                    <button
                        key={index.toString()}
                        className={`text-12 laptop:text-16 text-blue whitespace-nowrap ${index === names.length - 1? 'mr-[2px] laptop:pr-[10px] ': 'mr-[15px] laptop:mr-[0px] laptop:pr-[56px] '} ${index === 0? 'mr-[0px] laptop:pl-[0px] ': 'ml-[15px] laptop:ml-0 laptop:pl-[56px] '} pb-[6px] laptop:pb-[20px]  ${index <= selectIndex? index === selectIndex? 'border-b-blue ': 'border-b-white laptop:border-b-blue': 'border-b-white laptop:border-b-grey '}  border-b-[3px]`}
                        onClick={() => {
                            if(screenIndex > index) {
                                onChange(item);
                            }
                            
                        }}
                    >{item}</button>
                )
            }
            </div>
        </div>
    );
}