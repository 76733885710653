import * as React from 'react';
import { useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { Search } from '../../components/Search';
import { getPsychFullName } from '../../configs/functions';
import { GetData } from '../../requests/get';

import emptyImg from '../../assets/icons/emptyImg.svg';
import {useTranslation} from 'react-i18next';

export const ArticlePage = () => {
    const [search, setSearch] = React.useState('');
    // eslint-disable-next-line
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const token = useSelector(state => state.auth.session.token);
    const psych = useSelector(state => state.psych.psych);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const refRepeat = React.useRef(false);

    React.useEffect(() => {
        if(refRepeat.current === false) {
            setLoading(true);
            GetData(`/api/posts`, token, (json, statusCode) => {
                if(statusCode === 200) {
                    setData(json.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }, (e) => {
                console.log('e', e);
                setLoading(false);
            });
        }
        return () => refRepeat.current = true;
        //eslint-disable-next-line
    }, []);


    return(
        <div className={styles.main}>
            <p className={styles.title}>{t('news.articles')}</p>
            <Search 
                search={search}
                setSearch={setSearch}
            />
            <div className={styles.div}>
            {
                data.map((item, index) => 
                    <div 
                        key={index.toString()} 
                        className={styles.divItem}
                        onClick={() => {
                            navigate('/article/' + item.id, {state: item});
                        }}    
                    >
                        <p className={styles.author}>{t('news.author')} {getPsychFullName(psych, item.user_id)}, {new Date(item.created_at).toLocaleDateString('ru-RU', {day: '2-digit', month: 'long'})}</p>
                        <img 
                            className={'rounded-[19px] mt-[20px] laptop:h-[402px] w-full'}
                            src={item.cover_url? item.cover_url: emptyImg}
                            alt="image1"
                        />
                        <p className={styles.titleItem}>{item.title}</p>
                        <p className={styles.text} dangerouslySetInnerHTML={{__html: item.text}}></p>
                    </div>
                )
            }
            </div>
        </div>
    );
};

const styles = {
    main: 'container mx-auto',
    title: 'container mx-auto mt-[26px] desktop:mt-[90px] text-[16px] laptop:text-[50px] desktop:text-50 font-medium',
    div: 'grid grid-cols-1 laptop:grid-cols-2 gap-[20px] laptop:gap-[33px] mt-[20px] laptop:mt-[70px]',
    divItem: '',
    author: 'text-grey text-[13px] font-regular',
    titleItem: 'text-blue text-[16px] laptop:text-[20px] font-regular mt-[20px] line-clamp-1',
    text: 'text-black text-[14px] laptop:text-[16px] font-regular mt-[10px] laptop:mt-[15px] line-clamp-3'
}