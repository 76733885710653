export const test1 = 
    {
        title: 'Индекс жизненной удовлетворенности',
        descr: `Опросник, диагностирующий индекс жизненной удовлетворенности, отражает общее психологическое состояние человека, степень его психологического комфорта и социально-психологической адаптированности. Тест «Индекс удовлетворенности жизнью» может быть использован для дифференцированной оценки особенностей стиля жизни, потребностей, мотивов, установок, ценностных ориентаций человека с целью определения, какие из них положительно сказываются на его общем психологическом состоянии, а какие - отрицательно.
        Тест «Индекс жизненной удовлетворенности» (Neugarten А.О.), разработанный группой американских ученых, занимающихся социально-психологическими проблемами геронтопсихологии, был впервые опубликован в 1961 году и вскоре получил широкое распространение. Методика была переведена и адаптирована Н.В. Паниной в 1993 году. Опросник ИЖУ состоит из 20 вопросов, отражающих различные аспекты общего психологического состояния человека и его удовлетворенности жизнью.
        Индекс общей жизненной удовлетворённости определяется начислением баллов по ключу. Максимальный индекс жизненной удовлетворённости составляет 40 баллов. Средняя жизненная удовлетворённость – 25–30 баллов. Показатели менее 25 баллов считаются низкими.`,
        instruction: `Оцените каждое утверждение в соответствии со степенью вашего согласия. В столбике с номером вопроса поставьте любой знак напротив нужного ответа – «согласен», «не согласен» или «не знаю».`,
        questions: [
            {
                name: 'С возрастом многое мне кажется лучше, чем я ожидал раньше'
            }, {
                name: 'Жизнь принесла мне больше разочарований, чем большинству людей, которых я знаю'
            }, {
                name: "Сейчас самый мрачный период в моей жизни"
            }, {
                name: 'Моя жизнь могла бы быть счастливее, чем есть'
            }, {
                name: 'Сейчас я почти так же счастлив, как и в то время, когда был моложе'
            }, {
                name: 'Большинство дел, которыми мне приходится заниматься, скучные и неинтересные'
            }, {
                name: 'Сейчас я переживаю лучшие годы в моей жизни'
            }, {
                name: 'Я считаю, что в будущем меня ожидают интересные и приятные дела'
            }, {
                name: 'К своим делам и занятиям я испытываю такой же интерес, как и раньше'
            }, {
                name: 'С возрастом я все больше ощущаю какую-то усталость'
            }, {
                name: 'Ощущение возраста не беспокоит меня'
            }, {
                name: 'Когда я оглядываюсь на свою жизнь, я испытываю чувство удовлетворения'
            }, {
                name: 'Я не изменил бы свою прошлую жизнь, даже если бы имел такую возможность'
            }, {
                name: 'По сравнению с другими людьми моего возраста, я сделал массу глупостей в своей жизни'
            }, {
                name: 'Я выгляжу лучше, чем большинство других людей моего возраста'
            }, {
                name: 'У меня есть некоторые планы, которые я намереваюсь осуществить в ближайшее время'
            }, {
                name: 'Оглядываясь на прошлое, могу сказать, что я многое упустил в своей жизни'
            }, {
                name: 'Я слишком часто, по сравнению с другими людьми, нахожусь в подавленном настроении'
            }, {
                name: 'Я получил довольно много из того, что ожидал от жизни'
            }, {
                name: 'Что бы ни говорили, а с возрастом большинству людей становится хуже, а не лучше'
            }
        ],
        keys: [
            { yes: 0, no: 1, yesno: 1 },
            { yes: 0, no: 2, yesno: 1 },
            { yes: 0, no: 2, yesno: 1 },
            { yes: 0, no: 2, yesno: 1 },
            { yes: 2, no: 0, yesno: 1 },

            { yes: 0, no: 2, yesno: 1 },
            { yes: 2, no: 0, yesno: 1 },
            { yes: 2, no: 0, yesno: 1 },
            { yes: 2, no: 0, yesno: 1 },
            { yes: 0, no: 2, yesno: 1 },

            { yes: 2, no: 0, yesno: 1 },
            { yes: 2, no: 0, yesno: 1 },
            { yes: 2, no: 0, yesno: 1 },
            { yes: 0, no: 2, yesno: 1 },
            { yes: 2, no: 0, yesno: 1 },

            { yes: 2, no: 0, yesno: 1 },
            { yes: 0, no: 2, yesno: 1 },
            { yes: 0, no: 2, yesno: 1 },
            { yes: 2, no: 0, yesno: 1 },
            { yes: 0, no: 2, yesno: 1 },
        ],
        interpretation: `Тест  измеряет  наиболее  общее  психологическое  состояние  человека,   определяемое  его  личностными  особенностями,  системой  отношений   к   различным   сторонам своей  жизни.  Индекс  жизненной  удовлетворенности  — интегративный  показатель,   включающий  в качестве  основного  носителя эмоциональную  составляющую.  Для обладателей  высокого  значения  индекса характерны  низкий  уровень   эмоциональной   напряженности,  высокая эмоциональная  устойчивость,  низкий  уровень  тревожности,  психологический комфорт,  высокий  уровень  удовлетворенности  ситуацией  и  своей  ролью  в ней.
        Под категорией «жизненная удовлетворенность» понимается самое общее представление человека о психологическом комфорте, которое включает в себя: 
        • интерес к жизни как противоположность апатии; 
        • решительность, целеустремленность, последовательность в достижении жизненных целей; 
        • согласованность между поставленными и реально достигнутыми целями; 
        • положительная оценка собственных качеств и поступков; 
        • общий фон настроения. 
        Максимальный индекс жизненной удовлетворённости составляет 40 баллов. Средняя жизненная удовлетворённость – 25–30 баллов. Показатели менее 25 баллов считаются низкими.
        В качестве дополнительной информации о том, какие конкретные сферы жизни приносят удовлетворение или недовольство, можно посчитать количество баллов по шкалам (максимальное количество баллов по каждой шкале – 8).
        `,
        other: `1. Интерес к жизни. Суждения № 1, 6, 9, 11. Шкала отражает степень энтузиазма, увлечённого отношения к обычной повседневной жизни.
        2. Последовательность  в  достижении  целей. Суждения № 8, 13, 16, 17. Высокие показатели по данной шкале отражают такие особенности отношения к жизни, как решительность, стойкость, направленные на достижение целей. Низкая оценка по этой шкале отражает пассивное примирение с жизненными неудачами, покорное принятие всего, что приносит жизнь.
        3. Согласованность между поставленными и достигнутыми целями. Суждения № 2, 4, 5, 19. Высокие показатели отражают убеждённость человека в том, что он достиг или способен достичь тех целей, которые считает для себя важными.
        4. Положительная оценка себя и собственных поступков. Суждения № 12, 14, 15, 20. Сюда относится оценка человеком своих внешних и внутренних качеств. Высокий балл отражает высокую самооценку.
        5. Общий фон настроения. Суждения № 3, 7, 10, 18. Шкала показывает степень оптимизма, удовольствия от жизни.
        Результаты данного теста дают возможность достаточно быстро получить информацию об общем эмоциональном состоянии человека, о сферах жизни, которые, возможно, являются «проблемными» для него и принять шаги для коррекции последних.`
    };
export const test2 = 
    {
        title: 'Тест на тип личности по Майерс',
        descr: `О типологии личности Майерс-Бриггс написано либо много и непонятно, либо мало и ещё непонятнее. И, несмотря на то, что тест на её определение нередко критикуется, он остаётся важным ключом для понимания характеристик личности. Если говорить обобщённо – с его помощью определяется, как люди воспринимают мир и принимают решения. Многие западные компании требуют прохождения теста Майерс-Бриггс при трудоустройстве. Также, по данным авторов Википедии, около 70% американских выпускников проходят тестирование с целью узнать свои сильные стороны и выбрать будущую профессию.`,
        history: `Предыстория появления типологии восходит к работам Карла Юнга, который в опубликованной в 1921 г. книге «Психологические типы» предположил, что основных психологических функций, помогающих человеку воспринимать мир, четыре. Это – мышление, чувства, интуиция и ощущения. Этот труд был гораздо фундаментальнее, чем представления американки Кэтрин Бриггс, которая просто интересовалась различиями в характерах разных людей. Но, познакомившись с типологией Юнга, она, поддерживаемая своей дочерью Изабель Бриггс-Майерс, начала детально изучать данную проблематику и даже опубликовала пару научных статей. Типов она выделяла также четыре, и основывалась, по собственному же признанию, на работах Юнга. Но позже теорию значительно расширила дочь, придав ей очертания современной.
        Случилось это в годы Второй мировой войны. Именно тогда появился Myers-Briggs Type Indicator (MBTI – собственно типология, также часто используется термин «соционика»). Это не была «голая» теория – исследовательницы опирались на оригинальные испытания, ими же составленные. Цель изысканий была самой благородной: на основе тестирования определить индивидуальные личностные предпочтения в работе и подобрать женщинам, которые должны были заменить на производстве ушедших в армию мужчин, занятия, где они смогут по достоинству проявить свои таланты. Уже позже, в 50-60-е годы, о типологии положительно отзывались видные учёные, были проведены новые опыты, позволяющие уточнить методику. Но кроме последователей, MBTI имеет и ряд критиков, которые указывают на то, что типология Майерс-Бриггс почти дублирует исследования К. Юнга в теоретической части и не всегда обнаруживает свою валидность на практике.`,
        desriptors: `Суть системы психологического тестирования MBTI заключается в том, что, благодаря измерению уникальных комбинаций личностных факторов человека, можно предугадать его склонность к определённому виду деятельности, стиль его действий, характер решений и прочие особенности, позволяющие ему чувствовать себя комфортно и уверенно. Для чего и были придуманы 4 шкалы (дескриптора), по которым исследуется личность:
        ориентация сознания (интроверсия-экстраверсия),
        ориентация в ситуации (здравый смысл – интуиция)
        основа принятия решений (логос – пафос)
        способ подготовки решений (рациональность – иррациональность)
        Пройти онлайн-тест из 20 вопросов
        Тест Майерс-Бриггс, наряду с многими другими популярными в мировой практике тестами, включен в курс «Самопознание». Пройдя его, можно получить детальную характеристику своей личности, слабых и сильных сторон, склонностей, чтобы лучше разобраться в себе и использовать эти знания для саморазвития.
        Приведенный ниже тест позволит определить, к какому «полюсу» для каждой дихотомии более склонен человек. Тест содержит 20 вопросов: по 5 вопросов для каждого дескриптора. Именно нечетное количество вопросов для каждой шкалы дает возможность получить вашу склонность к тому или иному полюсу (четное количество дало бы возможность получить промежуточный результат: 50 на 50).
        Перед началом теста, важно понять следующие вещи:
        Никакие вопросы никакого, даже самого подробного, опросника не могут охватить все поведение человека. Данный тест позволяет лишь «очертить рамки» и указывает на уклон, а не на всеобъемлющее доминирование одних свойств над другими.
        Независимо от принадлежности к какому-либо типу, каждый человек использует в повседневной жизни оба полюса предпочтений, но в разной мере. К примеру, мы можем быть общительными с хорошими знакомыми, но чаще являться интровертами.
        При ответе на вопрос выбирайте тот вариант, который кажется наиболее предпочтительным и комфортным для вас в большинстве жизненных ситуаций. Если оба варианта не нравятся, то выбирайте наименее непривлекательный вариант.
        Пройдя тест, вы узнаете не только свой тип личности, но и получите небольшое объяснение результата. Не забудьте ответить на все вопросы, для этого удобнее всего идти по порядку.`,
        questions: [
            {
                name: 'В компании вы:', 
                first: {name: 'являетесь инициатором разговора', otvet: '(E)'}, 
                second: {name: 'ждете, когда к вам обратятся', otvet: '(I)'}
            },
            {
                name: 'Вы в большей степени:', 
                first: {name: 'практик', otvet: '(S)'}, 
                second: {name: 'теоретик', otvet: '(N)'}
            },
            {
                name: 'Принимая решения, вам легче иметь дело с:', 
                first: {name: 'правилами и стандартами', otvet: '(J)'}, 
                second: {name: 'собственными чувствами', otvet: '(P)'}
            },
            {
                name: 'Вы чувствуете себя лучше, когда:', 
                first: {name: 'уже сделали покупку', otvet: '(J)'}, 
                second: {name: 'имеете возможность сделать покупку', otvet: '(P)'}
            },
            {
                name: 'На вечеринках вы:', 
                first: {name: 'задерживаетесь допоздна, все более оживляетесь', otvet: '(E)'}, 
                second: {name: 'уходите рано, чувствуя усталость', otvet: '(I)'}
            },
            {
                name: 'Выполняя обычную работу, вам приятнее:', 
                first: {name: 'делать ее общепринятым способом', otvet: '(T)'}, 
                second: {name: 'изобрести собственный способ', otvet: '(F)'}
            },
            {
                name: 'Чаще вы человек:', 
                first: {name: 'хладнокровный и спокойный', otvet: '(S)'}, 
                second: {name: 'сердечный и участливый', otvet: '(N)'}
            },
            {
                name: 'В большинстве случаев следует:', 
                first: {name: 'управлять ходом событий', otvet: '(J)'}, 
                second: {name: 'полагаться на естественный ход событий', otvet: '(P)'}
            },
            {
                name: 'Когда Вы находитесь в обществе, вы обычно предпочитаете:', 
                first: {name: 'участвовать в общей беседе', otvet: '(E)'}, 
                second: {name: 'беседовать с каждым отдельно', otvet: '(I)'}
            },
            {
                name: 'Когда вы читаете в свое удовольствие, вам нравится, когда писатель:', 
                first: {name: 'четко говорит, что он имеет в виду', otvet: '(S)'}, 
                second: {name: 'излагает мысли в необычной, оригинальной форме', otvet: '(N)'}
            },
            {
                name: 'Вас больше привлекает:', 
                first: {name: 'последовательность и логичность рассуждений', otvet: '(T)'}, 
                second: {name: 'гармоничность человеческих отношений', otvet: '(F)'}
            },
            {
                name: 'Вы можете сказать, что вы в большей степени:', 
                first: {name: 'серьезный и волевой человек', otvet: '(S)'}, 
                second: {name: 'легко относящийся к жизни свободный человек', otvet: '(N)'}
            },
            {
                name: 'Вы обычно:', 
                first: {name: 'можете легко разговаривать практически с любым человеком в течение любого времени', otvet: '(E)'}, 
                second: {name: 'можете найти тему для разговора только с немногими людьми и только в определенных ситуациях', otvet: '(I)'}
            },
            {
                name: 'Вас скорее можно назвать:', 
                first: {name: 'человеком исполнительным', otvet: '(J)'}, 
                second: {name: 'выдумщиком', otvet: '(P)'}
            },
            {
                name: 'Какое слово из пары вам больше нравится:', 
                first: {name: 'убедительный', otvet: '(S)'}, 
                second: {name: 'трогательный', otvet: '(N)'}
            },
            {
                name: 'Какое слово из пары вам больше нравится:', 
                first: {name: 'методичный', otvet: '(J)'}, 
                second: {name: 'спонтанный', otvet: '(P)'}
            },
            {
                name: 'Какое слово из пары вам больше нравится:', 
                first: {name: 'общительный', otvet: '(E)'}, 
                second: {name: 'рассудительный', otvet: '(I)'}
            },
            {
                name: 'Какое слово из пары вам больше нравится:', 
                first: {name: 'понятный', otvet: '(T)'}, 
                second: {name: 'красивый', otvet: '(F)'}
            },
            {
                name: 'Какое слово из пары вам больше нравится:', 
                first: {name: 'справедливость', otvet: '(T)'}, 
                second: {name: 'сочувствие', otvet: '(F)'}
            },
            {
                name: 'Следование расписанию в делах:', 
                first: {name: 'привлекает вас', otvet: '(T)'}, 
                second: {name: 'сковывает вас', otvet: '(F)'}
            },
        ],
        result: `Вы получили результат, который является одним из 16 типов личности, которые образуются в результате пересечения описанных выше 4-х дескрипторов, содержащих по 2 элемента (четыре в квадрате – 16). Эти 16 типов присвоено условное нарицательное имя для каждого типа, чтобы было проще ориентироваться:
        Реалист, администратор, руководитель (ESTJ: экстраверт, сенсорик, логик, рационал). Очень работоспособный, социально-адаптированный тип, всегда чувствует необходимость доводить начатое дело до завершения. Планирует деятельность, практически относиться к окружающим вещам. Склонен проявлять любовь и заботу о близких, любит шумные веселья, компании. Добродушен, но резковат, может быть вспыльчивым и упертым.
        
        Видят мир таким, «каков он есть», переводят свои восприятия на объективный язык. Испытывают необходимость в том, чтобы навязывать свои оценки окружающим, устанавливая твердый порядок действий, который опирается на определенную программу. Управлять порядком где-либо – самое естественное занятие для них.
        11% мужчин
        6% женщин
        
        Командир, предприниматель (ENTJ: экстраверт, интуит, логик, рационал). Способен четко выделять собственные возможности и способности, легко вдохновляется и начинает новые дела, увлекается динамичными видами спорта, которые дарят экстремальные ощущения. Чувствует новые тенденции, идет на риск, полагаясь на интуицию. С уверенностью использует в работе новые технологии, глубоко анализирует себя и окружающий мир. Склонен к позитивному мышлению и близкому общению с людьми.
        Имеют потребность в контроле и необычные способности к лидерству. Они распахнуты настежь, навстречу бесчисленным возможностям и смыслам, которые переводятся на язык объективных мыслительных операций и приводят к упорядоченной и своевременной деятельности. Для них жизнь раскрывается в борьбе, в споре, в схлестывании с окружающими во имя познания.
        3% мужчин
        1% женщин
        
        Организатор, инспектор (ISTJ: интроверт, сенсорик, логик, рационал). Любит порядок и строгость, глубоко вникает в работу, анализируя информацию с разных сторон. Отличается некой педантичностью. Реально смотрит на вещи, берется за дело только если точно знает, что сможет его завершить. Вызывает доверие, но предпочитает короткие деловые контакты с другими людьми.
        Имеют чувство ответственности. Их поведение ориентировано на конечный результат. Объективная, конкретная, ближайшая информация у них немедленно передается «внутрь» и внимательно анализируется. Их склонность ко всему, что находится «здесь и теперь», не позволяет им ничего принимать на веру или допускать что-либо. Все, что они видят, есть для них объективная и осязаемая реальность, в которой они немедленно наводят определенный порядок.
        16% мужчин
        7% женщин
        
        Увещеватель, наставник (ENFJ: экстраверт, интуит, этик, рационал). Очень эмоциональная личность, склонен к эмпатии и проявлению широкого спектра эмоций. Обладает выразительной мимикой и красноречием. Способен предчувствовать разные события и готовиться к ним заранее. Улавливает несоответствия в словах и эмоциях других людей. Часто не уверен в любви партнера, склонен к ревности.
        Их внимание сосредоточено на тех, кто их окружает, и они прекрасно понимают, кто в чем нуждается. Их богатое воображение и вдохновляющая натура выражают себя весьма конкретно и организованно, что позволяет им осуществлять свои фантазии. Имеют способность к интуитивному постижению ситуации при внимательном и заботливом отношении к позиции всех участников.
        
        3% женщин
        2% мужчин
        
        Учитель, воспитатель, энтузиаст (ESFJ: экстраверт, сенсорик, этик, рационал). Способен влиять на людей с помощью эмоционального давления, при этом хорошо ладит с ними, может поднять настроение, склонен жертвовать собственными интересами ради другого человека и проявлять любовь и заботу о близких. В работе всего добивается самостоятельно, любит, когда другие люди подчеркивают его достоинства.
        Предрасположенность к решению обеспечивает их всем необходимым для того, чтобы было легко устанавливать контакты с кем бы то ни было. Их субъективная чувствительность привносит гармонию в любую ситуацию, одновременно пытаясь упорядочить ее, направить ход событий по определенному руслу; и делают они это мягко, но настойчиво.
        17% женщин
        8% мужчин
        
        Аналитик, провидец, вдохновитель (INTJ: интроверт, интуит, логик, рационал). Умеет отличать главное от второстепенного, не любит пустых разговоров, склонен к четкому практичному мышлению. В работе этот тип любит использовать необычные идеи, при этом демонстрируя свою независимость. Использует интуицию там, где не знает точных ответов. Не любит шумных компаний, ощущает трудности в налаживании отношений с другими людьми.
        Их богатый внутренний мир хранит в себе безграничные возможности, которые реализуются в виде стремления все улучшить и усовершенствовать. Слова, планы, проекты, идеи, люди – все им хочется сделать лучше, чем есть в действительности. По их мнению, даже самое лучшее можно сделать еще более лучшим. Стремятся к законченности.
        3% мужчин
        1% женщин
        
        Вдохновитель, консультант, советчик, гуманист (INFJ: интроверт, интуит, этик, рационал). Тонко чувствует характер отношений между людьми, придает большое значение доверию, не прощает измен. Умеет выявлять скрытые способности других, наделен талантом воспитателя. Увлекается самообразованием, люди часто обращаются к нему за советом. Очень раним, тяжело переносить агрессию и недостаток любви.
        Их движущая сила – интуиция, направленная внутрь, – поставляет им неиссякаемый поток идей и возможностей. И чем большую роль играет в INFJ интроверсия, тем более текучей, податливой и открытой им кажется жизнь. Но внешний мир изменяет направление этого потока вдохновенной творческой активности: они чувствуют призвание служить людям и осуществляют это весьма организованно и упорядоченно.
        2% женщин
        1% мужчин
        
        Исполнитель, хранитель, защитник (ISFJ: интроверт, сенсорик, этик, рационал). Распознает наигранность и фальшь в отношениях, делит людей на своих – чужих, руководя психологической дистанцией. Отстаивает свои взгляды и принципы. Умеет постоять за себя и своих близких, не переносит морального превосходства других людей. Умеет глубоко анализировать себя и других.
        Аккуратные, добродушные, приверженные порядку и в высшей степени исполнительные и заботливые, они черпают силы в самих себе и во всем том, что видят, слышат, чувствуют, осязают и пробуют. Силы эти направлены на служение окружающим, при этом вся деятельность четко рассчитана и распланирована. Видят свое предназначение в том, чтобы помогать другим и делать их счастливее.
        19% женщин
        8% мужчин
        
        Изобретатель, искатель, мечтатель (ENTP: экстраверт, интуит, логик, иррационал). Отличается широким кругом интересов, умеет приспосабливаться к новым условиям и легко переходит к новым методам работы. Является генератором идей, не любит традиции и рутину. Умеет объяснять сложные идеи, будучи в них первопроходцем. В большей мере склонен к синтезу в мышлении, создает новую идею из готовых составляющих.
        Их изобретательность постоянно ищет применения в самых различных профессиональных и непрофессиональных сферах. Она берет начало в предрасположенности к интуиции, открывающей перед ними безграничные возможности, в сочетании с их объективной способностью к принятию решений, направленной на окружающий мир. Это приводит к тому, что все преобразуется в идеи и схемы. Их больше привлекают новые идеи, они находятся в непрерывном напряжении деятельности.
        4% мужчин
        2% женщин
        
        Непоседа, маршал, реалист (ESTP: экстраверт, сенсорик, логик, иррационал). Склонен применять физическую силу с целью достигнуть победы любой ценой. Препятствия только усиливают его желание выиграть. Любит руководить, не вынося подчинения. Анализируя ситуацию, любит составлять конкретный план действий, четко ему следует.
        Их внимание направлено на людей и мир объектов. Сбор информации происходит при помощи пяти органов чувств. В дальнейшем информация оценивается и анализируется объективно, но при этом они остаются подвижными и доступными для новых альтернатив. Способны дать быстрый, точный, практически ценный, объективный и ясно выраженный ответ в любой ситуации.
        6% мужчин
        3% женщин
        
        Медиатор, чемпион (ENFP: экстраверт, интуит, этик, иррационал). Способен тонко чувствовать других людей, обладает развитой фантазией. Любит творческую работу, не переносит однообразие и рутину. Общителен, любит давать дельные советы в сфере взаимодействия с людьми.
        Сочетание предрасположенности к экстраверсии, интуиции, чувствительности и восприимчивости дает им уникальную способность к эффективному сотрудничеству, участию в разнообразных предприятиях и умение противостоять неожиданностям. Воспринимают жизнь в разнообразии ее возможностей и истолковывают эти возможности с точки зрения их влияния на людей. Все это сопровождается активным взаимодействием с окружающим миром, а их любознательная позиция позволяет им ориентироваться в постоянной смене ситуаций.
        10% женщин
        6% мужчин
        
        Аниматор, политик, деятель (ESFP: экстраверт, сенсорик, этик, иррационал). Способен видеть возможности других, используя эти знания с целью манипуляции. Руководит более слабыми, четко определяя их слабые места. Любит держать дистанцию, в общении скорее руководствуется собственными интересами. В глазах других пытается выглядеть незаурядной оригинальной личностью, но часто таким не является.
        Для них достаточно надежно только то, что имеет отношение к «здесь и теперь». Живут, в основном, ради настоящего момента. Больше начинают, чем заканчивают. Сосредоточенность на немедленных результатах делает их нетерпимыми к разного рода процедурам, шаблонам и прочим препятствиям. Стараются использовать каждую минуту для того, чтобы вступить в полезную беседу. Стремятся к гармонии человеческих взаимоотношений.
        10% женщин
        7% мужчин
        
        Архитектор, критик, аналитик (INTP: интроверт, интуит, логик, иррационал). Этот тип – эрудит с философским складом ума. Осторожен, принимает решение только с уверенностью в его правильности, анализируя прошлое в его связи с будущим. Не любит бурных проявлений эмоций, ценит уют и комфорт.
        Задумчивость побуждает их исследовать все, что поставляет им интуиция. Их стремление к объективности требует тщательного анализа всей информации, а их непредвзятость и подвижность обеспечивает восприимчивость к неожиданным и новым фактам, какими бы они ни были. Такая комбинация предрасположений приводит к постановке парадоксальной цели: они вечно пытаются собрать в единое целое постоянно увеличивающееся количество данных. Однако постоянный приток новых сообщений и фактов этому препятствует. И в результате все мысли, идеи и планы, как бы окончательно они ни были сформулированы, в последний момент неизбежно меняются, лишь только «новые данные» о внешних или внутренних влияниях становятся доступными для исследователя. Поэтому они находятся в постоянном напряжении.
        5% мужчин
        2% женщин
        
        Мастер, умелец (ISTP: интроверт, сенсорик, логик, иррационал). Ощущения являются для него главным источником познания мира. Проявляет эмпатию, тонко чувствует и любит других людей, отвергает искусственность и фальшь. Отличается техническим складом ума, любит работать руками, при этом всегда укладываясь в нужные сроки.
        Сосредоточенные на себе, склонные к объективности в принятии решений, они более расположены выжидать, анализировать ситуацию, нежели сразу предлагать свое решение и бросаться в бой. Их взгляд на мир предельно конкретный, но в сочетании со свойственной им открытостью это может приводить к более непредсказуемым поступкам, чем этого можно было бы ожидать.
        
        9% мужчин
        2% женщин
        
        Созерцатель, лирик, целитель (INFP: интроверт, интуит, этик, иррационал). Мечтательная и лирическая личность, умеет интуитивно прогнозировать события, хорошо разбирается в людях, любит и «чувствует» их. Обладает хорошим чувством юмора, вызывает расположение других людей. Большое значение этот тип придает внешнему виду. Не умеет экономить, а во время работы любит долго отдыхать.
        Стремление к самопознанию, самоопределению и согласию с самим собой. Благодаря качествам интровертов, их размышления направлены на самих себя, свойства интуитивистов обеспечивают их чувством бесконечных возможностей, заключенных в человеке. Чувствительность заставляет задуматься о том, как использовать эти возможности к своей собственной пользе и на благо окружающих, а качества воспринимающего позволяют сохранять восприимчивость к постоянному потоку новой информации.
        5% женщин
        4% мужчин
        
        Придумщик, композитор (ISFP: интроверт, сенсорик, этик, иррационал). Умеет наслаждаться обычной жизнью, спокойно перенося однообразие и рутину. Легко уживается с людьми, уважая их личное пространство, при этом требуя от них такого же отношения. Любит шутить, развлекать, избегает конфликтных ситуаций. Часто является помощником, любит ощущать себя нужным и значимым в глазах других людей.
        Нежные и заботливые, открытые и подвижные, задумчивые и сдержанные, практичные и приземленные. Это люди, которым не хочется руководить и воздействовать на других, которые не стремятся переделать мир и даже до конца понять его, а принимают таким, каков он есть.
        10% женщин
        8% мужчин`
    };
   	 	

export const test3 =
    {   
        title: 'Опросник Юнга',
        instruction: `На каждый вопрос имеется два варианта ответа, необходимо выбрать ответ, который подходит вам больше.`,
        questions: [
            {
                name: 'Что вы предпочитаете?',
                a: 'немного близких друзей;',
                b: 'большую товарищескую компанию.'
            },
            {
                name: 'Какие книги вы предпочитаете читать?',
                a: 'с занимательным сюжетом;',
                b: 'с описанием переживаний героев;'
            }, 
            {
                name: 'Что вы скорее можете допустить в разговоре?',
                a: 'опоздание;',
                b: 'ошибки'
            },
            {
                name: "Если вы совершаете дурной поступок, то:",
                a: 'остро переживаете;',
                b: 'острых переживаний нет.'
            },
            {
                name: "Как вы сходитесь с людьми?",
                a: 'быстро, легко',
                b: 'медленно, осторожно'
            }, 
            {
                name: 'Считаете ли вы себя обидчивым?',
                a: 'да;',
                b: 'нет.'
            },
            {
                name: ' Склонны ли вы хохотать, смеяться от души?',
                a: 'да',
                b: 'нет'
            },
            {
                name: "Считаете ли вы себя?",
                a: 'молчаливым;',
                b: 'разговорчивым'
            },
            {
                name: 'Откровенны ли вы или скрытны?',
                a: 'откровенен',
                b: 'скрытен'
            },
            {
                name: "Любите ли вы заниматься анализом своих переживаний?",
                a: 'да',
                b: 'нет.'
            },
            {
                name: 'Находясь в обществе, вы предпочитаете:',
                a: 'говорить;',
                b: "слушать."
            },
            {
                name: "Часто ли вы переживаете недовольство собой?",
                a: 'да',
                b: 'нет'
            },
            {
                name: "Любите ли вы что-нибудь организовывать?",
                a: 'да',
                b: 'нет'
            },
            {
                name: 'Хотелось бы вам вести личный дневник?',
                a: 'да',
                b: 'нет'
            },
            {
                name: "Быстро ли вы переходите от решения к исполнению?",
                a: 'да',
                b: 'нет'
            },
            {
                name: 'Легко ли вы меняете ваше настроение?',
                a: 'да',
                b: 'нет'
            },
            {
                name: "Любите ли вы убеждать других, навязывать свои взгляды?",
                a: 'да',
                b: 'нет'
            },
            {
                name: "Ваши движения:",
                a: 'быстры;',
                b: 'замедленны.'
            },
            {
                name: 'Вы сильно беспокоитесь о возможных неприятностях:',
                a: 'часто',
                b: 'редко'
            },
            {
                name: 'В затруднительных случаях:',
                a: 'спешите обратиться за помощью к другим',
                b: 'не любите обращаться'
            }
        ],
        keys: [
            { key: 'b' },
            { key: 'a' },
            { key: 'b' },
            { key: 'b' },
            { key: 'a' },

            { key: 'b' },
            { key: 'a' },
            { key: 'b' },
            { key: 'a' },
            { key: 'b' },

            { key: 'a' },
            { key: 'b' },
            { key: 'a' },
            { key: 'b' },
            { key: 'a' },

            { key: 'a' },
            { key: 'a' },
            { key: 'a' },
            { key: 'b' },
            { key: 'a' },
        ],
        response: `Баллы: 0-35 баллов: интроверт — человек, который заинтересован в том, чтобы найти интерес к себе, к своему внутреннему миру.
        36-65 баллов: амбиверт — это человек, которому свойственны черты как интроверта, так и экстраверта. Они имеют особенности обоих этих типов характера, и могут даже приспосабливаться в зависимости от ситуации.66-100 баллов: экстраверт — человек, который заинтересован в коммуникации с внешним миром.`
    }
