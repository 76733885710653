import * as React from 'react';
import { Avatar } from '../../components/Avatar';
import { getPsychType } from '../../configs/functions';
import { Modal } from '../../components/Modal';
import { ModalRecordCancel } from '../../components/modals/ModalRecordCancel';
import { ModalRecordTransfer } from '../../components/modals/ModalRecordTransfer';
import { useTranslation } from 'react-i18next';

export const ViewRecord = () => {
    const [data, setData] = React.useState({
        window_id: 0,
        psych_id: 0,
        user_id: 0,
        user_note: '',
        email: '',
        phone: '',
        anonymously: false,
        session_type: '',
        group: 'alone',
        price: 0,
        meet_url: '',
        id: 0,
        window: {
            id: 0,
            working_date_id: 0,
            from: '09:00',
            to: '10:00',
            working_date: {
                id: 0,
                user_id: 0,
                date: '2019-09-04',
                active: false
            }
        },
        psych: {
            id: 0,
            first_name: 'Надежда',
            last_name: 'Иванова',
            middle_name: 'Алексеевна',
            role: 'psych',
            sex: 'MALE',
            phone: null,
            birthdate: '1984-06-08',
            city_id: 0,
            email: '',
            avatar_url: '',
            vk_url: null,
            telegram_url: null,
            video_presentation_url: null,
            active: false,
            price: 0,
            psych_type: 'staff',
            is_favorite: false
        }
    });
    const refRepeat = React.useRef(false);

    const [modalCancel, setModalCancel] = React.useState(false);
    const [modalTransfer, setModalTransfer] = React.useState(false);
    const {t} = useTranslation();

    React.useEffect(() => {
        if(refRepeat.current === false) {

        }

        return () => refRepeat.current = true;
    }, []);


    return(
        <div className={styles.main}>
            <title>{t('pageRecord.title')}</title>
            <p className='text-[16px] laptop:text-[39px] text-center laptop:text-start mt-[20px] laptop:mt-[30px] font-medium text-black laptop:text-blue'>{t('pageRecord.title').split(' ')[0]} <label className='text-black'>{t('pageRecord.title').substring(t('pageRecord.title').indexOf(' '))}</label></p>
            <div className='flex flex-col laptop:flex-row mt-[45px] laptop:mt-[90px] border-blue-border border-[1px] py-[33px] laptop:py-0 rounded-[19px] laptop:border-white'>
                <div className='border-white laptop:border-blue-border border-[1px] laptop:py-[33px] px-[30px] rounded-[19px] laptop:shadow-[0_0px_8px_-0px_rgba(158,158,158,0.08)]'>
                    <p className=''>{getPsychType(data.psych.psych_type)}</p>
                    <div className='flex flex-row mt-[40px]'>
                        <div>
                            <Avatar 
                                source={require('../../assets/icons/temp.png')} 
                                style='laptop:w-[179px] laptop:h-[179px] w-[82px] h-[82px] mr-[20px]' 
                                styleImage='laptop:w-[167.98px] laptop:h-[167.98px] w-[74px] h-[74px]'
                                online={false}
                            />
                        </div>
                        <div>
                            <p className='text-[16px] laptop:text-[20px] font-medium text-black'>Иванова Надежда Алексеевна</p>
                            <p className='text-12 laptop:hidden flex text-grey mt-[3px]'>{getPsychType(data.psych.psych_type)}</p>
                            <div>
                                <p className='text-16 font-regular text-blue mt-[15px] hidden laptop:flex'>nadja@mail.ru</p>
                                <p className='text-blue text-16 font-regular mt-[12px] hidden laptop:flex'>+1(234)567 89 10</p>
                                <div className='flex-row hidden laptop:flex'>
                                    <button className='mr-[10px]'>tg</button>
                                    <button>vk</button>
                                </div>
                                <p className='text-12 mt-[10px] flex laptop:hidden font-regular text-grey'>Сеанс: <label className='text-blue'>Очный</label></p>
                              
                            </div>    
                        </div>    
                    </div>
                    <div className='flex laptop:hidden flex-col mt-[20px]'>
                        <p className='text-12 font-regular text-blue'>nadja@mail.ru</p>
                        <p className='text-12 font-regular text-blue mt-[15px]'>+1(234) 567 89 10</p>
                        <p className='mt-[12px] text-grey font-regular text-12'>Статус записи: <label className='text-green'>Активный</label></p>
                    </div>
                    <p className='mt-[50px] text-16 font-regular hidden laptop:flex'>Действия</p>
                    <div className='flex flex-col items-center justify-center'>
                        <button 
                            className='bg-blue text-white font-medium text-16 laptop:text-14 h-[51px] rounded-[19px] mt-[39px] w-full laptop:w-[257px]'
                        >Подключиться к сеансу</button>
                        <p className='text-center text-[10px] laptop:text-[12px] text-grey mt-[10px]'>Действие активно за 10 минут до начала сеанса</p>
                        <button 
                            className='h-[51px] rounded-[19px] mt-[15px] text-blue bg-blue-border font-medium text-[16px] laptop:text-14 w-full laptop:w-[257px]'
                            onClick={() => { setModalTransfer(true); }}
                        >Перенести сеанс</button>
                        <p className='text-center text-[10px] laptop:text-[12px] font-regular text-grey mt-[10px]'>Не позднее 48 часов до сеанса</p>
                        <button
                            className='h-[51px] rounded-[19px] border-blue border-[1px] text-blue font-medium text-[16px] laptop:text-[14px] mt-[15px] w-full laptop:w-[257px]'
                            onClick={() => {setModalCancel(true);}}
                        >Отменить сеанс</button>
                    </div>
                </div>
                <div className='border-white laptop:border-blue-border border-[1px] h-fit laptop:py-[33px] px-[30px] rounded-[19px] laptop:ml-[33px]'>
                    <p className='text-[14px] laptop:text-[16px] font-regular mt-[20px] laptop:mt-0'>Информация о сеансе</p>
                    <div className='grid grid-cols-2 mt-[15px] laptop:mt-[35px]'>
                        <div>
                            <p className='text-grey text-12 laptop:text-16 font-regular'>Способ оплаты:</p>
                            <p className='text-grey text-12 laptop:text-16 font-regular mt-[15px] laptop:mt-[20px]'>Стоимость:</p>
                            <p className='text-grey text-12 laptop:text-16 font-regular mt-[15px] laptop:mt-[20px]'>Тип записи:</p>
                            <p className='text-grey text-12 laptop:text-16 font-regular mt-[15px] laptop:mt-[20px]'>Вид сеанса:</p>
                            <p className='text-grey text-12 laptop:text-16 font-regular mt-[15px] laptop:mt-[20px]'>Дата и время сеанса:</p>
                        </div>
                        <div>
                            <p className='text-12 laptop:text-16'>Мобильный банк</p>
                            <p className='text-12 laptop:text-16 mt-[15px] laptop:mt-[20px]'>2 000 P</p>
                            <p className='text-12 laptop:text-16 mt-[15px] laptop:mt-[20px]'>Я один</p>
                            <p className='text-12 laptop:text-16 mt-[15px] laptop:mt-[20px]'>Онлайн</p>
                            <div className='mt-[15px] laptop:mt-[20px] hidden laptop:flex'>
                                <p>Ср, 17 июля</p>
                                <p>10:00, 11:00</p>
                                <p>2 часа</p>
                            </div>
                        </div>
                        <div className='mt-[15px] laptop:mt-[20px] flex flex-col laptop:hidden'>
                            <p className='text-blue text-12 font-regular'>Ср, 17 июля</p>
                            <p className='text-blue text-12 font-regular mt-[12px]'>10:00, 11:00</p>
                            <p className='text-blue text-12 font-regular mt-[12px]'>2 часа</p>
                        </div>
                    </div>
                    <button 
                        className='h-[51px] w-full bg-blue rounded-[19px] flex flex-col items-center justify-center laptop:hidden text-white text-16 font-medium mt-[30px]'
                        onClick={() => {
                            setModalTransfer(true);
                        }}
                    >Перенести</button>
                    <button 
                        className='h-[51px] w-full text-blue border-blue border-[1px] rounded-[19px] mt-[10px] font-medium flex flex-col laptop:hidden items-center justify-center'
                        onClick={() => {
                            setModalCancel(true);
                        }}
                    >Отказаться</button>    
                </div>
                
            </div>
            <Modal 
                visible={modalCancel}
                children={
                    <ModalRecordCancel 
                        close={() => { setModalCancel(false); }}
                        onSelect={() => {
                            setModalCancel(false);
                        }}
                        data={{id: 0}} // Надо указать данные
                    />
                }
                onClose={() => { setModalCancel(false); }}
            />
            <Modal 
                visible={modalTransfer}
                children={
                    <ModalRecordTransfer 
                        close={() => { setModalTransfer(false); }}
                        onSelect={() => {
                            setModalTransfer(false);
                        }}
                        successFunction={() => {
                            setModalTransfer(false);
                            // добавить обновление страницы
                        }}
                    />
                }
                onClose={() => {setModalTransfer(false); }}
            />
        </div>
    );
};

const styles = {
    main: 'container mx-auto',

}