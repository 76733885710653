import * as React from 'react';

export const CustomPicker = (props) => {
    const {
        text, value, onChange
    } = props;

    return(
        <div 
            className={styles.main}
            onClick={() => { onChange(!value); }}
        >
            <div className={styles.box}>
            {
                value? 
                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.9194 0.962417C13.0047 0.875375 13.1065 0.806227 13.2188 0.759021C13.3312 0.711815 13.4519 0.6875 13.5737 0.6875C13.6956 0.6875 13.8163 0.711815 13.9286 0.759021C14.041 0.806227 14.1428 0.875375 14.2281 0.962417C14.5856 1.32367 14.5906 1.90742 14.2406 2.27492L6.84936 11.0124C6.76545 11.1046 6.66361 11.1786 6.55009 11.2301C6.43656 11.2815 6.31374 11.3093 6.18912 11.3116C6.06451 11.3139 5.94073 11.2908 5.82535 11.2437C5.70998 11.1965 5.60543 11.1264 5.51811 11.0374L1.02061 6.47992C0.847161 6.30302 0.75 6.06516 0.75 5.81742C0.75 5.56967 0.847161 5.33181 1.02061 5.15492C1.10592 5.06787 1.20774 4.99873 1.3201 4.95152C1.43246 4.90431 1.55311 4.88 1.67499 4.88C1.79687 4.88 1.91752 4.90431 2.02988 4.95152C2.14224 4.99873 2.24406 5.06787 2.32936 5.15492L6.14436 9.02117L12.8944 0.989918C12.9021 0.980257 12.9105 0.971074 12.9194 0.962417Z" fill="#1D1D1D"/>
                    </svg>:
                    <></>
            }
            </div>
            {
                text()
            }
        </div>
    );
};

const styles = {
    main: "flex flex-row items-start justify-start mt-[10px]",
    box: 'w-[20px] h-[20px] flex flex-row items-center justify-center rounded-[3px] border-[#234082] border-[1px] mr-[10px]'
}