import { createSlice } from '@reduxjs/toolkit';

export const favoriteSlice = createSlice({
    name: 'favorite',
    initialState: {
        favorite: []
    },
    reducers: {
        setFavoriteRedux: (state, action) => {
            state.favorite = action.payload;
        },
        addItemToFavorite: (state, action) => {
            if(!state.favorite.map((item, index) => item.id).includes(action.payload.id)) {
                state.favorite = state.favorite.concat([action.payload]);
            };
        },
        removeItemToFavorite: (state, action) => {
            if(state.favorite.map((item, index) => item.id).includes(action.payload)) {
                state.favorite = state.favorite.filter((item, index) => item.id !== action.payload);
            };
        }
    }
});

export const { setFavoriteRedux, addItemToFavorite, removeItemToFavorite } = favoriteSlice.actions;
export default favoriteSlice.reducer;