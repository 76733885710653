import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HamburgerModal } from './HamburgerModal';
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux';
import i18n from '../localization';

export const Navigation = ({ lang, setLang }) => {
    const isSignedIn = useSelector(state => state.auth.session.signedIn);

    const {t} = useTranslation()

    const navigate = useNavigate();

    return (
        <div className="flex flex-col desktop:h-[140px] laptop:h-[130px] tablet:h-[120px] mobile:h-[100px] justify-end mb-[10px] mobile:mt-[10px]">
            <div className='flex flex-col container mx-auto'>
                <div className='flex justify-between items-center '>
                    <Link to='/'  className=''>
                        <svg className='mobile:h-[40px] mobile:w-auto laptop:h-[55px] laptop:w-[274px]' viewBox="0 0 274 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_2924_1968)">
                            <path d="M66.7109 12.1258H70.3344C71.4918 12.1258 72.3977 12.4076 73.0519 12.9712C73.7061 13.5349 74.0332 14.4156 74.0332 15.6133C74.0332 16.2172 73.9276 16.7406 73.7162 17.1835C73.5149 17.6263 73.2331 17.9987 72.8707 18.3007C72.5185 18.5926 72.0957 18.809 71.6025 18.9499C71.1194 19.0908 70.596 19.1612 70.0324 19.1612H67.6923V22.7997H66.7109V12.1258ZM70.0022 18.2554C70.4551 18.2554 70.8678 18.2051 71.2402 18.1044C71.6126 18.0038 71.9297 17.8427 72.1913 17.6213C72.453 17.3999 72.6543 17.118 72.7953 16.7758C72.9462 16.4336 73.0217 16.026 73.0217 15.5529C73.0217 14.6471 72.7751 14.0029 72.2819 13.6204C71.7887 13.2279 71.1144 13.0316 70.2589 13.0316H67.6923V18.2554H70.0022Z" fill="#234082"/>
                            <path d="M76.1617 12.1258H83.182V13.0316H77.143V17.0476H82.5781V17.9534H77.143V21.8939H83.182V22.7997H76.1617V12.1258Z" fill="#234082"/>
                            <path d="M89.6793 12.8958C89.0049 12.9058 88.4211 13.0316 87.9279 13.2732C87.4348 13.5047 87.0322 13.8318 86.7201 14.2545C86.4081 14.6672 86.1766 15.1503 86.0257 15.7039C85.8747 16.2474 85.7992 16.8261 85.7992 17.4401C85.7992 18.0641 85.8747 18.653 86.0257 19.2065C86.1766 19.7601 86.4081 20.2483 86.7201 20.671C87.0422 21.0837 87.4448 21.4158 87.9279 21.6674C88.4211 21.909 89.0099 22.0298 89.6944 22.0298C90.0064 22.0298 90.3234 21.9996 90.6455 21.9392C90.9676 21.8687 91.2695 21.763 91.5514 21.6221C91.8332 21.4712 92.0848 21.285 92.3062 21.0635C92.5277 20.8421 92.6988 20.5754 92.8196 20.2634H93.9217C93.7707 20.7364 93.5493 21.144 93.2574 21.4863C92.9756 21.8184 92.6434 22.0902 92.2609 22.3015C91.8885 22.5129 91.4759 22.6689 91.0229 22.7696C90.5801 22.8702 90.1272 22.9205 89.6642 22.9205C88.8288 22.9205 88.1041 22.7846 87.4901 22.5129C86.8762 22.2311 86.3679 21.8486 85.9653 21.3655C85.5727 20.8824 85.2758 20.3086 85.0745 19.6444C84.8833 18.9801 84.7877 18.2554 84.7877 17.4703C84.7877 16.6953 84.8883 15.9757 85.0896 15.3114C85.2909 14.637 85.5929 14.0583 85.9955 13.5751C86.4081 13.082 86.9214 12.6995 87.5354 12.4277C88.1594 12.1459 88.8791 12.005 89.6944 12.005C90.1574 12.005 90.6002 12.0553 91.0229 12.156C91.4557 12.2566 91.8483 12.4177 92.2006 12.6391C92.5629 12.8505 92.8699 13.1222 93.1215 13.4544C93.3832 13.7865 93.5845 14.1891 93.7254 14.6622H92.729C92.6082 14.3501 92.4371 14.0834 92.2157 13.862C92.0043 13.6406 91.7627 13.4594 91.491 13.3185C91.2192 13.1675 90.9273 13.0618 90.6153 13.0014C90.3033 12.931 89.9913 12.8958 89.6793 12.8958Z" fill="#234082"/>
                            <path d="M104.398 22.7997H103.417V13.0316H97.0912V22.7997H96.1099V12.1258H104.398V22.7997Z" fill="#234082"/>
                            <path d="M107.484 21.8486C107.837 21.8385 108.139 21.8033 108.39 21.7429C108.652 21.6825 108.893 21.5819 109.115 21.441C109.326 21.3001 109.512 21.1239 109.673 20.9126C109.845 20.6911 110.016 20.4294 110.187 20.1275L106.095 12.1258H107.303L110.776 19.1008L113.599 12.1258H114.731C114.429 12.7901 114.142 13.4544 113.871 14.1187C113.599 14.7829 113.327 15.4523 113.055 16.1266C112.784 16.7909 112.507 17.4552 112.225 18.1195C111.943 18.7838 111.636 19.4431 111.304 20.0973C111.103 20.4898 110.886 20.8673 110.655 21.2296C110.423 21.5919 110.126 21.909 109.764 22.1807C109.432 22.4324 109.075 22.6035 108.692 22.6941C108.31 22.7746 107.907 22.8148 107.484 22.8148V21.8486Z" fill="#234082"/>
                            <path d="M116.147 12.1258H123.258V13.0316H117.128V16.655H119.83C120.354 16.655 120.852 16.6953 121.325 16.7758C121.798 16.8463 122.211 16.9922 122.563 17.2137C122.925 17.425 123.212 17.7219 123.424 18.1044C123.635 18.4869 123.741 18.9851 123.741 19.5991C123.741 20.213 123.635 20.7263 123.424 21.139C123.212 21.5416 122.925 21.8687 122.563 22.1204C122.201 22.3619 121.778 22.5381 121.295 22.6488C120.822 22.7494 120.313 22.7997 119.77 22.7997H116.147V12.1258ZM117.128 21.8939H119.589C119.991 21.8939 120.384 21.8687 120.766 21.8184C121.149 21.758 121.486 21.6523 121.778 21.5014C122.07 21.3403 122.301 21.1139 122.472 20.822C122.654 20.5301 122.744 20.1426 122.744 19.6595C122.744 19.1763 122.654 18.7989 122.472 18.5271C122.301 18.2453 122.07 18.034 121.778 17.893C121.486 17.7521 121.154 17.6615 120.782 17.6213C120.419 17.581 120.047 17.5609 119.664 17.5609H117.128V21.8939Z" fill="#234082"/>
                            <path d="M132.803 22.7997V13.0316H127.821C127.811 13.6053 127.801 14.179 127.791 14.7528L127.761 15.8851C127.761 16.4588 127.751 17.0325 127.731 17.6062C127.71 18.1497 127.685 18.7184 127.655 19.3122C127.625 19.896 127.539 20.4496 127.398 20.9729C127.227 21.6272 126.94 22.1053 126.538 22.4072C126.135 22.7092 125.602 22.8702 124.937 22.8903V21.8788C125.239 21.8788 125.496 21.8083 125.707 21.6674C125.919 21.5165 126.09 21.3202 126.221 21.0786C126.331 20.8673 126.417 20.5955 126.477 20.2634C126.548 19.9312 126.598 19.584 126.628 19.2216C126.659 18.8593 126.679 18.507 126.689 18.1648C126.699 17.8125 126.709 17.5156 126.719 17.274C126.739 16.4185 126.754 15.563 126.764 14.7075C126.774 13.8519 126.789 12.9914 126.81 12.1258H133.785V22.7997H132.803Z" fill="#234082"/>
                            <path d="M136.567 22.7997V12.1258H137.548V21.5014L143.678 12.1258H144.931V22.7997H143.949V13.4997L137.82 22.7997H136.567Z" fill="#234082"/>
                            <path d="M147.713 22.7997V12.1258H148.694V17.1835H150.566L154.144 12.1258H155.307L151.487 17.4401L155.337 22.7997H154.159L150.732 18.0893H148.694V22.7997H147.713Z" fill="#234082"/>
                            <path d="M157.831 20.4294L156.956 22.7997H155.854L159.839 12.1258H161.213L165.184 22.7997H164.082L163.206 20.4294H157.831ZM158.164 19.5236H162.889L160.519 13.0467L158.164 19.5236Z" fill="#234082"/>
                            <path d="M167.787 17.108H174.068V12.1258H175.049V22.7997H174.068V18.0138H167.787V22.7997H166.806V12.1258H167.787V17.108Z" fill="#234082"/>
                            <path d="M182.137 12.8958C181.463 12.9058 180.879 13.0316 180.386 13.2732C179.893 13.5047 179.49 13.8318 179.178 14.2545C178.866 14.6672 178.634 15.1503 178.483 15.7039C178.332 16.2474 178.257 16.8261 178.257 17.4401C178.257 18.0641 178.332 18.653 178.483 19.2065C178.634 19.7601 178.866 20.2483 179.178 20.671C179.5 21.0837 179.903 21.4158 180.386 21.6674C180.879 21.909 181.468 22.0298 182.152 22.0298C182.464 22.0298 182.781 21.9996 183.103 21.9392C183.425 21.8687 183.727 21.763 184.009 21.6221C184.291 21.4712 184.543 21.285 184.764 21.0635C184.985 20.8421 185.157 20.5754 185.277 20.2634H186.379C186.228 20.7364 186.007 21.144 185.715 21.4863C185.433 21.8184 185.101 22.0902 184.719 22.3015C184.346 22.5129 183.934 22.6689 183.481 22.7696C183.038 22.8702 182.585 22.9205 182.122 22.9205C181.287 22.9205 180.562 22.7846 179.948 22.5129C179.334 22.2311 178.826 21.8486 178.423 21.3655C178.03 20.8824 177.734 20.3086 177.532 19.6444C177.341 18.9801 177.245 18.2554 177.245 17.4703C177.245 16.6953 177.346 15.9757 177.547 15.3114C177.749 14.637 178.051 14.0583 178.453 13.5751C178.866 13.082 179.379 12.6995 179.993 12.4277C180.617 12.1459 181.337 12.005 182.152 12.005C182.615 12.005 183.058 12.0553 183.481 12.156C183.914 12.2566 184.306 12.4177 184.658 12.6391C185.021 12.8505 185.328 13.1222 185.579 13.4544C185.841 13.7865 186.042 14.1891 186.183 14.6622H185.187C185.066 14.3501 184.895 14.0834 184.673 13.862C184.462 13.6406 184.22 13.4594 183.949 13.3185C183.677 13.1675 183.385 13.0618 183.073 13.0014C182.761 12.931 182.449 12.8958 182.137 12.8958Z" fill="#234082"/>
                            <path d="M188.568 22.7997V12.1258H189.549V17.1835H191.421L194.999 12.1258H196.162L192.342 17.4401L196.192 22.7997H195.014L191.587 18.0893H189.549V22.7997H188.568Z" fill="#234082"/>
                            <path d="M197.871 22.7997V12.1258H198.852V21.5014L204.982 12.1258H206.235V22.7997H205.254V13.4997L199.124 22.7997H197.871Z" fill="#234082"/>
                            <path d="M213.229 10.0423C213.652 10.0423 214.024 9.95676 214.347 9.78566C214.679 9.60449 214.885 9.29247 214.966 8.84961H215.902C215.871 9.23208 215.776 9.54913 215.615 9.80075C215.454 10.0524 215.252 10.2587 215.011 10.4198C214.769 10.5808 214.493 10.6965 214.181 10.767C213.879 10.8274 213.562 10.8576 213.229 10.8576C212.897 10.8576 212.575 10.8274 212.263 10.767C211.961 10.6965 211.684 10.5858 211.433 10.4349C211.191 10.2738 210.99 10.0675 210.829 9.81585C210.668 9.55416 210.572 9.23208 210.542 8.84961H211.478C211.559 9.29247 211.765 9.60449 212.097 9.78566C212.429 9.95676 212.807 10.0423 213.229 10.0423ZM209.017 22.7997V12.1258H209.998V21.5014L216.128 12.1258H217.381V22.7997H216.4V13.4997L210.27 22.7997H209.017Z" fill="#234082"/>
                            <path d="M74.9995 41.9028H74.0182V32.1347H67.6923V41.9028H66.7109V31.2288H74.9995V41.9028Z" fill="#234082"/>
                            <path d="M82.0863 31.9988C81.4119 32.0088 80.8281 32.1347 80.3349 32.3762C79.8418 32.6077 79.4392 32.9348 79.1271 33.3576C78.8151 33.7702 78.5836 34.2533 78.4327 34.8069C78.2817 35.3504 78.2062 35.9292 78.2062 36.5431C78.2062 37.1672 78.2817 37.756 78.4327 38.3095C78.5836 38.8631 78.8151 39.3513 79.1271 39.774C79.4492 40.1867 79.8518 40.5188 80.3349 40.7704C80.8281 41.012 81.4169 41.1328 82.1014 41.1328C82.4134 41.1328 82.7304 41.1026 83.0525 41.0422C83.3746 40.9718 83.6765 40.8661 83.9584 40.7252C84.2402 40.5742 84.4918 40.388 84.7132 40.1665C84.9347 39.9451 85.1058 39.6784 85.2266 39.3664H86.3287C86.1777 39.8394 85.9563 40.2471 85.6644 40.5893C85.3826 40.9214 85.0504 41.1932 84.6679 41.4045C84.2955 41.6159 83.8829 41.7719 83.4299 41.8726C82.9871 41.9732 82.5342 42.0235 82.0712 42.0235C81.2358 42.0235 80.5111 41.8877 79.8971 41.6159C79.2831 41.3341 78.7749 40.9516 78.3723 40.4685C77.9797 39.9854 77.6828 39.4117 77.4815 38.7474C77.2903 38.0831 77.1947 37.3584 77.1947 36.5733C77.1947 35.7983 77.2953 35.0787 77.4966 34.4144C77.6979 33.74 77.9999 33.1613 78.4025 32.6782C78.8151 32.185 79.3284 31.8025 79.9424 31.5307C80.5664 31.2489 81.2861 31.108 82.1014 31.108C82.5643 31.108 83.0072 31.1583 83.4299 31.259C83.8627 31.3596 84.2553 31.5207 84.6076 31.7421C84.9699 31.9535 85.2769 32.2252 85.5285 32.5574C85.7902 32.8895 85.9915 33.2921 86.1324 33.7652H85.136C85.0152 33.4532 84.8441 33.1864 84.6226 32.965C84.4113 32.7436 84.1697 32.5624 83.898 32.4215C83.6262 32.2705 83.3343 32.1648 83.0223 32.1045C82.7103 32.034 82.3983 31.9988 82.0863 31.9988Z" fill="#234082"/>
                            <path d="M88.5169 41.9028V31.2288H89.4982V40.6044L95.6278 31.2288H96.8809V41.9028H95.8996V32.6027L89.77 41.9028H88.5169Z" fill="#234082"/>
                            <path d="M105.4 31.2288H106.623L103.302 36.5129L106.759 41.9028H105.536L102.652 37.3282L99.7537 41.9028H98.561L102.003 36.5582L98.6667 31.2288H99.9047L102.652 35.7581L105.4 31.2288Z" fill="#234082"/>
                            <path d="M112.623 42.0235C111.798 42.0235 111.073 41.8877 110.449 41.6159C109.825 41.3442 109.301 40.9667 108.879 40.4836C108.466 39.9904 108.154 39.4117 107.943 38.7474C107.731 38.0831 107.626 37.3584 107.626 36.5733C107.626 35.7883 107.731 35.0636 107.943 34.3993C108.154 33.7249 108.466 33.1462 108.879 32.6631C109.301 32.1799 109.825 31.8025 110.449 31.5307C111.073 31.2489 111.798 31.108 112.623 31.108C113.448 31.108 114.168 31.2489 114.782 31.5307C115.406 31.8025 115.924 32.185 116.337 32.6782C116.76 33.1613 117.077 33.735 117.288 34.3993C117.499 35.0636 117.605 35.7883 117.605 36.5733C117.605 37.3584 117.499 38.0831 117.288 38.7474C117.077 39.4117 116.76 39.9854 116.337 40.4685C115.924 40.9516 115.406 41.3341 114.782 41.6159C114.168 41.8877 113.448 42.0235 112.623 42.0235ZM112.623 31.9988C111.949 31.9988 111.36 32.1196 110.857 32.3611C110.353 32.6027 109.936 32.9348 109.603 33.3576C109.271 33.7702 109.025 34.2533 108.864 34.8069C108.703 35.3605 108.622 35.9493 108.622 36.5733C108.622 37.1974 108.703 37.7862 108.864 38.3397C109.035 38.8933 109.286 39.3764 109.619 39.7891C109.951 40.2018 110.363 40.5289 110.857 40.7704C111.36 41.012 111.949 41.1328 112.623 41.1328C113.297 41.1328 113.886 41.017 114.389 40.7855C114.893 40.544 115.305 40.2169 115.627 39.8042C115.949 39.3915 116.191 38.9084 116.352 38.3548C116.513 37.8013 116.594 37.2074 116.594 36.5733C116.594 35.9493 116.513 35.3605 116.352 34.8069C116.191 34.2533 115.944 33.7702 115.612 33.3576C115.29 32.9348 114.878 32.6027 114.374 32.3611C113.881 32.1196 113.297 31.9988 112.623 31.9988Z" fill="#234082"/>
                            <path d="M126.331 41.9028V32.1347H121.349C121.339 32.7084 121.328 33.2821 121.318 33.8558L121.288 34.9881C121.288 35.5618 121.278 36.1355 121.258 36.7092C121.238 37.2527 121.213 37.8214 121.183 38.4152C121.152 38.999 121.067 39.5526 120.926 40.076C120.755 40.7302 120.468 41.2083 120.065 41.5102C119.663 41.8122 119.129 41.9732 118.465 41.9934V40.9818C118.767 40.9818 119.024 40.9114 119.235 40.7704C119.446 40.6195 119.617 40.4232 119.748 40.1816C119.859 39.9703 119.945 39.6985 120.005 39.3664C120.075 39.0342 120.126 38.687 120.156 38.3246C120.186 37.9623 120.206 37.61 120.216 37.2678C120.226 36.9155 120.236 36.6186 120.246 36.3771C120.267 35.5215 120.282 34.666 120.292 33.8105C120.302 32.955 120.317 32.0944 120.337 31.2288H127.312V41.9028H126.331Z" fill="#234082"/>
                            <path d="M134.503 42.0235C133.677 42.0235 132.953 41.8877 132.329 41.6159C131.705 41.3442 131.181 40.9667 130.758 40.4836C130.346 39.9904 130.034 39.4117 129.822 38.7474C129.611 38.0831 129.505 37.3584 129.505 36.5733C129.505 35.7883 129.611 35.0636 129.822 34.3993C130.034 33.7249 130.346 33.1462 130.758 32.6631C131.181 32.1799 131.705 31.8025 132.329 31.5307C132.953 31.2489 133.677 31.108 134.503 31.108C135.328 31.108 136.048 31.2489 136.662 31.5307C137.286 31.8025 137.804 32.185 138.217 32.6782C138.639 33.1613 138.956 33.735 139.168 34.3993C139.379 35.0636 139.485 35.7883 139.485 36.5733C139.485 37.3584 139.379 38.0831 139.168 38.7474C138.956 39.4117 138.639 39.9854 138.217 40.4685C137.804 40.9516 137.286 41.3341 136.662 41.6159C136.048 41.8877 135.328 42.0235 134.503 42.0235ZM134.503 31.9988C133.828 31.9988 133.239 32.1196 132.736 32.3611C132.233 32.6027 131.815 32.9348 131.483 33.3576C131.151 33.7702 130.904 34.2533 130.743 34.8069C130.582 35.3605 130.502 35.9493 130.502 36.5733C130.502 37.1974 130.582 37.7862 130.743 38.3397C130.914 38.8933 131.166 39.3764 131.498 39.7891C131.83 40.2018 132.243 40.5289 132.736 40.7704C133.239 41.012 133.828 41.1328 134.503 41.1328C135.177 41.1328 135.766 41.017 136.269 40.7855C136.772 40.544 137.185 40.2169 137.507 39.8042C137.829 39.3915 138.071 38.9084 138.232 38.3548C138.393 37.8013 138.473 37.2074 138.473 36.5733C138.473 35.9493 138.393 35.3605 138.232 34.8069C138.071 34.2533 137.824 33.7702 137.492 33.3576C137.17 32.9348 136.757 32.6027 136.254 32.3611C135.761 32.1196 135.177 31.9988 134.503 31.9988Z" fill="#234082"/>
                            <path d="M141.668 41.9028V31.2288H148.386V32.1347H142.649V41.9028H141.668Z" fill="#234082"/>
                            <path d="M150.263 41.9028V31.2288H151.245V40.6044L157.374 31.2288H158.628V41.9028H157.646V32.6027L151.517 41.9028H150.263Z" fill="#234082"/>
                            <path d="M165.063 37.147C165.486 37.147 165.879 37.137 166.241 37.1168C166.613 37.0866 167.011 37.0464 167.434 36.9961V31.2288H168.415V41.9028H167.434V37.8868C167.051 37.9371 166.679 37.9774 166.316 38.0076C165.954 38.0277 165.582 38.0378 165.199 38.0378C164.948 38.0378 164.691 38.0328 164.429 38.0227C164.168 38.0126 163.906 37.9875 163.644 37.9472C163.393 37.9069 163.141 37.8466 162.889 37.766C162.648 37.6755 162.416 37.5597 162.195 37.4188C161.963 37.2678 161.772 37.0866 161.621 36.8753C161.47 36.6539 161.354 36.4173 161.274 36.1657C161.193 35.9141 161.138 35.6574 161.108 35.3957C161.078 35.124 161.062 34.8572 161.062 34.5956V31.2288H162.044V34.2936C162.044 34.7163 162.079 35.124 162.15 35.5165C162.22 35.909 162.421 36.2563 162.753 36.5582C162.904 36.6891 163.07 36.7948 163.252 36.8753C163.443 36.9457 163.639 37.0011 163.84 37.0414C164.052 37.0816 164.258 37.1118 164.459 37.1319C164.671 37.142 164.872 37.147 165.063 37.147Z" fill="#234082"/>
                            <path d="M171.2 31.2288H178.22V32.1347H172.181V36.1506H177.616V37.0565H172.181V40.9969H178.22V41.9028H171.2V31.2288Z" fill="#234082"/>
                            <path d="M184.717 31.9988C184.043 32.0088 183.459 32.1347 182.966 32.3762C182.473 32.6077 182.07 32.9348 181.758 33.3576C181.446 33.7702 181.215 34.2533 181.064 34.8069C180.913 35.3504 180.837 35.9292 180.837 36.5431C180.837 37.1672 180.913 37.756 181.064 38.3095C181.215 38.8631 181.446 39.3513 181.758 39.774C182.08 40.1867 182.483 40.5188 182.966 40.7704C183.459 41.012 184.048 41.1328 184.732 41.1328C185.044 41.1328 185.361 41.1026 185.683 41.0422C186.005 40.9718 186.307 40.8661 186.589 40.7252C186.871 40.5742 187.123 40.388 187.344 40.1665C187.566 39.9451 187.737 39.6784 187.857 39.3664H188.96C188.809 39.8394 188.587 40.2471 188.295 40.5893C188.013 40.9214 187.681 41.1932 187.299 41.4045C186.926 41.6159 186.514 41.7719 186.061 41.8726C185.618 41.9732 185.165 42.0235 184.702 42.0235C183.867 42.0235 183.142 41.8877 182.528 41.6159C181.914 41.3341 181.406 40.9516 181.003 40.4685C180.611 39.9854 180.314 39.4117 180.112 38.7474C179.921 38.0831 179.826 37.3584 179.826 36.5733C179.826 35.7983 179.926 35.0787 180.128 34.4144C180.329 33.74 180.631 33.1613 181.033 32.6782C181.446 32.185 181.959 31.8025 182.573 31.5307C183.197 31.2489 183.917 31.108 184.732 31.108C185.195 31.108 185.638 31.1583 186.061 31.259C186.494 31.3596 186.886 31.5207 187.238 31.7421C187.601 31.9535 187.908 32.2252 188.159 32.5574C188.421 32.8895 188.622 33.2921 188.763 33.7652H187.767C187.646 33.4532 187.475 33.1864 187.254 32.965C187.042 32.7436 186.801 32.5624 186.529 32.4215C186.257 32.2705 185.965 32.1648 185.653 32.1045C185.341 32.034 185.029 31.9988 184.717 31.9988Z" fill="#234082"/>
                            <path d="M191.148 41.9028V31.2288H192.129V36.2865H194.001L197.579 31.2288H198.742L194.922 36.5431L198.772 41.9028H197.594L194.167 37.1923H192.129V41.9028H191.148Z" fill="#234082"/>
                            <path d="M200.451 41.9028V31.2288H201.432V40.6044L207.562 31.2288H208.815V41.9028H207.834V32.6027L201.704 41.9028H200.451Z" fill="#234082"/>
                            <path d="M215.81 29.1453C216.232 29.1453 216.605 29.0598 216.927 28.8887C217.259 28.7075 217.465 28.3955 217.546 27.9526H218.482C218.452 28.3351 218.356 28.6522 218.195 28.9038C218.034 29.1554 217.833 29.3617 217.591 29.5228C217.349 29.6838 217.073 29.7996 216.761 29.87C216.459 29.9304 216.142 29.9606 215.81 29.9606C215.477 29.9606 215.155 29.9304 214.843 29.87C214.541 29.7996 214.265 29.6888 214.013 29.5379C213.771 29.3768 213.57 29.1705 213.409 28.9189C213.248 28.6572 213.152 28.3351 213.122 27.9526H214.058C214.139 28.3955 214.345 28.7075 214.677 28.8887C215.009 29.0598 215.387 29.1453 215.81 29.1453ZM211.597 41.9028V31.2288H212.579V40.6044L218.708 31.2288H219.961V41.9028H218.98V32.6027L212.85 41.9028H211.597Z" fill="#234082"/>
                            <path d="M226.813 41.9028V31.2288H227.794V40.9969H233.758V31.2288H234.739V40.9969H236.068V44.9374H235.086V41.9028H226.813Z" fill="#234082"/>
                            <path d="M237.812 31.2288H244.832V32.1347H238.793V36.1506H244.228V37.0565H238.793V40.9969H244.832V41.9028H237.812V31.2288Z" fill="#234082"/>
                            <path d="M248.008 36.211H254.288V31.2288H255.27V41.9028H254.288V37.1168H248.008V41.9028H247.026V31.2288H248.008V36.211Z" fill="#234082"/>
                            <path d="M265 32.1347H261.467V41.9028H260.485V32.1347H256.953V31.2288H265V32.1347Z" fill="#234082"/>
                            <path d="M266.68 31.2288H270.303C271.461 31.2288 272.367 31.5106 273.021 32.0743C273.675 32.6379 274.002 33.5186 274.002 34.7163C274.002 35.3202 273.896 35.8436 273.685 36.2865C273.484 36.7293 273.202 37.1017 272.84 37.4037C272.487 37.6956 272.065 37.912 271.571 38.0529C271.088 38.1938 270.565 38.2643 270.001 38.2643H267.661V41.9028H266.68V31.2288ZM269.971 37.3584C270.424 37.3584 270.837 37.3081 271.209 37.2074C271.581 37.1068 271.898 36.9457 272.16 36.7243C272.422 36.5029 272.623 36.2211 272.764 35.8788C272.915 35.5366 272.991 35.129 272.991 34.6559C272.991 33.7501 272.744 33.1059 272.251 32.7235C271.758 32.3309 271.083 32.1347 270.228 32.1347H267.661V37.3584H269.971Z" fill="#234082"/>
                            <g clipPath="url(#clip1_2924_1968)">
                            <path d="M27.0875 54.5593C42.0141 54.7734 54.2882 42.8466 54.5024 27.9199C54.7166 12.9933 42.7897 0.71917 27.8631 0.504991C12.9364 0.290813 0.662312 12.2176 0.448134 27.1443C0.233955 42.071 12.1608 54.3451 27.0875 54.5593Z" fill="#234082"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M27.9167 4.7967C15.3264 4.61605 4.97344 14.6761 4.79279 27.2664C4.61215 39.8567 14.6722 50.2096 27.2625 50.3903C27.3923 50.3922 27.496 50.4989 27.4942 50.6287C27.4923 50.7585 27.3856 50.8622 27.2558 50.8604C14.4058 50.6759 4.13838 40.1095 4.32276 27.2597C4.50713 14.4097 15.0735 4.14229 27.9235 4.32666C28.0533 4.32853 28.157 4.43526 28.1551 4.56505C28.1532 4.69485 28.0465 4.79856 27.9167 4.7967ZM44.5747 12.0326C44.6713 11.9459 44.8199 11.9539 44.9066 12.0505C48.6806 16.2533 50.9438 21.8338 50.8565 27.9274C50.7691 34.0209 48.3465 39.5342 44.4534 43.627C44.3639 43.7211 44.2151 43.7248 44.1211 43.6353C44.027 43.5458 44.0233 43.3971 44.1128 43.303C47.9276 39.2925 50.3008 33.8913 50.3864 27.9206C50.472 21.9499 48.2549 16.4828 44.5569 12.3646C44.4701 12.268 44.4781 12.1194 44.5747 12.0326Z" fill="white"/>
                            <path d="M27.31 44.035V44.27H27.7801V44.035H27.31ZM26.2279 15.4527L26.0474 15.3022L25.9219 15.4527L26.0474 15.6032L26.2279 15.4527ZM28.8623 15.4527L29.0428 15.6032L29.1683 15.4527L29.0428 15.3022L28.8623 15.4527ZM27.7801 30.2048V29.9698H27.31V30.2048H27.7801ZM27.31 27.1753C27.31 27.3051 27.4153 27.4104 27.5451 27.4104C27.6748 27.4104 27.7801 27.3051 27.7801 27.1753H27.31ZM27.6353 13.9805L27.4548 14.1309L27.6353 13.9805ZM27.2743 13.83L26.0474 15.3022L26.4084 15.6032L27.6353 14.1309L27.2743 13.83ZM26.0474 15.6032L27.3646 17.1837L27.7256 16.8828L26.4084 15.3022L26.0474 15.6032ZM28.6817 15.3022L27.3646 16.8828L27.7256 17.1837L29.0428 15.6032L28.6817 15.3022ZM27.4548 14.1309L28.6817 15.6032L29.0428 15.3022L27.8158 13.83L27.4548 14.1309ZM26.1818 15.6832L27.499 15.9466L27.5911 15.4857L26.274 15.2222L26.1818 15.6832ZM27.5911 15.9466L28.9083 15.6832L28.8162 15.2222L27.499 15.4857L27.5911 15.9466ZM27.31 15.7161V17.0333H27.7801V15.7161H27.31ZM27.31 30.2048V44.035H27.7801V30.2048H27.31ZM27.31 17.0333V27.1753H27.7801V17.0333H27.31ZM27.6353 14.1309C27.5883 14.1873 27.5018 14.1873 27.4548 14.1309L27.8158 13.83C27.6751 13.6608 27.4151 13.6608 27.2743 13.83L27.6353 14.1309Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M24.9375 44.1667C24.9375 44.0369 25.0427 43.9316 25.1725 43.9316H29.9143C30.0441 43.9316 30.1493 44.0369 30.1493 44.1667C30.1493 44.2965 30.0441 44.4017 29.9143 44.4017H25.1725C25.0427 44.4017 24.9375 44.2965 24.9375 44.1667Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M14.4933 18.5409C13.8714 17.9234 13.0958 17.4804 12.1557 17.5695C12.0264 17.5817 11.9117 17.4869 11.8995 17.3576C11.8873 17.2284 11.9821 17.1137 12.1113 17.1015C13.2426 16.9943 14.148 17.5356 14.8245 18.2073C15.4999 18.8779 15.9713 19.7011 16.2447 20.2183L16.2448 20.2184C19.0785 25.5867 21.9508 28.3604 24.688 29.4222C24.809 29.4692 24.8691 29.6054 24.8221 29.7264C24.7752 29.8474 24.639 29.9074 24.518 29.8605C21.6277 28.7393 18.6857 25.8495 15.8291 20.438C15.561 19.9309 15.1164 19.1596 14.4933 18.5409Z" fill="white"/>
                            <path d="M43.2411 17.5484C43.3699 17.5643 43.4613 17.6817 43.4456 17.8105C43.4296 17.9393 43.3123 18.0308 43.1833 18.0149L43.2411 17.5484ZM27.5391 29.9699C31.1023 29.9699 34.9977 27.663 39.02 20.5455L39.4292 20.7767C35.3721 27.9556 31.3519 30.44 27.5391 30.44V29.9699ZM39.02 20.5455C39.3081 20.0364 39.8029 19.2269 40.4974 18.5759C41.1929 17.9239 42.1134 17.4088 43.2411 17.5484L43.1833 18.0149C42.2462 17.8989 41.4583 18.3194 40.8187 18.9189C40.1783 19.5195 39.7117 20.2775 39.4292 20.7767L39.02 20.5455Z" fill="white"/>
                            <path d="M27.5397 29.3865H27.3047V28.9164H27.5397V29.3865ZM38.527 19.6927L38.319 19.583L38.3193 19.5829L38.527 19.6927ZM42.4236 16.5352C42.5536 16.5352 42.6586 16.6404 42.6586 16.7702C42.6586 16.9 42.5536 17.0052 42.4236 17.0052V16.5352ZM27.5397 28.9164C31.047 28.9166 34.6148 26.6062 38.319 19.583L38.7348 19.8023C35.0021 26.8795 31.3112 29.387 27.5397 29.3865V28.9164ZM38.3193 19.5829C38.5907 19.0689 39.086 18.3145 39.7725 17.6852C40.4589 17.0563 41.3558 16.5352 42.4236 16.5352V17.0052C41.5158 17.0052 40.727 17.4481 40.0903 18.0318C39.4538 18.6151 38.9887 19.3219 38.7348 19.8025L38.3193 19.5829Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M26.2578 28.916H27.5183V29.3861H26.7279V44.4016H26.2578V28.916Z" fill="white"/>
                            </g>
                            </g>
                            <defs>
                            <clipPath id="clip0_2924_1968">
                            <rect width="274" height="55" fill="white"/>
                            </clipPath>
                            <clipPath id="clip1_2924_1968">
                            <rect width="55" height="55" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </Link>

                    {
                        window.location.pathname.includes('login') || window.location.pathname.includes('register') || window.location.pathname.includes('recovery')?
                        <></>:
                        <>
                            <div className="text-center align-middle justify-around flex-1 columns-3 desktop:flex laptop:flex tablet:hidden mobile:hidden">
                                <div><Link className='hover:text-blue font-medium whitespace-nowrap desktop:flex laptop:hidden tablet:hidden mobile:hidden' to={isSignedIn? "/my-sessions" : "/psycologies"}>{isSignedIn? 'Мои записи' : 'Список психологов'}</Link></div>
                                <div><Link className='hover:text-blue font-medium whitespace-nowrap ' to="/tests">{t('test.title')}</Link></div>
                                <div><Link className="hover:bg-blue font-medium hover:text-white text-blue border-solid px-[40.5px] pb-[12.5px] pt-[10.5px] border-[1px] border-blue rounded-[19px] whitespace-nowrap" to="/psycologies">{t('writeCons.title')}</Link></div>
                            </div>

                            <div className="desktop:gap-[64px] laptop:gap-[34px] tablet:gap-[24px] mobile:gap-x-[16px] flex items-center justify-center">
                                {/* {
                                    isSignedIn?
                                    <Link to="/" >
                                        <div className='flex justify-center items-center w-[40px] h-[40px]'>
                                            <svg className='hover:stroke-blue stroke-black' width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21 9.33398C21 7.47747 20.2625 5.69699 18.9497 4.38424C17.637 3.07148 15.8565 2.33398 14 2.33398C12.1435 2.33398 10.363 3.07148 9.05025 4.38424C7.7375 5.69699 7 7.47747 7 9.33398C7 17.5006 3.5 19.834 3.5 19.834H24.5C24.5 19.834 21 17.5006 21 9.33398Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16.021 24.5C15.8159 24.8536 15.5215 25.1471 15.1673 25.3511C14.8131 25.5551 14.4115 25.6625 14.0027 25.6625C13.5939 25.6625 13.1923 25.5551 12.8381 25.3511C12.4839 25.1471 12.1895 24.8536 11.9844 24.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </Link>:<></>
                                } */}

                                {
                                    isSignedIn?
                                    <button
                                        className='w-[40px] h-[40px]'
                                        onClick={() => {navigate('/client/profile')}}>
                                            <div className='flex justify-center items-center w-[40px] h-[40px]'>
                                                <svg className='hover:stroke-blue stroke-black' width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.3385 24.5V22.1667C23.3385 20.929 22.8469 19.742 21.9717 18.8668C21.0965 17.9917 19.9096 17.5 18.6719 17.5H9.33854C8.10086 17.5 6.91388 17.9917 6.03871 18.8668C5.16354 19.742 4.67188 20.929 4.67188 22.1667V24.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M13.9948 12.8333C16.5721 12.8333 18.6615 10.744 18.6615 8.16667C18.6615 5.58934 16.5721 3.5 13.9948 3.5C11.4175 3.5 9.32812 5.58934 9.32812 8.16667C9.32812 10.744 11.4175 12.8333 13.9948 12.8333Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            
                                    </button>
                                    :
                                    <>
                                        <button 
                                            onClick={()=>{navigate('/login')}}
                                            className="bg-blue rounded-[19px] h-[45px] text-white font-medium text-16 laptop:w-[189px] mobile:hidden laptop:flex justify-center items-center"
                                        >{t('login.sign')}</button>
                                        <button
                                            className='w-[40px] h-[40px] mobile:flex laptop:hidden'
                                            onClick={() => {navigate('/login')}}>
                                                <div className='flex justify-center items-center w-[40px] h-[40px]'>
                                                    <svg className='hover:stroke-blue stroke-black' width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M23.3385 24.5V22.1667C23.3385 20.929 22.8469 19.742 21.9717 18.8668C21.0965 17.9917 19.9096 17.5 18.6719 17.5H9.33854C8.10086 17.5 6.91388 17.9917 6.03871 18.8668C5.16354 19.742 4.67188 20.929 4.67188 22.1667V24.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.9948 12.8333C16.5721 12.8333 18.6615 10.744 18.6615 8.16667C18.6615 5.58934 16.5721 3.5 13.9948 3.5C11.4175 3.5 9.32812 5.58934 9.32812 8.16667C9.32812 10.744 11.4175 12.8333 13.9948 12.8333Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                
                                        </button>
                                    </>
                                    
                                    

                                }
                                
                                <HamburgerModal
                                    width="w-[40px]"
                                    height="h-[40px]"
                                    value={
                                        <div className='flex justify-center items-center w-[40px] h-[40px]'>
                                            <svg className='hover:stroke-blue stroke-black' width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 1H27.7732" strokeWidth="1.5" />
                                                <path d="M14.1094 21.2207L27.996 21.2207" strokeWidth="1.5" />
                                                <path d="M8.125 11.1113L27.9939 11.1113" strokeWidth="1.5" />
                                            </svg>
                                        </div>
                                        
                                    }
                                    placeholder="dasf"
                                    onChange={(value) => {
                                        if (value.to.includes('http')) {
                                            window.open(value.to)
                                        } else {
                                            navigate(value.to) 
                                        }
                                    }}
                                    data={
                                        isSignedIn?
                                        [
                                        { to: "/about-us", text: t('aboutUs.aboutUs') },
                                        { to: "/my-sessions", text: t('myRecords.title')},
                                        { to: "/psycologies", text: t('listPsy.title') },
                                        { to: "/favorite", text: t('favorite')},
                                        { to: "/usefultips", text: t('main.facts')},
                                        { to: "/gopsychotherapy", text: 'Подходы в психотерапии' },
                                        { to: "https://lk.psysakha.ru/", text: 'Психологам' }]
                                        :                                        
                                        [
                                            { to: "/login", text: 'Войти' },
                                            { to: "/about-us", text: t('aboutUs.aboutUs') },
                                            { to: "/psycologies", text: t('listPsy.title') },
                                            { to: "/usefultips", text: t('main.facts') },
                                            { to: "/gopsychotherapy", text: 'Подходы в психотерапии' },
                                            { to: "https://lk.psysakha.ru/", text: 'Психологам' }]
                                    }
                                />
                            </div>
                        </>
                    }
                    
                </div>
                {
                    window.location.pathname.includes('login') || window.location.pathname.includes('register') || window.location.pathname.includes('recovery')?
                    <></>:
                    <div className='flex justify-end desktop:mt-[20px] laptop:mt-[16px] tablet:mt-[10px] mobile:mt-[10px] mb-[1px]'>
                        <div className={`flex ${i18n.language === 'ru' ? 'bg-white-cards text-blue' : ''} font-regular px-[10px] py-[4.5px] rounded-[11px] cursor-pointer`} onClick={() => i18n.changeLanguage('ru')}>РУС</div>
                        <div 
                            className={`flex ${i18n.language === 'sa' ? 'bg-white-cards text-blue' : ''} font-regular px-[10px]  py-[4.5px] rounded-[11px] cursor-pointer`} 
                            onClick={() => {
                                i18n.changeLanguage('sa')
                            }}
                        >ЯКТ</div>
                    </div>}
                
            </div>

        </div>
    )
}