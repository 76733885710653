import * as React from 'react';
import {useTranslation} from 'react-i18next';

const tempData = [
    {
        name: 'Иванова Надежда Алексеевна',
        mail: 'nadja@mail.ru',
        phone: '+1 (234) 567 89 10',
        avatar: require('../../assets/icons/temp.png'),
        vkUrl: 'https://vk.com',
        telegramUrl: 'https://telegram.com'
    }
]

export const VideoConference = () => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();

    React.useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setData(tempData);
            setLoading(false);
        }, 1500);
    }, []);
    return(
        <div>
            <title>{t('videochat')}</title>
            <div className='container mx-auto items-center content-center desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]'>
                <div className="flex flex-row">
                    <div className="text-blue mobile:text-16 desktop:text-[50px] font-medium">{t('videochat')}</div>
                </div>
            </div>

            <div className='w-screen h-[60vh] bg-red items-center content-center desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]'>
                <div className="flex flex-1 flex-row  ">
                    <div className="">Само видео</div>
                </div>
            </div>
            <div className='flex laptop:flex-row mobile:flex-col container gap-[30px] mx-auto items-center content-center desktop:mt-[30px] laptop:mt-[20px] tablet:mt-[10px] mobile:mt-[10px]'>
                <div>
                    {
                        data.map((item, index) =>
                            <div className='flex flex-row '>
                                <div className=''>
                                    <img
                                        src={item.avatar}
                                        className="rounded-full laptop:w-[106px]  laptop:h-[106px] mobile:w-[86px] mobile:h-[86px] object-cover mr-[17px]"
                                    />    
                                </div>
                                <div className='flex flex-col gap-[10px]'>
                                    <div className='font-medium text-20'>{item.name}</div>
                                    <div className='flex flex-row whitespace-nowrap items-center'>
                                        <div className='flex'>
                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.0984 7.00318V13.7738C17.0984 14.4037 16.8482 15.0078 16.4028 15.4532C15.9574 15.8986 15.3533 16.1488 14.7234 16.1488H4.27344C3.64355 16.1488 3.03946 15.8986 2.59406 15.4532C2.14866 15.0078 1.89844 14.4037 1.89844 13.7738V7.00318L9.25714 11.3333C9.33024 11.3764 9.41357 11.3991 9.49844 11.3991C9.58331 11.3991 9.66663 11.3764 9.73974 11.3333L17.0984 7.00318ZM14.7234 3.79883C15.3078 3.79874 15.8717 4.0141 16.3073 4.40373C16.7428 4.79336 17.0194 5.32989 17.0842 5.91068L9.49844 10.3728L1.91269 5.91068C1.97744 5.32989 2.25405 4.79336 2.68959 4.40373C3.12514 4.0141 3.68905 3.79874 4.27344 3.79883H14.7234Z" fill="#3A7DFF"/>
                                            </svg>
                                        </div>
                                        <div className='text-16 text-blue ml-[10px]'>{item.mail}</div>
                                    </div>
                                    <div className='flex flex-row whitespace-nowrap items-center'>
                                        <div>
                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6654 10.6676C9.16158 12.1705 7.41928 13.6088 6.73053 12.9201C5.74538 11.9349 5.13738 11.0761 2.96378 12.8232C0.791135 14.5693 2.46028 15.734 3.41503 16.6878C4.51703 17.7898 8.62483 16.7467 12.6851 12.6873C16.7445 8.62702 17.7847 4.51922 16.6818 3.41722C15.727 2.46152 14.568 0.793324 12.8219 2.96597C11.0749 5.13862 11.9327 5.74662 12.9198 6.73272C13.6057 7.42147 12.1683 9.16377 10.6654 10.6676Z" fill="#3A7DFF"/>
                                            </svg>
                                        </div>
                                        <div className='text-16 text-blue ml-[10px]'>{item.phone}</div>
                                    </div>
                                    <div className='flex flex-row whitespace-nowrap items-center'>
                                        <div className='flex flex-row flex-nowrap desktop:gap-[15px] laptop:gap-x-[15px] tablet:gap-[10px] mobile:gap-x-[17px] desktop:pl-0 laptop:pl-0 tablet:pl-[55px] mobile:pl-[40px]'>
                                            <div
                                                className='bg-white-cards hover:bg-blue rounded-full desktop:w-[35px] desktop:h-[35px] laptop:w-[35px] laptop:h-[35px] tablet:h-[45px] tablet:w-[45px] mobile:w-[40px] mobile:h-[40px] justify-center flex items-center'
                                                onClick={() => {
                                                    window.open(item.vkUrl, '_blank').focus();
                                                }}
                                            >
                                                <svg className='hover:fill-white fill-blue rounded-full cursor-pointer' width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
                                                    {/* <rect width="80" height="80" rx="40" /> */}
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M58.1284 30.4897C58.3912 29.6317 58.1284 29 56.8697 29H52.7135C51.6559 29 51.1682 29.5453 50.9038 30.1471C50.9038 30.1471 48.7901 35.1694 45.7961 38.4317C44.8271 39.3777 44.3869 39.6779 43.8581 39.6779C43.5937 39.6779 43.1963 39.3777 43.1963 38.5181V30.4897C43.1963 29.4589 42.905 29 42.0246 29H35.4888C34.8285 29 34.4311 29.4777 34.4311 29.9319C34.4311 30.9077 35.9289 31.134 36.0825 33.8809V39.8491C36.0825 41.1581 35.8403 41.3954 35.3114 41.3954C33.9023 41.3954 30.4745 36.3496 28.4399 30.5761C28.0457 29.4526 27.6467 29 26.5843 29H22.425C21.2375 29 21 29.5453 21 30.1471C21 31.2189 22.4091 36.5429 27.5628 43.5844C30.9985 48.393 35.8355 51 40.2418 51C42.8844 51 43.2105 50.4217 43.2105 49.4239V45.7891C43.2105 44.631 43.4607 44.4 44.2983 44.4C44.9157 44.4 45.9718 44.7017 48.4386 47.0196C51.2569 49.768 51.7208 51 53.3072 51H57.4634C58.6509 51 59.2462 50.4217 58.9042 49.2777C58.5274 48.14 57.1816 46.4884 55.3972 44.5289C54.4282 43.4131 52.9747 42.211 52.533 41.6091C51.9171 40.8376 52.0928 40.4934 52.533 39.8067C52.533 39.8067 57.5996 32.8516 58.1268 30.4897H58.1284Z" />
                                                </svg>
                                            </div>
                                            <div
                                                className='bg-white-cards hover:bg-blue rounded-full desktop:w-[35px] desktop:h-[35px] laptop:w-[35px] laptop:h-[35px] tablet:h-[45px] tablet:w-[45px] mobile:w-[40px] mobile:h-[40px] justify-center flex items-center'
                                                onClick={() => {
                                                    window.open(item.telegramUrl, '_blank').focus();
                                                }}
                                            >
                                                <svg className='hover:fill-white fill-blue rounded-full cursor-pointer' width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
                                                    <path className='' d="M54.7051 26.1841L24.5876 37.5883C22.5322 38.399 22.5441 39.5249 24.2105 40.0269L31.9428 42.3955L49.8333 31.3116C50.6792 30.8062 51.4521 31.0781 50.8168 31.6318L36.322 44.4772H36.3186L36.322 44.4789L35.7886 52.3052C36.57 52.3052 36.9149 51.9532 37.3531 51.5379L41.1089 47.9517L48.9211 53.6179C50.3615 54.3969 51.396 53.9966 51.7545 52.3085L56.8828 28.576C57.4076 26.5094 56.0793 25.5736 54.7051 26.1841Z" />
                                                    {/* <rect width="80" height="80" rx="40" fill='none' /> */}

                                                </svg>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className='text-14 text-grey'>Если у вас возникли проблемы с подключением, вы можете<br/> совершить видеозвонок, сделав переход на соцсети психолога</div>
            </div>
            
        </div>
    )
}