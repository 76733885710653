import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PsycologyItem } from '../../components/PsycologyItem';
import { useNavigate } from 'react-router-dom';
import { Search } from '../../components/Search';
import { setFavoriteRedux } from '../../redux/favorite/favoriteSlice';
import { useTranslation } from 'react-i18next';
import {request} from '../../requests/requests';

export const Favorite = () => {
    const favoriteRedux = useSelector(state => state.favorite.favorite);
    const [data, setData] = React.useState(favoriteRedux);
    // eslint-disable-next-line
    const [loading, setLoading] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const token = useSelector(state => state.auth.session.token);
    const citiesRedux = useSelector(state => state.cities.cities);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const effectRun = React.useRef(false);
    const {t} = useTranslation();

    const getData = async () => {
        setLoading(true);
        try {
            const response = await request({
                method: 'GET',
                url: '/api/favorites',
                token: token,
                body: undefined
            });

            if(response.status === 200) {
                setData(response.data.data);
                dispatch(setFavoriteRedux(response.data.data));
            }
        } catch(e) {
            console.log('e', e);
        } finally {
            setLoading(false);
        };
    }
    
    React.useEffect(() => {
        if(effectRun.current === false) { getData(); }
        return () => { effectRun.current = true; }
        // eslint-disable-next-line
    }, [favoriteRedux.length]);

    return(
        <div className='container mx-auto'>
            <title>{t('favorite')}</title>
            <p className='text-[16px] laptop:text-[50px] font-medium text-blue'>{t('favorite').split(' ')[0]} <label className='text-black'>{t('favorite').substring(t('favorite').indexOf(' '))}</label></p>
            <Search 
                search={search}
                setSearch={setSearch}
            />
            {
                data.length > 0?
                    <div className='grid grid-cols-1 laptop:grid-cols-3 gap-[10px] laptop:gap-[33px] mt-[30px] laptop:mt-[50px]'>
                    {
                        data.map((item, index) => 
                            <PsycologyItem 
                                key={index.toString()}
                                item={item} 
                                index={index} 
                                navigate={navigate} 
                                dispatch={dispatch} 
                                favorite={data}
                                cities={citiesRedux}
                                token={token}
                                updateList={() => {getData();}}    
                            />
                        )
                    }
                    </div>:
                    <div className='flex flex-row items-center justify-center mt-[120px]'>
                        <p className='text-[16px] laptop:text-[24px] font-medium text-grey'>Избранные психологи отсуствуют</p>
                    </div>
            }
        </div>
    );
}