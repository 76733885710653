import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AbouUs = () => {
  const { t } = useTranslation();

  return (
    <div>
      <title>Портал психологов - {t("aboutUs.aboutUs")}</title>
      <div className="container mx-auto items-center content-center">
        <div className="flex flex-row">
          <div className="text-blue mobile:text-16 desktop:text-[50px] font-medium">
            {t("aboutUs.aboutUs").split(" ")[0]}
          </div>
          <div className="mobile:text-16 desktop:text-[50px] font-medium desktop:ml-[13px] mobile:ml-[5px]">
            {t("aboutUs.aboutUs").substring(t("aboutUs.aboutUs").indexOf(" "))}
          </div>
        </div>
        <div className="flex flex-col mobile:py-[45px] desktop:py-[100px] desktop:text-16 mobile:text-[12px]">
          <div className="font-regular pr-[40px] w-[100%]">
            <div className="text-blue mobile:whitespace-normal laptop:whitespace-nowrap">
              Якутский республиканский психологический центр
            </div>
            <div>
              ранее назывался «Центр социально-психологической поддержки семьи и
              молодежи». В 2022 году официально зарегистрировано короткое и
              понятное наименование взамен аббревиатуре «ЦСППСиМ». Учрежден
              Постановлением Правительства РС (Я) в 2000 г., действует при
              региональном Министерстве по делам молодежи и социальным
              коммуникациям. У учреждения 19 филиалов в районах и 3
              круглосуточные службы:
              <br />
              <br />
              Телефон доверия 8-800-100-35-50, интегрирован с системой 122
              <br />
              <br />
              Федеральный телефон доверия для детей, подростков и родителей –
              8-800-2000-122 (центр является основным региональным оператором)
              <br />
              <br />
              Мобильная кризисная служба 8-800-100-22-83 – если вы стали жертвой
              или очевидцем насилия над детьми и жестокого обращения с
              несовершеннолетними. Специально обученные психологи примут
              обращение, окажут экстренное консультирование, передадут
              информацию в правоохранительные органы. Специалисты сопровождают
              всех детей во время следственных мероприятий. Также служба
              оказывает комплексное психологическое сопровождение пострадавшим
              детям и их семьям во время восстановления.
            </div>
          </div>
          <div className="font-medium pr-[40px] desktop:pt-0 mobile:pt-[20px]">
            <div>
              Назначено Центром компетенций по общей координации и методическому
              сопровождению психологических служб на территории Якутии, с 2021
              г. Распоряжением Правительства РС (Я).
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white-cards py-[30px]">
        <div className="text-blue desktop:text-[39px] mobile:text-[14px] mobile:leading-normal font-medium leading-[55px] container mx-auto items-center content-center uppercase">
          {t("aboutUs.servicesCons")}
        </div>
      </div>
      <div className="container mx-auto items-center content-center mobile:border-b-[0px] desktop:border-b-[1px] desktop:border-l-[1px] desktop:px-[90px] border-blue mobile:border-l-0 mobile:pl-[0px]">
        <div className=" desktop:pt-[60px] text-grey desktop:text-[20px] font-regular mobile:pt-[20px] mobile:text-[12px]">
          {t("aboutUs.psyCons")}
        </div>
        <div className="flex justify-around desktop:flex-row columns-3 desktop:py-[100px] mobile:py-[20px] mobile:flex-col mobile:columns-1">
          <Block num={"1"} title={t("aboutUs.individual")} />
          <Block num={"2"} title={t("aboutUs.family")} />
          <Block num={"3"} title={t("aboutUs.group")} />
        </div>
      </div>
      <div className="container mx-auto items-center content-center mobile:border-b-[0px] desktop:border-b-[1px] desktop:border-l-[1px] mobile:border-l-[0px] border-blue desktop:pl-[90px] desktop:py-[100px] mobile:pl-[0px] mobile:py-[35px] ">
        <Link
          to="/management"
          className="mobile:py-[10px] desktop:text-[39px] mobile:text-[14px] mobile:font-medium desktop:font-light flex flex-row justify-between mobile:w-[100%] desktop:w-[30vw] border-b-[1px] border-blue/40 items-center hover:text-blue"
        >
          {t("aboutUs.management")}
          <svg
            className="desktop:flex mobile:hidden"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 18L28.5 18"
              stroke="#3A7DFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 7.5L28.5 18L18 28.5"
              stroke="#3A7DFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
        <Link
          to="/contacts"
          className="mobile:py-[10px] desktop:text-[39px] mobile:text-[14px] mobile:font-medium desktop:font-light flex flex-row justify-between mobile:w-[100%] desktop:w-[30vw] border-b-[1px] border-blue/40 items-center hover:text-blue"
        >
          {t("aboutUs.contacts")}
          <svg
            className="desktop:flex mobile:hidden"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 18L28.5 18"
              stroke="#3A7DFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 7.5L28.5 18L18 28.5"
              stroke="#3A7DFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>

      <div className="container mx-auto items-center content-center laptop:py-[70px] mobile:py-[30px]">
        <h2 className="mobile:text-[24px]">Направления работы:</h2>
        <div className="my-[20px]">
          <p className="text-[18px] font-regular mb-[10px]">
            - Консультирование: первые консультации бесплатно всем обращающимся,
            далее платно или бесплатно/льготно по ряду социальных программ
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Диагностика и профориентация
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Горячая линия круглосуточной психологической поддержки
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Поддержка участникам СВО и их близким
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Психологиум: открытые мероприятия для всех (все просветительские
            мероприятия для широкой аудитории)
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Психологиум Проф: мероприятия для психологов (все они проходят по
            протоколу Центра компетенций по общей координации работы и
            методическому сопровождению психологических служб на территории
            Якутии)
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Психологиум Плюс: разработка индивидуальных и корпоративных
            программ (платные просветительские, информационные, обучающие и пр.
            мероприятия)
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Психологиум Gov: профилактические, просветительские и обучающие
            мероприятия для исполнительных органов государственной власти и
            органом местного самоуправления (ИОГВ и ОМСУ){" "}
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Психологическая спасательная служба (межведомственный протокол при
            ЧС, в координации с МЧС)
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Психологическая экспертиза
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Комплексное психологическое сопровождение пострадавших от насилия
            и жесткого обращения несовершеннолетних и их семей (в сотрудничестве
            с правоохранительными структурами)
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Реализация ряда федеральных, окружных и региональных проектов и
            программ в рамках Нацпроектов «Здоровье» («Здравоохранение») и
            «Демография», а также в рамках ФЗ №120
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Реализация региональной программы «Охрана психологического
            здоровья населения РС (Я) на 2021-2025 гг.»
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Межведомственная комиссия по охране психологического здоровья
            населения Республики Саха (Якутия)
          </p>
        </div>
        <p className="text-[18px] font-semibold mb-[10px]">
          *В 2022 г. поручением Главы РС (Я) оказывает усиленную психологическую
          поддержку участникам СВО, их семьям, демобилизованным.
        </p>
      </div>

      <div className="container mx-auto items-center content-center laptop:pb-[50px] mobile:py-[30px]">
        <h2 className="mobile:text-[24px]">
          Наши ссылки и официальные информационные ресурсы:
        </h2>
        <div className="my-[20px]">
          <p className="text-[18px] font-regular mb-[10px]">
            Официальная страница с базовой информацией, сведениями о руководстве
            и контактами всех структурных подразделений:
            <a
              target="_blank"
              href="https://minmol.sakha.gov.ru/psycentre"
              className="text-blue font-semibold hover:text-sky-blue"
            >
              {" "}
              https://minmol.sakha.gov.ru/psycentre
            </a>
          </p>

          <p className="text-[18px] font-regular mb-[10px]">
            Онлайн-сервис Центра (на данный момент находится на стадии
            разработки планируется во втором квартале 2023 г., со временем весь
            сайт переедет на этот адрес):
            <a
              target="_blank"
              href="https://psy.sakha.gov.ru/"
              className="text-blue font-semibold hover:text-sky-blue"
            >
              {" "}
              https://psy.sakha.gov.ru/
            </a>
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            Госпаблик Центра, официальная информация и полезные рекомендации,
            будет развиваться:{" "}
            <a
              target="_blank"
              href="https://vk.com/psycentrykt"
              className="text-blue font-semibold hover:text-sky-blue"
            >
              {" "}
              https://vk.com/psycentrykt
            </a>
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            Официальная страница А.Г. Подголова, через которого озвучивается
            эмоциональная информация о Центре:
            <a
              target="_blank"
              href="https://vk.com/id711791358"
              className="text-blue font-semibold hover:text-sky-blue"
            >
              {" "}
              https://vk.com/id711791358
            </a>
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            Госпаблик Министерства:
            <a
              target="_blank"
              href="https://vk.com/minmolykt"
              className="text-blue font-semibold hover:text-sky-blue"
            >
              {" "}
              https://vk.com/minmolykt
            </a>
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            Телеграм-канал нашего зампреда О.В. Балабкиной:
            <a
              target="_blank"
              href="https://t.me/balabkinaov"
              className="text-blue font-semibold hover:text-sky-blue"
            >
              {" "}
              https://t.me/balabkinaov
            </a>
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            Канал, где публикуются брифинги директора по работе Центра по СВО:
            <a
              target="_blank"
              href="https://t.me/YakutiaOpershtab"
              className="text-blue font-semibold hover:text-sky-blue"
            >
              {" "}
              https://t.me/YakutiaOpershtab
            </a>
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            Официальный информационно-новостной портал Правительства, где
            публикуется официальная новостная информация по нашей деятельности:
            <a
              target="_blank"
              href="https://sakha.gov.ru/"
              className="text-blue font-semibold hover:text-sky-blue"
            >
              {" "}
              https://sakha.gov.ru/
            </a>
          </p>
        </div>
      </div>

      <div className="container mx-auto items-center content-center ">
        <h2 className="mobile:text-[24px]">Правила публикации в СМИ:</h2>
        <div className="my-[20px]">
          <p className="text-[18px] font-regular mb-[10px]">
            - Сотрудничество с официальными СМИ
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - При запросе на публикацию в неофициальных СМИ, таблоидах и желтой
            прессе необходимо предоставление информации и согласование с
            пресс-службой и руководством
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Публикация любых материалов в СМИ происходит после согласования с
            пресс-службой
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Пресс-служба – это не контрольный орган. Это помощь с тезисами и
            формулировками, соблюдение правил публикации, помощь с корректными
            наименованиями и ФИО, отработка согласования с руководством,
            фиксация публикации и сохранение ссылки на публикацию,
            взаимодействие с редакцией и т.д. Все это – исходя из стратегии
            развития Центра, актуальной повестки, целей и задач деятельности.
          </p>
          <p className="text-[18px] font-regular mb-[10px]">
            - Пресс-служба входит состав информационного отдела. Отдел
            занимается внешними социальными коммуникациями, информационными
            технологиями, разработкой и заказом полиграфии.
          </p>
        </div>
      </div>

      <div className="container mx-auto rounded-[19px] grid desktop:grid-cols-2 mobile:grid-cols-1 bg-blue  desktop:mt-[100px] mobile:mt-[35px]">
        <div className="flex flex-col columns-1 desktop:pt-[100px] mobile:pt-[40px] desktop:pb-[60px] mobile:pb-[16px] mobile:pl-[20px] desktop:pl-[120px] items-start">
          <div className="text-white mobile:text-[16px] desktop:text-[58px] font-medium desktop:leading-[64px] mobile:leading-tight">
            {t("aboutUs.choose100")}
          </div>
          <div className="text-white mt-[20px] desktop:text-[22px] font-medium mobile:text-[10px] desktop:leading-[28px] mobile:leading-tight">
            {t("aboutUs.weHappy")}
          </div>
          <a
            className="text-blue font-medium bg-white rounded-[19px] py-[15.5px] px-[82px] mt-[40px]"
            // onClick={() => {
            //     navigate('https://psy-lk.sakha.gov.ru/')
            // }}
            href="https://psy-lk.sakha.gov.ru"
          >
            {t("aboutUs.cooperate")}
          </a>
        </div>
        <div className="mobile:hidden desktop:flex bg-[50%] bg-no-repeat bg-aboutusBg"></div>
      </div>
      {/* <div className='container mx-auto items-center content-center '>
                <div className="desktop:pl-[90px] mobile:pl-[0px] desktop:pt-[60px] text-grey desktop:text-[20px] font-regular mobile:pt-[20px] mobile:text-[12px]">{t('main.partner')}</div>
                <div className='flex justify-start desktop:my-[100px] laptop:my-[80px] tablet:my-[40px] mobile:my-[20px] desktop:gap-[80px] laptop:gap-[60px] tablet:gap-[40px] mobile:gap-[20px]'>
                    <img className='rounded-full desktop:w-auto desktop:h-auto mobile:w-[66px] mobile:h-[66px] ' src={require('../../assets/icons/images/partner-1.png')}  alt='...'/>
                    <img className='rounded-full desktop:w-auto desktop:h-auto mobile:w-[66px] mobile:h-[66px]' src={require('../../assets/icons/images/partner-2.png')}  alt='...'/>
                </div>
            </div> */}
    </div>
  );
};

const Block = ({ title, num }) => {
  return (
    <div className="mobile:pb-[6px] desktop:pb-[100px]">
      <div className="border-blue border-[1px] rounded-[19px] px-[44px] py-[48px] flex font-medium text-[30px] w-[333px] justify-center mobile:text-[14px] mobile:py-[35px]">
        {title}
      </div>
      <div className="text-blue text-[63px] bg-white leading-[80px] mt-[-140px] ml-[-15px] w-[50px] text-center font-medium desktop:flex mobile:hidden">
        {num}
      </div>
    </div>
  );
};
