import React from "react";

export const Modal = ({
    visible,
    children,
    onClose
}) => {
    if (visible === true) {
        return(
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-[rgba(0,0,0,0.4)]"
                onClick={(e) => { e.currentTarget === e.target && onClose(); }}
            >
                {children}
            </div>
        )
    }
}
