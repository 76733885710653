import {createSlice} from '@reduxjs/toolkit';

export const worriesSlice = createSlice({
    name: 'worries',
    initialState: {
        worries: []
    },
    reducers: {
        setWorriesRedux: (state, action) => {
            state.worries = action.payload;
        }
    }
});

export const { setWorriesRedux } = worriesSlice.actions;
export default worriesSlice.reducer;