import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';

import viewPass from '../../assets/icons/viewPass.svg';
import { Loading } from '../../components/Loading';
import {Checkbox} from '../../components/Checkbox';
import { onSignInSuccess, saveEmailPass } from '../../redux/auth/sessionSlice';
import { setUser } from '../../redux/auth/userSlice';
import {request} from '../../requests/requests';

export const Login = (props) => {
    const { t } = useTranslation();
    const emailRedux = useSelector(state => state.auth.session.email);
    const passwordRedux = useSelector(state => state.auth.session.password);

    const [email, setEmail] = React.useState(emailRedux);
    const [password, setPassword] = React.useState(passwordRedux);
    const [savePassword, setSavePassword] = React.useState(emailRedux !== '' && passwordRedux !== ''? true: true);
    const refLogin = React.useRef(null);
    const refPassword = React.useRef(null);

    const [errorStates, setErrorStates] = React.useState({
        email: false,
        password: false
    });
    const [loading, setLoading] = React.useState(false);
    const [showPass, setShowPass] = React.useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    return(
        <div className={styles.main}>
            <title>{t('login.authorization')}</title>
            <div className={styles.divTopNull}></div>
            <div className={styles.divCenter}>
                <div className={styles.divCenter1}>
                    <p className={styles.p_auth}>{t('login.authorization')}</p>
                    <p className={styles.p_login_descr}>{t('login.descr')}</p>
                    <div className='mt-[44px] '>
                        <p className={styles.p_login}>{t('login.login')}:</p>
                        <div onClick={() => {refLogin.current?.focus();}} className={styles.div_email(errorStates)}>
                            <label className={styles.label_email}>{t('login.enterEmail')}</label>
                            <input 
                                ref={refLogin}
                                className={styles.input_email(errorStates)}
                                type={'email'}
                                value={email}
                                autoComplete={'on'}
                                onChange={(event) => {
                                    setEmail(event.target.value); 
                                    setErrorStates({email: false, password: errorStates.password}) 
                                }}
                            />
                        </div>
                        <p className={styles.p_password}>{t('login.password')}:</p>
                        <div className={styles.div_password(errorStates)}>
                            <label className={styles.label_password}>{t('login.enterPass')}</label>
                            <div onClick={() => {refPassword.current?.focus(); }} className='flex flex-row items-center justify-between mt-[6px]'>
                                <input 
                                    ref={refPassword}
                                    className={styles.input_password(errorStates)}
                                    type={showPass? 'text': 'password'}
                                    autoComplete={'on'}
                                    value={password}
                                    onChange={(event) => {setPassword(event.target.value); setErrorStates({email: errorStates.email, password: false})}}
                                />
                                <button onClick={() => {setShowPass(!showPass); }}>
                                {
                                    showPass?
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23 11.5C23 11.5 18.6875 3.59375 11.5 3.59375C4.3125 3.59375 0 11.5 0 11.5C0 11.5 4.3125 19.4062 11.5 19.4062C18.6875 19.4062 23 11.5 23 11.5ZM1.68619 11.5C2.38124 10.4436 3.18067 9.45974 4.07244 8.56319C5.9225 6.71025 8.4525 5.03125 11.5 5.03125C14.5475 5.03125 17.0761 6.71025 18.929 8.56319C19.8208 9.45974 20.6202 10.4436 21.3153 11.5C21.2319 11.6251 21.1399 11.7631 21.0349 11.914C20.5534 12.604 19.8418 13.524 18.929 14.4368C17.0761 16.2898 14.5461 17.9688 11.5 17.9688C8.4525 17.9688 5.92394 16.2898 4.071 14.4368C3.17924 13.5403 2.37981 12.5564 1.68475 11.5H1.68619Z" fill="#3A7DFF"/>
                                            <path d="M11.5 7.90625C10.5469 7.90625 9.63279 8.28488 8.95884 8.95884C8.28488 9.63279 7.90625 10.5469 7.90625 11.5C7.90625 12.4531 8.28488 13.3672 8.95884 14.0412C9.63279 14.7151 10.5469 15.0938 11.5 15.0938C12.4531 15.0938 13.3672 14.7151 14.0412 14.0412C14.7151 13.3672 15.0938 12.4531 15.0938 11.5C15.0938 10.5469 14.7151 9.63279 14.0412 8.95884C13.3672 8.28488 12.4531 7.90625 11.5 7.90625ZM6.46875 11.5C6.46875 10.1656 6.99883 8.88591 7.94237 7.94237C8.88591 6.99883 10.1656 6.46875 11.5 6.46875C12.8344 6.46875 14.1141 6.99883 15.0576 7.94237C16.0012 8.88591 16.5312 10.1656 16.5312 11.5C16.5312 12.8344 16.0012 14.1141 15.0576 15.0576C14.1141 16.0012 12.8344 16.5312 11.5 16.5312C10.1656 16.5312 8.88591 16.0012 7.94237 15.0576C6.99883 14.1141 6.46875 12.8344 6.46875 11.5Z" fill="#3A7DFF"/>
                                        </svg>:
                                        <img src={viewPass} alt="viewPass" />
                                }
                                </button>
                            </div>
                            
                        </div>
                        <p className={styles.p_mistake_email_password(errorStates)}>{t('login.phoneMistake')}</p>
                        <div className='grid grid-cols-2 mt-[20px]'>
                            <div className='flex '>
                                <Checkbox 
                                    value={savePassword}
                                    onChange={(value) => setSavePassword(value)}
                                />
                                <p className='col-span-1 text-14 ml-[10px] font-regular'>{t('login.rememberMe')}</p> 
                            </div>
                            <p 
                                className='text-end text-blue text-14 font-regular cursor-pointer'
                                onClick={() => { navigate('/recovery'); }}    
                            >{t('login.forgot')}</p>
                        </div>
                    </div>
                    <div className={styles.divLogin} >
                        <button
                            className={styles.buttonLogin}
                            onClick={async () => {
                                if(email.match(/\S+@\S+\.\S+/) && password.length >= 6) {
                                    setLoading(true);
                                    setErrorStates({
                                        email: false,
                                        password: false
                                    });
                                    try {
                                        const response = await request({
                                            method: "POST",
                                            url: '/api/auth/login',
                                            token: undefined,
                                            body: {
                                                email: email,
                                                password: password
                                            }
                                        });
                                        console.log('response', response);
                                        if(response.status === 200) {
                                            dispatch(onSignInSuccess(response.data.token));
                                            dispatch(setUser({
                                                email: email
                                            }));
                                            if(savePassword) {
                                                dispatch(saveEmailPass({email: email, password: password}))
                                            } else {
                                                dispatch(saveEmailPass({email: '', password: ''}))
                                            }
                                            setLoading(false);
                                            navigate('/');
                                        } else {
                                            if(response.data.message === 'Неверный email или пароль') {
                                                setErrorStates({
                                                    email: true,
                                                    password: true
                                                })
                                            }
                                        }
                                    } catch(e) {
                                        console.log('e', e);
                                    } finally {
                                        setLoading(false);
                                    };
                                } else {
                                    if(password.length >= 6) {
                                        setErrorStates({
                                            email: true,
                                            password: false
                                        });
                                    } else {
                                        setErrorStates({
                                            email: true,
                                            password: true
                                        });
                                    };
                                };
                            }}
                        >{
                            loading?
                                <Loading 
                                    width="w-[20px]"
                                    height="h-[20px]"
                                    fill="fill-blue"
                                    text="text-white"
                                />:
                                'Войти'
                        }</button>
                    </div>
                    <div className={styles.divNotAccount}>
                        <p className={styles.pNotAccount}>{t('login.notAccount')}</p>
                        <p onClick={() => {navigate('/register');}} className={styles.pRegister}>{t('login.register')}</p>
                    </div>
                </div>
            </div>
            <div className={styles.divBottomNull}></div>
        </div>
    );
};

const styles = {
    main: 'grid grid-cols-1 laptop:grid-cols-3 content-start laptop:content-center',
    divTopNull: 'col-span-0',
    divBottomNull: 'col-span-0',

    divLogin: 'grid grid-cols-1 mt-[50px]',
    buttonLogin: 'bg-blue h-[54px] ml-0 laptop:ml-20 mr-0 laptop:mr-20 text-white font-medium rounded-[19px] flex flex-col items-center justify-center',
    divNotAccount: 'flex flex-row mt-[30px]',
    pNotAccount: 'text-14 font-regular',
    pRegister: 'ml-[10px] text-14 font-regular text-blue cursor-pointer',
    divCenter: 'grid content-start laptop:content-center col-span-1',
    divCenter1: 'grid content-start bg-white pl-0 mobile:pl-10 pr-0 mobile:pr-10',
    p_auth: 'text-16 laptop:text-35 text-center font-medium mt-[57px] laptop:mt-[0px]',
    p_login_descr: 'text-12 laptop:text-14 mt-[16px] text-center font-thin',
    p_login: 'text-14 text-black font-medium invisible laptop:visible',
    div_email: (errorStates) => `grid border-[1px] pl-10 pr-10 pt-[12px] pb-[12px] rounded-[19px] mt-[10px] ${errorStates.email? 'border-red': 'border-blue'} shadow-[0_4px_15px_-0px_rgba(26,132,255,0.18)] ${errorStates.email? 'bg-red2': 'bg-white-input'}`,
    label_email: 'text-blue text-10 laptop:text-12 font-regular',
    input_email: (errorStates) => `grid mt-[6px] text-14 laptop:text-16 text-black focus:outline-none ${errorStates.email? 'bg-red2-input': 'bg-white-input'}`,
    p_password: 'mt-0 laptop:mt-[20px] font-medium text-black text-14 invisible laptop:visible',
    div_password: (errorStates) => `grid border-[1px] pl-10 pr-10 pt-[12px] pb-[12px] rounded-[19px] mt-[10px] ${errorStates.password? 'border-red': 'border-blue'} shadow-[0_4px_15px_-0px_rgba(26,132,255,0.18)] ${errorStates.password? 'bg-red2': 'bg-white-input'}`,
    label_password: 'text-blue text-10 laptop:text-12 font-regular',
    input_password: (errorStates) => `grid text-14 laptop:text-16 text-black w-full focus:outline-none ${errorStates.password? 'bg-red2-input': 'bg-white-input'}`,
    p_mistake_email_password: (errorStates) => `text-12 laptop:text-14 mt-[20px] text-start laptop:text-center text-red ${Object.values(errorStates).reduce((accumulator, currentValue) => {if(currentValue === true) {return accumulator + 1} else {return accumulator}}, 0) > 0? 'visible': 'invisible'}`
}