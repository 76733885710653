import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { Navigation } from './components/Header-main';
import { MainRoutes } from './Routes';
import { Footer } from './components/Footer';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, {persistor} from './redux/store';

function App() {
  const [lang, setLang] = React.useState(true);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className='flex flex-col justify-between'>
          <Router>
            <Navigation lang={lang} setLang={setLang} />
            <MainRoutes />
            <Footer className='' />
          </Router>
        </div>
      </PersistGate>
    </Provider>
    
  );
}

export default App;