import { createSlice} from '@reduxjs/toolkit';
import { GetData } from '../../requests/get';

const initialState = {
    cities: [],

};

export const citiesSlice = createSlice({
    name: 'cities',
    initialState,
    reducers: {
        setCitiesRedux: (state, action) => {
            state.cities = action.payload;
        },
        getCities: (state, action) => {
            GetData(`/api/cities`, null, (json, statusCode) => {
                if(statusCode === 200) {
                    state.cities = json.data;
                }
            }, (e) => {
                console.log('e', e);
            })
        }
    }
});


export const { 
    setCitiesRedux,
    getCities
} = citiesSlice.actions;

export default citiesSlice.reducer;