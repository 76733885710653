import React from "react";
import { Accordion } from "react-accessible-accordion";
import { FaqsComponent } from "../../components/FaqsComponent";
import { useTranslation } from 'react-i18next';

export const GoPsychotherapy = () => {
    const { t } = useTranslation();
    return (
        <div>
            
            <title>{t('headerBurger.teraphy')}</title>
            <div className='container mx-auto items-center content-center desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]'>
                <div className="flex flex-row">
                    <div className="text-blue mobile:text-16 desktop:text-[50px] font-medium">{t('headerBurger.teraphy').split(' ')[0]}</div>
                    <div className="mobile:text-16 desktop:text-[50px] font-medium desktop:ml-[13px] mobile:ml-[5px]">{t('headerBurger.teraphy').substring(t('headerBurger.teraphy').indexOf(' '))}</div>
                </div>
            </div>
            <div className='container mx-auto items-center content-center desktop:mt-[130px] laptop:mt-[110px] tablet:mt-[80px] mobile:mt-[45px]'>
                <div className="desktop:w-3/5 laptop:w-3/4 tablet:w-full mobile:w-full">
                    <Accordion
                        allowZeroExpanded={true}
                        allowMultipleExpanded={true}>
                        <FaqsComponent
                            num='1'
                            title={t('psychoteraphy.psyAnalytics')}
                            text='Психоанализ и психоаналитический подход сегодня - это объяснение поведения человека психологическими причинами в нем самом, которые он сам не осознает. Понятно, что сегодня это огромное число специалистов, психологов-практиков используют подобные объяснения.'
                        />
                        <FaqsComponent
                            num='2'
                            title={t('psychoteraphy.transactAnalys')}
                            text='Продолжительность одного занятия 50 минут. Так же, при необходимости вы можете согласовать сдвоенные занятия со своим психологом.'
                        />
                        <FaqsComponent
                            num='3'
                            title={t('psychoteraphy.transPersonal')}
                            text='Продолжительность одного занятия 50 минут. Так же, при необходимости вы можете согласовать сдвоенные занятия со своим психологом.'
                        />
                        <FaqsComponent
                            num='4'
                            title={t('psychoteraphy.geshtalt')}
                            text='Продолжительность одного занятия 50 минут. Так же, при необходимости вы можете согласовать сдвоенные занятия со своим психологом.'
                        />
                        <FaqsComponent
                            num='5'
                            title={t('psychoteraphy.art')}
                            text='Продолжительность одного занятия 50 минут. Так же, при необходимости вы можете согласовать сдвоенные занятия со своим психологом.'
                        />
                        <FaqsComponent
                            num='6'
                            title={t('psychoteraphy.psychosintez')}
                            text='Психосинтез - подход в психологии: феноменологическая парадигма, смесь психоаналитического и трансперсонального направления. В психосинтезе основные проблемы взрослого поведения не только объясняются событиями и переживаниями детства (как в психоанализе), но и рассматриваются как возможность самоосознания и фундамент для обретения психологической целостности.'
                        />
                        <FaqsComponent
                            num='7'
                            title={t('psychoteraphy.Kholodinamyc')}
                            text='Продолжительность одного занятия 50 минут. Так же, при необходимости вы можете согласовать сдвоенные занятия со своим психологом.'
                        />
                    </Accordion>
                </div>
            </div>
        </div>
    )
}