import React from "react";
import {useTranslation} from 'react-i18next';

export const Requisites  = () => {
    const {t} = useTranslation();

    return(
        <div className="desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]">
            
            <title>{t('requisites.title')}</title>
            
            <div className='container mx-auto items-center content-center '>
                <div className="flex flex-row">
                    <div className="text-blue mobile:text-16 tablet:text-16 laptop:text-[50px] desktop:text-[50px] font-medium">{t('requisites.title').split(' ')[0]}</div>
                    <div className="mobile:text-16 desktop:text-[50px] laptop:text-[50px] tablet:text-16 font-medium desktop:ml-[13px] mobile:ml-[5px]">{t('requisites.title').substring(t('requisites.title').indexOf(' '))}</div>
                </div>
                <div className="desktop:mt-[100px] laptop:mt-[60px] tablet:mt-[40px] mobile:mt-[20px]">
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 "></p>
                </div>
                
                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">Полное название организации</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">УФК Минфин РС (Я) Государственное бюджетное учреждение Республики Саха (Якутия) «Центр социально психологической поддержки семьи и молодежи»</p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">Адрес</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">677000 г. Якутск ул. Курашова 36</p>
                </div>
                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">Тел./факс </h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">8(4112)31-91-09</p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">ИНН</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">1435114502</p>
                </div>
            
                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">КПП</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">143501001</p>
                </div>
                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">Корреспондентский счет</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">40102810345370000085</p>
                </div>
                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">Расчетный счет</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">03224643980000001601</p>
                </div>
                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">Лицевой счет</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">20080035649</p>
                </div>
                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">БИК</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">019805001</p>
                </div>
                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">Банк</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">ОТДЕЛЕНИЕ-НБ РЕСПУБЛИКА САХА (ЯКУТИЯ) БАНКА РОССИИ//УФК по Республике Саха (Якутия) г.Якутск.</p>
                </div>
                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">Директор </h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">Скрябин Владимир Борисович, действующий на основании Устава</p>
                </div>
            </div>
        </div>
    )
}