import React from 'react';

export const ManagementComponent = (props) => {
    
    const { name, position, avatar } = props;
    return (
        <div
            className=' py-[48px] rounded-[19px] bg-white border-[1px] border-blue/10 drop-shadow-[0px_0px_80px_rgba(158,158,158,0.08)] flex flex-col items-center'>
            <div className='bg-white drop-shadow-[0px_6px_19px_rgba(105,105,105,0.25)] mt-[15px] rounded-full desktop:rounded-full desktop:mt-[60px] w-[160px] h-[160px] mb-[20px] items-center flex justify-center'>
                <img className='rounded-full desktop:rounded-full w-[144px] h-[144px] object-cover' alt='image1' src={avatar} />
            </div>

            <div className='mb-[15px] desktop:text-[30px] laptop:text-[30px] tablet:text-[14px] mobile:text-[14px] font-medium leading-[35px] text-center'>{name}</div>
            <span className='text-[20px] text-center'>{position}</span>

        </div>
    )
}
