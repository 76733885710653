import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    records: []
};

export const recordsSlice = createSlice({
    name: 'records',
    initialState,
    reducers: {
        setRecord: (state, action) => {
            state.records = action.payload
        },
        addItemToRecord: (state, action) => {
            if(!state.records.map((item, index) => item.id).includes(action.payload.id)) {
                state.records.push(action.payload.data);
            }
        },
        removeItemFromRecord: (state, action) => {
            if(state.records.map((item, index) => item.id).includes(action.payload)) {
                state.records = state.records.filter((item, index) => item.id !== action.payload);
            }
        }
    }
});

export const { 
    setRecord, addItemToRecord, removeItemFromRecord
} = recordsSlice.actions;

export default recordsSlice.reducer;