import React from "react";
import { Accordion } from "react-accessible-accordion";
import { FaqsComponent } from "../../components/FaqsComponent";

import { useNavigate } from "react-router-dom";
import {useTranslation} from 'react-i18next';


export const Collaboration = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    return(
        <div className="desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]">
            <title>Портал психологов - Сотрудничество</title>
            <div className='container mx-auto items-center content-center '>
                <div className="flex flex-row">
                    <div className="text-blue mobile:text-16 tablet:text-16 laptop:text-[50px] desktop:text-[50px] font-medium"></div>
                    <div className="mobile:text-16 desktop:text-[50px] laptop:text-[50px] tablet:text-16 font-medium">Сотрудничество</div>
                </div>
            </div>
            <div className="container mx-auto rounded-[19px] grid desktop:grid-cols-2 mobile:grid-cols-1 bg-blue  desktop:mt-[100px] mobile:mt-[35px]">
                <div className="flex flex-col columns-1 desktop:pt-[100px] mobile:pt-[40px] desktop:pb-[60px] mobile:pb-[16px] mobile:pl-[20px] desktop:pl-[120px] items-start">
                    <div className="text-white mobile:text-[16px] desktop:text-[58px] font-medium desktop:leading-[64px] mobile:leading-tight">
                    {t('aboutUs.choose100')} 
                    </div>
                    <div className="text-white mt-[20px] desktop:text-[22px] font-medium mobile:text-[10px] desktop:leading-[28px] mobile:leading-tight">
                    {t('aboutUs.weHappy')}
                    </div>
                    <button
                        className="text-blue font-medium bg-white rounded-[19px] py-[15.5px] px-[82px] mt-[40px]"
                        onClick={() => {
                            navigate('/')
                        }}>{t('writePsy.form')}</button>
                </div>
                <div className="mobile:hidden desktop:flex bg-[50%] bg-no-repeat bg-aboutusBg"></div>
            </div>
            
            <div className='container mx-auto items-center content-center desktop:mt-[100px] laptop:mt-[80px] tablet:mt-[60px] mobile:mt-[45px]'>
                <div className="desktop:w-3/5 laptop:w-3/4 tablet:w-full mobile:w-full">
                    <Accordion 
                        allowZeroExpanded={true}
                        allowMultipleExpanded={true}>
                        <FaqsComponent
                            num='01'
                            title='Список требований к специалисту'
                            text='Продолжительность одного занятия 50 минут. Так же, при необходимости вы можете согласовать сдвоенные занятия со своим психологом.'
                        />
                        <FaqsComponent
                            num='02'
                            title='Мини-инструкция как начать сотрудничать'
                            text='Продолжительность одного занятия 50 минут. Так же, при необходимости вы можете согласовать сдвоенные занятия со своим психологом.'
                        />
                        <FaqsComponent
                            num='03'
                            title='Контактная информация для связи'
                            text='Продолжительность одного занятия 50 минут. Так же, при необходимости вы можете согласовать сдвоенные занятия со своим психологом.'
                        />
                </Accordion>
                </div>
            </div>
        </div>
    )
}