import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import { Loading } from '../../components/Loading';
// import { PostData } from '../../requests/post';
import {request} from '../../requests/requests';

export const Recovery = () => {
    const [email, setEmail] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [errorState, setErrorState] = React.useState(false);
    const {t} = useTranslation();
    const ref = React.useRef(null);
    const navigate = useNavigate();

    return(
        <div className={styles.main}>
            <title>{t('recovery.title')}</title>
            <div className='col-span-0'></div>
            <div className={styles.gridDivCenter}>
                <p className={styles.title1}>{t('recovery.title')}</p>
                <p className={styles.title2}>{t('recovery.descr')}</p>
                <div 
                    onClick={() => { ref.current.focus(); }} 
                    className={styles.divEmail(errorState)}
                >
                    <label className={styles.inputLabel}>{t('recovery.email')}</label>
                    <input 
                        ref={ref}
                        className={styles.input(errorState)}
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                            setErrorState(false);
                        }}
                    />
                </div>
                <button
                    disabled={!email.match(/\S+@\S+\.\S+/)}
                    className={styles.btnSend(email)}
                    onClick={async () => {
                        if(email.match(/\S+@\S+\.\S+/)) {
                            try {
                                setLoading(true);
                                let response = await request({
                                    method: 'POST',
                                    url: '/api/auth/forgot-password',
                                    token: null,
                                    body: {
                                        email: email
                                    }
                                });
                                console.log('response', response);
                                if(response.status === 200 && response.data.status === 'We have emailed your password reset link!') {
                                    setLoading(false);
                                    alert('recovery.alert');
                                    navigate('/login');
                                } else {
                                    setLoading(false);
                                }
                                // PostData(`/api/auth/forgot-password`, null, JSON.stringify({
                                //     email: email
                                // }), (json, statusCode) => {
                                //     if(statusCode === 200) {
                                //         if(json.status === "We have emailed your password reset link!") {
                                //             setLoading(false);
                                //             alert(t('recovery.alert'));
                                //             navigate('/login');
                                //         } else {
                                //             setLoading(false);
                                //         };
                                //     } else {
                                //         setLoading(false);
                                //     };
                                // }, (e) => {
                                //     console.log('e forgot-password', e);
                                //     setLoading(false);
                                // });
                            } catch(e) {
                                console.log('e', e);
                            } finally {
                                setLoading(false);
                            }
                        } else {
                            setErrorState(true);
                        };
                    }}
                >{ loading? <Loading />:t('recovery.send') }</button>
            </div>
            <div className='col-span-0'></div>
        </div>
    );    
};

const styles = {
    main: "container mx-auto grid grid-cols-1 laptop:grid-cols-3",
    gridDivCenter: 'grid col-span-1 content-start laptop:content-center',
    title1: 'text-16 laptop:text-[24px] desktop:text-[35px] text-center font-medium mt-[57px] laptop:mt-[90px]',
    title2: 'text-12 laptop:text-14 text-center mt-[17px] desktop:mt-[15px]',
    divEmail: (errorState) => `grid border-[1px]  pl-[53px] pr-[53px] rounded-[19px] mt-[40px] laptop:mt-[44px] ${errorState? 'border-red': 'border-blue'} shadow-[0_4px_15px_-0px_rgba(26,132,255,0.18)] ${errorState? 'bg-red2': 'bg-white-input'}`,
    inputLabel: 'text-[10px] laptop:text-[12px] mt-[12px] font-regular text-blue ',
    input: (errorState) => `text-14 desktop:text-16 mb-[12px] font-regular focus:outline-none ${errorState? 'bg-red2-input': 'bg-white-input'}`,
    btnSend: (email) => `${email.match(/\S+@\S+\.\S+/)? 'bg-blue': 'bg-grey'} h-[60px] mt-[20px] rounded-[19px] text-white font-medium text-16 flex flex-col items-center justify-center`
}