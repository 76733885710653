import * as React from 'react';
import { PsycologyItem } from '../../components/PsycologyItem';
import { DropDown } from '../../components/DropDown';
import { useNavigate } from 'react-router-dom';
import { 
    typesData,
    genderData,
    WhoData
} from '../../configs/data';
import { useSelector, useDispatch } from 'react-redux';
import { Search } from '../../components/Search';
import { groupFunction, groupFunctionRevert, sessionTypeFunction, sessionTypeRevert, sexFunction, sexFunctionRevert } from '../../configs/functions';
import { Loading } from '../../components/Loading';
import {useTranslation} from 'react-i18next';
import { request } from '../../requests/requests';
import { CustomListbox } from '../../components/Listbox';

const getMonths = () => {
    let arr =[];
    for(let i = 0; i < 12; i++) {
        arr.push({
            name: new Date(new Date().getFullYear(), i).toLocaleString('default', {month: 'long'}),
            number: new Date(new Date().getFullYear(), i).getMonth()
        });
    };

    return arr;
}

const getDays = () => {
    let arr = [];
    for(let i = 1; i <= 31; i++) {
        arr.push({
            name: i.toString(),
            value: i
        });
    };

    return arr;
};

export const PsychologiesClient = () => {
    const filterPsy = useSelector(state => state.filter.psycologies);
    const [search, setSearch] = React.useState('');
    
    const token = useSelector(state => state.auth.session.token);
    const favorite = useSelector(state => state.favorite.favorite);
    const citiesRedux = useSelector(state => state.cities.cities);
    const dispatch = useDispatch();

    const worriesRedux = useSelector(state => state.worries.worries);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const repeatRef = React.useRef(false);

    const [city, setCity] = React.useState({name: filterPsy.city, id: filterPsy.cityId});
    const [worry, setWorry] = React.useState({name: filterPsy.worry, id: filterPsy.worryId});
    const [sessionType, setSessionType] = React.useState(filterPsy.type!== ''? filterPsy.type: 'Тип/вид' );
    const [sex, setSex] = React.useState(filterPsy.sex !== ''? sexFunction(filterPsy.sex): 'Пол');
    const [group, setGroup] = React.useState(filterPsy.group !== ''? groupFunctionRevert(filterPsy.group): 'Я хочу прийти');

    const [chooseDate, setChooseDate] = React.useState({
        month: filterPsy.date !== null? filterPsy.date?.toLocaleString('ru-RU', {month: 'long'}) : "Не важно", // 'Месяц',
        day: filterPsy.date !== null? filterPsy.date?.getDate() : "Не важно" // 'День'
    });

    const [minPrice, setMinPrice] = React.useState(filterPsy.minPrice);
    const [maxPrice, setMaxPrice] = React.useState(filterPsy.maxPrice);

    const {t} = useTranslation();
    const navigate = useNavigate();

    React.useEffect(() => {
        // if(repeatRef.current === false) {
            (async function() {
                try {
                    setLoading(true);
                    let response = await request({
                        method: 'GET',
                        url: `/api/users?role=psych${city.id !== 0? `&city_id=`+city.id: ''}${minPrice !== ''? '&min_price='+minPrice:''}${maxPrice !== ''? '&max_price='+maxPrice:''}${worry.id !== 0? `&worry_id=`+worry.id:''}${sexFunctionRevert(sex) !== ''? `&sex=`+sexFunctionRevert(sex): ''}${group !== '' && group !== 'Я хочу прийти'? `&group=`+group: ''}${sessionType !== '' && sessionType !== 'Тип/вид'?  `&session_type=`+sessionType: ''}&query=${search}`,
                        token: token,
                        body: undefined
                    });
                    console.log('response', response);
                    if(response.status === 200) {
                        setData(response.data.data);
                    }
                } catch(e) { } finally {
                    setLoading(false);
                }
            })()
        // }
        // return () => repeatRef.current = true;
    }, [city.id, minPrice, maxPrice, chooseDate.day, chooseDate.month, sexFunctionRevert(sex), group, repeatRef.current, search]);

    return(
        <div className={styles.main}>
            <title>{t('listPsy.title')}</title>
            <div className={styles.divTitle}>
                <p className={styles.titleBlue}>{t('listPsy.title').split(' ')[0]}</p>
                <p className={styles.titleBlack}>{t('listPsy.title').substring(t('listPsy.title').indexOf(' '))}</p>
            </div>
            {
                token?
                <>
                    <Search 
                        search={search}
                        setSearch={setSearch}
                    />
                    <div className='invisible-scrollbar'>
                      
                    <div className={styles.divSort1() + ""} >
                        <DropDown 
                            value={city.name !== ''? city.name: t('listPsy.location')} 
                            data={[{id:0, name: 'Не важно'}].concat(citiesRedux)} 
                            placeholder={t('listPsy.location')} 
                            onChange={(value) => {repeatRef.current = false; setCity({name: value.name, id: value.id}); }} 
                            styleButton={['text-black', 'text-12', 'laptop:text-16', 'font-regular', 'whitespace-nowrap', 'mr-[20px]']} 
                            styleMenuItem={['font-regular ', 'text-12 laptop:text-16', 'font-medium', 'whitespace-nowrap']} 
                            styleMenu={['mr-[25px]']}
                        />
                        <DropDown 
                            value={worry.name !== ''? worry.name: t('listPsy.what')} 
                            data={[{id: 0, title: 'Не важно'}].concat(worriesRedux)} 
                            placeholder="Что вас беспокоит" 
                            onChange={(value) => {repeatRef.current = false; setWorry({name: value.title, id: value.id}); }} 
                            styleButton={['text-black', 'text-[12px]', 'laptop:text-[16px]', 'font-regular', 'whitespace-nowrap', 'mr-[20px]']} 
                            styleMenuItem={['font-regular ', 'text-[12px] laptop:text-[16px]', 'font-medium', 'whitespace-nowrap']}   
                            styleMenu={['mr-[25px]']}  
                            right
                        />
                        <DropDown 
                            value={sessionTypeRevert(sessionType) !== ''? sessionTypeRevert(sessionType): t('listPsy.vidSeans')} 
                            data={[{name: "Не важно"}].concat(typesData)} 
                            placeholder={t('listPsy.vidSeans')} 
                            onChange={(value) => { repeatRef.current = false; setSessionType(sessionTypeFunction(value.name)); }} 
                            styleButton={['text-black', 'text-[12px]', 'laptop:text-[16px]', 'font-regular', 'whitespace-nowrap', 'mr-[20px]']} 
                            styleMenuItem={['font-regular ', 'text-[12px] laptop:text-[16px]', 'font-medium', 'whitespace-nowrap']} 
                            styleMenu={['mr-[25px]']}
                            right
                        />
                        <DropDown 
                            value={group === 'Я хочу прийти'? t('listPsy.want'): groupFunctionRevert(group)} 
                            data={ [{name: "Не важно"}].concat(WhoData) } 
                            placeholder={t('listPsy.want')} 
                            onChange={(value) => { repeatRef.current = false; setGroup(groupFunction(value.name)); }} 
                            styleButton={['text-black', 'text-12', 'laptop:text-16', 'font-regular', 'whitespace-nowrap', 'mr-[20px]']} 
                            styleButtonMain={['font-regular']}
                            styleMenuItem={['font-regular ', 'text-12 laptop:text-16', 'font-medium', 'whitespace-nowrap']} 
                            styleMenu={['mr-[25px]']}
                            right
                        />
                        <DropDown 
                            value={sex === 'Пол'? t('listPsy.sex'): checkValidSex(sex)} 
                            data={genderData} 
                            placeholder={t('listPsy.sex')} 
                            onChange={(value) => { repeatRef.current = false; setSex(value.name); }} 
                            styleButton={['text-black', 'text-[12px]', 'laptop:text-[16px]', 'font-regular', 'whitespace-nowrap', 'mr-[20px]']} 
                            styleButtonMain={['text-black', 'text-[12px] laptop:text-[16px]', 'font-regular']}
                            styleMenuItem={['text-black', 'text-[12px] laptop:text-[16px]', 'font-medium', 'whitespace-nowrap']} 
                            right
                        />
                    </div>
                    </div>
                    <div className={styles.divSort2()} >
                        <div className={styles.divSort2_1}>
                            <p className={styles.sort2Label}>{t('listPsy.date')} </p>
                            <div className='flex flex-row mt-[15px]'>
                                <DropDown 
                                    value={chooseDate.day} 
                                    data={[{name: 'Не важно'}].concat(getDays())} 
                                    placeholder="День" 
                                    onChange={(value) => { repeatRef.current = false; setChooseDate({day: value.name, month: chooseDate.month}); }} 
                                    styleButton={['laptop:w-[162px]', 'w-[107px]', 'text-[12px] laptop:text-[16px]', 'font-regular']}
                                    styleMenu={['mr-[25px]']}
                                    styleMenuItem={['laptop:w-[162px]', 'w-full']}
                                    styleButtonMain={['text-[12px] laptop:text-[16px]', 'font-regular']}
                                />
                                <DropDown 
                                    value={chooseDate.month} 
                                    data={[{name: 'Не важно'}].concat(getMonths())} 
                                    placeholder="Месяц" 
                                    onChange={(value) => { repeatRef.current = false; setChooseDate({day: chooseDate.day, month: value.name}); }} 
                                    styleMenuItem={['laptop:w-[162px]', 'w-full']}
                                    styleButton={['laptop:w-[162px]', 'w-[107px]', 'text-[12px] laptop:text-[16px]', 'font-regular']}
                                    styleButtonMain={['text-[12px] laptop:text-[16px]', 'font-regular']}
                                    right
                                />
                            </div>
                        </div>
                        <div className={styles.divSort2_2}>
                            <p className={styles.sort2Label}>{t('listPsy.cost')}</p>
                            <div className='flex flex-row mt-[15px]'>
                                <input 
                                    className='border-blue border-[1px] min-w-[162px] mr-[10px] h-[30px] laptop:h-[48px] rounded-[11px] laptop:rounded-[19px] text-center text-[12px] laptop:text-[16px] font-regular focus:outline-none appearance-none' 
                                    inputMode='numeric'
                                    pattern='[0-9]'
                                    type={'number'}
                                    placeholder={t('writeCons.from')}
                                    value={minPrice}
                                    onChange={(event) => { setMinPrice(event.target.value); }}    
                                />
                                <input 
                                    className='border-blue border-[1px] min-w-[162px] h-[30px] laptop:h-[48px] rounded-[11px] laptop:rounded-[19px] text-center text-[12px] laptop:text-[16px] font-regular focus:outline-none'
                                    type={'number'}
                                    placeholder={t('writeCons.to')}
                                    value={maxPrice}
                                    onChange={(event) => { setMaxPrice(event.target.value); }}
                                /> 
                            </div>
                        </div>
                    </div>
                    <p className={styles.pInfo}>Отображение свободных психологов на указанную дату</p>
                    <div>
                    {
                        loading?
                            <div className='flex flex-col items-center justify-center h-[50px] laptop:h-[350px] bg-white'>
                                <Loading
                                    width="w-[40px]"
                                    height="h-[40px]"
                                    fill="fill-white"
                                    text="text-blue"
                                />
                            </div>:
                            data
                            .length === 0?
                                <div className={styles.divNullData}>
                                    <p className={styles.pNullData}>{t('listPsy.notFound')}</p>
                                </div>:
                                <div className={styles.divData}>
                                {
                                    data
                                        .map((item, index) => 
                                            <PsycologyItem 
                                                key={index.toString()}
                                                item={item}
                                                index={index}
                                                navigate={navigate}
                                                favorite={favorite}
                                                dispatch={dispatch}
                                                cities={citiesRedux}
                                                token={token}
                                                updateList={() => {}}
                                            />
                                    )
                                }
                                </div>
                    }
                    </div>
                </>
                :
                <>
                <div >
                    <h4 className='mt-[30px] desktop:leading-[36px] laptop:leading-[24px] tablet:leading-[16px] mobile:leading-[16px] mobile:text-[16px] tablet:text-[18px] laptop:text-[20px] desktop:text-[26px] desktop:pb-[50px] mobile:pb-[15px] font-regular'>
                        {t('listPsy.token')} 
                    </h4>
                    <div className="flex flex-wrap gap-4 mt-[30px]">
                        <button 
                            onClick={()=>{navigate('/login')}}
                            className="bg-blue rounded-[19px] h-[45px] text-white hover:bg-white hover:text-blue hover:border-blue hover:border-[1px] font-medium text-16 laptop:w-[189px] mobile:hidden laptop:flex justify-center items-center"
                        >{t('login.sign')}</button>
                        <button 
                            onClick={()=>{navigate('/register')}}
                            className="bg-white rounded-[19px] border-blue border-[1px] hover:bg-blue hover:text-white h-[45px] text-blue font-medium text-16 laptop:w-[189px] mobile:hidden laptop:flex justify-center items-center"
                        >{t('register.title')}</button>
                        
                    </div>
                </div>
                </>
            }
         
        </div>
    );
};

const styles = {
    main: 'container mx-auto flex flex-col flex-1 relative',
    divTitle: 'flex flex-row',
    titleBlue: 'text-16 laptop:text-39 desktop:text-50 font-medium text-blue mt-[23px] desktop:mt-[90px]',
    titleBlack: 'text-black text-16 laptop:text-39 font-medium desktop:text-50 ml-[10px] mr-[10px] mt-[23px] desktop:mt-[90px]',
    divSearch: 'bg-white-input laptop:bg-white flex flex-row items-center mt-[11px] laptop:mt-[65px] h-[43px] laptop:h-[78px] rounded-[11px] laptop:rounded-[0px]',
    divIconSearch: 'flex laptop:hidden mx-[17px]',
    search: 'flex-1 flex bg-white-input flex-row items-center border-white-input laptop:border-blue border-[1px] text-14 laptop:text-16 text-black font-regular focus:outline-none laptop:h-[78px] laptop:px-[53px] laptop:rounded-[19px]',
    buttonSearch: 'hidden laptop:flex w-[78px] h-[78px] items-center justify-center rounded-[20px] bg-blue ml-[10px]',
    divSort1: () => {return `invisible-scrollbar static overflow-scroll overscroll-none flex mt-[29px] laptop:mt-[60px] border-b-grey-divide border-b-[1px] pb-[30px]`},
    divSort2: () => {return `${'overflow-x-scroll'} invisible-scrollbar flex row border-b-[1px] desktop:border-b-0 border-b-white`},
    divSort2_1: 'pr-[33px] pt-[14px] pb-[14px]',
    sort2Label: 'text-12 desktop:text-16 font-regular text-black',
    divSort2_2: 'border-l-[1px] pt-[14px] pb-[14px] border-l-grey-divide pl-[33px] pr-[33px]',
    divSort2_3: 'border-l-[1px] pt-[14px] pb-[14px] border-l-grey-divide pl-[33px]',
    divNullData: 'flex flex-1 items-center justify-center mt-[120px]',
    pNullData: 'text-14 laptop:text-16 text-grey font-regular',
    divData: 'mt-[60px] grid grid-cols-1 desktop:grid-cols-3 laptop:grid-cols-2 gap-[10px] laptop:gap-[33px]',
    pInfo: 'invisible laptop:visible text-12 font-regular text-grey'
};

const checkValidSex = (sex) => {
    if(sex === 'Мужской' || sex === 'У мужчины') {
        return 'Мужской'
    } else {
        if(sex === 'Женский' || sex === 'У женщины') {
            return 'Женский'
        } else {
            return sex;
        };
    };
};