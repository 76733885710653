import React from "react";
// import { Link, useNavigate } from "react-router-dom";

export const Payment  = () => {
    return(
        <div className="desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]">
            
            <title>Портал психологов - Порядок оплаты</title>
            
            <div className='container mx-auto items-center content-center '>
                <div className="flex flex-row">
                    <div className="text-blue mobile:text-16 tablet:text-16 laptop:text-[50px] desktop:text-[50px] font-medium">Порядок</div>
                    <div className="mobile:text-16 desktop:text-[50px] laptop:text-[50px] tablet:text-16 font-medium desktop:ml-[13px] mobile:ml-[5px]">оплаты</div>
                </div>
                <div className="desktop:mt-[100px] laptop:mt-[60px] tablet:mt-[40px] mobile:mt-[20px]">
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        Покупая на psysakha.ru, вы можете выбрать любой из доступных способов оплаты или комбинировать их.
                        Оплачивайте заказы банковской картой или ЮMoney. Если вы оформляете заказ на сайте, вы можете выбрать оплату с помощью Системы быстрых платежей. Если на вашем Балансе средств есть доступные средства, вы можете платить с него. Покупая на psysakha.ru, вы можете выбрать любой из доступных способов оплаты или комбинировать их.
                        Оплачивайте заказы банковской картой или ЮMoney. Если вы оформляете заказ на сайте, вы можете выбрать оплату с помощью Системы быстрых платежей. Если на вашем Балансе средств есть доступные средства, вы можете платить с него.
                    </p>
                </div>
                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">Банковская карта</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        Чтобы оплатить заказ банковской картой:
                        При оформлении заказа в разделе Способ оплаты выберите сохранённую карту или нажмите Новой картой онлайн.
                        Нажмите Оплатить онлайн.
                        Если вы оплачиваете новой картой, введите данные карты.
                        Нажмите Оплатить.е заказы банковской картой или ЮMoney. Если вы оформляете заказ на сайте, вы можете выбрать оплату с помощью Системы быстрых платежей. Если на вашем Балансе средств есть доступные средства, вы можете платить с него.
                    </p>
                </div>
                {/* <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">Банковская карта</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        Чтобы оплатить заказ банковской картой:
                        При оформлении заказа в разделе Способ оплаты выберите сохранённую карту или нажмите Новой картой онлайн.
                        Нажмите Оплатить онлайн.
                        Если вы оплачиваете новой картой, введите данные карты.
                        Нажмите Оплатить.е заказы банковской картой или ЮMoney. Если вы оформляете заказ на сайте, вы можете выбрать оплату с помощью Системы быстрых платежей. Если на вашем Балансе средств есть доступные средства, вы можете платить с него.
                    </p>
                </div> */}
            </div>
        </div>
    )
}