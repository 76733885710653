export const groupFunction = (name) => {
    switch(name) {
        case 'Один': return 'alone';
        case 'С партнером': return 'with_partner';
        case 'С семьей': return 'with_family';
        case 'С группой': return 'group';
        default: return '';
    }
};

export const groupFunctionRevert = (name) => {
    switch(name) {
        case 'alone': return 'Один';
        case 'with_partner': return 'С партнером';
        case 'with_family': return 'С семьей';
        case 'group': return 'С группой';
        default: return 'Я хочу прийти';
    }
};

export const sessionTypeFunction = (name) => {
    if(name === 'Онлайн') {
        return 'online'
    } else {
        if(name === 'Оффлайн') {
            return 'offline';
        } else {
            return '';
        };
    };
};

export const sessionTypeRevert = (name) => {
    if(name === 'online') {
        return 'Онлайн';
    } else {
        if(name === 'offline') {
            return 'Оффлайн';
        } else {
            return '';
        };
    };
};

export const sexFunction = (name) => {
    if(name === 'FEMALE') {
        return 'У женщины';
    } else {
        if(name === 'MALE') {
            return 'У мужчины';
        } else {
            return ''
        }
    }
};

export const sexFunctionRevert = (name) => {
    if(name === 'У женщины' || name === 'Женский') {
        return 'FEMALE'
    } else {
        if(name === 'У мужчины' || name === 'Мужской') {
            return 'MALE'
        } else {
            return '';
        };
    };
};

export const isWorkingDay = (datesStr, date) => {
    let strDate = date !== null? 
        date.toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit'}):
        'null';
    const check = datesStr.map(item => new Date(item.date).toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'}));

    return check.includes(strDate);
};

export const isTimeEqual = (datesStr, time) => {
    return datesStr.map((item, index) => (item.from)).includes(time);
}

export const getTimeFromArray = (data, time) => {
    let state = null;
    data.forEach((item, index) => {
        if(item.from === time) {
            state = item;
        }
    });

    return state;
};

export const getWorkingDay = (datesStr, date) => {
    let find = null;

    let strDate = date !== null? 
        date.toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'}):
        'null';
    datesStr.forEach((item, index) => {
        if(new Date(item.date).toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'}) === strDate) {
            find = item;
        }
    });

    return find;
};

export const getTimeOfArray = (data, time) => {
    return false;
};

export const getIdDate = () => {
    
};

export const getPsychType = (type) => {
    switch(type) {
        case 'staff': return 'Штатный психолог';
        case 'independent': return 'Частный психолог';
        default: return '';
    };
};

export const getPsychTypeRevert = (type) => {
    switch(type) {
        case 'Штатный психолог': return 'staff';
        case "Частный психолог": return 'independent';
        default: return '';
    };
};

export const timeData = [
    '08:00', '09:00', '10:00', 
    '11:00', '12:00', '13:00', 
    '14:00', '15:00', '16:00', 
    '17:00', '18:00', '19:00',
    '20:00', '21:00'
];

export const getDuration = (from, to) => {
    let fromInt = parseInt(from[0] + from[1]) * 60 + parseInt(from[3] + from[4]);
    let toInt = parseInt(to[0] + to[1]) * 60 + parseInt(to[3] + to[4]);

    return (toInt - fromInt) + ' мин';
};

export const getPsychFullName = (data, user_id) => {
    if(data.length > 0) {
        let name = data.find(({id}) => id === user_id) !== undefined? data.find(({id}) => id === user_id).first_name? data.find(({id}) => id === user_id).first_name: '': ''; 
        let surname = data.find(({id}) => id === user_id) !== undefined? data.find(({id}) => id === user_id).last_name? ' ' + data.find(({id}) => id === user_id).last_name: '': '';
        return name + surname;
    } else {
        return 'not found';
    }
};