import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropDown } from '../../components/DropDown';
import { setUser, userLoggedOut } from '../../redux/auth/userSlice';
import { API } from '../../configs/api';
import { useNavigate } from 'react-router-dom';
import { onSignOutSuccess } from '../../redux/auth/sessionSlice';

import {useTranslation} from 'react-i18next';
import { request } from '../../requests/requests';

export const ProfileClient = () => {
    const citiesRedux = useSelector(state => state.cities.cities);
    const [city, setCity] = React.useState('');
    const [avatar, setAvatar] = React.useState('');
    const navigate = useNavigate();
    const {t} = useTranslation();

    let token = useSelector(state => state.auth.session.token);

    const dispatch  = useDispatch();

    const [errorStates, setErrorStates] = React.useState({
        name: false,
        surname: false,
        birthdate: false,
        city: false,
        email: false,
        phone: false
    });

    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [data, setData] = React.useState({
        last_name: '',
        first_name: '',
        birthdate: new Date(),
        city_id: '',
        email: '',
        avatar_url: '',
        phone: '',
        id: 0,
        email_verified_at: null
    })

    const RepeatFunc = React.useRef(false);

    React.useEffect(() => {
        if(RepeatFunc.current === false)
        
        {
            (async function() {
                if(token) {
                    try {
                        let response = await request({
                            method: 'GET',
                            url: '/api/users/me',
                            token: token,
                            body: undefined
                        });
                        if(response.status === 200) {
                            const bday = new Date(String(response.data.data.birthdate).replace('000000', '000'))
                                setData({
                                    first_name: response.data.data.first_name,
                                    last_name: response.data.data.last_name,
                                    city_id: response.data.data.city_id,
                                    email: response.data.data.email,
                                    avatar_url: response.data.data.avatar_url,
                                    phone: response.data.data.phone, 
                                    birthdate: `${bday.getFullYear()}-${bday.toLocaleDateString('ru-RU', {month: '2-digit'})}-${bday.toLocaleDateString('ru-RU', {day: '2-digit'})}`,
                                    session_types: response.data.data.session_types.map(item => item.session_type),
                                    id: response.data.data.id,
                                    role: response.data.data.role,
                                    notification_enabled: response.data.data.notification_enabled,
                                    sex: response.data.data.sex,
                                    email_verified_at: response.data.data.email_verified_at
                                }) 
                                setAvatar(response.data.data.avatar_url)
                                setCity(citiesRedux.find(city => city.id === response.data.data.city_id).name) 
                        }
                    } catch(e) {} finally {
                        setLoading(false);
                    }
                }
            })()
        }
        return () => {
            RepeatFunc.current = true;
        }
    }, [citiesRedux, token])

    return (
        <div>
            <title>{t('personalScreen.title')}</title>
            <h1 className='container mx-auto text-16 laptop:text-[39px]'>{t('personalScreen.title')}</h1>
            {
                loading? 
                <div className="flex flex-1 h-[50vh] justify-center items-center">
                    <div className="text-blue spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                        <span className="visually-hidden">Загрузка...</span>
                    </div>
                </div> : 
                <>                    
                    <div className='container mx-auto flex mobile:flex-col laptop:flex-row laptop:mt-[120px]'>
                        <div className="laptop:basis-1/3 laptop:px-[40px] desktop:px-[40px] desktop:basis-1/4">
                            <div className=" flex mobile:flex-row tablet:flex-row laptop:flex-col desktop:flex-col rounded-[19px] items-center mobile:border-[1px] tablet:border-[1px] laptop:border-0 desktop:border-0 border-blue-border p-[15px] gap-[10px]">
                                <div className="rounded-full bg-white p-1 flex flex-0 ">
                                    <img className='rounded-full desktop:rounded-full mobile:h-[120px] mobile:w-[120px] laptop:w-[194px] laptop:h-[194px] desktop:w-[244px] desktop:h-[244px] object-cover' src={ avatar ? avatar : require('../../assets/icons/nulled-photo.png')} alt='' name='file'/>
                                </div>
                                <input 
                                    type='file'
                                    name='img-file'
                                    id='asfaesdgqaergaga'
                                    title='Изменить фото'
                                    accept="image/gif, image/jpeg, image/png"
                                    onChange={(event)=> {
                                        const objectUrl = URL.createObjectURL(event.target.files[0])
                                        setAvatar(objectUrl)
                                        
                                        console.log(objectUrl)
                                    }}

                                    className="text-blue text-16 p-[15px] hidden"
                                    />
                                <button 
                                    className="text-blue text-16 p-[15px]"
                                    onClick={() => document.getElementById('asfaesdgqaergaga').click()}
                                >{t('personalScreen.editPhoto')}</button>
                            </div>
                            <button 
                                onClick={() => {navigate('/change-password');}}
                                className="h-[54px] w-full text-16 font-medium rounded-[19px] border border-blue mt-[10px] mobile:bg-blue mobile:text-white tablet:bg-blue tablet:text-white laptop:bg-white laptop:text-blue desktop:bg-white desktop:text-blue "
                                >
                                    {t('personalScreen.changePass')}
                            </button>
                            <button 
                                onClick={() => {
                                    dispatch(userLoggedOut());
                                    dispatch(onSignOutSuccess());
                                    navigate('/')
                                }}
                                className='text-16 font-medium hover:border hover:border-red rounded-[19px] bg-transparent text-red justify-center items-center h-[54px] w-full mt-[20px]'>
                                {t('personalScreen.quit')}
                            </button>
                        </div>
                        <div className='flex flex-col laptop:basis-2/3 desktop:basis-3/4 '>
                            {
                                data.email_verified_at?
                                <>
                                    <div className='container mx-auto flex mb-[15px]'>
                                        <h4 className='text-green'>
                                            {t('personalScreen.verification')}
                                        </h4>
                                    </div>
                                </>:
                                <>
                                    <div className='container mx-auto grid mb-[15px]'>
                                        <h4 className='text-red '>
                                            {t('personalScreen.no_verification')}
                                        </h4>
                                    </div>
                                </>
                            }
                            <div className='flex flex-1 laptop:flex-row mobile:flex-col laptop:gap-[40px] mobile:gap-0'>
                                <div className='flex flex-1 flex-col desktop:row-span-3'>
                                    <Form
                                        name={t('personalScreen.surname') + ':'}
                                        label={t('register.enterSurname')}
                                        value={data.last_name}
                                        maxLength={16}
                                        onChange={txt => setData({ ...data, last_name: txt })}
                                    />
                                    <Form
                                        name={t('personalScreen.name') + ':'}
                                        label={t('register.enterName')}
                                        value={data.first_name}
                                        maxLength={16}
                                        onChange={txt => setData({ ...data, first_name: txt })}
                                    />
                                    <div className='desktop:hidden laptop:block'>
                                        <Form
                                            
                                            type={'date'}
                                            name={t('personalScreen.bidthdate') + ':'}
                                            label={t('register.enterBirthdate')}
                                            value={data.birthdate}
                                            onChange={txt => setData({ ...data, birthdate: txt })}
                                        />
                                    </div>
                                    
                                </div>
                                <div className='flex-1 flex-col desktop:row-span-3 desktop:flex  laptop:hidden tablet:hidden mobile:hidden'>
                                    <Form
                                        type={'date'}
                                        name={t('personalScreen.bidthdate') + ':'}
                                        label={t('register.enterBirthdate')}
                                        value={data.birthdate}
                                        onChange={txt => setData({ ...data, birthdate: txt })}
                                    />
                                    <div>
                                        <p className='text-14 font-medium text-black'>{t('personalScreen.location')}:</p>
                                        
                                        <DropDownForm 
                                            label={t('personalScreen.location') + ':'}
                                            required 
                                            name="city"
                                            type="checkbox"
                                            value={city}
                                            onChange={(value) => {setCity(value.name); setErrorStates({...errorStates, city: false}); }}
                                            data={citiesRedux}
                                            errorStates={errorStates}
                                            setErrorStates={setErrorStates}
                                        />
                                    </div>
                                </div>
                                
                                <div className='flex flex-1 flex-col desktop:row-span-3'>
                                    <div className='desktop:hidden laptop:block'>
                                        <p className='text-14 font-medium text-black'>{t('personalScreen.location')}:</p>
                                        
                                        <DropDownForm 
                                            label={t('personalScreen.location') + ':'}
                                            required 
                                            name="city"
                                            type="checkbox"
                                            value={city}
                                            onChange={(value) => {setCity(value.name); setErrorStates({...errorStates, city: false}); }}
                                            data={citiesRedux}
                                            errorStates={errorStates}
                                            setErrorStates={setErrorStates}
                                        />
                                    </div>
                                    <Form
                                        name={t('personalScreen.email') + ':'}
                                        label={t('register.enterEmail')}
                                        value={data.email}
                                        onChange={txt => setData({ ...data, email: txt })}
                                    />
                                    <Form
                                        name={t('personalScreen.phone') + ':'}
                                        label={t('register.enterPhone')}
                                        value={data.phone}
                                        onChange={txt => setData({ ...data, phone: txt })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button 
                        onClick={async () =>{
                            setSaving(true)
                            const img = document.querySelector('input[name="img-file"]').files[0]
                            if (img instanceof File) {
                                const formData = new FormData();
                                formData.append('file', img);
                                fetch(API+'/api/storage', {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json'
                                    },
                                    body:formData
                                }).then(res => res.json())
                                .then(async json => {
                                    let response = await request({
                                        method: 'PUT',
                                        url: '/api/users/' + data.id,
                                        token: token,
                                        body: {
                                            ...data,
                                            birthdate: new Date(data.birthdate), 
                                            avatar_url:json.url
                                        }
                                    });
                                    setSaving(false);
                                    if(response.status === 200) {
                                        dispatch(setUser(response.data.data));
                                    } else {
                                        console.log('json save', response);
                                    }
                                }).catch((e)=>{
                                    console.log(e);
                                })
                            } else {
                                try {
                                    let response = await request({
                                        method: 'PUT',
                                        url: '/api/users/' + data.id,
                                        token: token,
                                        body: data
                                    })
                                    setSaving(false);
                                    if(response.status === 200) {
                                        dispatch(setUser(response.data.data));
                                    } else {
                                        console.log('json save', response);
                                    }
                                } catch(e) { }
                            }
                        }}
                        className="bg-blue rounded-[19px] flex justify-center items-center h-[52px] text-white disabled:bg-grey min-w-[289px] mobile:w-auto tablet:w-auto laptop:w-auto desktop:w-auto mx-auto mobile:mt-[30px] laptop:mt-[40px] "
                    >
                        {
                            saving? 
                            
                            <div className="flex justify-center items-center">
                                <div className="text-white spinner-border animate-spin inline-block w-6 h-6 border-3 rounded-full" role="status">
                                    <span className="visually-hidden">Загрузка...</span>
                                </div>
                            </div>
                            : 
                            t('personalScreen.save')
                        }
                    </button>
                </>
            }
        </div>
    );
}

const Form = ({ name, label, type, defaultValue, onChange, value, ...props}) => {
    const ref = React.useRef();
    return (
        <div className='mb-[30px]'>
            <p className='text-14 font-medium text-black'>{name}</p>
            <div className='grid grid-rows-2 bg-white-cards border-blue border-[1px] rounded-[19px] pt-[10px] pb-[7px] pl-[53px] pr-[10px] mt-[10px] h-[67px]' onClick={() => { ref.current?.focus() }}>
                <label className='text-12 text-pick text-blue font-regular'>{label}</label>
                <input
                    className='text-16 outline-none font-regular bg-white-cards'
                    type={type}
                    value={value}
                    {...props}
                    defaultValue={defaultValue}
                    ref={ref}
                    onChange={(e) => { if (typeof onChange === 'function') { onChange(e.currentTarget.value) } }}
                />
            </div>
        </div>
    );
}

const DropDownForm = (props) => {
    const {
        value, onChange, label2,
        data, errorStates, name
    } = props;
    
    return(
        <div className='mb-[30px]'>
            <div className={`${errorStates[name]? 'border-red': 'border-blue'} border-[1px] ${errorStates[name]?'bg-red2': 'bg-white-input'} rounded-[19px] mt-0 laptop:mt-[10px] h-[60px] laptop:h-[67px] flex flex-col justify-center pl-[53px] pr-[20px]`}>
                {label2? 
                    <p className='text-[10px] laptop:text-[12px] text-blue'>{label2}</p>:
                    <></>
                }
                <DropDown 
                    value={value}
                    data={data}
                    placeholder="Пол"
                    onChange={onChange}
                    styleButton={[errorStates[name]? 'bg-red2-input':'bg-white-input', 'w-full', 'text-start', 'mt-[0px]']}
                    styleButtonMain={['border-white', 'w-full', 'mt-[0px]']}
                    styleMenuItem={['w-full', 'text-14', 'font-medium', 'w-full']}
                    styleMenu={['justify-between', 'mt-[0px]']}
                />
            </div>
        </div>
    );
}