import * as React from 'react';
import { HoverMain } from '../../components/HoverMain';
import { useNavigate } from "react-router-dom";
import { ReviewComponent } from '../../components/Review';
import { Modal } from '../../components/Modal';

import "swiper/css";
import "swiper/css/pagination";
import { ReviewModal } from '../../components/ReviewModal';
import { useDispatch, useSelector } from 'react-redux';
import { setPsych } from '../../redux/psych/psychSlice';
import {useTranslation} from 'react-i18next';
import { request } from '../../requests/requests';

export const MainClient = () => {
    const [ModalVisibleCancel, setModalVisibleCancel] = React.useState(false);

    const token = useSelector(state => state.auth.session.token)
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [hoverActive, setHoverActive] = React.useState(false);
    const [modalValue, setModalValue] = React.useState({ avatar: null, author: '', text: '' });

    const [data, setData] = React.useState([])
    const [reviewPage, setReviewPage] = React.useState(1);

    const RepeatFunc = React.useRef(false);

    React.useEffect(() => {
        if(RepeatFunc.current === false) {
            (async function() {
                try {
                    let responseReviews = await request({
                        method: 'GET',
                        url: '/api/reviews',
                        token: null,
                        body: undefined
                    });
                    if(responseReviews.status === 200) {
                        setData(responseReviews.data.data);
                    }
                } catch(e) {}
                try {
                    let responsePsych = await request({
                        method: 'GET',
                        url: '/api/users?role=psych',
                        token: token,
                        body: undefined
                    });
                    if(responsePsych.status === 200) {
                        dispatch(setPsych(responsePsych.data.data));
                    }
                } catch(e) {}  
            })()
        }
        return () => RepeatFunc.current = true;
        // eslint-disable-next-line
    }, [])

    return (
        <div className="">
            <title>Портал психологов</title>

            <Modal 
                visible={ModalVisibleCancel}
                children={
                    <ReviewModal
                        close={() => { setModalVisibleCancel(false); }}
                        data={modalValue}
                    />
                }
                onClose={() => {setModalVisibleCancel(false); }}
            />

            <div id="carouselExampleCaptions" className='container mx-auto carousel slide relative' data-bs-ride="carousel">
                <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
                    <button
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                    ></button>
                    <button
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                    ></button>
                    <button
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                    ></button>
                    <button
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to="3"
                        aria-label="Slide 4"
                    ></button>
                </div>

                <div className=" carousel-inner relative w-full overflow-scroll invisible-scrollbar">
                    <div className="carousel-item active relative float-left w-full">
                        <img
                            src={require("../../assets/icons/images/new-banner.jpg")}
                            className="object-cover block w-full rounded-[19px] laptop:h-[500px] mobile:h-[335px]"
                            alt="..."
                        />
                        <div className="carousel-caption md:block absolute">
                            <h2 className="text-blue laptop:text-[24px] mobile:text-[16px]">Зарегистрируйся и получи бесплатную консультацию</h2>
                            {/* <p className='mobile:text-[10px] laptop:text-[17px]'>{t('main.weTake')}</p> */}
                            <button
                                onClick={()=>{navigate('/register')}}  
                                className='hover:bg-blue hover:text-white flex bg-white mobile:rounded-[11px] laptop:rounded-[19px] mobile:h-[35px] laptop:h-[51px] text-blue mobile:px-[36px] laptop:px-[60px] items-center mobile:mt-[22px] laptop:mt-[36px] font-medium whitespace-nowrap mobile:text-[12px] laptop:text-[16px]'
                            >Зарегистрироваться</button>
                        </div>
                    </div>
                    <div className="carousel-item relative float-left w-full">
                        <img
                            src={require("../../assets/icons/images/banner-1.png")}
                            className="object-cover block w-full rounded-[19px] laptop:h-[500px] mobile:h-[335px]"
                            alt="..."
                        />
                        <div className="carousel-caption md:block absolute">
                            <h2 className="laptop:text-[24px] mobile:text-[16px]">Группы поддержки матерей, супруг, близких людей участников сво</h2>
                            {/* <p className='mobile:text-[10px] laptop:text-[17px]'>{t('main.weTake')}</p> */}
                            <button
                                onClick={()=>{navigate('/psycologies')}}  
                                className='hover:bg-blue hover:text-white flex bg-white mobile:rounded-[11px] laptop:rounded-[19px] mobile:h-[35px] laptop:h-[51px] text-blue mobile:px-[36px] laptop:px-[60px] items-center mobile:mt-[22px] laptop:mt-[36px] font-medium whitespace-nowrap mobile:text-[12px] laptop:text-[16px]'
                            >{t('main.record')}</button>
                        </div>
                    </div>
                    <div className="carousel-item relative float-left w-full">
                        <img
                            src={require("../../assets/icons/images/banner-2.png")}
                            className="object-cover block w-full rounded-[19px] laptop:h-[500px] mobile:h-[335px]"
                            alt="..."
                        />
                        <div className="carousel-caption md:block absolute">
                            <h2 className="laptop:text-[24px] mobile:text-[16px]">Помощь при насилии над детьми 8-800-100-22-83 или 122 (далее 6 и 2)</h2>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href='tel:88001002283';
                                    }}  
                                className='hover:bg-blue hover:text-white flex bg-white mobile:rounded-[11px] laptop:rounded-[19px] mobile:h-[35px] laptop:h-[51px] text-blue mobile:px-[36px] laptop:px-[60px] items-center mobile:mt-[22px] laptop:mt-[36px] font-medium whitespace-nowrap mobile:text-[12px] laptop:text-[16px]'
                            >Позвонить</button>
                        </div>
                    </div>
                    <div className="carousel-item relative float-left w-full">
                        <img
                            src={require("../../assets/icons/images/banner-3.png")}
                            className="object-cover block w-full rounded-[19px] laptop:h-[500px] mobile:h-[335px]"
                            alt="..."
                        />
                        <div className="carousel-caption md:block absolute">
                            <h2 className="laptop:text-[24px] mobile:text-[16px]">Психологическая поддержка на всех этапах жизни</h2>
                            <p className='mobile:text-[10px] laptop:text-[17px]'></p>
                            <button
                                onClick={()=>{navigate('/psycologies')}}
                                className='hover:bg-blue hover:text-white flex bg-white mobile:rounded-[11px] laptop:rounded-[19px] mobile:h-[35px] laptop:h-[51px] text-blue mobile:px-[36px] laptop:px-[60px] items-center mobile:mt-[22px] laptop:mt-[36px] font-medium whitespace-nowrap mobile:text-[12px] laptop:text-[16px]'
                            >{t('main.record')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto mt-[26px] rounded-[19px] bg-[#F9FBFF] pt-[28px] pb-[28px] desktop:columns-4 mobile:columns-1 ">
                <div className="text-center">
                    <div className="text-[36px] text-blue font-medium"><CounterNumber count={92850} /></div>
                    <div className="text-[20px] text-grey font-regular">помощи</div>
                </div>
                <div className="text-center">
                    <div className="text-[36px] text-blue font-medium"><CounterNumber count={4} /></div>
                    <div className="text-[20px] text-grey">экстренные службы</div>
                </div>
                <div className="text-center">
                    <div className="text-[36px] text-blue font-medium"><CounterNumber count={20} /></div>
                    <div className="text-[20px] text-grey">филиалов</div>
                </div>
                <div className="text-center">
                    <div className="text-[36px] text-blue font-medium"><CounterNumber count={100} />%</div>
                    <div className="text-[20px] text-grey">конфиденциальность</div>
                </div>
            </div>
            <div className="container mx-auto  mt-[26px]">
                <div className='grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-2 gap-[15px] pb-[50px]'>

                    <HoverMain
                        uriLink='about-us'
                        titleText={t('main.aboutUs')}
                        text={t('main.psyCenter')}
                    />
                    <HoverMain
                        uriLink='usefultips'
                        titleText={t('main.usefulTips')}
                        text={t('main.read')}
                    />
                    <HoverMain
                        uriLink='psycologies'
                        titleText={t('main.ourPsy')}
                        text={t('main.onlineHelp')}
                    />
                    <HoverMain
                        uriLink='news'
                        titleText={t('main.news')}
                        text={t('main.learnNew')}
                    />
                    <HoverMain
                        uriLink='faqs'
                        titleText={t('main.questions')}
                        text={t('main.howWrite')}
                    />
                    <div
                        onMouseEnter={() => { setHoverActive(true) }}
                        onMouseLeave={() => { setHoverActive(false) }}
                        onClick={() => {
                            navigate('/psycologies')
                        }}
                        className=' relative cursor-pointer desktop:h-[400px] mobile:h-auto mobile:px-[10px] tablet:px-[20px] laptop:px-[40px] desktop:px-[52px] mobile:pt-[35px] mobile:pb-[35px] desktop:pt-[66px] pb-[28px] bg-blue hover:bg-white hover:text-blue text-white rounded-[19px] border-solid border-blue border-[1px] shadow-[0_4px_20px_rgba(34,134,250,0.05)]'>
                        <h3 className='desktop:leading-[36px] laptop:leading-[24px] tablet:leading-[16px] mobile:leading-[16px] mobile:text-[13px] tablet:text-[16px] laptop:text-[24px] desktop:text-[30px] desktop:pb-[50px] mobile:pb-[15px] font-medium desktop:flex laptop:flex tablet:hidden mobile:hidden'>{t('main.regular')}</h3>
                        <h3 className='desktop:leading-[36px] laptop:leading-[24px] tablet:leading-[16px] mobile:leading-[16px] mobile:text-[13px] tablet:text-[16px] laptop:text-[24px] desktop:text-[30px] desktop:pb-[50px] mobile:pb-[15px] font-medium desktop:hidden laptop:hidden tablet:flex mobile:flex'>Подобрать психолога</h3>
                        <span className='desktop:text-16 mobile:text-[12px] font-regular desktop:leading-[20px] laptop:leading-[20px] tablet:leading-[15px] mobile:leading-[12px]'>Подобрать подходящего на мою ситуацию психолога</span>
                        <button className={`${hoverActive ? ' bg-white border-blue' : 'bg-blue border-white'} font-medium desktop:flex laptop:flex tablet:hidden mobile:hidden absolute bottom-[28px] rounded-[19px] border-[1px] desktop:text-16 laptop:text-14 desktop:px-[40px] desktop:py-[17px] laptop:px-[20px] laptop:py-[15px]`}>{t('main.situation')}</button>
                        {/*  */}

                    </div>
                </div>
            </div>
            <div className="bg-white-cards">
                <div className="container mx-auto py-[50px] w-screen">
                    <div className='flex flex-row justify-between'>
                        <div className="flex flex-row">
                            <div className="text-blue mobile:text-[20px] tablet:text-[24px] laptop:text-[30px] desktop:text-[39px] uppercase">{t('main.review').split(' ')[0]}</div>
                            <div className="mobile:text-[20px] tablet:text-[24px] laptop:text-[30px] desktop:text-[39px] desktop:ml-[13px] mobile:ml-[5px]  whitespace-nowrap uppercase">{t('main.review').substring(t('main.review').indexOf(' '))}</div>
                        </div>
                        <div className='container mx-auto hidden desktop:flex flex-row justify-end'>
                            <button
                                className='mr-[30px] disabled:stroke-[#ACBED8] stroke-blue'
                                disabled={reviewPage <= 1}
                                onClick={() => {
                                    setReviewPage(reviewPage-1)
                                }}
                            >
                                <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.5 15.5L18.5 24.5L27.5 33.5"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <rect x="48.5" y="48.5" width="48" height="48" rx="23.5" transform="rotate(180 48.5 48.5)" />
                                </svg>
                            </button>
                            <button
                                onClick={() => {
                                    setReviewPage(reviewPage+1)
                                }}
                                disabled={reviewPage*3 > data.length-1}
                                className="disabled:stroke-grey stroke-blue"
                            >
                                <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.5 33.5L30.5 24.5L21.5 15.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <rect x="0.5" y="0.5" width="48" height="48" rx="23.5" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    
                    
                    <div className='grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 gap-[15px] pt-[25px] pb-[50px]'>
                        {
                            data.filter((item, index) => (index < reviewPage*3 && index >= (reviewPage*3-3))).map((item, index) => 
                                <ReviewComponent
                                    key={item.id+''}
                                    onClick={(props) => {
                                        setModalValue(props);
                                        setModalVisibleCancel(true);
                                    }}
                                    token={token}
                                    author={item.user_id}
                                    text={item.comment}
                                />
                            )
                        }

                    </div>

                </div>
            </div>
            <div className='container mx-auto items-center content-center laptop:py-[50px] mobile:pt-[30px]'>
                <div className="flex flex-row flex-nowrap ">
                        <div className="text-blue mobile:text-[20px] tablet:text-[24px] laptop:text-[30px] desktop:text-[39px] uppercase">{t('main.partner').split(' ')[0]}</div>
                        <div className="mobile:text-[20px] tablet:text-[24px] laptop:text-[30px] desktop:text-[39px] desktop:ml-[13px] mobile:ml-[5px] uppercase">{t('main.partner').substring(t('main.partner').indexOf(' '))}</div>

                    </div>
                <div className='flex justify-start desktop:my-[100px] laptop:my-[80px] tablet:my-[40px] mobile:my-[20px] desktop:gap-[80px] laptop:gap-[60px] tablet:gap-[40px] mobile:gap-[20px]'>
                    <img className='rounded-full cursor-pointer desktop:w-auto desktop:h-auto mobile:w-[66px] mobile:h-[66px]' 
                        alt='image1'
                        onClick={() => {
                            window.open(`https://www.sakha.gov.ru/`, '_blank').focus();
                        }}
                        src={require('../../assets/icons/images/partner-1.png')}  
                    />
                    <img className='rounded-full cursor-pointer desktop:w-auto desktop:h-auto mobile:w-[66px] mobile:h-[66px]' 
                        alt='image2'
                        onClick={() => {
                            window.open(`https://minmol.sakha.gov.ru/`, '_blank').focus();
                        }}
                        src={require('../../assets/icons/images/partner-2.png')}  
                    />
                </div>
            </div>
            
            {/* social links */}
            <div className=" bg-white-cards desktop:py-[70px] laptop:py-[60px] tablet:py-[50px] mobile:py-[40px]">
                <div className="container mx-auto  flex flex-col  items-start  desktop:gap-[50px] laptop:gap-[40px] tablet:gap-[25px] mobile:gap-[15px]">
                    <div className="flex flex-row flex-nowrap ">
                        <div className="text-blue mobile:text-[20px] tablet:text-[24px] laptop:text-[30px] desktop:text-[39px] uppercase">{t('main.social').split(' ')[0]}</div>
                        <div className="mobile:text-[20px] tablet:text-[24px] laptop:text-[30px] desktop:text-[39px] desktop:ml-[13px] mobile:ml-[5px] uppercase">{t('main.social').substring(t('main.social').indexOf(' '))}</div>

                    </div>
                    {/* element-1 */}
                    <div className="flex  desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col  flex-nowrap justify-between desktop:w-[50%] laptop:w-[60%] tablet:w-[100%] mobile:w-[100%] tablet:gap-[12px] mobile:gap-[8px] ">
                        <div className='flex flex-row flex-nowrap gap-[12.5px]  items-center'>
                            {/* Icon organization */}
                            <div className='flex desktop:h-[66px] laptop:h-[55px] tablet:h-[45px] mobile:h-[32px] desktop:w-[66px] laptop:w-[55px] tablet:w-[45px] mobile:w-[32px]'>
                                <svg  viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="14.1977" cy="14.1977" r="14.1977" fill="white" />
                                    <path d="M14.2408 25.3025C21.3354 25.3025 25.5087 21.6043 25.5684 14.9237C25.6232 8.72024 20.4412 4.18695 14.2408 4.02829C8.04051 3.94836 3.09217 8.95884 3.03256 14.9332C2.79408 21.0675 8.39822 25.3025 14.2408 25.3025Z" fill="url(#paint0_linear_1817_6396)" />
                                    <path d="M15.546 24.2532C15.5734 24.246 15.5985 24.2389 15.6259 24.2293C25.0337 20.9475 14.4884 16.7709 14.4884 16.7709C2.72682 12.4535 7.32461 6.33594 7.32461 6.33594C7.32461 6.33594 1.06466 10.8096 3.62826 18.6235C7.23637 26.1834 14.9462 24.4047 15.546 24.2532Z" fill="url(#paint1_linear_1817_6396)" />
                                    <path d="M24.9697 18.8615C21.7502 27.9877 10.9605 22.0825 10.9605 22.0825C6.13138 19.279 5.36588 16.8179 5.73313 15.4603C5.87567 14.9805 6.1682 14.559 6.56779 14.2578C6.96619 13.9587 7.45048 13.7964 7.94855 13.7949C31.4156 13.4705 18.4832 4.84766 18.4832 4.84766C18.4832 4.84766 28.0102 8.0651 24.9697 18.8615Z" fill="url(#paint2_linear_1817_6396)" />
                                    <path d="M14.8268 13.3596L15.2322 13.1688L15.5244 13.0256C19.6893 10.9856 18.5959 9.36199 17.2008 8.43625C16.4116 7.92765 15.5215 7.59634 14.5919 7.46517C13.9062 7.375 13.2321 7.21101 12.5816 6.97605C12.3558 6.88766 12.1523 6.75044 11.9857 6.57419C11.8191 6.39795 11.6934 6.18704 11.6178 5.95655C11.5421 5.72607 11.5183 5.48171 11.5481 5.24095C11.5778 5.00019 11.6604 4.76898 11.7899 4.56386C12.9512 2.39403 16.7785 3.86416 18.547 4.87046C24.3062 8.36705 22.5939 10.7482 19.8515 12.113L19.6774 12.1834L19.4079 12.2955L19.2052 12.3742L18.9989 12.4482L18.8022 12.5162L18.5876 12.5854L18.3765 12.6498L18.138 12.7178L17.983 12.7608L17.704 12.8323L17.4393 12.8968L17.2616 12.9373L17.0816 12.9767L16.929 13.0101L16.7048 13.0554L16.4735 13.1008L16.1682 13.1556L15.9608 13.1914L15.7402 13.2284L15.4683 13.2702L15.2012 13.3095L14.9627 13.3417L14.8268 13.3596Z" fill="url(#paint3_linear_1817_6396)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_1817_6396" x1="14.2969" y1="4.02709" x2="14.4497" y2="29.0154" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#F2F5FF" />
                                            <stop offset="1" stopColor="#F2F5FF" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_1817_6396" x1="10.953" y1="5.20977" x2="11.4304" y2="24.4166" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FD1919" />
                                            <stop offset="1" stopColor="#FD1919" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_1817_6396" x1="15.9931" y1="9.93138" x2="15.6477" y2="25.5517" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#0039E3" />
                                            <stop offset="1" stopColor="#0039E3" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint3_linear_1817_6396" x1="20.642" y1="8.52497" x2="15.1583" y2="13.2751" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#648BFF" />
                                            <stop offset="1" stopColor="#648BFF" stopOpacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>

                            </div>
                            {/* Title */}
                            <div className='desktop:text-20 laptop:text-[16px] tablet:text-14 mobile:text-[12px] font-regular leading-[20px]'>ГБУ РС(Я) “Центр социально-психологической <br className='desktop:flex laptop:flex tablet:hidden mobile:hidden' />поддержки семьи и молодёжи”</div>
                        </div>
                        {/* Social icons */}
                        <div className='flex flex-row flex-nowrap desktop:gap-[35px] laptop:gap-x-[30px] tablet:gap-[20px] mobile:gap-x-[17px] desktop:pl-0 laptop:pl-0 tablet:pl-[55px] mobile:pl-[40px]'>
                            <div
                                className='bg-white hover:bg-blue rounded-full desktop:w-[57px] desktop:h-[57px] laptop:w-[50px] laptop:h-[50px] tablet:h-[45px] tablet:w-[45px] mobile:w-[40px] mobile:h-[40px] justify-center flex items-center'
                                onClick={() => {
                                    window.open(`https://vk.com/psycentrykt`, '_blank').focus();
                                }}
                            >
                                <svg className='hover:fill-white fill-blue rounded-full cursor-pointer' width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
                                    {/* <rect width="80" height="80" rx="40" /> */}
                                    <path fillRule="evenodd" clipRule="evenodd" d="M58.1284 30.4897C58.3912 29.6317 58.1284 29 56.8697 29H52.7135C51.6559 29 51.1682 29.5453 50.9038 30.1471C50.9038 30.1471 48.7901 35.1694 45.7961 38.4317C44.8271 39.3777 44.3869 39.6779 43.8581 39.6779C43.5937 39.6779 43.1963 39.3777 43.1963 38.5181V30.4897C43.1963 29.4589 42.905 29 42.0246 29H35.4888C34.8285 29 34.4311 29.4777 34.4311 29.9319C34.4311 30.9077 35.9289 31.134 36.0825 33.8809V39.8491C36.0825 41.1581 35.8403 41.3954 35.3114 41.3954C33.9023 41.3954 30.4745 36.3496 28.4399 30.5761C28.0457 29.4526 27.6467 29 26.5843 29H22.425C21.2375 29 21 29.5453 21 30.1471C21 31.2189 22.4091 36.5429 27.5628 43.5844C30.9985 48.393 35.8355 51 40.2418 51C42.8844 51 43.2105 50.4217 43.2105 49.4239V45.7891C43.2105 44.631 43.4607 44.4 44.2983 44.4C44.9157 44.4 45.9718 44.7017 48.4386 47.0196C51.2569 49.768 51.7208 51 53.3072 51H57.4634C58.6509 51 59.2462 50.4217 58.9042 49.2777C58.5274 48.14 57.1816 46.4884 55.3972 44.5289C54.4282 43.4131 52.9747 42.211 52.533 41.6091C51.9171 40.8376 52.0928 40.4934 52.533 39.8067C52.533 39.8067 57.5996 32.8516 58.1268 30.4897H58.1284Z" />
                                </svg>
                            </div>
                            <div
                                className='bg-white hover:bg-blue rounded-full desktop:w-[57px] desktop:h-[57px] laptop:w-[50px] laptop:h-[50px] tablet:h-[45px] tablet:w-[45px] mobile:w-[40px] mobile:h-[40px] justify-center flex items-center'
                                onClick={() => {
                                    window.open(`https://t.me/ykt_psy_z_bot`, '_blank').focus();
                                }}
                            >
                                <svg className='hover:fill-white fill-blue rounded-full cursor-pointer' width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
                                    <path className='' d="M54.7051 26.1841L24.5876 37.5883C22.5322 38.399 22.5441 39.5249 24.2105 40.0269L31.9428 42.3955L49.8333 31.3116C50.6792 30.8062 51.4521 31.0781 50.8168 31.6318L36.322 44.4772H36.3186L36.322 44.4789L35.7886 52.3052C36.57 52.3052 36.9149 51.9532 37.3531 51.5379L41.1089 47.9517L48.9211 53.6179C50.3615 54.3969 51.396 53.9966 51.7545 52.3085L56.8828 28.576C57.4076 26.5094 56.0793 25.5736 54.7051 26.1841Z" />
                                    {/* <rect width="80" height="80" rx="40" fill='none' /> */}

                                </svg>
                            </div>
                        </div> 
                    </div>
                    
                    {/* element-2 */}
                    <div className="flex  desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col  flex-nowrap justify-between desktop:w-[50%] laptop:w-[60%] tablet:w-[100%] mobile:w-[100%] tablet:gap-[12px] mobile:gap-[8px] ">
                        <div className='flex flex-row flex-nowrap gap-[12.5px]  items-center'>
                            {/* Icon organization */}
                            <div className=' desktop:h-[66px] laptop:h-[55px] tablet:h-[45px] mobile:h-[32px] desktop:w-[66px] laptop:w-[55px] tablet:w-[45px] mobile:w-[32px]'>
                                <img className='bg-auto' alt='image3' src={require('../../assets/icons/minmol-logo.png')} />
                            </div>
                            {/* Title */}
                            <div className='desktop:text-20 laptop:text-[16px] tablet:text-14 mobile:text-[12px] font-regular leading-[20px]'>Министерство по делам молодежи и социальным <br className='desktop:flex laptop:flex tablet:hidden mobile:hidden' />коммуникациям Республики Саха (Якутия)</div>
                        </div>
                        {/* Social icons */}
                        <div className='flex flex-row flex-nowrap desktop:gap-[35px] laptop:gap-x-[30px] tablet:gap-[20px] mobile:gap-x-[17px] desktop:pl-0 laptop:pl-0 tablet:pl-[55px] mobile:pl-[40px]'>
                            <div
                                className='bg-white hover:bg-turquoise rounded-full desktop:w-[57px] desktop:h-[57px] laptop:w-[50px] laptop:h-[50px] tablet:h-[45px] tablet:w-[45px] mobile:w-[40px] mobile:h-[40px] justify-center flex items-center'
                                onClick={() => {
                                    window.open(`https://vk.com/minmolykt`, '_blank').focus();
                                }}
                            >
                                <svg className='hover:fill-white fill-turquoise rounded-full cursor-pointer' width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
                                    {/* <rect width="80" height="80" rx="40" /> */}
                                    <path fillRule="evenodd" clipRule="evenodd" d="M58.1284 30.4897C58.3912 29.6317 58.1284 29 56.8697 29H52.7135C51.6559 29 51.1682 29.5453 50.9038 30.1471C50.9038 30.1471 48.7901 35.1694 45.7961 38.4317C44.8271 39.3777 44.3869 39.6779 43.8581 39.6779C43.5937 39.6779 43.1963 39.3777 43.1963 38.5181V30.4897C43.1963 29.4589 42.905 29 42.0246 29H35.4888C34.8285 29 34.4311 29.4777 34.4311 29.9319C34.4311 30.9077 35.9289 31.134 36.0825 33.8809V39.8491C36.0825 41.1581 35.8403 41.3954 35.3114 41.3954C33.9023 41.3954 30.4745 36.3496 28.4399 30.5761C28.0457 29.4526 27.6467 29 26.5843 29H22.425C21.2375 29 21 29.5453 21 30.1471C21 31.2189 22.4091 36.5429 27.5628 43.5844C30.9985 48.393 35.8355 51 40.2418 51C42.8844 51 43.2105 50.4217 43.2105 49.4239V45.7891C43.2105 44.631 43.4607 44.4 44.2983 44.4C44.9157 44.4 45.9718 44.7017 48.4386 47.0196C51.2569 49.768 51.7208 51 53.3072 51H57.4634C58.6509 51 59.2462 50.4217 58.9042 49.2777C58.5274 48.14 57.1816 46.4884 55.3972 44.5289C54.4282 43.4131 52.9747 42.211 52.533 41.6091C51.9171 40.8376 52.0928 40.4934 52.533 39.8067C52.533 39.8067 57.5996 32.8516 58.1268 30.4897H58.1284Z" />
                                </svg>
                            </div>
                            <div
                                className='bg-white hover:bg-turquoise rounded-full desktop:w-[57px] desktop:h-[57px] laptop:w-[50px] laptop:h-[50px] tablet:h-[45px] tablet:w-[45px] mobile:w-[40px] mobile:h-[40px] justify-center flex items-center'
                                onClick={() => {
                                    window.open(`https://t.me/minmolYakutia`, '_blank').focus();
                                }}
                            >
                                <svg className='hover:fill-white fill-turquoise rounded-full cursor-pointer' width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
                                    <path className='' d="M54.7051 26.1841L24.5876 37.5883C22.5322 38.399 22.5441 39.5249 24.2105 40.0269L31.9428 42.3955L49.8333 31.3116C50.6792 30.8062 51.4521 31.0781 50.8168 31.6318L36.322 44.4772H36.3186L36.322 44.4789L35.7886 52.3052C36.57 52.3052 36.9149 51.9532 37.3531 51.5379L41.1089 47.9517L48.9211 53.6179C50.3615 54.3969 51.396 53.9966 51.7545 52.3085L56.8828 28.576C57.4076 26.5094 56.0793 25.5736 54.7051 26.1841Z" />
                                    {/* <rect width="80" height="80" rx="40" fill='none' /> */}

                                </svg>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            
        </div>
    );
}

const CounterNumber = ({ count, extra }) => {
    const [num, setNum] = React.useState(0);

    React.useEffect(() => {
        if (count) {
            const frames = count;
            const duration = 1500;
            const frameDuration = duration / frames;
            let n = 0
            const interval = setInterval(() => {
                setNum(n)
                if (frameDuration < 10) {
                    n = n + 10 / frameDuration
                } else {
                    n = n + 1
                }
                if (n >= count) {
                    setNum(count)
                    clearInterval(interval);
                }
            }, (frameDuration < 10 ? 10 : frameDuration))
        } else {
            setNum(0);
        }
    }, [count]);

    return Math.round(num).toLocaleString('ru-RU')
}