import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Eye from '../../assets/icons/Eye.svg';
import viewPass from '../../assets/icons/viewPass.svg';
import { Loading } from '../../components/Loading';
import { PostData } from '../../requests/post';


export const Reset = () => {
    const token = window.location.pathname.substring(window.location.pathname.lastIndexOf('reset/') + 6, window.location.pathname.lastIndexOf('/'));
    const emailFromPath = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)

    const [loading, setLoading] = React.useState(false);
    const [visibleTextNew, setVisibleTextNew] = React.useState(false);
    const [visibleTextRepeat, setVisibleTextRepeat] = React.useState(false);    
    const navigate = useNavigate();
    const {t} = useTranslation();

    const refNew = React.useRef(null);
    const refRepeat = React.useRef(null);

    const [errorStates, setErrorStates] = React.useState({
        newPassword: false,
        repeatPassword: false
    });
    const [newPassword, setNewPassword] = React.useState('');
    const [repeatPassword, setRepeatPassword] = React.useState('');

    React.useEffect(() => {
        // navigate(`/recovery/${token}/${email}`);
    }, []);

    return(
        <div className='container mx-auto grid grid-cols-1 laptop:grid-cols-3 content-start laptop:content-center'>
            <title>{t('recovery.title')}</title>
            <div className='grid col-span-0' />
            <div className='grid col-span-1'>
                <p className='text-16 desktop:text-35 font-medium text-center mt-[57px] desktop:mt-0'>{t('password.change')}</p>
                <p className='text-12 desktop:text-16 font-regular text-center mt-[17px] desktop:mt-[20px]'>{t('recovery.enterNewPass')}</p>
                <div onClick={()=> { refNew.current?.focus(); }} className={`flex flex-row shadow-[0_4px_15px_-0px_rgba(26,132,255,0.18)] justify-between items-center pr-5 ${errorStates.newPassword? 'border-red': 'border-blue'} border-[1px] pl-[53px] pt-[12px] pb-[12px] mt-[44px] desktop:mt-[62px] rounded-[19px] ${errorStates.newPassword? 'bg-red2': 'bg-white-input'}`}>
                    <div className='grid flex-1 grid-rows-2 mr-5'>
                        <label className='text-[10px] desktop:text-12 text-blue'>{t('recovery.enterNewPass')}</label>
                        <input 
                            ref={refNew}
                            className={`text-14 desktop:text-16 focus:outline-none ${errorStates.newPassword? 'bg-red2-input': 'bg-white-input'}`}
                            value={newPassword}
                            type={visibleTextNew? 'text': 'password'}
                            onChange={(event) => {
                                setNewPassword(event.target.value);
                            }}
                        />
                    </div>
                    <img onClick={() => { setVisibleTextNew(!visibleTextNew); }} src={visibleTextNew? Eye: viewPass} alt='image1'/>
                    {/* <div className='bg-red w-[24px] h-[24px]' onClick={() => { setVisibleTextNew(!visibleTextNew); }} /> */}
                </div>
                <div ref={refRepeat} className={`flex flex-row justify-between items-center pl-[53px] pr-5 pt-[12px] pb-[12px] ${errorStates.repeatPassword? 'border-red': 'border-blue'} border-[1px] mt-[20px] shadow-[0_4px_15px_-0px_rgba(26,132,255,0.18)] rounded-[19px] ${errorStates.repeatPassword? 'bg-red2': 'bg-white-input'}`}>
                    <div className='flex-1 grid grid-rows-2 mr-5'>
                        <label className='text-[10px] desktop:text-12 text-blue'>{t('recovery.enterRepeat')}</label>
                        <input
                            className={`text-14 desktop:text-16 focus:outline-none ${errorStates.repeatPassword? 'bg-red2-input': 'bg-white-input'}`}
                            ref={refRepeat}
                            type={visibleTextRepeat? 'text': 'password'}
                            value={repeatPassword}
                            onChange={(event) => {
                                setRepeatPassword(event.target.value);
                            }}
                        />
                    </div>
                    <img onClick={() => {setVisibleTextRepeat(!visibleTextRepeat)}} src={visibleTextRepeat? Eye: viewPass} alt='...'/>
                </div>
                <button
                    disabled={newPassword === repeatPassword && newPassword.length >= 6? false: true}
                    className={`h-[60px] rounded-[19px] ${newPassword === repeatPassword && newPassword.length >= 6? 'bg-blue': 'bg-grey'} items-center justify-center flex text-white font-medium text-14 desktop:text-16 mt-[20px] laptop:mt-[50px]`}
                    onClick={() => {
                        setErrorStates({
                            newPassword: false,
                            repeatPassword: false
                        });
                        setLoading(true);
                        PostData(`/api/auth/reset-password`, null, JSON.stringify({
                            token: token,
                            email: emailFromPath,
                            password: newPassword,
                            password_confirmation: repeatPassword
                        }), (json, statusCode) => {
                            if(statusCode === 200 && json.status === 'Your password has been reset!') {
                                setLoading(false);
                                navigate('/login');
                            } else {
                                setLoading(false);
                            };
                        }, (e) => {
                            console.log('e', e);
                            setLoading(false);
                        });
                    }}
                >{loading?<Loading />:<p>{t('recovery.confirm')}</p>}</button>
            </div>
            <div className='grid col-span-0' />
        </div>
    )
}