import * as React from 'react';

export const ReviewModal = ({data, close}) => {
    const { avatar_url,
        first_name,
        lastName,
        text} = data;

    return(
        <div 
            className=' py-[20px] desktop:px-[40px] laptop:px-[30px] tablet:px-[24px] mobile:px-[18px] bg-white rounded-[19px] laptop:w-[600px]'>
            <div className='flex flex-row justify-between items-start pb-[20px]'>
                <div className='flex flex-row items-center'>
                    <div className='bg-white shadow-[0px_6px_19px_rgba(105,105,105,0.25)] rounded-full desktop:w-[74px] desktop:h-[74px] laptop:w-[74px] laptop:h-[74px] tablet:w-[55px] tablet:h-[55px] mobile:w-[55px] mobile:h-[55px] items-center flex justify-center'>
                        <img className='rounded-full desktop:rounded-full desktop:w-[64px] desktop:h-[64px] laptop:w-[64px] laptop:h-[64px] tablet:w-[47px] tablet:h-[47px] mobile:w-[47px] mobile:h-[47px] object-cover' alt='image1' src={avatar_url ? avatar_url : require('../assets/icons/nulled-photo.png')} />
                    </div>
                    <div className='text-[20px] leading-[25px] ml-[15px] desktop:text-black laptop:text-black tablet:text-blue mobile:text-blue'>{first_name} {lastName}</div>
                </div>
                <button onClick={close}>
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M26.8433 4.15765C26.9336 4.24764 27.0051 4.35454 27.054 4.47224C27.1028 4.58993 27.128 4.7161 27.128 4.84353C27.128 4.97095 27.1028 5.09713 27.054 5.21482C27.0051 5.33251 26.9336 5.43942 26.8433 5.5294L5.53085 26.8419C5.34894 27.0238 5.10223 27.126 4.84497 27.126C4.58772 27.126 4.341 27.0238 4.1591 26.8419C3.97719 26.66 3.875 26.4133 3.875 26.156C3.875 25.8988 3.97719 25.6521 4.1591 25.4702L25.4716 4.15765C25.5616 4.06744 25.6685 3.99586 25.7862 3.94702C25.9039 3.89819 26.03 3.87305 26.1575 3.87305C26.2849 3.87305 26.4111 3.89819 26.5288 3.94702C26.6465 3.99586 26.7534 4.06744 26.8433 4.15765Z" fill="#1D1D1D"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.15961 4.15765C4.06939 4.24764 3.99781 4.35454 3.94898 4.47224C3.90014 4.58993 3.875 4.7161 3.875 4.84353C3.875 4.97095 3.90014 5.09713 3.94898 5.21482C3.99781 5.33251 4.06939 5.43942 4.15961 5.5294L25.4721 26.8419C25.654 27.0238 25.9007 27.126 26.158 27.126C26.4152 27.126 26.6619 27.0238 26.8439 26.8419C27.0258 26.66 27.128 26.4133 27.128 26.156C27.128 25.8988 27.0258 25.6521 26.8439 25.4702L5.53136 4.15765C5.44137 4.06744 5.33447 3.99586 5.21677 3.94702C5.09908 3.89819 4.97291 3.87305 4.84548 3.87305C4.71806 3.87305 4.59189 3.89819 4.47419 3.94702C4.3565 3.99586 4.2496 4.06744 4.15961 4.15765Z" fill="#1D1D1D"/>
                    </svg>
                </button>
            </div>

            <div className='overflow-y-auto h-[50vh] desktop:text-16 tablet:text-16 laptop:text-12 mobile:text-12 desktop:leading-[20px] laptop:leading-[20px] tablet:leading-[15px] mobile:leading-[15px]'>{text}</div>
        </div>
    )
}