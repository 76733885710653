import * as React from 'react';

export const Avatar = (props) => {
    const {
        source, style1, styleImage
    } = props;

    return(
        <div className={`${style1} relative bg-white shadow-[0px_6px_19px_rgba(105,105,105,0.25)] rounded-full items-center flex justify-center`}>
            <img className={`${styleImage} rounded-full object-cover`} alt="photo1" src={source} />
            {/* <div className={`${online? 'flex ': 'hidden '} w-[14px] laptop:w-[22px] h-[14px] laptop:h-[22px] rounded-full bg-green absolute right-[2.3px] bottom-[4.3px]`} /> */}
        </div>
    );
}
