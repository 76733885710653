import * as React from 'react';
import {
    DropDown
} from '../../components/DropDown';

import { Calendar } from '../../components/Calendar';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {WhoData} from '../../configs/data';
import { useSelector, useDispatch } from 'react-redux';
import { setPsycologiesRedux } from '../../redux/filters/filterSlice';
import { groupFunction, groupFunctionRevert, sessionTypeFunction, sessionTypeRevert, sexFunction } from '../../configs/functions';

export const FormClient = () => {
    const citiesRedux = useSelector(state => state.cities.cities);
    const worriesRedux = useSelector(state => state.worries.worries);
    const {t} = useTranslation();

    const filterPsy = useSelector(state => state.filter.psycologies);

    const [city, setCity] = React.useState({name: filterPsy.city? filterPsy.city: 'Иной субъект РФ', id: filterPsy.cityId !== 0? filterPsy.cityId: 0});
    const [worrie, setWorrie] = React.useState({name: filterPsy.worry? filterPsy.worry: '', id: filterPsy.worryId? filterPsy.worryId: 0});
    const [type, setType] = React.useState(sessionTypeRevert(filterPsy.type));
    const [group, setGroup] = React.useState(groupFunctionRevert(filterPsy.group));
    const [sex, setSex] = React.useState(filterPsy.sex? sexFunction(filterPsy.sex): 'Не имеет значения');
    const [dateType, setDateType] = React.useState(filterPsy.dateType !== ''? filterPsy.dateType: 'Любое');
    const [date, setDate] = React.useState(null);

    const [minPrice, setMinPrice] = React.useState(filterPsy.minPrice);
    const [maxPrice, setMaxPrice] = React.useState(filterPsy.maxPrice);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [dateScreen, setDateScreen] = React.useState(false);

    if(dateScreen === false) {
        return(
            <div className={styles.main}>
                <title>{t('writeCons.title')}</title>
                <div className={styles.div1}>
                    <div>
                        <p className={styles.title}>{t('writeCons.form')}</p>
                        <p className={styles.descr}>{t('writeCons.answerQuestion')}</p>
                        <div>
                            <p className='text-[12px] font-medium desktop:mt-[130px] laptop:mt-[60px] mobile:mt-[45px]'>{t('writeCons.location')}</p>
                            <DropDown 
                                value={city.name}
                                data={citiesRedux}
                                placeholder="Иной субъект РФ"
                                width=" self-start desktop:w-[410px] laptop:w-[410px] mobile:w-full mt-[10px]"
                                height='h-[67px]'
                                onChange={(value) => { setCity({name: value.name, id: value.id}); }}
                                styleMenu={["mt-[10px]", 'w-full laptop:w-[300px]']}
                                styleButton={['w-[152px]', 'w-full', 'text-start', 'text-[14px] laptop:text-[16px]', 'font-regular']}
                                styleButtonMain={['h-[60px] laptop:h-[67px]', 'w-full', 'justify-between']}
                                styleMenuItem={["text-12 laptop:text-16", 'font-medium']}
                            />
                            <p className={styles.label}>{t('writeCons.what')}</p>
                            <DropDown 
                                value={worrie.name}
                                data={worriesRedux}
                                placeholder="Семейные отношения"
                                width="self start desktop:w-[410px] laptop:w-[410px] mobile:w-full mt-[10px]"
                                height='h-[67px]'
                                onChange={(value) => { setWorrie({name: value.title, id: value.id}); }}
                                styleMenu={["mt-[10px]", 'w-full laptop:w-[300px]']}
                                styleButton={['w-full', 'text-start', 'mr-[20px]']}
                                styleButtonMain={['h-[60px] laptop:h-[67px]', 'justify-between', 'w-full']}
                                styleMenuItem={["text-w-full whitespace-nowrap", "text-[12px] laptop:text-[16px]", 'font-medium']}
                            />
                            <p className={styles.label}>{t('writeCons.seans')}</p>
                            <DropDown 
                                value={type}
                                data={[{name: 'Онлайн'}, {name: 'Оффлайн'}]}
                                placeholder="Онлайн"
                                width="self start desktop:w-[410px] laptop:w-[410px] mobile:w-full mt-[10px]"
                                height='h-[67px]'
                                onChange={(value) => { setType(value.name); }}
                                styleMenu={["mt-[10px]", 'w-full laptop:w-[300px]']}
                                styleButton={['w-full', 'text-start', 'mr-[20px]']}
                                styleButtonMain={['h-[60px] laptop:h-[67px]', 'justify-between', 'w-full']}
                                styleMenuItem={["text-w-full whitespace-nowrap", "text-[12px] laptop:text-[16px]", 'font-medium']}
                            />
                            <p className={styles.label}>{t('writeCons.want')}</p>
                            <DropDown 
                                value={group}
                                data={WhoData}
                                placeholder="Один"
                                width=" self-start desktop:w-[410px] laptop:w-[410px] mobile:w-full mt-[10px]"
                                height='h-[67px]'
                                onChange={(value) => { setGroup(value.name); }}
                                styleMenu={["mt-[10px]", 'w-full laptop:w-[300px]']}
                                styleButton={['w-[152px]', 'w-full', 'text-start', 'text-[14px] laptop:text-[16px]', 'font-regular']}
                                styleButtonMain={['h-[60px] laptop:h-[67px]', 'justify-between', 'w-full']}
                                styleMenuItem={["text-12 laptop:text-16", 'font-medium']}
                            />
                            <p className={styles.label}>{t('writeCons.who')}</p>
                            <div className={styles.divBtns}>
                                <button className={styles.whoButton(sex, 'У женщины')} onClick={() => {setSex('У женщины'); }}>{t('writeCons.female')}</button>
                                <button className={styles.whoButton(sex, 'У мужчины')} onClick={() => {setSex('У мужчины'); }}>{t('writeCons.male')}</button>
                                <button className={styles.whoButton(sex, 'Не имеет значения')} onClick={() => {setSex('Не имеет значения'); }}>{t('writeCons.noSex')}</button>
                            </div>
                            <p className={styles.label}>{t('writeCons.time')}</p>
                            <div className={styles.divBtns}>
                                <button 
                                    onClick={() => {setDateType('Конкретное'); setDateScreen(true)}}
                                    className={styles.timeTypeButton(dateType, 'Конкретное')}
                                >{date === null? t('writeCons.change') : date.toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'})}</button>
                            </div>
                            <p className={styles.label}>{t('writeCons.cost')}</p>
                            <div className='flex flex-row items-center justify-start mt-[10px]'>
                                <input 
                                    className={styles.priceInput + ' mr-[20px]'}
                                    value={minPrice}
                                    onChange={(event) => {setMinPrice(event.target.value); }}
                                    placeholder="От"
                                    type={'number'}
                                />
                                <input 
                                    className={styles.priceInput}
                                    value={maxPrice}
                                    onChange={(event) => {setMaxPrice(event.target.value); }}
                                    placeholder="До"
                                    type={'number'}
                                />
                            </div>
                        </div>        
                    </div>
                    <div></div>
                </div>
                
                <div className={styles.divSend}>
                    <button 
                        className={styles.btnSend}
                        onClick={() => {
                            dispatch(setPsycologiesRedux({
                                city: city.name,
                                cityId: city.id,
                                worry: worrie.name,
                                worryId: worrie.id,
                                type: sessionTypeFunction(type),
                                group: groupFunction(group),
                                sex: sex === 'У женщины'? "FEMALE": sex === 'У мужчины'? 'MALE': '',
                                date: date,
                                dateType: dateType,
                                minPrice: minPrice,
                                maxPrice: maxPrice
                            }));
                            navigate('/psycologies');
                        }}
                    >{t('writeCons.continue')}</button>
                </div>
            </div>
        );
    } else {
        return(
            <DateType 
                date={date}
                onChangeDate={setDate}
                setDateScreen={setDateScreen}
            />
        );
    }; 
};

const DateType = (props) => {
    const {
        date, onChangeDate, setDateScreen
    } = props;
    const [screenType, setScreenType] = React.useState(false);
    const [select, setSelect] = React.useState(null);
    const {t} = useTranslation();

    const [time] = React.useState([
        '09:00', '10:00', '11:00',
        '12:00', '13:00', '14:00',
        '15:00', '16:00', '17:00',
        '18:00', '19:00', '20:00'
    ])

    if(screenType === false) {
        return(
            <div className={styles2.main}>
                <title>Выбор даты</title>
                <p className={styles2.title}>{t('concrete.chooseTitle')}</p>
                <Calendar 
                    isSimple
                    workingDates={[]}
                    date={date}
                    onChangeDate={(date) => {
                        onChangeDate(date);
                        setScreenType(true);
                    }}
                    loading={false}
                />
            </div>
        );
    } else {
        return (
            <div className={styles2.main}>
                <title>{t('writeCons.timeSeans')}</title>
                <p className={styles2.title}>{t('writeCons.chooseTimeSeans')}</p>
                <div className={styles2.divTime} >
                {
                    time.map((item, index) => 
                        <button 
                            key={index.toString()}
                            className={styles2.time(select, item)}
                            onClick={() => {
                                setSelect(item);
                            }}
                        >{item}</button>
                    )
                }
                </div>
                <div className={styles2.divBtn}>
                    <button 
                        className={styles2.btn}
                        onClick={() => { 
                            let temp = new Date(date.getFullYear(), date.getMonth(), date.getDate(), parseInt(select[0] + select[1]), parseInt(select[3] + select[4]));
                            onChangeDate(temp);
                            setDateScreen(false);
                        }}    
                    >{t('writeCons.next')}</button>
                </div>
                
            </div>
        );
    }   
};

const styles = {
    main: 'container mx-auto',
    div1: 'laptop:grid laptop:grid-cols-2',
    whoButton: (who, name) => `rounded-[19px] h-[60px] px-[27px] text-[14px] laptop:text-[16px] font-regular whitespace-nowrap laptop:mr-[15px] ${who === name? 'bg-blue text-white': 'border-blue border-[1px] text-black'} h-[60px] laptop:h-[67px]`,
    timeTypeButton: (timeType, name) => `rounded-[19px] h-[60px] px-[27px] text-[12px] laptop:text-[16px] font-regular laptop:mr-[15px]  ${timeType === name? 'bg-blue text-white': 'border-blue border-[1px] text-black'}`,
    divSend: 'mt-[30px] laptop:mt-[150px] flex flex-row items-end justify-center',
    btnSend: `bg-blue text-white laptop:w-[289px] mobile:flex-1 laptop:flex-none h-[60px] rounded-[19px] font-medium`,
    title: 'text-16 laptop:text-20 desktop:text-36 font-medium desktop:text-start laptop:text-start mobile:text-center',
    descr: 'laptop:hidden mobile:flex text-center text-12 font-regular text-grey mt-[17px]',
    label: `text-12 mt-[30px] font-medium`,
    divBtns: `grid laptop:flex grid-cols-2 laptop:flex-row mt-[10px] gap-[15px] laptop:gap-0`,
    priceInput: `border-blue-border bg-white-input border-[1px] h-[44px] rounded-[19px] text-center placeholder:text-grey placeholder:text-[14px] placeholder:laptop:text-[16px] focus:outline-none`
};

const styles2 = {
    main: 'container mx-auto',
    title: 'mt-[23px] text-16 desktop:text-20 font-medium desktop:text-center laptop:text-center text-start',
    divTime: 'mt-[100px] grid grid-cols-3 gap-[10px] laptop:gap-[37px]',
    divBtn: 'flex items-center justify-center',
    time: (select, item) => `${select === item? 'bg-blue text-white': 'bg-white-input text-blue'} h-[50px] laptop:h-[70px] rounded-[19px]`,
    btn: 'w-full laptop:w-[289px] bg-blue mt-[30px] rounded-[19px] h-[54px] laptop:h-[60px] text-white font-medium'
}