import { API } from '../configs/api';

export const request = async ({method="GET", url, token, body}) => {
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    if(token) {
        headers = Object.assign(headers, {
            'Authorization': `Bearer ${token}`
        })
    }    
    const response = await fetch(API + url, {
        method: method,
        headers: headers,
        body: JSON.stringify(body)
    });

    const respData = await response.json();

    return {data: respData, status: response.status}
};