import * as React from 'react';
import { Menu, Transition } from '@headlessui/react';
//import ArrowDown from '../assets/icons/ArrowDown.svg'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
};
     
export const DropDown = (props) => {
    const {
        value, data, onChange, 
        styleButton, styleMenu, styleMenuItem,
        styleButtonMain, right
    } = props;

    return(
        <Menu as="div" className={addStyle("inline-block text-left ", Array.isArray(styleMenu)? styleMenu: [] )}>
            {
                ({open}) => {
                    // ref.current = open;
                    return (
                        <>
                            <div>
                                <Menu.Button className={addStyle("inline-flex items-center rounded-[11px] laptop:rounded-[19px] border border-blue px-4 py-[3px] laptop:py-[10px] hover:bg-gray-50 focus:outline-none focus:ring-offset-2 flex-row justify-between", Array.isArray(styleButtonMain)? styleButtonMain: [])}>
                                    <p className={addStyle('', Array.isArray(styleButton)? styleButton: [])  }>{value}</p>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 9L12 15L18 9" stroke="#3A7DFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </Menu.Button>
                            </div>

                            <Transition
                                as={React.Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className={`absolute max-h-[400px] overflow-y-scroll ${right? 'mobile:right-0 laptop:right-auto mobile:left-0 mobile:w-full laptop:left-auto laptop:w-fit': 'mobile:w-full laptop:w-auto'} bg-white z-10 mt-2 origin-top-right rounded-[11px] laptop:rounded-[19px] shadow-[0_4px_15px_-0px_rgba(105,105,105,0.08)] focus:outline-none`}>
                                    <div className="py-1 max-h-[400px] ">
                                        {
                                            data.map((item, index) => 
                                                <Menu.Item
                                                    key={index.toString()}
                                                    as={'div'}
                                                    onClick={() => {onChange(item);}}
                                                >
                                                {({active}) => (
                                                    <p
                                                        className={addStyle(classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm',
                                                            'h-[41px] laptop:h-[60px]',
                                                            value === item.name || value === item.title? 'bg-white-input': 'bg-white',
                                                            'rounded-[19px]',
                                                            value === item.name || value === item.title? 'text-blue': 'text-black',
                                                            'flex', 'items-center',
                                                            'px-[33px] laptop:px-[37px]'

                                                        ), Array.isArray(styleMenuItem)? styleMenuItem: [] )}
                                                    >{item.name? item.name: item.title? item.title: ''}</p>
                                                )}
                                                </Menu.Item>
                                            )
                                        }
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </>
                    )
                }
            }
        </Menu>
    );
}

const addStyle = (oldStyle, addStyle) => {
    let strArr = oldStyle.split(' '); //['color-[16px]', 'bg-[]']
    let strAddArr = addStyle; // ['w-[23px]', 'color-[32px]']
    //let result = strArr;
    strAddArr.forEach((item, index) => {
        let tempAdd = item.split('-')[0];
        if(strArr.includes(tempAdd) === false) {
            strArr.push(item);
        } else {
            let indexOld = strArr.findIndex(function(element, index, array) {
                return element.includes(tempAdd);
            });
            strArr[indexOld] = item;
        }
    })

    return strArr.join(' '); 
}

/*

export const DropDown = (props) => {
    const {value, data, placeholder, onChange, width='min-w-[200px]', height='h-[44px]', backgroundColor} = props;

    return(
        <Menu as={'div'} className=''>
            <Menu.Button
                className={`border-blue border-[1px] rounded-[19px] flex row items-center justify-between mr-[25px] ${width} ${height} ${backgroundColor}`}
            >
                {typeof value === 'string' ? <>
                    <p className={`ml-[20px] mr-[10px] text-14 desktop:text-16 ${value !== null && value !== ''? 'text-black': 'text-grey'} mr-[10px]`}>{value === null || value === ''? placeholder: value}</p>
                    <img className='mr-[20px]' src={ArrowDown}/>
                </> : value}
                
            </Menu.Button>
            <Menu.Items as={'div'} className={`flex flex-col bg-white shadow-[0_4px_15px_-0px_rgba(105,105,105,0.25)] ${width} rounded-[19px] mr-[25px] mt-[10px]`}>
            {
                data.map((item, index) => 
                    <Menu.Item 
                        as={'div'} 
                        onClick={() => {onChange(item); }} 
                        key={index.toString()} 
                        className={`flex items-center h-[60px] pl-[20px] pr-[20px] ${value === item.name? 'bg-white-input': 'bg-white'} ${index === 0? 'rounded-tl-[19px] rounded-tr-[19px]': ''} ${index === data.length - 1? 'rounded-bl-[19px] rounded-br-[19px]': ''}`}>
                    {
                        ({active}) => (
                            <p
                                className={`text-14 desktop:text-16 font-medium ${value === item.name? 'text-blue': 'text-black'} 'rounded-tl-[18px] rounded-tr-[18px]': ''}`}
                                onClick={() => {
                                    // onChange(item);
                                }}
                            >{typeof item === 'object'? item.name: item}</p>
                        )
                    }
                    </Menu.Item>
                )   
            }
                
            </Menu.Items>
        </Menu>
    );
}

*/

export const DropDown2 = () => {
   
    //const [show, setShow] = React.useState(false);

    // console.log('show', show);    

    return(
       <div></div>
    );
}

export const DropDownNo = (props) => {
    const {label, style, data} = props;
    const [show, setShow] = React.useState(false);

    return(
        <div className={`flex flex-col self-start w-full ${style}`}>
            <div 
                className='flex flex-row items-center justify-between'
                onClick={() => { setShow(!show); }}    
            >
                <p className='text-14 desktop:text-20 laptop:text-20 flex flex-1 font-medium mr-[50px]'>{label}</p>
                <div className={`${show? 'rotate-[180deg]': 'rotate-0'}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 9L12 15L18 9" stroke="#3A7DFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>

            <div
                className={`${show? 'flex ': 'hidden '}bg-white flex-col ${show? 'translate-y-0 opacity-1': '-translate-y-10 opacity-0 '}`}
            >
            {
                data.map((item, index) => 
                    <p key={index.toString()} className={`${index === 0? 'mt-[20px] desktop:mt-[30px] laptop:mt-[30px] ': 'mt-[15px] '}text-12 desktop:text-16 laptop:text-16 font-regular`}>{typeof item === 'string'? item: item.title? item.title: item.name? item.name: ''}</p>
                )
            }
            </div>
            
        </div>
    );
}