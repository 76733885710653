import {combineReducers} from 'redux';

import auth from './auth';
import cities from './cities/citiesSlice';
import recordsSlice from './records/recordsSlice';
import worriesSlice from './worries/worriesSlice';
import filter from './filters/filterSlice';
import favorite from './favorite/favoriteSlice';
import psych from './psych/psychSlice';

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        auth,
        cities,
        records:recordsSlice,
        favorite,
        worries: worriesSlice,
        filter,
        psych,
        ...asyncReducers
    });

    return combinedReducer(state, action);
};

export default rootReducer;