import * as React from 'react';
import { getTimeFromArray, isTimeEqual } from '../configs/functions';

export const TimePicker = (props) => {
    const {
        times, style1,
        value, onChange, windows,
        setWindowItem
    } = props;
    
    //console.log('windows', windows);

    return(
        <div className={`grid grid-cols-4 gap-[10px] laptop:gap-[37px] ${style1}`}>
        {
            times.map((item, index) => 
                <button 
                    key={index.toString()}
                    className={`w-full h-[54px] laptop:h-[60px] flex flex-col items-center justify-center rounded-[11px] laptop:rounded-[19px] ${value === item? 'bg-blue text-white': isTimeEqual(windows, item)? 'bg-white-input text-blue': 'bg-grey-divide text-grey'} px-[10px] laptop:px-[20px] font-regular text-[12px] laptop:text-[18px]`}
                    disabled={!isTimeEqual(windows, item)}
                    onClick={() => {
                        onChange(item);
                        setWindowItem(getTimeFromArray(windows, item));
                    }}
                >{item}</button>
            )
        }
        </div>
    );
}