import {createSlice}  from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        signedIn: false,
        email: '',
        password: ''
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true;
            state.token = action.payload;
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false;
            state.token = '';
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        saveEmailPass: (state, action) => {
            state.email = action.payload.email;
            state.password = action.payload.password
        }
    }
});

export const { onSignInSuccess, onSignOutSuccess, setToken, saveEmailPass } = sessionSlice.actions;
export default sessionSlice.reducer;