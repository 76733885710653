import * as React from 'react';
import { useSelector } from 'react-redux';
import { PostData } from '../requests/post';

 
export const ReviewPost = ({close}) => {
    const token = useSelector(state => state.auth.session.token);
    const [comment, setComment] = React.useState('');
    const [send, setSend] = React.useState(false);


    return(
        <div className=''>
            {
                !send?
                <>
                    <div className='bg-white rounded-[19px] flex flex-col py-[30px] px-[20px] laptop:min-w-[500px]'>
                        <div className='flex items-center justify-between'>
                            <div></div>
                            <div className='font-medium text-20'>Оставить отзыв</div>
                            <button  onClick={close}>
                                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M26.8433 4.15765C26.9336 4.24764 27.0051 4.35454 27.054 4.47224C27.1028 4.58993 27.128 4.7161 27.128 4.84353C27.128 4.97095 27.1028 5.09713 27.054 5.21482C27.0051 5.33251 26.9336 5.43942 26.8433 5.5294L5.53085 26.8419C5.34894 27.0238 5.10223 27.126 4.84497 27.126C4.58772 27.126 4.341 27.0238 4.1591 26.8419C3.97719 26.66 3.875 26.4133 3.875 26.156C3.875 25.8988 3.97719 25.6521 4.1591 25.4702L25.4716 4.15765C25.5616 4.06744 25.6685 3.99586 25.7862 3.94702C25.9039 3.89819 26.03 3.87305 26.1575 3.87305C26.2849 3.87305 26.4111 3.89819 26.5288 3.94702C26.6465 3.99586 26.7534 4.06744 26.8433 4.15765Z" fill="#1D1D1D"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.15961 4.15765C4.06939 4.24764 3.99781 4.35454 3.94898 4.47224C3.90014 4.58993 3.875 4.7161 3.875 4.84353C3.875 4.97095 3.90014 5.09713 3.94898 5.21482C3.99781 5.33251 4.06939 5.43942 4.15961 5.5294L25.4721 26.8419C25.654 27.0238 25.9007 27.126 26.158 27.126C26.4152 27.126 26.6619 27.0238 26.8439 26.8419C27.0258 26.66 27.128 26.4133 27.128 26.156C27.128 25.8988 27.0258 25.6521 26.8439 25.4702L5.53136 4.15765C5.44137 4.06744 5.33447 3.99586 5.21677 3.94702C5.09908 3.89819 4.97291 3.87305 4.84548 3.87305C4.71806 3.87305 4.59189 3.89819 4.47419 3.94702C4.3565 3.99586 4.2496 4.06744 4.15961 4.15765Z" fill="#1D1D1D"/>
                                </svg>
                            </button>
                        </div>
                        <textarea
                            onChange={(e) => {
                                setComment(e.target.value);
                            }}
                            value={comment}
                            className='focus:outline-blue focus:outline-[1px] py-[27px] px-[40px] mt-[35px] mb-[40px] h-[300px] bg-[#F9FBFF] rounded-[19px] shadow-[0_4px_15px_-0px_rgba(26,132,255,0.18)]'/>
                        <button 

                            disabled={comment.length > 0? false: true}
                            className='bg-blue rounded-[19px] flex justify-center items-center h-[52px] text-white disabled:bg-grey min-w-[289px] mobile:w-auto tablet:w-auto laptop:w-auto desktop:w-auto mx-auto mobile:mt-[30px] laptop:mt-[40px] '
                            onClick={() =>{
                                PostData('/api/reviews/', token, JSON.stringify({comment: comment, anonymously: false}), 
                                        (json, statusCode) => {
                                            if(statusCode === 200) {
                                                setSend(true)
                                            } else {
                                                //console.log('json enter ', statusCode, json);
                                            }
                                        }
                                    , (e) => {
                                        console.log('e', e);
                                    })
                            }}
                        >
                            Отправить
                        </button>
                    </div>
                </>:
                <>
                    <div className='bg-white rounded-[19px] flex flex-col py-[30px] px-[20px] laptop:min-w-[500px]'>
                        <div className='flex items-center justify-between'>
                            <div></div>
                            <div></div>
                            <button  
                                onClick={() => {
                                    close()
                                    setSend(false)
                                    setComment('')
                                }}
                                >
                                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M26.8433 4.15765C26.9336 4.24764 27.0051 4.35454 27.054 4.47224C27.1028 4.58993 27.128 4.7161 27.128 4.84353C27.128 4.97095 27.1028 5.09713 27.054 5.21482C27.0051 5.33251 26.9336 5.43942 26.8433 5.5294L5.53085 26.8419C5.34894 27.0238 5.10223 27.126 4.84497 27.126C4.58772 27.126 4.341 27.0238 4.1591 26.8419C3.97719 26.66 3.875 26.4133 3.875 26.156C3.875 25.8988 3.97719 25.6521 4.1591 25.4702L25.4716 4.15765C25.5616 4.06744 25.6685 3.99586 25.7862 3.94702C25.9039 3.89819 26.03 3.87305 26.1575 3.87305C26.2849 3.87305 26.4111 3.89819 26.5288 3.94702C26.6465 3.99586 26.7534 4.06744 26.8433 4.15765Z" fill="#1D1D1D"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.15961 4.15765C4.06939 4.24764 3.99781 4.35454 3.94898 4.47224C3.90014 4.58993 3.875 4.7161 3.875 4.84353C3.875 4.97095 3.90014 5.09713 3.94898 5.21482C3.99781 5.33251 4.06939 5.43942 4.15961 5.5294L25.4721 26.8419C25.654 27.0238 25.9007 27.126 26.158 27.126C26.4152 27.126 26.6619 27.0238 26.8439 26.8419C27.0258 26.66 27.128 26.4133 27.128 26.156C27.128 25.8988 27.0258 25.6521 26.8439 25.4702L5.53136 4.15765C5.44137 4.06744 5.33447 3.99586 5.21677 3.94702C5.09908 3.89819 4.97291 3.87305 4.84548 3.87305C4.71806 3.87305 4.59189 3.89819 4.47419 3.94702C4.3565 3.99586 4.2496 4.06744 4.15961 4.15765Z" fill="#1D1D1D"/>
                                </svg>
                            </button>
                        </div>
                        <div className='text-blue text-20 font-medium text-center mt-[40px] mb-[60px]'>Спасибо за ваш отзыв!</div>
                    </div>
                </>
            }
            
        </div>
    )
}