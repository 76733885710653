// import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './rootReducer';

const middlewares = [];

const persistConfig = {
    key: 'root',
    'keyPrefix': '',
    storage,
    whitelist: ['auth', 'session', 'cities', 'favorite', 'psych']
};

const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer()),
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false
        }).concat(middlewares)
});

// store.asyncReducers = {};

export const persistor = persistStore(store);

// export const injectReducer = (key, reducer) => {
//     if(store.as)
// }

export default store;