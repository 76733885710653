import React from 'react';
import { request } from '../requests/requests';

export const ReviewComponent = (props) => {
    const { author, text, onClick, token} = props;

    const [authorData, setAuthorData] = React.useState({
        first_name: '',
        last_name: '',
        avatar_url: '',
    });

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        (async function() {
            try {
                setLoading(true);
                let response = await request({
                    method: 'GET',
                    url: '/api/users/' + author,
                    token: token,
                    body: undefined
                });
                if(response.status === 200) {
                    setAuthorData(response.data.data);
                }
            } catch(e) {
                console.log('e', e);
            } finally {
                setLoading(false);
            }
        })()
        // eslint-disable-next-line
    },[])

    return (
        <button 
            onClick={() => onClick({
                avatar_url: authorData.avatar_url,
                first_name: authorData.first_name,
                lastName: authorData.last_name,
                text: text
            })}
            disabled={loading}
            className='flex flex-col items-start mobile:min-h-[150px] laptop:min-h-[234px] text-start cursor-pointer py-[20px] desktop:px-[40px] laptop:px-[30px] tablet:px-[24px] mobile:px-[18px] bg-white rounded-[19px]'>
            <div className='flex flex-row items-center pb-[20px]'>
                <div className='bg-white shadow-[0px_6px_19px_rgba(105,105,105,0.25)] rounded-full desktop:w-[74px] desktop:h-[74px] laptop:w-[74px] laptop:h-[74px] tablet:w-[55px] tablet:h-[55px] mobile:w-[55px] mobile:h-[55px] items-center flex justify-center'>
                    <img className='rounded-full desktop:rounded-full desktop:w-[64px] desktop:h-[64px] laptop:w-[64px] laptop:h-[64px] tablet:w-[47px] tablet:h-[47px] mobile:w-[47px] mobile:h-[47px] object-cover' alt='image1' src={authorData.avatar_url ? authorData.avatar_url : require('../assets/icons/nulled-photo.png')} />
                </div>
                <div className='text-[20px] leading-[25px] ml-[15px] desktop:text-black laptop:text-black tablet:text-blue mobile:text-blue'>{authorData.first_name} {authorData.last_name}</div>
            </div>

            <div className='desktop:text-16 tablet:text-16 laptop:text-12 mobile:text-12 desktop:leading-[20px] laptop:leading-[20px] tablet:leading-[15px] mobile:leading-[15px] line-clamp-5'>{text}</div>
        </button>
    )
}