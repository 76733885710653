import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';

import Eye from '../../assets/icons/viewPass.svg';
import viewPass from '../../assets/icons/Eye.svg';
import { DropDown } from '../../components/DropDown';
import { Loading } from '../../components/Loading';
import {onSignInSuccess} from '../../redux/auth/sessionSlice';
import {setUser} from '../../redux/auth/userSlice';
import { CustomPicker } from './register/CustomPicker';
import {request} from '../../requests/requests';

export const Register = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const citiesRedux = useSelector(state => state.cities.cities);
    const {t} = useTranslation();

    const [name, setName] = React.useState('');
    const [surname, setSurname] = React.useState('');
    const [midname] = React.useState('');
    const [gender, setGender] = React.useState('');
    const [birthdate, setBirthdate] = React.useState('');
    const [city, setCity] = React.useState('');
    const [cityId, setCityId] = React.useState(0);
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [repeat, setRepeat] = React.useState('');
    const [modal, setModal] = React.useState(false);

    const [errorStates, setErrorStates] = React.useState({
        name: false,
        gender: false,
        birthdate: false,
        city: false,
        email: false,
        password: false,
        repeat: false
    });

    const [emailFlag, setEmailFlag] = React.useState(true);
    const [registerFlag, setRegisterFlag] = React.useState(true);
    const dispatch = useDispatch();

    const refresh = async () => {
        const response = await request({
            method: 'GET', 
            url: '/api/cities',
            token: undefined,
            body: undefined
        });

        console.log('response', response);
    }

    React.useEffect(() => {
        refresh();
    }, []);

    return(
        <div className={styles.main}>
            <title>{t('register.title')}</title>
            <div className='col-span-0' />
            <div className='col-span-3'>
                <p className={styles.title}>{t('register.title')}</p>
                <p className={styles.pLogin}>{t('register.registered')} <label className='text-blue' onClick={() => {navigate('/login'); }}>{t('login.sign')}</label></p>
                <div className='grid grid-cols-1 laptop:grid-cols-2 mt-[40px] gap-x-[33px] gap-y-[20px]'>
                    <Form2 
                        label={t('register.name') + ":"} 
                        required
                        name="name"
                        type="text"
                        label2={t('register.enterName')}
                        value={name}
                        onChange={(event) => {setName(event.target.value); setErrorStates({...errorStates, name: false}); }}
                        errorStates={errorStates}
                    />
                    <Form2 
                        label={t('register.surname') + ":"} 
                        name="surname"
                        type="text"
                        label2={t('register.enterSurname')}
                        value={surname}
                        onChange={(event) => { setSurname(event.target.value); setErrorStates({...errorStates, surname: false}); }}
                        errorStates={errorStates}
                    />
                    <DropDownForm 
                        label={t('register.sex') + ":"}
                        required 
                        name="gender"
                        type="checkbox"
                        value={gender}
                        onChange={(value) => { setGender(value.name); setErrorStates({...errorStates, gender: false}) }}
                        data={[{name: 'Мужской'}, {name: 'Женский'}]}
                        errorStates={errorStates}
                    />
                    <Form2 
                        label={t('register.birthdate') + ":"} 
                        required
                        name="birthdate"
                        type="date"
                        label2={t('register.enterBirthdate')}
                        value={birthdate}
                        onChange={(event) => { setBirthdate(event.target.value); setErrorStates({...errorStates, birthdate: false}); }}
                        errorStates={errorStates} 
                    />
                    <DropDownForm 
                        label={t('register.location') + ":"}
                        required 
                        name="city"
                        type="checkbox"
                        value={city}
                        onChange={(value) => { setCityId(value.id); setCity(value.name); setErrorStates({...errorStates, city: false}); }}
                        data={citiesRedux}
                        errorStates={errorStates}
                    />
                </div>
                <div className='grid grid-cols-1 laptop:grid-cols-2 mt-[20px] laptop:mt-[40px] gap-x-[33px] gap-y-[20px]'>
                    <Form2 
                        label={t('register.email') + ":"} 
                        required
                        name="email"
                        type="email"
                        label2={t('register.enterEmail')}
                        value={email}
                        onChange={(event) => { setEmail(event.target.value); setErrorStates({...errorStates, email: false}) }}
                        errorStates={errorStates}
                    />
                    <div>
                        <Phone 
                            label={t('register.phone')}
                            type="number"
                            label2={t('register.enterPhone')}
                            value={phone}
                            onChange={(event) => { setPhone(event.target.value); setErrorStates({...errorStates, phone: false}); }}
                        />
                        <p className='text-[12px] mt-[5px] text-grey'>Пожалуйста, укажите действующий номер телефона, чтобы мы смогли подтвердить запись и отправить вам напоминание накануне консультации После</p>
                    </div>
                </div>
                <div className='grid grid-cols-1 laptop:grid-cols-2 mt-[15px] laptop:mt-[20px] gap-x-[33px] gap-y-[20px]'>
                    <PasswordForm 
                        label={t('register.create') + ":" }
                        required
                        name="password"
                        type="password"
                        label2={t('register.enterPass')}
                        value={password}
                        onChange={(event) => { setPassword(event.target.value); setErrorStates({...errorStates, password: false}) }}
                        errorStates={errorStates}
                    />
                    <PasswordForm
                        label={t('register.repeat') + ":"} 
                        required
                        name="repeat"
                        type="password"
                        label2={t('register.enterRepeat')}
                        value={repeat}
                        onChange={(event) => { setRepeat(event.target.value); setErrorStates({...errorStates, repeat: false}); }}
                        errorStates={errorStates}
                    />
                </div>
                <p className={`mt-0 laptop:mt-[20px] text-14 font-regular text-center text-red ${Object.values(errorStates).reduce((accumulator, currentValue) => {if(currentValue === true) {return accumulator + 1} else {return accumulator}}, 0) > 0? 'visible': 'invisible'}`}>{t('register.fillAll')}</p>
                <CustomPicker 
                    value={emailFlag}
                    onChange={(value) => {setEmailFlag(value); }}
                    text={() => (<p className='text-[12px] laptop:text-[14px] font-regular'>{t('register.getEmailNot')}</p>)}
                />
                <CustomPicker
                    value={registerFlag}
                    onChange={(value) => {setRegisterFlag(value); }} 
                    text={() => (<p className='text-[12px] laptop:text-[14px] font-regular'>Нажимая на кнопку Зарегистрироваться, я принимаю условия <label className='text-blue' onClick={() => {navigate('/privacy-policy')}}>политики конфиденциальности</label> и <label className='text-blue' onClick={() => {navigate('/term-of-use')}}>пользовательского соглашения</label></p>)}
                />
                <div className='mt-[29px] laptop:mt-[50px] flex items-center justify-center'>
                    <button
                        className={`h-[60px] ${
                            name.length > 0 && 
                            gender !== '' && 
                            cityId !== 0 &&
                            (birthdate !== null && birthdate !== '') && 
                            password === repeat &&
                            phone.length === 11 &&
                            password.length >= 6 &&
                            registerFlag === true &&
                            email.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)?
                                'bg-blue':
                                'bg-grey'
                            } rounded-[19px] text-white font-medium text-[16px] w-full laptop:w-[289px] flex flex-col items-center justify-center`}
                        disabled={
                            name.length > 0 &&
                            gender !== '' &&
                            cityId !== 0 &&
                            (birthdate !== null && birthdate !== '') &&
                            password === repeat &&
                            password.length >= 6 &&
                            phone.length === 11 &&
                            registerFlag === true &&
                            email.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)?
                                false:
                                true
                        }
                        onClick={async () => {
                            try {
                                setLoading(true);
                                const response = await request({
                                    method: 'POST',
                                    url: '/api/auth/register',
                                    token: undefined,
                                    body: {
                                        role: 'client',
                                        first_name: name,
                                        last_name: surname,
                                        middle_name: midname,
                                        sex: gender === 'Мужской'? 'MALE': "FEMALE",
                                        birthdate: birthdate.toString(),
                                        city_id: cityId,
                                        email: email,
                                        phone: phone,
                                        notification_enabled: emailFlag === 'false'? false: true,
                                        password: password,
                                        password_confirmation: repeat
                                    }
                                });
                                console.log('response', response);
                                if(response.status === 200) {
                                    dispatch(onSignInSuccess(response.data.token));
                                    dispatch(setUser({
                                        role: 'psych',
                                        first_name: name,
                                        last_name: surname,
                                        middle_name: midname,
                                        sex: gender === 'Мужской'? 'MALE': "FEMALE",
                                        birthdate: birthdate.toString(),
                                        city_id: 1,
                                        email: email,
                                        phone: phone,
                                        notification_enabled: true    
                                    }));
                                    setLoading(false);
                                    alert('На вашу электронную почту придет сообщение с подтверждением');
                                    navigate('/');
                                } else {
                                    if(response.data.message.includes('users_email_unique')) {
                                        setModal(true);
                                    }
                                }
                            } catch(e) {
                                console.log('e', e);
                            } finally {
                                setLoading(false);
                            }
                        }}
                    >{
                        loading?
                            <Loading  />:
                            t('register.register')
                    }</button>
                </div>
                {
                    modal && <div className='fixed w-screen h-full py-[30px] px-[20px]  top-0 left-0 flex flex-col items-center justify-center bg-[#00000020]'>
                        <div className='mobile:w-[90%] laptop:w-[500px] desktop:w-[500px] tablet:w-[500px] h-[130px] shadow-[0_4px_15px_-0px_rgba(105,105,105,0.25)] rounded-[12px] flex flex-col items-center justify-center bg-white'>
                        <p className='text-center'>{t('register.emailExist')}</p>
                        <div className='flex flex-row items-center justify-center'>
                        <button className='w-[100px] h-[40px] rounded-[12px] mt-[10px] text-white bg-blue self-center' onClick={() => setModal(false)}>{t('register.ok')}</button>
                        </div>
                        </div>
                    </div>
                }
            </div>
            <div className='col-span-0' />
        </div>
    );
}

const styles = {
    main: 'container mx-auto grid grid-cols-1 laptop:grid-cols-3',
    title: 'text-16 laptop:text-35 font-medium text-center mt-[70px] laptop:mt-[90px]',
    pLogin: 'text-12 laptop:text-14 text-center mt-[17px] laptop:mt-[15px]',
    div: 'grid grid-cols-1 laptop:grid-cols-2 gap-0 laptop:gap-[15px]',

};

const Phone = (props) => {
    const { 
        value, onChange,
    } = props;
    const ref = React.useRef(null);

    return(
        <div className=''>
            <p className='flex text-[14px] font-medium'>{t('register.phone')}: <p className='text-red ml-[5px]'>*</p></p>
            <div 
                className={`flex flex-col h-[60px] laptop:h-[67px] border-blue border-[1px] pl-[53px] pr-[20px] justify-center rounded-[19px] mt-0 laptop:mt-[10px] bg-white-input`}
                onClick={() => {ref.current?.focus(); }}    
            >
                <p className='text-[10px] laptop:text-[12px] text-blue'>{t('register.enterPhone')}</p>
                <input 
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    type={'tel'}
                    pattern="[0-9]{3}"
                    autoComplete='on'
                    maxLength={'11'}
                    className={`focus:outline-none bg-white-input text-[14px] laptop:text-[16px] font-regular`}
                />
            </div>
            
        </div>
    );
};

const PasswordForm = (props) => {
    const {
        value, onChange, label, label2, required,
        errorStates, name
    } = props;
    const [visible, setVisible] = React.useState(false);
    const ref=React.useRef(null);

    return(
        <div>
            <div className='flex flex-row'>
                <p className='flex text-[14px] font-medium'>{label}</p>
                {required? <p className='text-red ml-[5px]'>*</p>: <></>}
            </div>
            <div 
                className={`${errorStates[name]? 'border-red': 'border-blue'} border-[1px] ${errorStates[name]?'bg-red2': 'bg-white-input'} rounded-[19px] pl-[53px] pr-[20px]  mt-0 laptop:mt-[10px] h-[60px] laptop:h-[67px] flex flex-col justify-center`}
                onClick={() => { ref.current?.focus(); }}
            >
                {label2? 
                    <p className='text-[10px] laptop:text-[12px] text-blue'>{label2}</p>:
                    <></>
                }
                <div className='flex flex-row '>
                    <input 
                        ref={ref}
                        className={`focus:outline-none flex-1 ${errorStates[name]?'bg-red2-input': 'bg-white-input'}`}
                        value={value}
                        onChange={onChange}
                        type={visible?'text': 'password'}
                    />
                    <button 
                        className='focus:outline-none'
                        onClick={() => { setVisible(!visible); }}
                    >
                    {
                        visible? <img src={viewPass} />: <img src={Eye} />
                    }
                    </button>
                </div>
                
            </div>
        </div>
    );
}

const DropDownForm = (props) => {
    const {
        value, onChange, label2, label, required,
        data, errorStates, name
    } = props;
    
    return(
        <div>
            <div className='flex flex-row'>
                <p className='flex text-[14px] font-medium'>{label}</p>
                {required? <p className='text-red ml-[5px]'>*</p>: <></>}
            </div>
            <div className={`${errorStates[name]? 'border-red': 'border-blue'} border-[1px] ${errorStates[name]?'bg-red2': 'bg-white-input'} rounded-[19px] mt-0 laptop:mt-[10px] h-[60px] laptop:h-[67px] flex flex-col justify-center pl-[53px] pr-[20px]`}>
                {label2? 
                    <p className='text-[10px] laptop:text-[12px] text-blue'>{label2}</p>:
                    <></>
                }
                <DropDown 
                    value={value}
                    data={data}
                    placeholder="Пол"
                    onChange={onChange}
                    styleButton={[errorStates[name]? 'bg-red2-input':'bg-white-input', 'w-full', 'text-start', 'mt-[0px]']}
                    styleButtonMain={['border-white', 'w-full', 'mt-[0px]']}
                    styleMenuItem={['w-full', 'text-14', 'font-medium', 'w-full']}
                    styleMenu={['justify-between', 'mt-[0px]']}
                />
            </div>
        </div>
    );
}

const Form2 = (props) => {
    const {
        label, label2, required, name,
        type, value, onChange, errorStates,
    } = props;
    const ref = React.useRef(null);

    return(
        <div className=''>
            <div className='flex flex-row'>
                <p className='flex text-[14px] font-medium'>{label}</p>
                {required? <p className='text-red ml-[5px]'>*</p>: <></>}
            </div>
            <div 
                className={`${errorStates[name] && required?'border-red': 'border-blue'} border-[1px] ${errorStates[name] && required?'bg-red2': 'bg-white-input'} rounded-[19px] pl-[53px] pr-[20px]  mt-0 laptop:mt-[10px] h-[60px] laptop:h-[67px] flex flex-col justify-center`}
                onClick={() => {ref.current?.focus(); }}    
            >
                {label2? 
                    <p className='text-[10px] laptop:text-[12px] text-blue'>{label2}</p>:
                    <></>
                }
                {
                    name === 'name' || name === 'surname' || name === 'phone' || name === 'email'?
                        <input 
                            ref={ref}
                            className={`focus:outline-none ${errorStates[name] && required? 'bg-red2-input': 'bg-white-input'} text-[14px] laptop:text-[16px]`}
                            value={value}
                            onChange={onChange}
                            type={type}
                            maxLength={name === 'phone'? '11': undefined}
                        />:
                        <input 
                            ref={ref}
                            className={`focus:outline-none ${errorStates[name] && required? 'bg-red2-input': 'bg-white-input'} text-[14px] laptop:text-[16px] font-regular`}
                            type={'date'}
                            value={value}
                            onChange={onChange}
                        />
                }
            </div>
           
        </div>
    );
}
