import * as React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Pagination, Controller } from 'swiper';
import { useNavigate } from 'react-router-dom';

import "swiper/css";
import "swiper/css/pagination";
import { GetData } from '../../requests/get';
import { useSelector } from 'react-redux';

import emptyImg from '../../assets/icons/emptyImg.svg';
import { getPsychFullName } from '../../configs/functions';

import {useTranslation} from 'react-i18next';
import { Spinner } from 'react-activity';

export const NewsClient = () => {
    const [search, setSearch] = React.useState('');
    const token = useSelector(state => state.auth.session.token);

    const psych = useSelector(state => state.psych.psych);
    const [dataNews, setDataNews] = React.useState([]);
    const [dataArticle, setDataArticle] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [swipeState, setSwipeState] = React.useState(false);

    const refRepeat = React.useRef(false);
    const refSwipe = React.useRef(null);
    const {t} = useTranslation();

    const navigate = useNavigate();

    React.useEffect(() => {
        if(refRepeat.current === false) {
            setLoading(true);
            GetData(`/api/posts`, token, (json, statusCode) => {
                if(statusCode === 200) {
                    setDataArticle(json.data);

                    setLoading(false);    
                } else {
                    setLoading(false);
                }
            }, (e) => {
                console.log('e', e);
                setLoading(false);
            });

            GetData(`/api/news`, token, (json, statusCode) => {
                if(statusCode === 200) {
                    setDataNews(json.data);

                    setLoading(false);    
                } else {
                    setLoading(false);
                }
            }, (e) => {
                console.log('e', e);
                setLoading(false);
            });
        }
        return () => refRepeat.current = true;
    }, [token]);

    return(
        <div className=''>
            <title>{t('news.news')}</title>
            <div className='container mx-auto h-[43px] flex desktop:hidden flex-row items-center bg-white-input mt-[23px] pl-[17px] rounded-[19px]'>
                <svg 
                    width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.4948 24.4987L19.2611 19.2557L24.4948 24.4987ZM22.1615 12.2487C22.1615 14.8788 21.1167 17.4011 19.2569 19.2608C17.3972 21.1206 14.8749 22.1654 12.2448 22.1654C9.61473 22.1654 7.09239 21.1206 5.23265 19.2608C3.37291 17.4011 2.32813 14.8788 2.32812 12.2487C2.32813 9.61863 3.37291 7.09629 5.23265 5.23656C7.09239 3.37682 9.61473 2.33203 12.2448 2.33203C14.8749 2.33203 17.3972 3.37682 19.2569 5.23656C21.1167 7.09629 22.1615 9.61863 22.1615 12.2487V12.2487Z" stroke="#3A7DFF" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                <input 
                    className='bg-white-input flex-1 ml-[20px] mr-[20px] text-16 focus:outline-none'
                    value={search}
                    onChange={(event) => { setSearch(event.target.value); }}
                    placeholder={t('news.search')}
                />
            </div>
            <p className='container mx-auto mt-[26px] desktop:mt-[90px] text-12 desktop:text-50 font-medium'>{t('news.news')}</p>
            <div className='container mx-auto h-[78px] mt-[65px] hidden desktop:flex'>
                <input 
                    className='border-blue border-[1px] flex-1 mr-[10px] rounded-[19px] pl-[53px] pr-[53px] text-16 focus:outline-none' 
                    value={search}
                    onChange={(event) => {
                        setSearch(event.target.value);
                    }}
                    placeholder={t('news.search')}
                />
                <button 
                    className='bg-blue h-[78px] w-[78px] flex items-center justify-center rounded-[19px]'
                    onClick={() => {

                    }}
                >
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.4948 24.4987L19.2611 19.2557L24.4948 24.4987ZM22.1615 12.2487C22.1615 14.8788 21.1167 17.4011 19.2569 19.2608C17.3972 21.1206 14.8749 22.1654 12.2448 22.1654C9.61473 22.1654 7.09239 21.1206 5.23265 19.2608C3.37291 17.4011 2.32813 14.8788 2.32812 12.2487C2.32813 9.61863 3.37291 7.09629 5.23265 5.23656C7.09239 3.37682 9.61473 2.33203 12.2448 2.33203C14.8749 2.33203 17.3972 3.37682 19.2569 5.23656C21.1167 7.09629 22.1615 9.61863 22.1615 12.2487V12.2487Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </button>
            </div>
            <p className='container mx-auto mt-[15px] text-12 text-grey flex desktop:hidden'>{t('news.added')}</p>
            <div className='container mx-auto grid grid-cols-1 desktop:grid-cols-2 mt-[20px] desktop:mt-[96px] gap-[30px]'>
                <div className='hidden desktop:flex flex-col justify-between border-r-blue border-r-[1px]'>
                    <p className='text-16 text-grey font-thin'>{t('news.added')}</p>
                    <button className='h-[111px] bg-white-input flex items-center pl-[30px] text-blue text-16 font-medium'>
                        {t('news.viewAll')} 
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.5 18L28.5 18" stroke="#3A7DFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M18 7.5L28.5 18L18 28.5" stroke="#3A7DFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </div>
                <div>
                    {
                        loading? 
                        <Spinner/>
                        :null
                    }
                {
                    dataNews.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).filter((a, i) => i < 5).map((item, index) => 
                        <ItemNews 
                            key={index.toString()}
                            item={item}
                            other={dataNews.filter((n,i) => i !== index)}
                            index={index}
                            navigate={navigate}
                        />
                    )
                }
                </div>
            </div>
            <div className='mt-[30px] container mx-auto flex flex-1'>
                <button
                    className='h-[60px] rounded-[19px] text-blue text-16 font-medium desktop:hidden border-blue border-[1px] flex-1'
                    onClick={() => {  }}
                >{t('news.viewAll')}</button>
            </div>
            
            {
                token?
                <div className='desktop:pt-[50px] desktop:pb-[76px] bg-white desktop:bg-white-input mt-[35px] desktop:mt-[65px]'>
                    <div className='container mx-auto'>
                        <p className='text-16 desktop:text-50 font-medium'>{t('news.articles')}</p>
                    </div>
                    <div className='container mx-auto hidden desktop:flex flex-row justify-end'>
                        <div className='container mx-auto  mt-[20px] flex h-[60px] flex-col items-end'>
                            <button
                                className='border-blue min-w-[230px] border-[1px] text-blue flex flex-1 items-center justify-center rounded-[19px]'
                                onClick={() => { navigate('/articles'); }}
                            >{t('news.viewAll')}</button>
                        </div>
                    </div>
                    <div className='container mx-auto items-center content-center'>
                        <Swiper 
                            className='' 
                            ref={refSwipe}
                            spaceBetween={30}
                            height={200}
                            onSwiper={(swiper) => {
                                setSwipeState(!swipeState);
                            }}
                            onSlideChange={(slide) => {
                                setSwipeState(!swipeState);
                            }}
                            modules={[Pagination, Controller]}
                            controller={{
                                control: true
                            }}
                        >
                        {
                            dataArticle.filter(item => item.verified).reduce(function (accumulator, currentValue, currentIndex, array) {
                                if(currentIndex % 2 === 0) {
                                    accumulator.push(array.slice(currentIndex, currentIndex + 2))
                                }
                                return accumulator;
                            }, []).filter((a,i) => i < 1).map((item, index) => 
                                <SwiperSlide key={index.toString()} className='grid grid-cols-1 desktop:grid-cols-2 gap-[20px] desktop:gap-[33px]'>
                                    <div 
                                        className='border-grey-divide desktop:border-white-input border-b-[1px] pb-[10px] desktop:pb-0'
                                        onClick={() => {navigate('/article/'+item[0].id, {state: item[0]})}}
                                    >
                                        <p className='text-blue mt-[20px] text-20 flex desktop:hidden'>{item[0].title}</p>
                                        <p className='text-[13px] text-grey'>{t('news.author')}: {getPsychFullName(psych, item[0].user_id)}, {new Date(item[0].created_at).toLocaleDateString('ru-RU', {day: '2-digit', month: 'long'})}</p>
                                        <img 
                                            className='rounded-[19px] mt-[20px] laptop:h-[402px] w-full'
                                            src={item[0].cover_url? item[0].cover_url: emptyImg}
                                            alt=""
                                        />
                                        
                                        <p className='text-blue mt-[20px] text-20 hidden desktop:flex'>{item[0].title}</p>
                                        <div className='mt-[15px] text-black text-[12px] laptop:text-[16px] font-regular line-clamp-3' dangerouslySetInnerHTML={{__html: item[0].text}}></div>
                                    </div>
                                    {
                                        item[1] !== undefined?
                                        <div    
                                            className='border-grey-divide desktop:border-white-input border-b-[1px] pb-[10px] desktop:pb-0'
                                            onClick={() => { navigate('/article/'+item[1].id, {state: item[1]}); }}
                                        >
                                            <p className='text-blue mt-[20px] text-20 flex desktop:hidden'>{item[0].title}</p>
                                            <p className='text-[13px] text-grey'>{t('news.author')} {getPsychFullName(psych, item[0].user_id)}, {new Date(item[1].created_at).toLocaleDateString('ru-RU', {day: '2-digit', month: 'long'})}</p>
                                            <img 
                                                className='rounded-[19px] mt-[20px] laptop:h-[402px] w-full'
                                                src={item[1].cover_url? item[1].cover_url: emptyImg}
                                                alt=""
                                            />
                                            <p className='text-blue mt-[20px] text-20 hidden desktop:flex'>{item[1].title}</p>
                                            <div className='mt-[15px] text-black text-[12px] laptop:text-[16px] font-regular line-clamp-3' dangerouslySetInnerHTML={{__html: item[0].text}}></div>
                                        </div>:<></>
                                    }
                                </SwiperSlide>
                            )
                        }
                        </Swiper>
                    </div>
                </div> : <></>
            }
        </div>
    );
};

const ItemNews = (props) => {
    const {item, index, other, navigate} = props;

    return(
        <div 
            key={index.toString()} 
            className={`border-b-blue border-b-[1px] pb-[25px] ${index === 0? '': 'pt-[30px]'}`}
            onClick={() => {
                navigate(`/news/${item.id}`,{state: {data: item, other}});
            }}
        >
            <p className='text-grey text-[13px] hidden desktop:flex'>{new Date(item.created_at).toLocaleDateString('ru-RU', {day: '2-digit', month: 'long', year: 'numeric'})}</p>
            <div className='flex flex-col desktop:grid grid-cols-0 desktop:grid-cols-10 gap-0 desktop:gap-[20px] mt-[3px]'>
                <p className='text-14 desktop:text-20 col-span-4 font-regular text-blue desktop:text-black'>{item.title}</p>
                <p className='text-12 flex desktop:hidden text-grey mt-[6px] font-thin'>{new Date(item.date).toLocaleDateString('ru-RU', {day: '2-digit', month: 'long', year: 'numeric'})}</p>
                <div className='text-16 mt-[15px] desktop:mt-0 col-span-6 font-regular'><div dangerouslySetInnerHTML={{__html: item.text}}></div></div>
            </div>
        </div>
    );
};