export const factsStatic = [
    {
        id: 1,
        title: 'Наука «Психология»',
        text: `
        Путь развития психологической науки был более трудным, чем развитие других наук, например физики или химии. Понять причины этого различия нетрудно. Ведь, как хорошо известно, объекты физики, химии, других естественных наук, так или иначе видимы, осязаемы, материальны. Психология же имеет дело с субстанцией, которая, хотя и постоянно обнаруживает себя, тем не менее выступает как особая реальность высшего уровня и отличается от материальной реальности своей невидимостью, неосязаемостью, невещественностью.
        Именно это различие, порождая трудности фиксации психологических явлений, и затрудняло с самого начала развитие психологического знания, его превращение в самостоятельную науку, поскольку сам объект его долгое время представлялся неуловимым, таинственным.
        История психологического знания насчитывает более 2000 лет, на протяжении которых она развивалась главным образом в рамках философии и естествознания.
        Однако лишь с начала XX в. психология окончательно выделилась в самостоятельную науку. Па рубеже XX-XXI вв. существенно возросло значение психологии в связи со все большей ее включенностью в различные виды практической деятельности. Возникли такие ее отрасли, как педагогическая, юридическая, военная, управленческая, спортивная психология и т.д. Вместе с тем своеобразие объекта психологической науки породило в ее составе большое число научных школ и теорий, дополняющих друг друга и нередко противоречащих друг другу.
        Смысл самого слова «психология» становится понятным, если учесть, что оно состоит из двух греческих терминов: «psyche» — душа, происходящего от имени греческой богини Психеи, и «logos» - слово, понятие, учение, наука.
        Психология, есть наука, объектом которой являются закономерности психики как особой, высшей формы жизнедеятельности человека и животных.
        
        
        Сама же психика сегодня понимается не как нечто таинственное и необъяснимое, а как возникшая в результате длительного процесса самоорганизации природы высшая форма взаимосвязи живых существ с предметным миром, выраженная в их способности реализовывать свои побуждения на основе информации об этом мире.
        На уровне человека, выражающего высшую ступень процесса организации, упорядоченности бытия, психика приобретает качественно новый характер в силу того, что биологическая природа человека преобразуется социокультурными факторами, благодаря которым возникает обширный внутренний план жизнедеятельности — сознание, а человек становится личностью.
        История становления и развития психологических знаний представляет собой, хотя и сложный, противоречивый, но вполне логичный процесс, связанный с изменением понимания сущности человека и формированием на этой основе новых объяснений его психики.
        В этом процессе обычно выделяют три основных исторических этапа, которым соответствуют три формы психологического знания:
            •	донаучная, или житейская, психология;
            •	психология в рамках философского знания;
            •	научная психология.
        `,
        date: new Date(2022, 11, 15, 18, 0)
    }, 
    {
        id: 2,
        title: 'Миф о Психеи',
        text: `
        Своим названием психология обязана греческой мифологии. Согласно одному из мифов, бог любви Эрот влюбился в простую крестьянку Психею. отличавшуюся, однако, божественной красотой. Но мать Эрота богиня Афродита была очень недовольна тем, что ее сын. небожитель, захотел соединить свою судьбу с простой смертной. Афродита стала прилагать усилия, чтобы разлучить влюбленных. Она заставила Психею пройти через множество испытаний. Но стремление Психеи соединить свою судьбу с Эротом оказалось столь велико, что это произвело сильное впечатление на богов Олимпа и они решили помочь Психею преодолеть все выпавшие на ее долю испытания и выполнить нее требования Афродиты. А тем временем Эроту удалось убедить верховного Бога — Зевса превратить Психею в богиню, сделать ее такой же бессмертной, как боги. Так влюбленным удаюсь соединиться навеки.
        Хотя содержание этого мифа представляется весьма наивным, тем не менее в нем можно обнаружить не только описание человеческой любви, но и весьма глубокую догадку о нерасторжимом единстве духовного начала бытия, которое выражено образом Психеи, с другим, противоположным началом, материальным, телесным, которое выражает Эрот.
        `,
        date: new Date(2022, 11, 15, 18, 0)
    },  
    {
        id: 2,
        title: 'Факты о психологии человека',
        text: `
        1. Мозговая деятельность продолжается даже тогда, когда мы отдыхаем. В этот момент происходит фильтрация того, что действительно нужно отложить в памяти, а что убрать «про запас». Проснувшись утром вы никогда не забудете то, что действительно важно для вас, но и не вспомните того, на чем остановили свое внимание вчера. 
        2. Человек чувствует себя более успешным и счастливым только тогда, когда его мозг чем-то занят. Более того, серое вещество не интересует монотонная работа — речь идет как раз о постоянной суете концентрации и переключения с одной работы на другую. Только в такие моменты человек чувствует себя счастливым. 
        3. Всем известно о семи смертных грехах, чего не скажешь о шести универсальных эмоциях, которые испытывают все без исключения люди. Это счастье, гнев, печаль, страх, отвращение и удивление.
        4. Логика начинает работать лучше, когда мы думаем на другом языке. Вспомните, как напрягается мозг при попытке подыскать в словарном запасе слово, наиболее точно характеризующее то, что вы пытаетесь сказать. 
        5. Сто пятьдесят — именно со столькими людьми мы способны поддерживать устойчивые и близкие отношения. Но никак не больше.
        6. Волонтёры, а также люди, которые добровольно помогают другим, более удовлетворены своей жизнью.
        `,
        date: new Date(2022, 11, 15, 18, 0)
    }
]