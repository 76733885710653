import * as React from 'react';
import sendComment from '../assets/icons/sendComment.svg';
import arrowDown from '../assets/icons/ArrowDown.svg';
import Send from '../assets/icons/send.svg';
import fiUser from '../assets/icons/fi_user.svg';
import {CommentInput} from './CommentInput';
import { PostData } from '../requests/post';

export const CommentComponent = (props) => {
    const {item, index, token, onSend} = props;
    const [viewAll, setViewAll] = React.useState(false);
    const [answer, setAnswer] = React.useState(false); 

    const [answerComment, setAnswerComment] = React.useState('');
    const refAnswerComment = React.useRef(null);

    return(
        <div 
            key={index.toString()}
            className={styles.main}
            onClick={() => {

            }}    
        >
            <div className={styles.divUser}>
                <Avatar 
                    src={item.user.avatar_url}
                />
                <div className={styles.divNameDate}>
                    <p className={styles.name}>{item.user.first_name}{item.user.last_name? ' ' + item.user.last_name: ''}</p>
                    <p className={styles.date}>{new Date(item.created_at).toLocaleDateString('ru-RU', {day: '2-digit', month: 'long', year: 'numeric'})}</p>
                </div>
            </div>   
            <p className={styles.text}>{item.text}</p> 
            <button
                className={styles.btnAnswer}
                onClick={() => { setAnswer(true); }}
            >Ответить</button>
            {
                answer?
                    <div 
                        onClick={() => {refAnswerComment.current?.focus(); }} 
                        className={styles.divAnswer}
                    >
                        <div className={styles.divInput1}>
                            <p className={styles.pWrite}>Написать комментарий</p>
                            <input 
                                ref={refAnswerComment}
                                className={styles.inputAnswerComment}
                                value={answerComment}
                                onChange={(event) => {setAnswerComment(event.target.value); }}
                                placeholder='Написать ответ'
                            />
                        </div>
                        
                        <button 
                            className={styles.btnSend(answerComment)}
                            onClick={() => {
                                if(answerComment.length > 0) {

                                    PostData(`/api/comments/${item.id}/replies`, token, JSON.stringify({
                                        text: answerComment
                                    }), (json, statusCode) => {
                                        if(statusCode === 200) {
                                            setAnswerComment('');
                                            setAnswer(false);
                                            onSend();
                                        }
                                    }, (e) => {
                                        console.log('e ', e);
                                    })
                                } else {

                                }
                            }}    
                        >
                            <img className={``} alt="image1" src={Send} />
                        </button>
                        <div className={styles.divBtns}>
                            <button
                                className={styles.btnCancel}
                                onClick={() => { setAnswerComment(''); setAnswer(false); }}
                            >Отмена</button>
                            <button
                                className={styles.btnSend2(answerComment)}
                                onClick={() => {
                                    if(answerComment.length > 0) {
                                        PostData(`/api/comments/${item.id}/replies`, token, JSON.stringify({
                                            text: answerComment
                                        }), (json, statusCode) => {
                                            if(statusCode === 200) {
                                                setAnswerComment('');
                                                setAnswer(false);
                                                onSend();
                                            }
                                        }, (e) => {
                                            console.log('e ', e);
                                        })
                                    } else {

                                    }
                                }}
                            ><img src={sendComment} alt="image2" /></button>
                        </div>
                    </div>:<></>
            }
            {
                item.replies.length > 0?
                    <div className='border-b-[rgba(77, 158, 253, 0.3)] border-b-[1px] pb-[30px]'>
                        <div className={styles.divReplies}>
                            <button
                                className={styles.btnViewAll}
                                onClick={() => {
                                    setViewAll(!viewAll);
                                }}
                            >{item.replies.length} {commentText(item.replies.length)}</button>
                            <img className={styles.imgViewAll(viewAll)} alt="image3" src={arrowDown} />
                        </div>
                        {
                            viewAll?
                                <div>
                                {
                                    item.replies.map((item2, index2) => 
                                        <RepliesComponent 
                                            item2={item2}
                                            index2={index2}
                                            index={index}
                                            key={index.toString() + index2.toString()}
                                            onSend={(text, setText) => {
                                                PostData(`/api/comments/${item.id}/replies`, token, JSON.stringify({
                                                    text: text
                                                }), (json, statusCode) => {
                                                    if(statusCode === 200) {
                                                        setText('');
                                                        onSend();
                                                    }
                                                }, (e) => {
                                                    console.log('e ', e);
                                                })
                                            }}
                                        />
                                    )
                                }
                                </div>:
                                <></>
                        }
                    </div>
                    :
                    <></>
            }
        </div>
    );
};

const styles = {
    main: 'container mx-auto mt-[20px] laptop:mt-[30px]',
    divUser: 'flex flex-row items-center justify-start',
    avatar: 'hidden laptop:flex w-[40px] h-[40px] rounded-[20px] bg-red',
    divNameDate: 'laptop:ml-[5px]',
    name: 'text-[14px] laptop:text-[20px] font-regular text-black',
    date: 'text-[12px] laptop:text-[13px] font-regular text-grey mt-[2px] laptop:mt-[5px]',
    text: 'text-[12px] laptop:text-[16px] font-regular mt-[15px] laptop:mt-[10px]',
    btnAnswer: 'mt-[5px] laptop:mt-[10px] text-blue laptop:text-grey text-[12px] laptop:text-[13px] font-regular',
    divAnswer: 'border-white laptop:border-blue-border border-[1px] rounded-[19px] laptop:shadow-[0_4px_15px_-0px_rgba(26,132,255,0.18)] laptop:pl-[40px] laptop:pr-[15px] py-0 laptop:py-[27px] mt-[10px] bg-white laptop:bg-white-input flex flex-row laptop:flex-col',
    divInput1: 'h-[60px] laptop:h-fit flex flex-col justify-center bg-white-input border-blue laptop:border-white-input border-[1px] rounded-[11px] px-[30px] laptop:px-0 w-full',
    pWrite: 'flex laptop:hidden text-blue text-[10px] font-regular',
    inputAnswerComment: 'bg-white-input focus:outline-none w-full text-[14px] laptop:text-[16px] font-regular text-black',
    btnSend: (answerComment) => `ml-[8px] flex laptop:hidden flex-row items-center justify-center ${answerComment.length > 0? 'bg-blue': 'bg-grey'} w-[63px] h-[60px] rounded-[11px]`,
    divBtns: 'hidden laptop:flex flex-row items-center justify-end mt-[12px]',
    btnCancel: 'text-[16px] text-blue font-regular mr-[30px]',
    btnSend2: (answerComment) => `w-[35px] h-[35px] rounded-[35px] ${answerComment.length === 0? 'bg-grey': 'bg-blue'} flex flex-col items-center justify-center`,
    divReplies: 'flex flex-row items-center mt-[5px] laptop:mt-[10px]',
    btnViewAll: 'mr-[10px] text-blue text-[12px] laptop:text-[16px] font-regular',
    imgViewAll: (viewAll) => `w-[20px] h-[20px] ${viewAll? 'rotate-180': ''}`
};

const commentText = (length) => {
    
    switch(length) {
        case 1: return 'комментарий';
        case 2: case 3: case 4: return 'комментария';
        case 5: case 6: case 7: case 8: case 9: case 0: return 'комментариев'
        default: return 'комментариев';
    }
};

const Avatar = (props) => {
    const {src} = props;

    return(
        <div className='flex flex-col items-center justify-center w-[50px] h-[50px] rounded-[25px] shadow-[0_4px_15px_-0px_rgba(0,0,0,0.05)] mr-[10px]'>
            <img 
                className='w-[40px] h-[40px] rounded-[20px]'
                src={src? src: fiUser}
                alt="image4"
            />
        </div>
    );
};

const RepliesComponent = (props) => {
    const { item2, index2, index, onSend } = props;
    const [visibleAnswer, setVisibleAnswer] = React.useState(false);
    const [comment, setComment] = React.useState('');

    return(
        <div 
            key={index.toString() + index2.toString()}
            className='mt-[20px]'
        >
            <div className='flex flex-row'>
                <Avatar 
                    src={item2.user.avatar_url}
                />
                <div className=''>
                    <p className='text-[14px] laptop:text-[20px] font-regular text-black'>{item2.user.first_name}</p>
                    <p className='mt-[2px] laptop:mt-[5px] text-grey text-[12px] laptop:text-[13px]'>{new Date(item2.created_at).toLocaleDateString('ru-RU', {day: '2-digit', month: 'long', year: 'numeric'})}</p>
                </div>
            </div>
            <p className='mt-[15px] laptop:mt-[12px] text-[12px] laptop:text-[16px]'>{item2.text}</p>
            <button
                className='text-[12px] laptop:text-[13px] text-blue laptop:text-grey font-regular mt-[5px] laptop:mt-[10px]'
                onClick={() => {
                    setVisibleAnswer(!visibleAnswer);
                }}
            >{visibleAnswer? 'Скрыть': 'Ответить'}</button>
            {
                visibleAnswer?
                    <CommentInput 
                        comment={comment}
                        setComment={setComment}
                        onSend={() => {
                            onSend(comment, setComment);
                        }}
                    />:
                    <></>
            }
        </div>
    );
}