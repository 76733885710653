import * as React from 'react';
import { timeData } from '../../configs/functions';
import { Calendar } from '../Calendar';
import { Loading } from '../Loading';
import { TimePicker } from '../TimePicker';
import { useTranslation } from 'react-i18next';
import { request } from '../../requests/requests';

export const ModalRecordTransfer = (props) => {
    const {
        close,
        successFunction,
        id,
        token,
        idRecord,
        status
    } = props;

    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);
    const [date, setDate] = React.useState(new Date());
    const [data, setData] = React.useState([]);
    const [dateScreen, setDateScreen] = React.useState(false);
    const [windowItem, setWindowItem] = React.useState(null);
 
    const [chooseId, setChooseId] = React.useState(null);
    const {t} = useTranslation();

    React.useEffect(() => {
        if(refRepeat.current === false) {
            setLoading(true);
            (async function() {
                try {
                    setLoading(true);
                    let response = await request({
                        method: 'GET',
                        url: `/api/working-dates?user_id=${id}`,
                        token: token,
                        body: undefined
                    });
                    if(response.status === 200) {
                        setData(response.data.data);
                    }
                } catch(e) {} finally {
                    setLoading(false);
                }
                
            })()
        }
        return () => refRepeat.current = true;
        // eslint-disable-next-line
    }, []);

    const [comment, setComment] = React.useState('');
    const [confirmState, setConfirmState] = React.useState(false);

    if(confirmState) {
        return(
            <div className='bg-white mobile:w-[90%] tablet:w-[620px] laptop:w-[620px] desktop:w-[620px] py-[30px] rounded-[19px] px-[50px]'>
                <div className='flex flex-row justify-between'>
                    <div className='w-[30px]' />
                    <p className='text-[20px] font-medium text-black mx-[10px]'>{t('pageRecord.confirmMove')}</p>
                    <button
                        onClick={() => {close(); }}
                    >
                        <svg width="30" height="30" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M26.8433 4.15765C26.9336 4.24764 27.0051 4.35454 27.054 4.47224C27.1028 4.58993 27.128 4.7161 27.128 4.84353C27.128 4.97095 27.1028 5.09713 27.054 5.21482C27.0051 5.33251 26.9336 5.43942 26.8433 5.5294L5.53085 26.8419C5.34894 27.0238 5.10223 27.126 4.84497 27.126C4.58772 27.126 4.341 27.0238 4.1591 26.8419C3.97719 26.66 3.875 26.4133 3.875 26.156C3.875 25.8988 3.97719 25.6521 4.1591 25.4702L25.4716 4.15765C25.5616 4.06744 25.6685 3.99586 25.7862 3.94702C25.9039 3.89819 26.03 3.87305 26.1575 3.87305C26.2849 3.87305 26.4111 3.89819 26.5288 3.94702C26.6465 3.99586 26.7534 4.06744 26.8433 4.15765Z" fill="#1D1D1D"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.15961 4.15765C4.06939 4.24764 3.99781 4.35454 3.94898 4.47224C3.90014 4.58993 3.875 4.7161 3.875 4.84353C3.875 4.97095 3.90014 5.09713 3.94898 5.21482C3.99781 5.33251 4.06939 5.43942 4.15961 5.5294L25.4721 26.8419C25.654 27.0238 25.9007 27.126 26.158 27.126C26.4152 27.126 26.6619 27.0238 26.8439 26.8419C27.0258 26.66 27.128 26.4133 27.128 26.156C27.128 25.8988 27.0258 25.6521 26.8439 25.4702L5.53136 4.15765C5.44137 4.06744 5.33447 3.99586 5.21677 3.94702C5.09908 3.89819 4.97291 3.87305 4.84548 3.87305C4.71806 3.87305 4.59189 3.89819 4.47419 3.94702C4.3565 3.99586 4.2496 4.06744 4.15961 4.15765Z" fill="#1D1D1D"/>
                        </svg>
                    </button>
                </div>
                <p className='mt-[30px] text-[14px] laptop:text-[16px] font-regular text-grey'>Текущая</p>
                <div className='px-[19.5px] py-[13px] rounded-[19px] bg-white-input mt-[10px] text-[17px] font-regular text-blue'>с {new Date().toLocaleDateString('ru-RU', {weekday: 'short', day: '2-digit', month: 'long', hour: '2-digit', minute: '2-digit'})}</div>
                <p className='mt-[30px] text-[14px] laptop:text-[16px] font-regular text-grey'>Новая</p>
                <div className='px-[19.5px] py-[13px] rounded-[19px] bg-white-input mt-[10px] text-[17px] font-regular text-blue'>на {date.toLocaleDateString('ru-RU', {weekday: 'short', day: '2-digit', month: 'long'})}, {windowItem.from}</div>
                <p className='mt-[30px] text-[14px] laptop:text-[16px] font-regular text-grey'>Комментарий</p>
                <input 
                    value={comment}
                    onChange={(event) => {setComment(event.target.value); }}
                    className="border-blue border-[1px] rounded-[19px] h-[54px] laptop:h-[60px] mt-[10px] w-full text-16 focus:outline-none px-[20px]"
                />
                <div className='flex justify-center w-full mt-[50px]'>
                    <button
                        className='h-[60px] rounded-[19px] bg-blue text-white font-medium text-[16px] mx-[20px] w-full'
                        onClick={async () => {
                            try {
                                let response = await request({
                                    method: 'PUT', 
                                    url: `/api/records/${idRecord}`,
                                    token: token,
                                    body: {
                                        window_id: windowItem.id,
                                        user_note: comment,
                                        status: status
                                    }
                                });
                                if(response.status === 200) {
                                    successFunction();
                                }
                            } catch(e) {

                            }
                        }}
                    >{t('pageRecord.confirm')}</button>
                </div>
                
            </div>
        ); 
    } else {
        if(dateScreen) {
            return(
                <ChangeTime 
                    choose={chooseId}
                    token={token}
                    setConfirmState={setConfirmState}
                    windowItem={windowItem}
                    setWindowItem={setWindowItem}
                />
            )
        } else {
            return(
                <div className='bg-white mobile:w-[90%] tablet:w-[620px] laptop:w-[620px] desktop:w-[620px] py-[30px] rounded-[19px] px-[50px] mx-[20px] laptop:mx-0'>
                    <div className='flex flex-row justify-between items-center'>
                        <p className='text-[16px] laptop:text-[20px] font-medium text-black'>Перенос записи</p>
                    </div>
                    <Calendar 
                        date={date}
                        workingDates={data}
                        onChangeDate={(value, d) => {setDate(value); setChooseId(d.id); setDateScreen(true); console.log('cvalue', value, d);}}
                        loading={loading}
                    />
                </div>
            );
        }
    };
};

const ChangeTime = (props) => {
    const {
        choose, token, setConfirmState,
        windowItem, setWindowItem
    } = props;
    const [time, setTime] = React.useState({});
    const [windows, setWindows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const refRepeat = React.useRef(false);

    React.useEffect(() => {
        if(refRepeat.current === false) {
            setLoading(true);
            (async function() {
                try {
                    setLoading(true);
                    let response = await request({
                        method: 'GET',
                        url: `/api/working-dates/${choose}/windows`,
                        token: token,
                        body: undefined
                    });
                    console.log('response', response);
                    if(response.status === 200) {
                        setWindows(response.data.data);
                    }
                } catch(e){}
                finally {
                    setLoading(false);
                }
            })()
        }
        return () => refRepeat.current = true;
        // eslint-disable-next-line
    }, []);

    return(
        <div className='bg-white py-[30px] rounded-[19px] px-[50px] mx-[20px] laptop:mx-0'>
            <div className='flex flex-row justify-between items-center mb-[20px]'>
                <p className='text-[16px] laptop:text-[20px] font-medium text-black'>Перенос записи</p>
            </div>
            <TimePicker 
                times={timeData}
                value={time} 
                onChange={(value) => {setTime(value);}} 
                windows={windows}
                setWindowItem={setWindowItem}
            />
            <div className='flex flex-row items-center justify-center mt-[20px]'>
                <button
                    className={`h-[60px] laptop:h-[67px] ${windowItem === null? 'bg-grey': 'bg-blue'} rounded-[19px] w-[240px] px-[20px] text-white text-[14px] laptop:text-[16px] font-medium flex flex-row items-center justify-center`}
                    disabled={windowItem === null? true: false}
                    onClick={() => {
                        setConfirmState(true);
                    }}
                >{loading? <Loading fill="fill-grey" text="text-white"/>:'Выбрать'}</button>
            </div>
            
        </div>
    );
};