import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {factsStatic} from '../../configs/facts';

export const UsefulTips = () => {
    const [search, setSearch] = React.useState('');
    const [data, setData] = React.useState([]);
    const navigate = useNavigate();
    const {t} = useTranslation(); 

    const searchRef = React.useRef(null);

    React.useEffect(() => {
        setData(factsStatic);
    }, []);

    return(
        <div className='container mx-auto bg-white flex-1'>
            <title>{t('facts')}</title>
            <div onClick={() => { searchRef.current?.focus(); }} className='visible desktop:invisible mb-[26px] desktop:mb-0 border-blue border-[1px] h-[43px] rounded-[19px] mt-[23px] desktop:hidden flex flex-row items-center'>
                <svg
                    className='ml-[20px]' 
                    width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.4948 24.4987L19.2611 19.2557L24.4948 24.4987ZM22.1615 12.2487C22.1615 14.8788 21.1167 17.4011 19.2569 19.2608C17.3972 21.1206 14.8749 22.1654 12.2448 22.1654C9.61473 22.1654 7.09239 21.1206 5.23265 19.2608C3.37291 17.4011 2.32813 14.8788 2.32812 12.2487C2.32813 9.61863 3.37291 7.09629 5.23265 5.23656C7.09239 3.37682 9.61473 2.33203 12.2448 2.33203C14.8749 2.33203 17.3972 3.37682 19.2569 5.23656C21.1167 7.09629 22.1615 9.61863 22.1615 12.2487V12.2487Z" stroke="#3A7DFF" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                <input 
                    ref={searchRef}
                    className='ml-[20px] flex flex-1 mr-[20px] h-[41px] bg-white text-14 font-regular text-black focus:outline-none'
                    value={search}
                    onChange={(event) => {
                        setSearch(event.target.value);
                    }}
                    placeholder="Поиск"
                />
            </div>
            <p className='text-[16px] laptop:text-[35px] mt-[26px] laptop:mt-0 font-medium'><text className='text-blue'>{t('main.facts').split(' ')[0]}</text> {t('main.facts').substring(t('main.facts').indexOf(' '))}</p>
            <p className='text-20 hidden font-regular text-black laptop:grid mt-[29px]'></p>
            <div className='grid row-auto mt-[45px] laptop:mt-[90px]'>
            {
                data.map((item, index) => 
                    <Item 
                        key={index.toString()}
                        item={item}
                        index={index}
                        navigate={navigate}
                    />
                )
            }
            </div>
            <div className={`${data.length > 3? 'flex': 'hidden'} desktop:hidden flex flex-1 mt-[39px]`}>
                <button
                    className={`h-[60px] border-blue border-[1px] rounded-[19px] text-blue text-16 font-medium flex-1`}
                    onClick={() => {}}
                >{t('news.viewAll')}</button></div>
        </div>
    );
};

const Item = (props) => {
    const {item, index, navigate} = props;
    const [hoverActive, setHoverActive] = React.useState(false);

    return(
        <div onClick={(e) => { e.stopPropagation(); navigate(`/usefultips/${item.id}`, {
            state: item
        }); }} className={`${index === 0? 'border-t-0 border-t-blue desktop:border-t-[1px]': ''} border-b-blue border-b-0 desktop:border-b-[1px] pt-[25px] pb-[25px] flex desktop:grid flex-col desktop:grid-cols-7 gap-0 desktop:gap-10 content-center`} key={index.toString()}>
            <p className='col-span-2 text-14 desktop:text-20 font-medium text-blue desktop:text-black'>{item.title}</p>
            <p className='flex desktop:hidden mt-[6px] text-grey text-12'>{item.date.toLocaleDateString('ru-RU', {day: '2-digit', month: 'long', year: 'numeric'})}</p>
            <p className='mt-[15px] desktop:mt-0 desktop:col-span-4 text-12 desktop:text-16 line-clamp-3'>{item.text}</p>
            <div className='col-span-1 desktop:flex flex-1 justify-end items-center hidden '>
                <svg
                    onMouseEnter={() => { setHoverActive(true); }}
                    onMouseLeave={() => { setHoverActive(false); }}
                    className={`bottom-[28px] fill-none  rounded-[50%] ${hoverActive ? 'bg-blue ' : 'bg-transparent'}  stroke-blue`} 
                    width="68" height="68" 
                    viewBox="0 0 68 68" xmlns="http://www.w3.org/2000/svg"
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/usefultips/${item.id}`, {state: item});
                    }}
                >
                    <rect x="1" y="1" width="66" height="66" rx="33" stroke={hoverActive ? 'stroke-white' : 'stroke-blue'} />
                    <path d="M26.7109 26.7129H41.2862V41.2882" strokeWidth="2" stroke={hoverActive? 'white': 'stroke-blue'} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M26.7109 41.2871L41.2862 26.7118" strokeWidth="2" stroke={hoverActive? 'white': 'stroke-blue'} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>
    );
}