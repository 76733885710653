import React from "react";
import { Link, useNavigate } from "react-router-dom";

export const TermOfUse  = () => {
    return(
        <div className="desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]">
            
            <title>Пользовательское соглашение</title>
            
            <div className='container mx-auto items-center content-center '>
                <div className="flex flex-row">
                    <div className="text-blue mobile:text-16 tablet:text-16 laptop:text-[50px] desktop:text-[50px] font-medium">Пользовательское  </div>
                    <div className="mobile:text-16 desktop:text-[50px] laptop:text-[50px] tablet:text-16 font-medium desktop:ml-[13px] mobile:ml-[5px]">соглашение</div>
                </div>
                <div className="desktop:mt-[100px] laptop:mt-[60px] tablet:mt-[40px] mobile:mt-[20px]">
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        Настоящее Пользовательское соглашение (актуальная редакция соглашения, опубликованная	по ссылке (адрес ссылки)	является
                        публичной офертой и определяет условия использования веб-сайта ГБУ РС(Я) «Центр социально-психологической поддержки семьи и молодежи» (далее -  Республиканский психологический центр), доступного по ссылке http://psysakha.ru/ (далее — Сайт), любыми пользователями сети Интернет, просматривающими Сайт (далее — Пользователи Сайта).
                        Настоящее Пользовательское соглашение приравнивается к договору, составленному в письменной форме. Принимая настоящее Пользовательское соглашение, Пользователь выражает полное и безоговорочное согласие со всеми его условиями, в том числе, в части предоставления согласия на обработку персональных данных Пользователя на условиях, указанных в разделе 2 настоящего Пользовательского соглашения. В случае несогласия с данными условиями Пользователь должен покинуть Сайт.
                    </p>
                </div>
                
                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">1.	Общие положения</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        1.1.	Настоящее Пользовательское соглашение вступает в силу с момента размещения его на Сайте и действует в отношении всей информации, размещенной на сайте в информационно-телекоммуникационной сети «Интернет».
                        <br/>1.2.	Пользователь принимает условия настоящего Пользовательского соглашения в полном объеме путем нажатия кнопки «Согласен» после подтверждения ознакомления с настоящим Пользовательским соглашением. Пользователь подтверждает свое ознакомление и согласие на использование файлов cookie
                        <br/>1.3.	Сайт использует сервисы веб-аналитики Яндекс.Метрика и «Google Analytics». Собранная при помощи cookie информация не может идентифицировать Пользователя, при этом направлена на улучшение работы сайта. Информация об использовании Сайта, собранная при помощи cookie, будет передаваться Яндексу и Google для обработки и оценки использования Сайта, составления отчетов о деятельности Сайта.
                    </p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">2.	Персональные данные</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        2.1.	В случае если отдельные сервисы Сайта предусматривают ввод персональных данных, такие персональные данные хранятся 
                        и обрабатываются в соответствии с принципами и правилами обработки персональных данных, предусмотренными Федеральным законом Российской Федерации от 27 июля 2006 г. № 152-ФЗ «О персональных данных».

                        <br/>2.2.	В отношении персональных данных сохраняется их конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.
                        <br/>2.3.	Сайт не передает персональные данные третьим лицам, если только такая передача не предусмотрена законодательством Российской Федерации.
                        <br/>2.4.	Администрация Сайта принимает необходимые организационные и технические меры для защиты персональных данных от использования, не предусмотренного настоящим Пользовательским соглашением.
                    </p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">3.	Обязательства Пользователя</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        3.1.	Пользователь соглашается не предпринимать действий и не оставлять комментарии и записи, которые могут рассматриваться как нарушающие законодательство Российской Федерации или нормы международного права, в том числе в сфере интеллектуальной собственности, авторских и/или смежных прав, общепринятые нормы морали и нравственности, а также любых действий, которые приводят или могут привести к нарушению нормальной работы сервисов Сайта и Сайта в целом.
                        <br/>3.2.	Использование материалов Сайта без согласия правообладателей не допускается.              
                        <br/>3.3.	При цитировании материалов Сайта, включая охраняемые авторские произведения, ссылка на Сайт обязательна.
                        <br/>3.4.	Администрация Сайта не несет ответственности за посещение и использование Пользователем внешних ресурсов, ссылки на которые могут содержаться на Сайте.
                        <br/>3.5.	Администрация Сайта не несет ответственности и не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими убытками, связанными с любым содержанием Сайта, регистрацией авторских прав и сведениями о такой регистрации, доступными на Сайте или полученными через внешние сайты или ресурсы либо иные контакты Пользователя, в которые он вступил, используя размещенную на Сайте информацию или ссылки на внешние ресурсы.
                    </p>
                </div>

                <div className="desktop:mt-[40px] laptop:mt-[40px] tablet:mt-[20px] mobile:mt-[20px]">
                    <h4 className="text-blue desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-16 desktop:mb-[20px]laptop:mb-[20px] tablet:mb-[10px] mobile:mb-[10px]">4.	Прочие условия</h4>
                    <p className="desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-14 ">
                        4.1.	Все возможные споры, вытекающие из настоящего Пользовательского соглашения или связанные с ним, подлежат разрешению в соответствии с законодательством Российской Федерации.
                        <br/>4.2.	Бездействие со стороны Администрации Сайта в случае нарушения Пользователем положений Пользовательского соглашения не лишает Администрацию Сайта права предпринять позднее соответствующие действия в защиту своих интересов и защиту авторских прав на охраняемые в соответствии с законодательством материалы Сайта.  
                        <br/>4.3.	Администрация Сайта вправе в любое время в одностороннем порядке изменять условия настоящего Пользовательского соглашения. Такие изменения вступают в силу с момента размещения новой версии Пользовательского соглашения на сайте. При несогласии Пользователя с внесенными изменениями он обязан покинуть Сайт, прекратить использование материалов и сервисов Сайта.
                    </p>
                </div>
            </div>
        </div>
    )
}