import React from "react";
import { Accordion } from "react-accessible-accordion";
import { FaqsComponent } from "../../components/FaqsComponent";
import {useTranslation} from 'react-i18next';

export const Faqs = () => {
    const {t} = useTranslation();
    return (
        <div>
            <title>Портал психологов - FAQs</title>
            <div className='container mx-auto items-center content-center desktop:mt-[90px] laptop:mt-[70px] tablet:mt-[40px] mobile:mt-[20px]'>
                <div className="flex flex-row">
                    <div className="text-blue mobile:text-16 desktop:text-[50px] font-medium">{t('questions').split(' ')[0]}</div>
                    <div className="mobile:text-16 desktop:text-[50px] font-medium desktop:ml-[13px] mobile:ml-[5px]">{t('questions').substring(t('questions').indexOf(' '))}</div>
                </div>
            </div>
            <div className='container mx-auto items-center content-center desktop:mt-[130px] laptop:mt-[110px] tablet:mt-[80px] mobile:mt-[45px]'>
                <div className="desktop:w-3/5 laptop:w-3/4 tablet:w-full mobile:w-full">
                    <Accordion 
                        allowZeroExpanded={true}
                        allowMultipleExpanded={true}>
                        <FaqsComponent
                            num='1'
                            title='Как проходит консультация?'
                            text='Консультация проходит в форме беседы. Длится примерно от 45 минут до часа.'
                        />
                        <FaqsComponent
                            num='2'
                            title='Какие специалисты работают и принимают?'
                            text='В Центре работают дипломированные специалисты, имеющие опыт практической работы от трех лет. '
                        />
                        <FaqsComponent
                            num='3'
                            title='Характер запросов по работе с клиентами?'
                            text='Семейные и детско-родительские отношения, кризисные состояния, насилие, зависимость и отклоняющее поведение, социальная адаптация, психическое здоровье, личностное развитие.'
                        />
                        <FaqsComponent
                            num='4'
                            title='Принимают ли детей, как с ними проходит работы?'
                            text='Детей принимаем вместе с родителями либо законным представителем'
                        />
                        <FaqsComponent
                            num='5'
                            title='Скидки и стоимость индивидуальных консультаций?'
                            text='Стоимость одной консультации 3500 руб., скидка -50% для: пенсионеров, студентов, многодетных, малоимущих и инвалидов.'
                        />
                        <FaqsComponent
                            num='6'
                            title='Защита информации клиента?'
                            text='Информация клиента не подлежит разглашению, согласно этическому кодексу психолога, о конфиденциальности. '
                        />
                        <FaqsComponent
                            num='7'
                            title='Характер работы в семейном консультировании? '
                            text='Первая встреча с супругами проходит совместно, затем назначаются индивидуальные консультации для мужа и жены.'
                        />
                    </Accordion>
                </div>
            </div>
        </div>
    )
}