import React from "react";
import {
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItem
} from "react-accessible-accordion";

export const FaqsComponent = ({ title, text, num }) => {
    const [stateAcc, setStateAcc] = React.useState(false);

    //desktop: laptop: tablet: mobile:
    return (
        <AccordionItem

            key={num} className="desktop:px-[35px] laptop:px-[35px] tablet:px-[0px] mobile:px-[0px] py-[20px] mb-[15px] desktop:border-t-[1px] laptop:border-t-[1px] tablet:border-t-[0px] mobile:border-t-[0px] desktop:border-b-[0px] laptop:border-b-[0px] tablet:border-b-[1px] mobile:border-b-[1px]  desktop:rounded-[19px] laptop:rounded-[19px] tablet:rounded-[0px] mobile:rounded-[0px] desktop:border-black laptop:border-black tablet:border-grey mobile:border-grey">
            <AccordionItemHeading onClick={() => {
                setStateAcc(!stateAcc)
            }} >
                <AccordionItemButton
                    className="flex flex-row justify-between">
                    <div className="flex flex-row ">
                        <div className="desktop:flex laptop:flex tablet:hidden mobile:hidden text-blue text-20 pr-[34px]">{num}</div>
                        <div className="desktop:text-[20px] laptop:text-[20px] tablet:text-[14px] mobile:text-[14px]">{title}</div>
                    </div>
                    {/* if(allowZeroExpanded === true) {className=""} */}
                    <svg

                        className={`${stateAcc ? 'rotate-[270deg]' : 'rotate-90'} desktop:flex laptop:flex tablet:hidden mobile:hidden`}
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.5 18L28.5 18"
                            stroke="#3A7DFF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M18 7.5L28.5 18L18 28.5"
                            stroke="#3A7DFF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <svg className={`${stateAcc ? 'rotate-[180deg]' : 'rotate-0'} desktop:hidden laptop:hidden tablet:flex mobile:flex`}
                        width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1048_7556)">
                            <path d="M0.999999 6L10 14L19 6" stroke="#3A7DFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1048_7556">
                                <rect width="20" height="20" fill="white" transform="translate(20 20) rotate(180)" />
                            </clipPath>
                        </defs>
                    </svg>

                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="desktop:text-[16px] laptop:text-[16px] tablet:text-[12px] mobile:text-[12px] pt-[10px]">{text}</AccordionItemPanel>
        </AccordionItem>
    );
};

