import * as React from 'react';
import LinkTo from '../../assets/icons/link-to.svg';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export const useContainerDimensions = myRef => {
    const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 })
  
    React.useEffect(() => {
      const getDimensions = () => ({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight
      })
  
      const handleResize = () => {
        setDimensions(getDimensions())
      }
  
      if (myRef.current) {
        setDimensions(getDimensions())
      }
  
      window.addEventListener("resize", handleResize)
  
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [myRef])
  
    return dimensions;
  };

export const Tests = (props) => {
    const {} = props;
    const navigate = useNavigate();
    const {t} = useTranslation();
    const componentRef = React.useRef()
    const { width, height } = useContainerDimensions(componentRef)

    const [data] = React.useState([
        {
            name: 'Тест индекс жизненной удовлетворенности (ИЖУ) (адаптация Н.В. Паниной)',
            descr: 'Опросник, диагностирующий индекс жизненной удовлетворенности, отражает общее психологическое состояние человека, степень его психологического комфорта и социально-психологической адаптированности.'
        },
        {
            name: 'Тест на тип личности по Майерс-Бриггс (MBTI)',
            descr: 'О типологии личности Майерс-Бриггс написано дибо много и непонятно, либо мало и еще непонятнее. И, несмотря на то, что тест на ее определение нередко критикуется, он остается важным ключом для понимания характеристик личности.'
        },
        {
            name: 'Опросник Юнга',
            descr: 'На каждый вопрос имеется два варианта ответа, необходимо выбрать ответ, который подходит вам больше'
        }
    ]);

    return(
        <div ref={componentRef} className={styles.main} >
            <title>{t('test.title')}</title>
            <p className={styles.title}>{t('test.title').split(' ')[0]} <label className='text-black'>{t('test.title').substring(t('test.title').indexOf(' '))}</label></p>
            {/* <p className={styles.label}>Psycology is the scientific study of mind and behavior</p> */}
            {
                data.map((item, index) => 
                    <div className={styles.div(index)} onClick={() => {
                        if(width >= 640) {
                            navigate('/tests/' + (index + 1))
                        }
                        
                    }} key={index.toString()}>
                        <p className={styles.name}>{item.name}</p>
                        <p className={styles.descr}>{item.descr}</p>
                        <button 
                            className={styles.btnLaptop}
                            onClick={() => {
                                navigate('/tests/' + (index + 1));
                            }}
                        >
                            <img src={LinkTo} />
                        </button>
                        <button 
                            className={styles.btnMobile}
                            onClick={() => {
                                navigate('/tests/' + (index + 1));
                            }}
                        >Перейти</button>
                    </div>
                )
            }
        </div>
    );
};

const styles = {
    main: 'container mx-auto',
    title: 'text-[20px] laptop:text-[39px] font-medium text-blue mt-[30px]',
    label: 'text-[16px] laptop:text-[18px] text-grey mt-[20px]',
    div: (index) => `${index === 0? 'mt-[20px] laptop:mt-[60px] border-t-white laptop:border-t-blue border-t-[1px]': ''} grid grid-cols-1 laptop:grid-cols-6 py-[20px] gap-x-[90px] border-b-white laptop:border-b-blue border-b-[1px] pointer-events-auto`,
    name: 'col-span-1 laptop:col-span-2 text-[16px] laptop:text-[20px] font-medium',
    descr: 'col-span-1 laptop:col-span-3 text-[14px] laptop:text-[16px] font-regular mt-[20px] laptop:mt-0',
    btnLaptop: 'hidden laptop:flex col-span-1 flex flex-col items-end justify-center',
    btnMobile: 'flex laptop:hidden bg-blue mt-[20px] items-center justify-center h-[60px] rounded-[19px] text-white font-medium'
};