import * as React from 'react';

export const Input = (props) => {
    const {
        value,
        onChange,
        label,
        styleLabel,
        styleInput,
        style1,
        multiline
    } = props;
    const ref = React.useRef(null);

    if(multiline) {
        return(
            <div className={`${style1} border border-blue rounded-[19px] px-[53px] py-[12px] bg-white-input`} onClick={() => {ref.current?.focus(); }}>
                <p className={`${styleLabel} text-blue text-10 laptop:text-12 font-regular`}>{label}</p>
                <textarea
                    ref={ref}
                    rows={'4'}
                    value={value}
                    onChange={onChange}
                    className={`${styleInput} bg-white-input focus:outline-none mt-[6px] flex flex-1 w-full`}
                ></textarea>
            </div>
        )
    } else {
        return(
            <div className={`${style1} border border-blue rounded-[19px] px-[53px] py-[12px] bg-white-input`} onClick={() => {ref.current?.focus(); }}>
                <p className={`${styleLabel} text-blue text-10 laptop:text-12 font-regular`}>{label}</p>
                <input 
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    className={`${styleInput} text-14 laptop:text-16 text-black font-regular flex flex-1 mt-[6px] focus:outline-none w-full bg-white-input`}
                />
            </div>
        );
    }    
}