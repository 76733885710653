import React from "react";
import { ManagementComponent } from "../../components/ManagementComponent";
import {useTranslation} from 'react-i18next';
import { request } from "../../requests/requests";

const data = [
    {
        name: 'Скрябин Владимир Борисович',
        position: 'Директор',
        avatar: require('../../assets/icons/images/Vladimir.png')
    },
    {
        name: 'Загоренко Мария Николаевна',
        position: 'Заместитель директора по работе с муниципальными образованиями',
        avatar: require('../../assets/icons/images/Maria.png')
    },
    {
        name: 'Подголов Александр Григорьевич',
        position: 'Заместитель директора по организационным вопросам',
        avatar: require('../../assets/icons/images/Alex.png')
    },
    {
        name: 'Туприна Октябрина Ксенофонтовна',
        position: 'Заместитель директора по психологической работе',
        avatar: require('../../assets/icons/images/Octyabrina.png')
    },
]

export const Management = () => {
//     const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();
    
    const refRepeat = React.useRef(false);
    
//     React.useEffect(() => {
//         if(refRepeat.current === false) {
            
//             (async function() {
//                 try {
//                     setLoading(true);
//                     let response = await request({
//                         method: 'GET',
//                         url: '/api/heads',
//                         token: null,
//                         body: undefined
//                     });
//                     console.log('response', response);
//                     if(response.status === 200) {
//                         setData(response.data.data);
//                     }
//                 } catch(e) {

//                 } finally {
//                     setLoading(false);
//                 }
                
//             })()
//         }
//         return () => refRepeat.current = true;
//     }, []); 

    return (
        <div className="desktop:mt-[90px]">
            <title>Портал психологов - Руководство</title>
            <div className='container mx-auto items-center content-center'>
                <div><h1 className="mobile:text-16 tablet:text-16 laptop:text-[50px] desktop:text-[50px]">{t('aboutUs.management')}</h1></div>
            </div>
            {
                loading? 
                <div className="flex flex-1 h-[50vh] justify-center items-center">
                    <div className="text-blue spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                        <span className="visually-hidden">Загрузка...</span>
                    </div>
                </div> :
                <>
                    <div className='container mx-auto items-center content-center desktop:mt-[130px] laptop:mt-[110px] tablet:mt-[80px] mobile:mt-[45px]'>
                        <div className="grid desktop:grid-cols-3 laptop:grid-cols-2 mobile:grid-cols-1 desktop:gap-[67px] laptop:gap-[40px] tablet:gap-[20px] mobile:gap-[20px]">
                            {
                                data.map((item, index) =>
                                    <ManagementComponent
                                        avatar={item.avatar}
                                        name={item.name}
                                        key={index.toString()}
                                        position={item.position}
                                    />
                                )
                            }
                        </div>
                    </div>
                </>
            }   
            
        </div>
    )
}